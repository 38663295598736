import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string; 

  applicationSettings: ApplicationSettings;
}

export function Coinbase(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor href="https://www.coinbase.com/settings/api" target="_blank">
      <strong>Coinbase</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto og gå til <strong>Settings</strong>{" "}
        og derefter <strong>API access</strong>.
      </label>,
      <label className="instruction-label">
        Tryk på <strong>+ New API Key</strong>.
      </label>,
      <label className="instruction-label">
        Tilføj alle de af dine Coinbase accounts du gerne vil have integreret
        med CryptoSkat (vi anbefaler at trykke all, så vil alle dine Coinbase
        handelsdata blive integreret).
      </label>,
      <label className="instruction-label">
        Følgende rettigheder kræves:
        <br /> <strong>wallet:accounts:read</strong>,{" "}
        <strong>wallet:buys:read</strong>,{" "}
        <strong>wallet:payment-methods:read</strong>,{" "}
        <strong>wallet:sells:read</strong> og{" "}
        <strong>wallet:transactions:read</strong>
      </label>,
      <label className="instruction-label">
        Tryk på <strong>Save</strong>.
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account and navigate to{" "}
        <strong>Settings</strong> {"->"} <strong>API Access</strong>.
      </label>,
      <label className="instruction-label">
        Click <strong>+ New API Key</strong>.
      </label>,
      <label className="instruction-label">
        Enable all accounts you would like to check (we recommend to check all).
      </label>,
      <label className="instruction-label">
        The following privileges are required:
        <br /> <strong>wallet:accounts:read</strong>,{" "}
        <strong>wallet:buys:read</strong>,{" "}
        <strong>wallet:payment-methods:read</strong>,{" "}
        <strong>wallet:sells:read</strong> and{" "}
        <strong>wallet:transactions:read</strong>
      </label>,
      <label className="instruction-label">Save the key.</label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput applicationSettings={props.applicationSettings} platformId={props.platformId} />

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
