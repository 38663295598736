import { useMediaQuery } from "@mantine/hooks";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { Binance } from "./Instructions/exchanges/Binance";
import { Bitfinex } from "./Instructions/exchanges/Bitfinex";
import { Bitstamp } from "./Instructions/exchanges/Bitstamp";
import { Bittrex } from "./Instructions/exchanges/Bittrex";
import { Coinbase } from "./Instructions/exchanges/Coinbase";
import { FTX } from "./Instructions/exchanges/FTX";
import { Kraken } from "./Instructions/exchanges/Kraken";
import { Kucoin } from "./Instructions/exchanges/Kucoin";
import { CSV } from "./Instructions/manual/CSV";
import { Manual } from "./Instructions/manual/Manual";
import { Exodus } from "./Instructions/wallets/Exodus";
import { Ledger } from "./Instructions/wallets/Ledger";
import { Metamask } from "./Instructions/wallets/Metamask";
import { MyEtherWallet } from "./Instructions/wallets/MyEtherWallet";
import { Trezor } from "./Instructions/wallets/Trezor";
import { CustomFormatUpload } from "./Instructions/manual/CustomFormatUpload";

interface Props {
  applicationSettings: ApplicationSettings;
  platformName: string;
  platformId: string;
  platformType: string;
  handleLogout: () => Promise<void>;
  closeDrawer: () => void;
}

export function Instructions(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");

  const instructionComponents: any = {
    exchanges: {
      binance: (
        <Binance
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      bittrex: (
        <Bittrex
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      coinbase: (
        <Coinbase
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      kraken: (
        <Kraken
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      kucoin: (
        <Kucoin
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      bitstamp: (
        <Bitstamp
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      bitfinex: (
        <Bitfinex
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
      ftx: (
        <FTX
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
        />
      ),
    },
    wallets: {
      exodus: (
        <Exodus
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
          closeDrawer={props.closeDrawer}
        />
      ),
      ledger: (
        <Ledger
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
          closeDrawer={props.closeDrawer}
        />
      ),
      trezor: (
        <Trezor
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
          closeDrawer={props.closeDrawer}
        />
      ),
      metamask: (
        <Metamask
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
          closeDrawer={props.closeDrawer}
        />
      ),
      myetherwallet: (
        <MyEtherWallet
          applicationSettings={props.applicationSettings}
          platformId={props.platformId}
          closeDrawer={props.closeDrawer}
        />
      ),
    },
    manual: {
      csv: (
        <CSV
          closeDrawer={props.closeDrawer}
          applicationSettings={props.applicationSettings}
          handleLogout={props.handleLogout}
        />
      ),
      manual: (
        <Manual
          closeDrawer={props.closeDrawer}
          applicationSettings={props.applicationSettings}
          handleLogout={props.handleLogout}
        />
      ),
      penning: (
        <CustomFormatUpload
          closeDrawer={props.closeDrawer}
          applicationSettings={props.applicationSettings}
          handleLogout={props.handleLogout}
          customer="penning"
        />
      ),
      "bitcoin-suisse": (
        <CustomFormatUpload
          closeDrawer={props.closeDrawer}
          applicationSettings={props.applicationSettings}
          handleLogout={props.handleLogout}
          customer="bitcoin-suisse"
        />
      ),
    },
  };

  return <>{instructionComponents[props.platformType][props.platformName]}</>;
}
