import { Chip, Popover, Text } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useState } from "react";
import { FiFilter, FiX } from "react-icons/fi";
import { PollContext } from "../../../App";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { DateInterval } from "../../../interfaces/Report/DateInterval";
import {
  FilterExchange,
  TransactionFilters,
} from "../../../interfaces/Report/TransactionFilters";
import {
  SetFilterParametersContext,
  TransactionFilterParametersContext,
} from "../../../pages/ReportPage";
import {
  parseDateToFilterParameterDateString,
  parseFilterParameterDateStringToDate,
} from "../../../utils/formatter";
import {
  ClearFiltersLabel,
  PickCustomDateRangeLabel,
} from "../../../utils/labels";
import { transactionTypeIds } from "../../../utils/transactionTypeHelper";
import { isEqual } from "../../../utils/validation";
import { PlatformLogo } from "../../Import/Instructions/manual/PlatformLogo";
import { DepositHeader } from "../ReportSharedComponents/DepositHeader";
import { PlatformBadge } from "../ReportSharedComponents/PlatformBadge";
import { TradeHeader } from "../ReportSharedComponents/TradeHeader";
import { WithdrawalHeader } from "../ReportSharedComponents/WithdrawalHeader";
import { CancelledTransactionHeader } from "../ReportSharedComponents/CancelledTransactionHeader";
import { SpecialCustomerLogo } from "../../Import/Instructions/manual/CustomCustomerLogo";
import {
  getSpecialCustomerFromLowercase,
  getSpecialCustomers,
} from "../../../utils/specialCustomersUtil";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionFilters: TransactionFilters | undefined;
  walletsWithLogos: string[] | undefined;
  exchangesWithLogos: string[] | undefined;
  compact?: boolean;
}
export function ReportFilterMenu(props: React.PropsWithChildren<Props>) {
  const transactionFilterParameters = useContext(
    TransactionFilterParametersContext
  );
  const setTransactionFilterParameters = useContext(SetFilterParametersContext);
  const isPolling = useContext(PollContext);

  const [exchangeMenuOpened, setExchangeMenuOpened] = useState(false);
  const [transactionTypeMenuOpened, setTransactionTypeMenuOpened] =
    useState(false);

  const [filterPopoverOpened, setFilterPopoverOpened] = useState(false);

  const hasAnyFiltersEnabled = () => {
    if (!transactionFilterParameters) return false;
    return (
      transactionFilterParameters.transactionTypeId !== undefined ||
      transactionFilterParameters.currencyFromId !== undefined ||
      transactionFilterParameters.currencyToId !== undefined ||
      transactionFilterParameters.exchangeId !== undefined ||
      transactionFilterParameters.endDate !== undefined ||
      transactionFilterParameters.startDate !== undefined ||
      transactionFilterParameters.withErrors !== undefined
    );
  };

  const anyFiltersEnabled = hasAnyFiltersEnabled();
  const selectedExchange = props.transactionFilters?.exchanges.find(
    (exchange) =>
      exchange.exchangeId === transactionFilterParameters?.exchangeId
  );

  const selectedTransactionType = Object.keys(transactionTypeIds).find(
    (key) =>
      transactionTypeIds[key].toString() ===
      transactionFilterParameters?.transactionTypeId
  );

  const currentYear = new Date().getFullYear();
  const currentYearDateStart = new Date(currentYear, 0, 1);
  const currentYearDateEnd = new Date(currentYear, 11, 31);
  const previousYear = currentYear - 1;
  const previousYearDateStart = new Date(previousYear, 0, 1);
  const previousYearDateEnd = new Date(previousYear, 11, 31);
  const currentYearInterval: DateInterval = {
    startDate:
      parseDateToFilterParameterDateString(currentYearDateStart).split(" ")[0],
    endDate:
      parseDateToFilterParameterDateString(currentYearDateEnd).split(" ")[0],
  };
  const previousYearInterval: DateInterval = {
    startDate: parseDateToFilterParameterDateString(
      previousYearDateStart
    ).split(" ")[0],
    endDate:
      parseDateToFilterParameterDateString(previousYearDateEnd).split(" ")[0],
  };

  const currentSelectedDateInterval =
    transactionFilterParameters.startDate && transactionFilterParameters.endDate
      ? {
          startDate: transactionFilterParameters.startDate,
          endDate: transactionFilterParameters.endDate,
        }
      : undefined;

  const [customDateRange, setCustomDateRange] = useState<
    [Date | null, Date | null]
  >([
    transactionFilterParameters.startDate
      ? parseFilterParameterDateStringToDate(
          transactionFilterParameters.startDate
        )
      : null,
    transactionFilterParameters.endDate
      ? parseFilterParameterDateStringToDate(
          transactionFilterParameters.endDate
        )
      : null,
  ]);

  const handleCustomDateRangeChange = useCallback(
    (dateRange: [Date | null, Date | null]) => {
      setCustomDateRange(dateRange);
      if (dateRange[0] && dateRange[1]) {
        setTransactionFilterParameters({
          ...transactionFilterParameters,
          startDate: parseDateToFilterParameterDateString(dateRange[0]),
          endDate: parseDateToFilterParameterDateString(dateRange[1]),
        });
      }
    },
    [
      setCustomDateRange,
      setTransactionFilterParameters,
      transactionFilterParameters,
    ]
  );

  const lowercasePlatformNames = props.transactionFilters?.exchanges.map(
    (exchange: FilterExchange) => exchange.exchangeName.toLowerCase()
  );

  const today = new Date();

  const getPlatformIds = useCallback(() => {
    if (!props.transactionFilters?.exchanges) return;
    if (!lowercasePlatformNames) return;
    const _lowercasePlatformIds: { [key: string]: string } = {};

    for (let i = 0; i < lowercasePlatformNames.length; i++) {
      _lowercasePlatformIds[lowercasePlatformNames[i]] =
        props.transactionFilters?.exchanges[i].exchangeId;
    }

    return _lowercasePlatformIds;
  }, [lowercasePlatformNames, props.transactionFilters?.exchanges]);

  const lowercasePlatformIds: { [key: string]: string } | undefined =
    getPlatformIds();

  useEffect(() => {
    if (
      transactionFilterParameters.startDate &&
      transactionFilterParameters.endDate
    ) {
      setCustomDateRange([
        parseFilterParameterDateStringToDate(
          transactionFilterParameters.startDate
        ),
        parseFilterParameterDateStringToDate(
          transactionFilterParameters.endDate
        ),
      ]);
    } else {
      setCustomDateRange([null, null]);
    }
  }, [
    transactionFilterParameters.startDate,
    transactionFilterParameters.endDate,
  ]);

  const specialCustomers = getSpecialCustomers();
  const lowercaseSpecialCustomers = specialCustomers.map((customer) =>
    customer.toLowerCase()
  );
  const ExchangesAndWalletsFilterMenu = useCallback(() => {
    return (
      <div className="exchange-filter-selection-container">
        <Text color={"dimmed"}>
          <label>Exchange/Wallet</label>
        </Text>
        <Popover
          disabled={isPolling.reportUpdating}
          opened={exchangeMenuOpened}
          closeOnClickOutside
          position="bottom"
          target={
            selectedExchange?.exchangeName ? (
              <div
                className="selected-filter-container"
                onClick={() =>
                  setTransactionFilterParameters({
                    ...transactionFilterParameters,
                    exchangeId: undefined,
                  })
                }
              >
                {props.exchangesWithLogos?.includes(
                  selectedExchange.exchangeName.toLowerCase()
                ) ? (
                  <PlatformLogo
                    platform={"exchanges"}
                    compact={false}
                    title={selectedExchange.exchangeName.toLowerCase()}
                    activeTheme={props.applicationSettings.theme}
                  />
                ) : props.walletsWithLogos?.includes(
                    selectedExchange.exchangeName.toLowerCase()
                  ) ? (
                  <PlatformLogo
                    platform={"wallets"}
                    compact={false}
                    title={selectedExchange.exchangeName.toLowerCase()}
                    activeTheme={props.applicationSettings.theme}
                  />
                ) : specialCustomers.includes(selectedExchange.exchangeName) ? (
                  <SpecialCustomerLogo
                    specialCustomer={selectedExchange.exchangeName}
                  />
                ) : (
                  <PlatformBadge
                    platformName={selectedExchange.exchangeName.toLowerCase()}
                    applicationSettings={props.applicationSettings}
                  />
                )}
              </div>
            ) : (
              <div
                className="unselected-filter-container"
                onClick={() => setExchangeMenuOpened(!exchangeMenuOpened)}
              >
                <Text color={"dimmed"}>
                  <label>
                    {props.applicationSettings.locale === "en"
                      ? "(click to select)"
                      : "(vælg filter)"}
                  </label>
                </Text>
              </div>
            )
          }
        >
          {lowercasePlatformIds && (
            <div
              className="filter-popover-container"
              onMouseLeave={() => setExchangeMenuOpened(false)}
            >
              {Object.keys(lowercasePlatformIds).map(
                (lowercasePlatformName: string, index: number) => {
                  if (
                    props.exchangesWithLogos?.includes(lowercasePlatformName)
                  ) {
                    return (
                      <div
                        className="filter-platform-item"
                        key={index}
                        onClick={() => {
                          setTransactionFilterParameters({
                            ...transactionFilterParameters,
                            exchangeId:
                              lowercasePlatformIds[lowercasePlatformName],
                          });
                          setExchangeMenuOpened(false);
                        }}
                      >
                        <PlatformLogo
                          platform={"exchanges"}
                          compact={false}
                          title={lowercasePlatformName}
                          activeTheme={props.applicationSettings.theme}
                        />
                      </div>
                    );
                  } else if (
                    props.walletsWithLogos?.includes(lowercasePlatformName)
                  ) {
                    return (
                      <div
                        className="filter-platform-item"
                        key={index}
                        onClick={() => {
                          setTransactionFilterParameters({
                            ...transactionFilterParameters,
                            exchangeId:
                              lowercasePlatformIds[lowercasePlatformName],
                          });
                          setExchangeMenuOpened(false);
                        }}
                      >
                        <PlatformLogo
                          platform={"wallets"}
                          compact={false}
                          title={lowercasePlatformName}
                          activeTheme={props.applicationSettings.theme}
                        />
                      </div>
                    );
                  } else if (
                    lowercaseSpecialCustomers.includes(lowercasePlatformName)
                  ) {
                    return (
                      <div
                        className="filter-platform-item"
                        key={index}
                        onClick={() => {
                          setTransactionFilterParameters({
                            ...transactionFilterParameters,
                            exchangeId:
                              lowercasePlatformIds[lowercasePlatformName],
                          });
                          setExchangeMenuOpened(false);
                        }}
                      >
                        <SpecialCustomerLogo
                          specialCustomer={getSpecialCustomerFromLowercase(
                            lowercasePlatformName
                          )}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="filter-platform-item"
                        key={index}
                        onClick={() => {
                          setTransactionFilterParameters({
                            ...transactionFilterParameters,
                            exchangeId:
                              lowercasePlatformIds[lowercasePlatformName],
                          });
                          setExchangeMenuOpened(false);
                        }}
                      >
                        <PlatformBadge
                          platformName={lowercasePlatformName}
                          applicationSettings={props.applicationSettings}
                        />
                      </div>
                    );
                  }
                }
              )}
            </div>
          )}
        </Popover>
      </div>
    );
  }, [
    isPolling.reportUpdating,
    exchangeMenuOpened,
    selectedExchange,
    transactionFilterParameters,
    setTransactionFilterParameters,
    props.exchangesWithLogos,
    props.walletsWithLogos,
    lowercasePlatformIds,
    setExchangeMenuOpened,
    props.applicationSettings,
    specialCustomers,
  ]);

  return (
    <>
      {props.compact ? (
        <Popover
          opened={filterPopoverOpened}
          closeOnClickOutside={true}
          closeOnEscape={true}
          clickOutsideEvents={["mousedown", "touchstart"]}
          classNames={{
            target: "compact-filter-popover-target",
          }}
          target={
            <>
              {filterPopoverOpened ? (
                <FiX
                  size={20}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setFilterPopoverOpened(!filterPopoverOpened);
                  }}
                />
              ) : (
                <FiFilter
                  size={20}
                  opacity={anyFiltersEnabled ? 1 : 0.3}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setFilterPopoverOpened(!filterPopoverOpened);
                  }}
                />
              )}
            </>
          }
        >
          <div
            className="compact-report-filter-menu-container"
            onMouseLeave={() => {
              setExchangeMenuOpened(false);
              setTransactionTypeMenuOpened(false);
            }}
          >
            {ExchangesAndWalletsFilterMenu()}
            {TransactionTypeFilterMenu()}
            <div className="date-interval-filter-selection-container">
              <Chip
                classNames={{
                  checked: "date-filter-menu-item-checked",
                  root: "date-filter-menu-item",
                  filled: "date-filter-menu-item-filled",
                }}
                color={
                  props.applicationSettings.theme === "light" ? "dark" : "gray"
                }
                disabled={isPolling.reportUpdating}
                radius={"sm"}
                onClick={() => {
                  currentSelectedDateInterval === undefined ||
                  !isEqual(currentSelectedDateInterval, currentYearInterval)
                    ? setTransactionFilterParameters({
                        ...transactionFilterParameters,
                        startDate: currentYearInterval.startDate,
                        endDate: currentYearInterval.endDate,
                      })
                    : setTransactionFilterParameters({
                        ...transactionFilterParameters,
                        startDate: undefined,
                        endDate: undefined,
                      });
                }}
                checked={
                  currentSelectedDateInterval
                    ? isEqual(currentSelectedDateInterval, currentYearInterval)
                    : false
                }
              >
                {currentYear}
              </Chip>
              <Chip
                classNames={{
                  checked: "date-filter-menu-item-checked",
                  root: "date-filter-menu-item",
                  filled: "date-filter-menu-item-filled",
                }}
                disabled={isPolling.reportUpdating}
                color={
                  props.applicationSettings.theme === "light" ? "dark" : "gray"
                }
                radius={"sm"}
                onClick={() => {
                  currentSelectedDateInterval === undefined ||
                  !isEqual(currentSelectedDateInterval, previousYearInterval)
                    ? setTransactionFilterParameters({
                        ...transactionFilterParameters,
                        startDate: previousYearInterval.startDate,
                        endDate: previousYearInterval.endDate,
                      })
                    : setTransactionFilterParameters({
                        ...transactionFilterParameters,
                        startDate: undefined,
                        endDate: undefined,
                      });
                }}
                checked={
                  currentSelectedDateInterval
                    ? isEqual(currentSelectedDateInterval, previousYearInterval)
                    : false
                }
              >
                {previousYear}
              </Chip>
              <DateRangePicker
                maxDate={today}
                disabled={isPolling.reportUpdating}
                className="calendar-filter-menu-item"
                classNames={{
                  selected: "calendar-filter-menu-item",
                  input: "calendar-filter-menu-item-input",
                  calendarBase: "calendar-filter-menu-item",
                  arrow: "calendar-filter-menu-item",
                  dropdown: "calendar-filter-menu-item",
                }}
                size="xs"
                placeholder={
                  PickCustomDateRangeLabel[props.applicationSettings.locale]
                }
                inputFormat="YYYY/MM/DD"
                value={customDateRange}
                onChange={handleCustomDateRangeChange}
              />
            </div>
            {anyFiltersEnabled && !isPolling.reportUpdating && (
              <motion.div
                initial={{ scale: 0, y: 12, x: "100%" }}
                animate={{ scale: 1, y: 2, x: "100%" }}
                transition={{ duration: 0.15, delay: 0.3 }}
                className="reset-filters-container"
                onClick={() => {
                  if (isPolling.reportUpdating) return;
                  setTransactionFilterParameters({
                    page: "1",
                  });
                }}
              >
                <FiX />
                <label>
                  {ClearFiltersLabel[props.applicationSettings.locale]}
                </label>
              </motion.div>
            )}
          </div>
        </Popover>
      ) : (
        <div
          className="report-filter-menu-container"
          onMouseLeave={() => {
            setExchangeMenuOpened(false);
            setTransactionTypeMenuOpened(false);
          }}
        >
          {ExchangesAndWalletsFilterMenu()}
          {TransactionTypeFilterMenu()}
          <div className="date-interval-filter-selection-container">
            <Chip
              classNames={{
                checked: "date-filter-menu-item-checked",
                root: "date-filter-menu-item",
                filled: "date-filter-menu-item-filled",
              }}
              color={
                props.applicationSettings.theme === "light" ? "dark" : "gray"
              }
              disabled={isPolling.reportUpdating}
              radius={"sm"}
              onClick={() => {
                currentSelectedDateInterval === undefined ||
                !isEqual(currentSelectedDateInterval, currentYearInterval)
                  ? setTransactionFilterParameters({
                      ...transactionFilterParameters,
                      startDate: currentYearInterval.startDate,
                      endDate: currentYearInterval.endDate,
                    })
                  : setTransactionFilterParameters({
                      ...transactionFilterParameters,
                      startDate: undefined,
                      endDate: undefined,
                    });
              }}
              checked={
                currentSelectedDateInterval
                  ? isEqual(currentSelectedDateInterval, currentYearInterval)
                  : false
              }
            >
              {currentYear}
            </Chip>
            <Chip
              classNames={{
                checked: "date-filter-menu-item-checked",
                root: "date-filter-menu-item",
                filled: "date-filter-menu-item-filled",
              }}
              disabled={isPolling.reportUpdating}
              color={
                props.applicationSettings.theme === "light" ? "dark" : "gray"
              }
              radius={"sm"}
              onClick={() => {
                currentSelectedDateInterval === undefined ||
                !isEqual(currentSelectedDateInterval, previousYearInterval)
                  ? setTransactionFilterParameters({
                      ...transactionFilterParameters,
                      startDate: previousYearInterval.startDate,
                      endDate: previousYearInterval.endDate,
                    })
                  : setTransactionFilterParameters({
                      ...transactionFilterParameters,
                      startDate: undefined,
                      endDate: undefined,
                    });
              }}
              checked={
                currentSelectedDateInterval
                  ? isEqual(currentSelectedDateInterval, previousYearInterval)
                  : false
              }
            >
              {previousYear}
            </Chip>
            <DateRangePicker
              maxDate={today}
              disabled={isPolling.reportUpdating}
              className="calendar-filter-menu-item"
              classNames={{
                selected: "calendar-filter-menu-item",
                input: "calendar-filter-menu-item-input",
                calendarBase: "calendar-filter-menu-item",
                arrow: "calendar-filter-menu-item",
                dropdown: "calendar-filter-menu-item",
              }}
              size="xs"
              placeholder={
                PickCustomDateRangeLabel[props.applicationSettings.locale]
              }
              inputFormat="YYYY-MM-DD"
              value={customDateRange}
              onChange={handleCustomDateRangeChange}
            />
          </div>
          {anyFiltersEnabled && !isPolling.reportUpdating && (
            <motion.div
              initial={{ scale: 0, y: 12, x: "100%" }}
              animate={{ scale: 1, y: "-100%", x: "100%" }}
              transition={{ duration: 0.15, delay: 0.3 }}
              className="reset-filters-container"
              onClick={() => {
                if (isPolling.reportUpdating) return;
                setTransactionFilterParameters({
                  page: "1",
                });
              }}
            >
              <FiX />
              <label>
                {ClearFiltersLabel[props.applicationSettings.locale]}
              </label>
            </motion.div>
          )}
        </div>
      )}
    </>
  );

  function TransactionTypeFilterMenu() {
    return (
      <div className="transaction-type-filter-selection-container">
        <Text color={"dimmed"}>
          <label>
            {props.applicationSettings.locale === "en"
              ? "Transaction type"
              : "Transaktionstype"}
          </label>
        </Text>
        <Popover
          opened={transactionTypeMenuOpened}
          disabled={isPolling.reportUpdating}
          closeOnClickOutside={true}
          position="bottom"
          target={
            selectedTransactionType ? (
              <div
                className="selected-filter-container"
                onClick={() =>
                  setTransactionFilterParameters({
                    ...transactionFilterParameters,
                    transactionTypeId: undefined,
                  })
                }
              >
                {selectedTransactionType.toLowerCase() === "deposit" && (
                  <DepositHeader
                    focus
                    applicationSettings={props.applicationSettings}
                  />
                )}
                {selectedTransactionType.toLowerCase() === "trade" && (
                  <TradeHeader
                    focus
                    applicationSettings={props.applicationSettings}
                  />
                )}
                {selectedTransactionType.toLowerCase() === "withdrawal" && (
                  <WithdrawalHeader
                    focus
                    applicationSettings={props.applicationSettings}
                  />
                )}
                {selectedTransactionType.toLowerCase() === "cancelled" && (
                  <CancelledTransactionHeader
                    focus
                    applicationSettings={props.applicationSettings}
                  />
                )}
              </div>
            ) : (
              <div
                className="unselected-filter-container"
                onClick={() =>
                  setTransactionTypeMenuOpened(!transactionTypeMenuOpened)
                }
              >
                <Text color={"dimmed"}>
                  <label>
                    {props.applicationSettings.locale === "en"
                      ? "(click to select)"
                      : "(vælg filter)"}
                  </label>
                </Text>
              </div>
            )
          }
        >
          <div
            className="filter-popover-container"
            onMouseLeave={() => setTransactionTypeMenuOpened(false)}
          >
            {Object.keys(transactionTypeIds)?.map(
              (transactionType: string, index: number) => (
                <div
                  className="filter-popover-item"
                  key={index}
                  onClick={() => {
                    if (isPolling.reportUpdating) return;
                    setTransactionFilterParameters({
                      ...transactionFilterParameters,
                      transactionTypeId:
                        transactionTypeIds[transactionType].toString(),
                    });
                    setTransactionTypeMenuOpened(false);
                  }}
                >
                  {transactionType.toLowerCase() === "deposit" && (
                    <DepositHeader
                      focus
                      applicationSettings={props.applicationSettings}
                    />
                  )}
                  {transactionType.toLowerCase() === "trade" && (
                    <TradeHeader
                      focus
                      applicationSettings={props.applicationSettings}
                    />
                  )}
                  {transactionType.toLowerCase() === "withdrawal" && (
                    <WithdrawalHeader
                      focus
                      applicationSettings={props.applicationSettings}
                    />
                  )}
                  {transactionType.toLowerCase() === "cancelled" && (
                    <CancelledTransactionHeader
                      focus
                      applicationSettings={props.applicationSettings}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </Popover>
      </div>
    );
  }
}
