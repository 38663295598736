import { Modal, Popover, Text } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import { FiDownloadCloud, FiHash, FiInfo } from "react-icons/fi";
import { ServiceCodes } from "../../../interfaces/Report/TransactionErrors";
import { ServiceCodeContext } from "../../../pages/ReportPage";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
  serviceCodes: number[];
  errorCodes: number[];
  enableLinkMode: () => void;
  handleFix: () => void;
}
export function TransactionServiceIndicator(
  props: React.PropsWithChildren<Props>
) {
  const serviceCodes: ServiceCodes | undefined = useContext(ServiceCodeContext);

  const [opened, setOpened] = useState(false);
  const [descriptionCode, setDescriptionCode] = useState<number | undefined>(
    undefined
  );

  const [modalOpened, setModalOpened] = useState(false);

  const getTransactionTextFromCode = useCallback(
    (code: string): string | undefined => {
      if (!serviceCodes) return;
      if (!serviceCodes.service_codes) return;
      const serviceCode = serviceCodes.service_codes.find(
        (serviceCode: any) => serviceCode.key.toString() === code
      );
      return serviceCode?.text[props.applicationSettings.locale];
    },
    [serviceCodes, props.applicationSettings]
  );
  const getTransactionDescriptionFromCode = useCallback(
    (code: number): string | undefined => {
      if (!serviceCodes) return;
      if (!serviceCodes.service_codes) return;
      const serviceCode = serviceCodes.service_codes.find(
        (serviceCode: any) => serviceCode.key === code
      );
      return serviceCode?.description[props.applicationSettings.locale];
    },
    [serviceCodes]
  );

  const sharedActions = useCallback(() => {
    setModalOpened(true);
    setOpened(false);
  }, [setModalOpened, setOpened]);

  const onClickDescriptions: { [k: string]: (e: any) => void } = {
    "1": (e: any) => {
      e.stopPropagation();
      sharedActions();
      setDescriptionCode(descriptionCode === 1 ? undefined : 1);
    },
    "2": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 2 ? undefined : 2);
    },
    "3": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 3 ? undefined : 3);
    },
    "4": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 4 ? undefined : 4);
    },
    "5": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 5 ? undefined : 5);
    },
    "6": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 6 ? undefined : 6);
    },
    "7": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 7 ? undefined : 7);
    },
    "8": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 8 ? undefined : 8);
    },
    "9": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 9 ? undefined : 9);
    },
    "10": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 10 ? undefined : 10);
    },
    "11": (e: any) => {
      e.stopPropagation();
      sharedActions();

      setDescriptionCode(descriptionCode === 11 ? undefined : 11);
    },
  };
  const onClickActions: { [k: string]: (e: any) => void } = {
    "1": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "2": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "3": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "4": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "5": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "6": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "7": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "8": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "9": (e: any) => {
      e.stopPropagation();
      props.handleFix();
      setOpened(false);
      setModalOpened(false);
    },
    "10": (e: any) => {
      e.stopPropagation();
      props.enableLinkMode();
      setOpened(false);
      setModalOpened(false);
    },
    "11": (e: any) => {
      e.stopPropagation();
      props.enableLinkMode();
      setOpened(false);
      setModalOpened(false);
    },
  };

  const serviceIcons: any = {
    "1": <FiDownloadCloud />,
    "2": <FiDownloadCloud />,
    "3": <FiDownloadCloud />,
    "4": <FiHash />,
  };

  const [autoCloseEnabled, setAutoCloseEnabled] = useState(false);

  return (
    <>
      <Popover
        opened={opened}
        onClose={() => {
          setOpened(false);
          setDescriptionCode(undefined);
        }}
        target={
          <FiInfo
            size={20}
            onClick={(e: any) => {
              e.stopPropagation();
              setOpened(!opened);
            }}
          />
        }
        spacing={4}
        closeOnClickOutside
        onScrollCapture={() => setOpened(false)}
        onMouseLeave={() => {
          if (autoCloseEnabled) {
            setOpened(false);
            setAutoCloseEnabled(false);
          }
        }}
        onMouseEnter={() => {
          setTimeout(() => setAutoCloseEnabled(true), 2000);
        }}
        closeOnEscape={true}
        shadow="xl"
        position="bottom"
        transition="fade"
        gutter={-5}
        withArrow
        className="transaction-service-indicator-popover"
        classNames={{
          target: "transaction-service-indicator-popover-target",
        }}
      >
        <div className="error-fix-button-array">
          {props.serviceCodes.map((code, idx) => (
            <div key={idx}>
              {code && (
                <div
                  className="transaction-popover-button-menu"
                  key={idx}
                  id="service-menu"
                >
                  <div
                    className="transaction-popover-button-menu-row"
                    id="service-menu"
                    key={`service-${idx}-${code}`}
                    onClick={onClickDescriptions[code]}
                  >
                    {serviceIcons[code]}
                    <label key={`label-${idx}`}>
                      {getTransactionTextFromCode(code.toString())}
                    </label>
                  </div>
                  <div
                    className="transaction-popover-button-menu-row"
                    id="service-menu-fix-button"
                    key={`service-${idx}`}
                    onClick={onClickActions[code]}
                  >
                    <label key={`label-${idx}`}>fix</label>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Popover>
      <Modal
        opened={descriptionCode !== undefined && modalOpened}
        centered
        onClose={() => {
          setModalOpened(false);
          setDescriptionCode(undefined);
        }}
        title={
          descriptionCode
            ? getTransactionTextFromCode(descriptionCode.toString())
            : ""
        }
        overlayBlur={2}
      >
        {descriptionCode && (
          <div className="description-container">
            <Text color={"dimmed"}>
              <label>
                {getTransactionDescriptionFromCode(descriptionCode)}
              </label>
            </Text>
          </div>
        )}
      </Modal>
    </>
  );
}
