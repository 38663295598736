import { Button, PasswordInput, Space, TextInput } from "@mantine/core";
import { motion } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { SetResetPhaseContext, TriggerNotificationContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import AuthService from "../../service/AuthService";
import {
  CheckYourEmailLabel,
  EmailHasBeenSentWithAResetLinkLabel,
  EnterEmailForResetLabel,
  ResetPasswordLabel,
} from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function ResetPasswordRequestForm(
  props: React.PropsWithChildren<Props>
) {
  const setResetPhase = useContext(SetResetPhaseContext);
  const triggerNotification = useContext(TriggerNotificationContext);

  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>();
  const [didSendEmail, setDidSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailIsValid = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

  const handleClick = useCallback(async () => {
    setLoading(true);
    try {
      await AuthService.requestResetPassword(email);
      setResetPhase(true);
      triggerNotification(
        [
          EmailHasBeenSentWithAResetLinkLabel[props.applicationSettings.locale],
          CheckYourEmailLabel[props.applicationSettings.locale],
        ],
        "info"
      );
      setDidSendEmail(true);
    } catch (error: any) {
      const message = error.response.data.message;
      triggerNotification(message, "error");
    } finally {
      setLoading(false);
    }
  }, [
    email,
    setError,
    setResetPhase,
    triggerNotification,
    setDidSendEmail,
    setLoading,
  ]);

  return (
    <div className="login-registration-form-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75 }}
      >
        {didSendEmail ? (
          <label>A link has been sent to your email</label>
        ) : (
          <div className="reset-form-container">
            <TextInput
              className="input-field"
              id="email-input"
              placeholder="Email"
              label={EnterEmailForResetLabel[props.applicationSettings.locale]}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              rightSection={
                <FiCheck
                  className={emailIsValid ? "checkmark-valid" : "checkmark"}
                />
              }
              required
            />
            <Space w={"lg"} />
            {error ? <label>{error}</label> : <br />}
            <Button
              className="cs-theme-button"
              radius={"xs"}
              disabled={!emailIsValid}
              onClick={handleClick}
              loading={loading}
            >
              {ResetPasswordLabel[props.applicationSettings.locale]}
            </Button>
          </div>
        )}
      </motion.div>
    </div>
  );
}
