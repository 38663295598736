import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string; 

  applicationSettings: ApplicationSettings;
}

export function Kraken(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor href="https://www.kraken.com/" target="_blank">
      <strong>Kraken</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto og gå til <strong>Settings</strong>{" "}
        og derefter <strong>API</strong>,
      </label>,
      <label className="instruction-label">
        Generér en ny API nøgle og tildel kun følgende rettigheder: <br />
        <strong>Query Ledger Entries</strong>, <strong>Query Funds</strong> og{" "}
        <strong>Export Data</strong>.
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account and navigate to
        <strong>Settings</strong> {"->"} <strong>API</strong>.
      </label>,

      <label className="instruction-label">
        Generate a new API key and only select the following privileges: <br />
        <strong>Query Ledger Entries</strong>, <strong>Query Funds</strong> and{" "}
        <strong>Export Data</strong>.
      </label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput applicationSettings={props.applicationSettings}  platformId={props.platformId}/>

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
