import { useMediaQuery } from "@mantine/hooks";
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { CompactMenu } from "./CompactMenu";
import { TopMenu } from "./TopMenu";

interface Props {
  handleSwitchLocale: () => void;
  handleSwitchTheme: () => void;
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}
export function MenuComponent(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    await props.handleLogout();
    navigate("/login");
    window.location.reload();
  }, [props, navigate]);

  const compact = useMediaQuery("(max-width: 768px)");
  if (compact) {
    return (
      <CompactMenu
        handleSwitchTheme={props.handleSwitchTheme}
        handleSwitchLocale={props.handleSwitchLocale}
        applicationSettings={props.applicationSettings}
        authenticationData={authenticationData}
        handleLogout={handleLogout}
      />
    );
  }
  return (
    <TopMenu
      applicationSettings={props.applicationSettings}
      authenticationData={authenticationData}
      handleLogout={handleLogout}
    />
  );
}
