import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
  name: string;
  id: string;
  type: string;
  index: number;
  handlePlatformClick: (
    type: string,
    platformName: string,
    platformId: string
  ) => void;
}
export function ImportItem(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");
  const smallScreen = useMediaQuery("(max-height: 768px)");
  const url = `url(/assets/${props.type}/${props.name}/normal/${props.name}-${props.applicationSettings.theme}.svg)`;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: .85, scale: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
      whileHover={{  opacity: 1, scale: 1.2 }}
      className="import-logo-container"
      style={{
        backgroundImage: url,
        height: compact || smallScreen ? "1.5rem" : `3rem`,
        width: compact || smallScreen ? "4rem" : `8rem`,
      }}
      onClick={() =>
        props.handlePlatformClick(props.type, props.name, props.id)
      }
    ></motion.div>
  );
}
