import "./voucher-content.scss";
import MembershipService from "../../../service/MembershipService";
import { motion } from "framer-motion";
import { useContext, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { AuthenticationContext } from "../../../App";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { PlanToBuy, PricePlan } from "../../../interfaces/Membership/PricePlan";
import { Button, Space } from "@mantine/core";
import {
  BuyLabel,
  ContinueToPaymentLabel,
  SubmitVoucherLabel,
  VatLabel,
  VoucherAppliedLabel,
  VoucherNotFound,
} from "../../../utils/labels";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { VoucherPrices } from "../../../interfaces/Membership/VoucherPrices";
import { darkTheme, lightTheme } from "../../../style/Themes";

interface Props {
  applicationSettings: ApplicationSettings;
  showVoucherField: boolean;
  voucherCode?: string;
  selectedPlan: PlanToBuy;
  setVoucherCode: (voucherCode: string) => void;
  setVoucherPhase: (voucherPhase: boolean) => void;
  setPayButtonLabel: (payButtonLabel: string) => void;
}
export function VoucherContent(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const [voucherInput, setVoucherInput] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [voucherPrices, setVoucherPrices] = useState<VoucherPrices | undefined>(
    undefined
  );

  const theme =
    props.applicationSettings.theme === "dark" ? darkTheme : lightTheme;

  const [submitVoucherLoading, setSubmitVoucherLoading] =
    useState<boolean>(false);

  const getPricePlan = useCallback(async () => {
    if (!authenticationData) return;
    return (await MembershipService.getPricePlan(
      authenticationData.token,
      props.selectedPlan.planHash
    )) as PricePlan;
  }, [authenticationData, props.selectedPlan]);

  const pricePlanQuery = useQuery("pricePlan", getPricePlan, {
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const handleSubmitVoucher = useCallback(async () => {
    if (!authenticationData) return;
    if (voucherInput.length === 0) return;
    setSubmitVoucherLoading(true);
    try {
      const response = await MembershipService.getPricePlanWithVoucher(
        authenticationData.token,
        props.selectedPlan.planId,
        voucherInput
      );
      if (response) {
        setVoucherPrices(response.data);
        props.setVoucherCode(voucherInput);
      }
      setShowError(false);
    } catch (error) {
      setShowError(true);
    } finally {
      setSubmitVoucherLoading(false);
      setVoucherInput("");
    }
  }, [
    setSubmitVoucherLoading,
    props,
    authenticationData,
    voucherInput,
    setVoucherInput,
    setShowError,
    setVoucherPrices,
  ]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="voucher-content"
    >
      {props.voucherCode && voucherPrices ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="voucher-applied-container"
        >
          <label>{VoucherAppliedLabel[props.applicationSettings.locale]}</label>
          <FiCheck size={22} />
        </motion.div>
      ) : (
        <>
          {props.showVoucherField && (
            <div className="voucher-input-container">
              <input
                type="text"
                placeholder="Voucher"
                className="voucher-input"
                value={voucherInput}
                onChange={(e) => setVoucherInput(e.target.value)}
              />
              <Button
                className="cs-theme-button"
                radius={"xs"}
                disabled={voucherInput.length === 0}
                loading={submitVoucherLoading}
                onClick={handleSubmitVoucher}
              >
                {SubmitVoucherLabel[props.applicationSettings.locale]}
              </Button>
            </div>
          )}
        </>
      )}

      {showError ? (
        <div className="error-label-container">
          <label>{VoucherNotFound[props.applicationSettings.locale]}</label>
        </div>
      ) : (
        <br />
      )}
      <div className="voucher-content-divider" />

      {pricePlanQuery.data && (
        <div className="price-plan-container">
          <div className="price-plan-row">
            <label>Voucher discount</label>
            <div className="price-plan-prices-row">
              {voucherPrices?.voucherDiscount && (
                <motion.div
                  className="discount-label-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <label id="new-value" style={{ color: theme.discountColor }}>
                    {voucherPrices.voucherDiscount} DKK
                  </label>
                </motion.div>
              )}
              <label id={voucherPrices?.voucherDiscount ? "old-value" : ""}>
                0 DKK
              </label>
            </div>
          </div>
          <div className="price-plan-row" id="price-plan-row-alt">
            <label>Subtotal</label>
            <div className="price-plan-prices-row">
              {voucherPrices?.subtotal && (
                <motion.div
                  className="discount-label-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.15 }}
                >
                  <label id="new-value" style={{ color: theme.discountColor }}>
                    {voucherPrices.subtotal} DKK
                  </label>
                </motion.div>
              )}
              <label id={voucherPrices?.voucherDiscount ? "old-value" : ""}>
                {pricePlanQuery.data.subtotal} DKK
              </label>
            </div>
          </div>
          <div className="price-plan-row">
            <label>{VatLabel[props.applicationSettings.locale]} (25%)</label>
            <div className="price-plan-prices-row">
              {voucherPrices?.vat && (
                <motion.div
                  className="discount-label-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <label id="new-value" style={{ color: theme.discountColor }}>
                    {voucherPrices.vat} DKK
                  </label>
                </motion.div>
              )}
              <label id={voucherPrices?.vat ? "old-value" : ""}>
                {pricePlanQuery.data.vat} DKK
              </label>
            </div>
          </div>
          <div className="price-plan-row" id="price-plan-row-alt">
            <label>Total</label>
            <div className="price-plan-prices-row">
              {voucherPrices?.newPrice && (
                <motion.div
                  className="discount-label-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.45 }}
                >
                  <label id="new-value" style={{ color: theme.discountColor }}>
                    {voucherPrices.newPrice} DKK
                  </label>
                </motion.div>
              )}
              <label id={voucherPrices?.newPrice ? "old-value" : ""}>
                {props.selectedPlan.planPreviewPrice ??
                  pricePlanQuery.data.price}{" "}
                DKK
              </label>
            </div>
          </div>

          <Space h={"lg"} />

          <Space h={"lg"} />
          {pricePlanQuery.data && pricePlanQuery.data.price && (
            <Button
              className="cs-theme-button"
              radius={"xs"}
              size={"lg"}
              loading={submitVoucherLoading}
              leftIcon={<FiArrowRight />}
              onClick={() => {
                props.setPayButtonLabel(`${
                  BuyLabel[props.applicationSettings.locale]
                } ${props.selectedPlan.planTitle} Plan
              | ${
                voucherPrices?.newPrice ??
                props.selectedPlan.planPreviewPrice ??
                pricePlanQuery.data!.price
              } DKK`);
                props.setVoucherPhase(false);
              }}
            >
              {ContinueToPaymentLabel[props.applicationSettings.locale]}
            </Button>
          )}
        </div>
      )}
    </motion.div>
  );
}
