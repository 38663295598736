import { useContext, useEffect } from "react";
import { RiDashboardFill } from "react-icons/ri";
import { CgImport } from "react-icons/cg";
import { BiTransfer } from "react-icons/bi";
import { MdAccountTree } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthenticationContext, SetAuthenticationDataContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AccountPage } from "../../pages/AccountPage";
import { DashboardPage } from "../../pages/DashboardPage";
import { ImportPage } from "../../pages/ImportPage";
import { LandingPage } from "../../pages/LandingPage";
import { LoginPage } from "../../pages/LoginPage";
import { PageContainer } from "../../pages/PageContainer";
import { PaymentSuccessfulPage } from "../../pages/PaymentSuccessfulPage";
import { RegistrationPage } from "../../pages/RegistrationPage";
import { ReportPage } from "../../pages/ReportPage";
import { ResetPasswordPage } from "../../pages/ResetPasswordPage";
import { SettingsPage } from "../../pages/SettingsPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { FallbackPage } from "../../pages/FallbackPage";

const iconSize = 22;

export const menuItems: any = [
  {
    daName: "Dashboard",
    enName: "Dashboard",
    link: "/dashboard",
    icon: <RiDashboardFill size={iconSize} />,
  },
  {
    daName: "Importér",
    enName: "Import",
    link: "/import",
    icon: <CgImport size={iconSize} />,
  },
  {
    daName: "Konti",
    enName: "Accounts",
    link: "/accounts",
    icon: <MdAccountTree size={iconSize} />,
  },
  {
    daName: "transaktioner",
    enName: "transactions",
    link: "/transactions",
    icon: <BiTransfer size={iconSize} />,
  },
  {
    daName: "Min Side",
    enName: "My Page",
    link: "/user",
    icon: <FaUserCircle size={iconSize} />,
  },
];

interface Props {
  applicationSettings: ApplicationSettings;
  handleSwitchLocale: () => void;
  handleSwitchTheme: () => void;
  handleLogout: () => Promise<void>;
}
export function RoutesComponent(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const setAuthenticationData = useContext(SetAuthenticationDataContext);

  const navigate = useNavigate();

  const sessionExists = localStorage.getItem("session") !== null;

  useEffect(() => {
    if (sessionExists && !authenticationData) {
      setAuthenticationData(JSON.parse(localStorage.getItem("session")!));
    }
  }, [
    authenticationData,
    navigate,
    sessionExists,
    setAuthenticationData,
    props,
  ]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageContainer
            applicationSettings={props.applicationSettings}
            children={
              <LandingPage applicationSettings={props.applicationSettings} />
            }
          />
        }
      ></Route>

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <DashboardPage
                  applicationSettings={props.applicationSettings}
                  handleLogout={props.handleLogout}
                />
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/import"
        element={
          <ProtectedRoute>
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <ImportPage
                  applicationSettings={props.applicationSettings}
                  handleLogout={props.handleLogout}
                />
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounts"
        element={
          <ProtectedRoute>
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <AccountPage
                  applicationSettings={props.applicationSettings}
                  handleLogout={props.handleLogout}
                />
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/transactions"
        element={
          <ProtectedRoute>
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <ReportPage
                  applicationSettings={props.applicationSettings}
                  handleLogout={props.handleLogout}
                />
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user"
        element={
          <ProtectedRoute>
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <SettingsPage
                  applicationSettings={props.applicationSettings}
                  handleSwitchLocale={props.handleSwitchLocale}
                  handleSwitchTheme={props.handleSwitchTheme}
                />
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment-successful"
        element={
          <ProtectedRoute>
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <PaymentSuccessfulPage
                  applicationSettings={props.applicationSettings}
                />
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <PageContainer
            applicationSettings={props.applicationSettings}
            children={
              <FallbackPage applicationSettings={props.applicationSettings} />
            }
          />
        }
      />

      {!authenticationData || !authenticationData.verified ? (
        <Route
          path="/login"
          element={
            <PageContainer applicationSettings={props.applicationSettings}>
              <LoginPage applicationSettings={props.applicationSettings} />
            </PageContainer>
          }
        ></Route>
      ) : (
        <Route
          path="/login"
          element={
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <LandingPage applicationSettings={props.applicationSettings} />
              }
            />
          }
        ></Route>
      )}
      {!authenticationData ? (
        <Route
          path="/register"
          element={
            <PageContainer applicationSettings={props.applicationSettings}>
              <RegistrationPage
                applicationSettings={props.applicationSettings}
              />
            </PageContainer>
          }
        ></Route>
      ) : (
        <Route
          path="/register"
          element={
            <PageContainer
              applicationSettings={props.applicationSettings}
              children={
                <LandingPage applicationSettings={props.applicationSettings} />
              }
            />
          }
        ></Route>
      )}
      <Route
        path="/resetPassword"
        element={
          <PageContainer applicationSettings={props.applicationSettings}>
            <ResetPasswordPage
              applicationSettings={props.applicationSettings}
            />
          </PageContainer>
        }
      ></Route>
    </Routes>
  );
}
