import { Tooltip } from "@mantine/core";
import { useCallback, useContext } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { useQuery } from "react-query";
import { AuthenticationContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { Invoice } from "../../interfaces/Membership/UserInvoices";
import MembershipService from "../../service/MembershipService";
import {
  DownloadInvoiceLabel,
  EmptyInvoicesLabel,
  InvoiceLabel,
  YourInvoicesLabel,
} from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function InvoicesContent(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);

  const fetchUserInvoices = useCallback(async () => {
    if (!authenticationData) return;
    return (await MembershipService.getUserInvoices(
      authenticationData.token
    )) as Invoice[];
  }, [authenticationData]);

  const userInvoicesQuery = useQuery("userInvoices", fetchUserInvoices, {
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const handleDownloadInvoice = useCallback(
    async (id: number) => {
      if (!authenticationData) return;
      await MembershipService.downloadPDFInvoice(authenticationData.token, id);
    },
    [authenticationData]
  );

  return (
    <div className="invoices-content-container">
      <div className="invoices-header">
        <label>{YourInvoicesLabel[props.applicationSettings.locale]}</label>
      </div>
      <div className="invoices-scrollable-body">
        {userInvoicesQuery.data && userInvoicesQuery.data.length > 0 ? (
          <>
            {userInvoicesQuery.data.map((invoice) => (
              <div className="invoice-item-container" key={invoice.id}>
                <Tooltip
                  className="download-button"
                  onClick={() => handleDownloadInvoice(invoice.id)}
                  label={DownloadInvoiceLabel[props.applicationSettings.locale]}
                >
                  <FiDownloadCloud size={24} />
                </Tooltip>
                <label className="invoice-body-label">
                  {InvoiceLabel[props.applicationSettings.locale]} ID:{" "}
                  {invoice.id}
                </label>
                <label className="invoice-date">{invoice.date}</label>
                <label className="invoice-sum">{invoice.text}</label>
              </div>
            ))}
          </>
        ) : (
          <label>{EmptyInvoicesLabel[props.applicationSettings.locale]}</label>
        )}
      </div>
    </div>
  );
}
