import "./manual-transaction.scss";
import { ApplicationSettings } from "../../../../../interfaces/ApplicationSettings";
import { Currency } from "../../../../../interfaces/Import/Currency";
import { CurrencySelect } from "./CurrencySelect";
import { NumberInput, Space } from "@mantine/core";
import { FiArrowRight } from "react-icons/fi";
import {
  CurrencyLabel,
  CurrencyOutLabel,
  SearchableCurrencySelectLabel,
} from "../../../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  currencies: Currency[];
  amountDisabled: boolean;
  value: number;
  setTransactionOutCurrencyId: (currencyId: string) => void;
  handleAmountChange: (amount: number) => void;
  blacklistedCurrencyId?: string;
}
export function TransactionOut(props: React.PropsWithChildren<Props>) {
  return (
    <div className="manual-transaction-out-input-container">
      <div className="manual-transaction-header-container">
        <label className="manual-transaction-header">
          {CurrencyOutLabel[props.applicationSettings.locale]}
        </label>
        <FiArrowRight size={20} className="arrow-transaction-out" />
      </div>
      <div className="manual-transaction-input-row">
        <CurrencySelect
          blacklistedCurrencyId={props.blacklistedCurrencyId}
          label={CurrencyLabel[props.applicationSettings.locale]}
          cleareable
          searchable
          placeholder={
            SearchableCurrencySelectLabel[props.applicationSettings.locale]
          }
          onChange={props.setTransactionOutCurrencyId}
        />
        <Space w={"sm"} />
        <NumberInput
          label={
            props.applicationSettings.locale === "en" ? "Quantity" : "Antal"
          }
          value={props.value}
          precision={6}
          min={0}
          step={0.05}
          disabled={props.amountDisabled}
          onChange={props.handleAmountChange}
          required
        />
      </div>
    </div>
  );
}
