import "./etherscan.scss";
import { useCallback, useContext, useState } from "react";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { Button, Loader, Space, TextInput } from "@mantine/core";
import {
  WalletAddressLabel,
  EnterWalletAddressLabel,
  EtherscanInstructionsLabel,
  UploadTransactionsLabel,
} from "../../../../utils/labels";
import { FiUploadCloud } from "react-icons/fi";
import {
  AuthenticationContext,
  TriggerNotificationContext,
  SetPollingStatusContext,
} from "../../../../App";
import ImportService from "../../../../service/ImportService";
import { isValidAddress } from "ethereumjs-util";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  applicationSettings: ApplicationSettings;
  walletId: string;
  closeDrawer: () => void;
}
export function EtherscanApiInput(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);
  const compact = useMediaQuery("(max-width: 900px");

  const [walletAddress, setWalletAddress] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isValid, setIsValid] = useState(false);

  const submitToEtherscan = useCallback(async () => {
    if (!authenticationData) return;
    if (!walletAddress) return;
    try {
      setError(undefined);
      setLoading(true);
      const response = await ImportService.importTransactionsFromEtherscan(
        authenticationData.token,
        props.walletId,
        walletAddress
      );
      triggerNotification(response["message"], "success");
      setPollingStatus({ needRequest: true, reportUpdating: true });
      props.closeDrawer();
    } catch (error: any) {
      const message = error.response.data.message;
      setError(message);
    } finally {
      setLoading(false);
    }
  }, [
    setError,
    authenticationData,
    setLoading,
    walletAddress,
    props.walletId,
    triggerNotification,
    setPollingStatus,
    props.closeDrawer,
  ]);

  const etherscanUrl = `url(/assets/other/Etherscan-${props.applicationSettings.theme}.svg)`;

  const validateWalletAddress = useCallback(
    (address: string) => {
      // Check if address is in valid format
      const addressRegex = /^(0x)[0-9a-fA-F]{40}$/;
      if (!addressRegex.test(address)) {
        return false;
      }

      // Check if address has valid checksum
      if (!isValidAddress(address)) {
        return false;
      }

      return true;
    },
    [isValidAddress]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputAddress = event.target.value;
      setWalletAddress(inputAddress);
      setIsValid(validateWalletAddress(inputAddress));
    },
    [setIsValid, setWalletAddress, validateWalletAddress]
  );

  return (
    <div className="etherscan-api-container">
      <div
        className="etherscan-logo"
        style={{
          backgroundImage: etherscanUrl,
        }}
      />

      <Space h="lg" />
      <label>
        {EtherscanInstructionsLabel[props.applicationSettings.locale]}
      </label>
      <Space h="lg" />

      <TextInput
        size={compact ? "xs" : "sm"}
        error={error}
        className="input-field-hash"
        id="wallet-address-input"
        placeholder={WalletAddressLabel[props.applicationSettings.locale]}
        label={EnterWalletAddressLabel[props.applicationSettings.locale]}
        value={walletAddress}
        onChange={handleChange}
      />
      <Space h="lg" />
      <div className="submit-api-button-container">
        <Button
          onClick={() => submitToEtherscan()}
          className="cs-theme-button"
          radius="xs"
          leftIcon={!loading ? <FiUploadCloud /> : <Loader />}
          uppercase
          disabled={loading || !isValid}
        >
          {UploadTransactionsLabel[props.applicationSettings.locale]}
        </Button>
      </div>
    </div>
  );
}
