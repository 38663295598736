import "./error-page.scss";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { Button } from "@mantine/core";
import { FiArrowLeft } from "react-icons/fi";
import {
  GenericErrorHeaderLabel,
  GenericErrorMessageLabel,
  ReturnLabel,
} from "../utils/labels";
import { PiSmileyXEyes } from "react-icons/pi";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function ErrorPage(props: React.PropsWithChildren<Props>) {
  return (
    <div className="error-page-container">
      <div className="error-card">
        <div className="error-header">
          <PiSmileyXEyes size={30}/>
          <label>
            {GenericErrorHeaderLabel[props.applicationSettings.locale]}
          </label>
        </div>
        <div className="error-body">
          <label>
            {GenericErrorMessageLabel[props.applicationSettings.locale]}
          </label>
        </div>
        <div className="error-footer">
          <Button
            className="cs-theme-button"
            radius={"xs"}
            variant="subtle"
            size="xs"
            leftIcon={<FiArrowLeft />}
            onClick={() => window.location.reload()}
          >
            {ReturnLabel[props.applicationSettings.locale]}
          </Button>
        </div>
      </div>
    </div>
  );
}
