import "./custom-format-upload.scss";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { useCallback, useContext, useMemo, useState } from "react";
import { UploadDropzoneButton } from "../UploadDropzoneButton";
import {
  ProcessedTransaction,
  getCustomAPISasUploadUri,
  processBlob,
  uploadFileToBlobStorage,
} from "../../../../service/CustomImportAPIService";
import {
  AuthenticationContext,
  CurrencyContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../../../../App";
import { decodeToken } from "../../../../utils/decoder";
import { BarLoader } from "../../../CustomLoaders/BarLoader";
import { TransactionType } from "../../../Report/TransactionTypeLabels/TransactionTypeLabel";
import { FiArrowLeft, FiArrowRight, FiPlus } from "react-icons/fi";
import CountUp from "react-countup";
import { determineDecimals } from "../../../../utils/formatter";
import { SpecialCustomer } from "../../ImportBody";
import { BiGasPump } from "react-icons/bi";
import { SpecialTypeIcon } from "../../../SpecialTypeIcons/SpecialTypeIcon";
import {
  Avatar,
  Button,
  Loader,
  Modal,
  Space,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  AccountLabel,
  CurrencyInLabel,
  CurrencyOutLabel,
  EmptyCustomTransactionsInputLabel,
  FeeCurrencyLabel,
  HangTightWhileWeProcessLabel,
  ImportLabel,
  MissingLabel,
  NTransactionsAddedLabel,
  ReplaceCurrencyLabel,
  SearchableCurrencySelectLabel,
  TransactionsLabel,
  TransactionsRequireManualActionLabel,
} from "../../../../utils/labels";
import { getCurrencySlugUrl } from "../../../../utils/selectDataUtil";
import { Currency } from "../../../../interfaces/Import/Currency";
import { CurrencySelect } from "./manualComponents/CurrencySelect";
import ImportService, {
  CustomFormatTransactionInput,
  ManualBulkImportPayload,
} from "../../../../service/ImportService";
import {
  getDefaultAccountName,
  getExchangeName,
} from "../../../../utils/specialCustomersUtil";

interface Props {
  applicationSettings: ApplicationSettings;
  closeDrawer: () => void;
  handleLogout?: () => Promise<void>;
  customer: SpecialCustomer;
}
export function CustomFormatUpload(props: React.PropsWithChildren<Props>) {
  const [file, setFile] = useState<File>();
  const authenticationData = useContext(AuthenticationContext);
  const currencies = useContext(CurrencyContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);

  const currencyShortnameMap = useMemo(() => {
    const currencyShortnameMap: { [key: string]: Currency } = {};
    if (!currencies) return currencyShortnameMap;
    Object.values(currencies).forEach((currency) => {
      currencyShortnameMap[currency.code] = currency;
    });
    return currencyShortnameMap;
  }, [currencies]);

  const [customCurrencyShortnameSelect, setCustomCurrencyShortnameSelect] =
    useState<string | undefined>();
  const [customCurrencyShortnameMap, setCustomCurrencyShortnameMap] = useState<{
    [key: string]: Currency;
  }>({});
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [processingFile, setProcessingFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [processedTransactions, setProcessedTransactions] =
    useState<ProcessedTransaction[]>();
  const [error, setError] = useState<string>();

  const [convertedTransactions, setConvertedTransactions] = useState<
    ProcessedTransaction[]
  >([]);

  const customExchange = getExchangeName(props.customer);

  const [customAccount, setCustomAccount] = useState<string>(
    getDefaultAccountName(props.customer)
  );

  const customerAcceptedFileTypes = {
    "bitcoin-suisse": [
      "text/xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    penning: ["text/csv"],
  };
  const customerUploadLabel = {
    "bitcoin-suisse": "Upload Excel",
    penning: "Upload Penning CSV",
  };

  const customerUris = {
    "bitcoin-suisse": process.env.REACT_APP_AZ_FUNCTION_BITCOIN_SUISSE_URI,
    penning: process.env.REACT_APP_AZ_FUNCTION_PENNING_URI,
  };

  const handleClick = useCallback(async () => {
    if (!file || !authenticationData) return;
    try {
      setError(undefined);
      const decodedToken = decodeToken(authenticationData.token);
      const blobUrl = `${decodedToken.userId}_${file.name}`;
      setUploadingFile(true);
      const sasUri = await getCustomAPISasUploadUri(
        authenticationData.token,
        props.customer,
        blobUrl
      );
      if (!sasUri) return;
      const uploadSuccesful = await uploadFileToBlobStorage(
        file,
        sasUri,
        authenticationData.token
      );
      if (!uploadSuccesful) return;
      setProcessingFile(true);
      const customerUri = customerUris[props.customer];
      if (!customerUri) {
        throw new Error(`No customer uri found for ${props.customer}`);
      }
      const response = await processBlob(
        authenticationData.token,
        blobUrl,
        customerUri
      );
      if (!response) return;
      setProcessedTransactions(response.processed_transactions);
    } catch (error: any) {
      setError(error.response.data);
    } finally {
      setFile(undefined);
      setTimeout(() => {
        setUploadingFile(false);
        setProcessingFile(false);
      }, 1500);
    }
  }, [
    file,
    authenticationData,
    setUploadingFile,
    setProcessingFile,
    setFile,
    setProcessedTransactions,
    customerUris,
    props.customer,
    setError,
  ]);

  const [invalidRows, setInvalidRows] = useState<number[]>([]);

  useMemo(() => {
    const _invalidRows: number[] = [];
    if (!processedTransactions) return;
    const convertedTransactions = processedTransactions.map(
      (transaction, idx) => {
        const standardCurrencyIn =
          currencyShortnameMap[transaction.currency_in];
        const standardCurrencyOut =
          currencyShortnameMap[transaction.currency_out];
        const customCurrencyIn =
          customCurrencyShortnameMap[transaction.currency_in];
        const customCurrencyOut =
          customCurrencyShortnameMap[transaction.currency_out];
        const currencyIn = customCurrencyIn || standardCurrencyIn;
        const currencyOut = customCurrencyOut || standardCurrencyOut;
        const invalidRow =
          transaction.transaction_type === "Trade"
            ? !currencyIn || !currencyOut
            : transaction.transaction_type === "Deposit"
            ? !currencyIn
            : !currencyOut;
        if (invalidRow) _invalidRows.push(idx);
        return {
          ...transaction,
          currency_in_id: currencyIn?.id ? parseInt(currencyIn?.id) : undefined,
          currency_out_id: currencyOut?.id
            ? parseInt(currencyOut.id)
            : undefined,
          fee_currency_id:
            transaction.transaction_type === "Deposit"
              ? currencyIn?.id
                ? parseInt(currencyIn?.id)
                : undefined
              : currencyOut?.id
              ? parseInt(currencyOut.id)
              : undefined,
        };
      }
    );
    setInvalidRows(_invalidRows);
    setConvertedTransactions(convertedTransactions);
  }, [
    processedTransactions,
    currencyShortnameMap,
    setConvertedTransactions,
    customCurrencyShortnameMap,
    setInvalidRows,
  ]);

  const validInput = useMemo(() => {
    if (customAccount === "") return false;
    return invalidRows.length === 0;
  }, [invalidRows, customAccount, customExchange]);

  const handleImportTransactions = useCallback(async () => {
    if (!authenticationData || !validInput) return;
    setLoading(true);
    try {
      setError(undefined);
      const dataPayload: CustomFormatTransactionInput[] = [];
      convertedTransactions.forEach((transaction) => {
        if (transaction.transaction_type === "Trade") {
          dataPayload.push({
            transaction_type: "trade",
            transaction_date: transaction.transaction_date,
            amount_in: transaction.amount_in,
            amount_out: transaction.amount_out,

            currency_in_id: transaction.currency_in_id,
            currency_out_id: transaction.currency_out_id,
            fee_amount: transaction.fee ?? undefined,
            fee_currency_id: transaction.fee
              ? transaction.fee_currency_id
              : undefined,
            special_transaction_type_id:
              transaction.special_transaction_type_id ?? undefined,
          });
        }
        if (transaction.transaction_type === "Deposit") {
          dataPayload.push({
            transaction_type: "deposit",
            transaction_date: transaction.transaction_date,
            amount_in: transaction.amount_in,
            currency_in_id: transaction.currency_in_id,
            crypto_tx_id:
              transaction.crypto_tx_id?.length > 0
                ? transaction.crypto_tx_id
                : undefined,
            fee_amount: transaction.fee ?? undefined,
            fee_currency_id: transaction.fee
              ? transaction.fee_currency_id
              : undefined,
            special_transaction_type_id:
              transaction.special_transaction_type_id ?? undefined,
          });
        }
        if (transaction.transaction_type === "Withdrawal") {
          dataPayload.push({
            transaction_type: "withdrawal",
            transaction_date: transaction.transaction_date,
            amount_out: transaction.amount_out,
            currency_out_id: transaction.currency_out_id,
            fee_amount: transaction.fee ?? undefined,
            fee_currency_id: transaction.fee
              ? transaction.fee_currency_id
              : undefined,
            special_transaction_type_id:
              transaction.special_transaction_type_id ?? undefined,
            crypto_tx_id:
              transaction.crypto_tx_id?.length > 0
                ? transaction.crypto_tx_id
                : undefined,
          });
        }
      });
      const manualBulkTransactionsInputPayload: ManualBulkImportPayload = {
        custom_account: customAccount,
        custom_exchange: customExchange,
        data: dataPayload,
      };
      const response = await ImportService.ImportManualTransactionsBulk(
        authenticationData.token,
        manualBulkTransactionsInputPayload
      );
      triggerNotification(
        [
          `${convertedTransactions.length} ${
            NTransactionsAddedLabel[props.applicationSettings.locale]
          }`,
          HangTightWhileWeProcessLabel[props.applicationSettings.locale],
        ],
        "success"
      );
      setPollingStatus({ needRequest: true, reportUpdating: true });
      props.closeDrawer();
    } catch (error: any) {
      const message = error.response.data.message;
      setError(message);
    } finally {
      setLoading(false);
    }
  }, [
    authenticationData,
    validInput,
    convertedTransactions,
    customAccount,
    customExchange,
    setLoading,
    triggerNotification,
    setPollingStatus,
    setError,
    props.closeDrawer,
    props.applicationSettings.locale,
  ]);

  return (
    <div className="custom-format-upload-container">
      {convertedTransactions.length === 0 && (
        <div className="dropzone-container">
          {!uploadingFile ? (
            <UploadDropzoneButton
              applicationSettings={props.applicationSettings}
              onSuccesfulDrop={setFile}
              onClick={handleClick}
              acceptedFileTypes={customerAcceptedFileTypes[props.customer]}
              customUploadText={customerUploadLabel[props.customer]}
            />
          ) : (
            <BarLoader applicationSettings={props.applicationSettings} />
          )}
        </div>
      )}
      <div className="custom-format-results-container">
        {!processingFile && convertedTransactions?.length > 0 ? (
          convertedTransactions?.map((transaction, index) => {
            const currencyIn = transaction.currency_in_id
              ? currencies?.[transaction.currency_in_id.toString()]
              : undefined;
            const currencyOut = transaction.currency_out_id
              ? currencies?.[transaction.currency_out_id.toString()]
              : undefined;
            const feeCurrency = transaction.fee_currency_id
              ? currencies?.[transaction.fee_currency_id.toString()]
              : undefined;

            return (
              <div
                key={index}
                className={
                  "converted-transaction-container" +
                  (invalidRows.includes(index) ? " invalid-row" : "")
                }
              >
                <div className="converted-transaction-date">
                  <label>
                    <strong>
                      {transaction.transaction_date.split(" ")[0]}
                    </strong>
                  </label>
                  <label>{transaction.transaction_date.split(" ")[1]}</label>
                </div>
                <TransactionType
                  type={transaction.transaction_type as TransactionType}
                  focus={true}
                />

                {["Trade", "Deposit"].includes(
                  transaction.transaction_type
                ) && (
                  <div
                    className={
                      "converted-transaction-type-container clickable" +
                      (!currencyIn ? " missing" : "")
                    }
                    onClick={() => {
                      setCustomCurrencyShortnameSelect(transaction.currency_in);
                    }}
                  >
                    <Tooltip
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label={
                        currencyIn
                          ? `${
                              CurrencyInLabel[props.applicationSettings.locale]
                            }: ${currencyIn.full_name}`
                          : `${
                              CurrencyInLabel[props.applicationSettings.locale]
                            } ${MissingLabel[props.applicationSettings.locale]}`
                      }
                    >
                      <FiArrowRight className="transaction-in" />
                      <Space w={"xs"} />

                      <CountUp
                        className="amount-label"
                        start={0}
                        end={transaction.amount_in}
                        decimals={determineDecimals(transaction.amount_in)}
                      />
                      <Space w={"xs"} />

                      {currencyIn ? (
                        <Avatar
                          size={"sm"}
                          radius={"xl"}
                          src={getCurrencySlugUrl(currencyIn)}
                        />
                      ) : (
                        <label>{transaction.currency_in}</label>
                      )}
                    </Tooltip>
                  </div>
                )}
                {["Trade", "Withdrawal"].includes(
                  transaction.transaction_type
                ) && (
                  <div
                    className={
                      "converted-transaction-type-container clickable" +
                      (!currencyOut ? " missing" : "")
                    }
                    onClick={() => {
                      setCustomCurrencyShortnameSelect(
                        transaction.currency_out
                      );
                    }}
                  >
                    <Tooltip
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label={
                        currencyOut
                          ? `${
                              CurrencyOutLabel[props.applicationSettings.locale]
                            }: ${currencyOut.full_name}`
                          : `${
                              CurrencyOutLabel[props.applicationSettings.locale]
                            } ${MissingLabel[props.applicationSettings.locale]}`
                      }
                    >
                      <CountUp
                        className="amount-label"
                        start={0}
                        end={transaction.amount_out}
                        decimals={determineDecimals(transaction.amount_out)}
                      />
                      <Space w={"xs"} />
                      {currencyOut ? (
                        <Avatar
                          size={"sm"}
                          radius={"xl"}
                          src={getCurrencySlugUrl(currencyOut)}
                        />
                      ) : (
                        <label>{transaction.currency_out}</label>
                      )}

                      <FiArrowLeft className="transaction-out" />
                    </Tooltip>
                  </div>
                )}
                {transaction.fee ? (
                  <div
                    className={
                      "converted-transaction-type-container" +
                      (!feeCurrency ? " missing" : "")
                    }
                  >
                    <Tooltip
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label={
                        feeCurrency
                          ? `${
                              FeeCurrencyLabel[props.applicationSettings.locale]
                            }: ${feeCurrency.full_name}`
                          : `${
                              FeeCurrencyLabel[props.applicationSettings.locale]
                            } ${MissingLabel[props.applicationSettings.locale]}`
                      }
                    >
                      <BiGasPump className="transaction-fee" />
                      <Space w={"xs"} />

                      {transaction.fee ? (
                        <CountUp
                          className="amount-label"
                          start={0}
                          end={transaction.fee}
                          decimals={determineDecimals(transaction.fee)}
                        />
                      ) : undefined}
                      <Space w={"xs"} />
                      {feeCurrency ? (
                        <Avatar
                          size={"sm"}
                          radius={"xl"}
                          src={getCurrencySlugUrl(feeCurrency)}
                        />
                      ) : (
                        <label> {transaction.fee_currency}</label>
                      )}
                    </Tooltip>
                  </div>
                ) : undefined}
                {transaction.special_transaction_type_id &&
                  transaction.special_transaction_type && (
                    <div
                      className="converted-transaction-type-container"
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      <SpecialTypeIcon
                        iconSize={20}
                        typeID={transaction.special_transaction_type_id}
                      />
                      <label>{transaction.special_transaction_type}</label>
                    </div>
                  )}
              </div>
            );
          })
        ) : (
          <div className="empty-custom-input-container">
            <label>
              {
                EmptyCustomTransactionsInputLabel[
                  props.applicationSettings.locale
                ]
              }
            </label>
          </div>
        )}
      </div>
      <Modal
        opened={customCurrencyShortnameSelect !== undefined}
        onClose={() => setCustomCurrencyShortnameSelect(undefined)}
        title={ReplaceCurrencyLabel[props.applicationSettings.locale]}
      >
        {currencies && (
          <CurrencySelect
            label={`${
              ReplaceCurrencyLabel[props.applicationSettings.locale]
            }: ${customCurrencyShortnameSelect}`}
            cleareable
            searchable
            placeholder={
              SearchableCurrencySelectLabel[props.applicationSettings.locale]
            }
            onChange={(currencyId) => {
              const currency = currencies[currencyId];
              setCustomCurrencyShortnameMap({
                ...customCurrencyShortnameMap,
                [customCurrencyShortnameSelect as string]: currency,
              });
              setCustomCurrencyShortnameSelect(undefined);
            }}
          />
        )}
      </Modal>
      <Space h={"md"} />
      {error && <label className="error-label">{error}</label>}
      <Space h={"md"} />

      {convertedTransactions.length > 0 && (
        <>
          <TextInput
            label={AccountLabel[props.applicationSettings.locale]}
            required
            onChange={(e) => setCustomAccount(e.target.value)}
            value={customAccount}
            style={{ width: "80%" }}
          />
          <Space h={"md"} />
          {invalidRows.length > 0 && (
            <label className="error-label">
              {invalidRows.length}{" "}
              {
                TransactionsRequireManualActionLabel[
                  props.applicationSettings.locale
                ]
              }
            </label>
          )}

          <Space h={"md"} />
          <Button
            onClick={handleImportTransactions}
            className="cs-theme-button"
            radius="xs"
            leftIcon={!loading ? <FiPlus /> : <Loader />}
            uppercase
            disabled={!validInput}
          >
            {ImportLabel[props.applicationSettings.locale]}{" "}
            {convertedTransactions.length}{" "}
            {TransactionsLabel[props.applicationSettings.locale]}
          </Button>
        </>
      )}
    </div>
  );
}
