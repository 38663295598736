import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import "./new-dashboard.scss";
import { DashboardInfo } from "../../interfaces/Dashboard/DashboardInfo";
import { ExchangeIntegrations } from "./ExchangeIntegrations";
import { WalletIntegrations } from "./WalletIntegrations";
import { ManualIntegrations } from "./ManualIntegrations";
import { Accordion, Button, Loader, RingProgress, Space } from "@mantine/core";
import { FiArrowUp, FiExternalLink, FiFileText, FiPlus } from "react-icons/fi";
import {
  DashboardWelcomeLabel,
  EditTransactionLabel,
  EtherscanAnnouncementLabel,
  ExchangeRateAnnouncementLabel,
  GenerateReportLabel,
  GeneratingReportLabel,
  GetStartedLabel,
  ImportTransactionFromApiLabel,
  ImportTransactionsLabel,
  ImportTransactionsManuallyLabel,
  MembershipLabel,
  NewDesignAnnouncementLabel,
  RemainingDaysOnPlanLabel,
  ReportsLabel,
  TagTransactionLabel,
  TaxLawLabel,
  TransactionsLabel,
  UpgradeMembershipLabel,
} from "../../utils/labels";
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  AuthenticationContext,
  PollContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../../App";
import { AnimatePresence, motion } from "framer-motion";
import { transactionLevels } from "../../utils/membershipHelper";
import { EditReportGuide } from "./dashboard-guides/EditReportGuide";
import { GeneratedReports } from "./GeneratedReports";
import TransactionService from "../../service/TransactionService";
import { parseDateToFilterParameterDateString } from "../../utils/formatter";
import { ManualTransactionGuide } from "./dashboard-guides/ManualTransactionGuide";
import { ApiTransactionGuide } from "./dashboard-guides/ApiTransactionGuide";

interface Props {
  applicationSettings: ApplicationSettings;
  dashboardInfo: DashboardInfo;
  refetchDashboardInfo: () => void;
}

export function Dashboard(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const pollStatus = useContext(PollContext);
  const setPollStatus = useContext(SetPollingStatusContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const navigate = useNavigate();
  const transactionCount = props.dashboardInfo.totalTransactionsCount;

  const calculateProgress = (level: string, tradeAmount: number) => {
    const cap = transactionLevels[level.toLowerCase()];
    if (tradeAmount === 0) return 0;
    return (tradeAmount / cap) * 100;
  };

  const showRemainingDays = props.dashboardInfo.remainingDays
    ? props.dashboardInfo.remainingDays > 0 &&
      props.dashboardInfo.remainingDays < 31 &&
      props.dashboardInfo.plan.toLowerCase() !== "intro"
    : false;

  // get the last 3 years
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < 3; i++) {
    years.push(currentYear - i);
  }
  const [
    listenForFinishedReportGeneration,
    setListenForFinishedReportGeneration,
  ] = useState(false);
  useEffect(() => {
    if (listenForFinishedReportGeneration) {
      if (!pollStatus.pdfReportGenerating) {
        props.refetchDashboardInfo();
        setListenForFinishedReportGeneration(false);
      }
    }
  }, [
    pollStatus,
    setListenForFinishedReportGeneration,
    listenForFinishedReportGeneration,
    props,
  ]);

  const generateReport = useCallback(
    async (year: number) => {
      if (!authenticationData) return;
      const startOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, 11, 31);
      const startString = parseDateToFilterParameterDateString(startOfYear);
      const endString = parseDateToFilterParameterDateString(endOfYear);
      try {
        const response = await TransactionService.generatePdfReport(
          authenticationData.token,
          startString,
          endString
        );
        triggerNotification(response["message"], "info");
        setPollStatus({
          ...pollStatus,
          needRequest: true,
          pdfReportGenerating: true,
        });
        setListenForFinishedReportGeneration(true);
      } catch (error: any) {
        triggerNotification(error["response"]["data"]["message"], "error");
      }
    },
    [
      authenticationData,
      setPollStatus,
      pollStatus,
      triggerNotification,
      setListenForFinishedReportGeneration,
    ]
  );

  return (
    <AnimatePresence>
      <motion.div className="dashboard-container" layout>
        <motion.div
          className="welcome-container"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="welcome-header">
            <label>
              {DashboardWelcomeLabel[props.applicationSettings.locale]}
            </label>
          </div>
          <div className="announcement-label">
            <label>
              <strong>19/10 2023</strong> |{" "}
              {ExchangeRateAnnouncementLabel[props.applicationSettings.locale]}
            </label>
          </div>
          <div className="announcement-label">
            <label>
              <strong>1/8 2023</strong> |{" "}
              {NewDesignAnnouncementLabel[props.applicationSettings.locale]}
            </label>
          </div>
          <div className="announcement-label">
            <label>
              <strong>13/4 2023</strong> |{" "}
              {EtherscanAnnouncementLabel[props.applicationSettings.locale]}
            </label>
          </div>
        </motion.div>
        <motion.div
          className="get-started-container"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="get-started-header">
            <label>{GetStartedLabel[props.applicationSettings.locale]}</label>
          </div>
          <div className="get-started-center-content">
            <Accordion className="guide-container" multiple={false}>
              <Accordion.Item
                label={
                  <label>
                    {
                      ImportTransactionFromApiLabel[
                        props.applicationSettings.locale
                      ]
                    }
                  </label>
                }
                className="guide-item-container"
              >
                <ApiTransactionGuide />
              </Accordion.Item>
              <Accordion.Item
                label={
                  <label>
                    {
                      ImportTransactionsManuallyLabel[
                        props.applicationSettings.locale
                      ]
                    }
                  </label>
                }
                className="guide-item-container"
              >
                <ManualTransactionGuide />
              </Accordion.Item>
              <Accordion.Item
                label={
                  <label>
                    {TagTransactionLabel[props.applicationSettings.locale]}
                  </label>
                }
                className="guide-item-container"
              >
                <EditReportGuide />
              </Accordion.Item>
              <Accordion.Item
                label={
                  <label>
                    {EditTransactionLabel[props.applicationSettings.locale]}
                  </label>
                }
                className="guide-item-container"
              >
                <EditReportGuide />
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="get-started-bottom-content">
            <Button
              className="cs-theme-button"
              radius={"xs"}
              variant="subtle"
              size="xs"
              leftIcon={<FiExternalLink />}
              onClick={() => {
                window.open("https://cryptoskat.dk/faq/", "_blank");
              }}
            >
              FAQ
            </Button>
            <Button
              className="cs-theme-button"
              radius={"xs"}
              variant="subtle"
              size="xs"
              leftIcon={<FiExternalLink />}
              onClick={() => {
                window.open(
                  "https://cryptoskat.dk/skattereglerne-kort-fortalt/",
                  "_blank"
                );
              }}
            >
              {TaxLawLabel[props.applicationSettings.locale]}
            </Button>
          </div>
        </motion.div>
        <motion.div
          className="integrations-parent-container"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {props.dashboardInfo.exchangesIntegrations.length === 0 &&
            props.dashboardInfo.walletsIntegrations.length === 0 &&
            props.dashboardInfo.manualExchanges.length === 0 && (
              <label>You have no transactions</label>
            )}
          <Button
            className="cs-theme-button"
            radius={"xs"}
            leftIcon={<FiPlus />}
            onClick={() => navigate("/import")}
          >
            {ImportTransactionsLabel[props.applicationSettings.locale]}
          </Button>
          <Space h="xl" />
          {props.dashboardInfo.exchangesIntegrations.length > 0 && (
            <ExchangeIntegrations
              applicationSettings={props.applicationSettings}
              exchanges={props.dashboardInfo.exchangesIntegrations}
            />
          )}

          {props.dashboardInfo.walletsIntegrations.length > 0 && (
            <WalletIntegrations
              applicationSettings={props.applicationSettings}
              wallets={props.dashboardInfo.walletsIntegrations}
            />
          )}

          {props.dashboardInfo.manualExchanges.length > 0 && (
            <ManualIntegrations
              applicationSettings={props.applicationSettings}
              manualIntegrationPlatforms={props.dashboardInfo.manualExchanges}
            />
          )}
        </motion.div>
        <motion.div
          className="membership-container"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <label className="membership-header-label">
            {MembershipLabel[props.applicationSettings.locale]}
          </label>
          <RingProgress
            size={140}
            thickness={10}
            label={
              <label className="ring-progress-center">
                {`${transactionCount} / ${
                  transactionLevels[props.dashboardInfo.plan.toLowerCase()]
                }`}{" "}
                {TransactionsLabel[props.applicationSettings.locale]}
              </label>
            }
            roundCaps
            sections={[
              {
                value: calculateProgress(
                  props.dashboardInfo.plan,
                  transactionCount
                ),
                color:
                  props.applicationSettings.theme === "light"
                    ? "#043577"
                    : "#fedd58",
              },
            ]}
          />
          <label className="membership-label">
            Plan: <strong>{props.dashboardInfo.plan}</strong>
          </label>
          <label className="membership-label">
            Status: <strong>{props.dashboardInfo.planStatus}</strong>
          </label>
          {showRemainingDays && (
            <label className="membership-label">
              {RemainingDaysOnPlanLabel[props.applicationSettings.locale]}:{" "}
              <strong>{props.dashboardInfo.remainingDays}</strong>
            </label>
          )}
          {props.dashboardInfo.plan.toLowerCase() !== "daytrader" && (
            <Button
              className="cs-theme-button"
              radius={"xs"}
              leftIcon={<FiArrowUp />}
              onClick={() => navigate("/user")}
            >
              {UpgradeMembershipLabel[props.applicationSettings.locale]}
            </Button>
          )}
        </motion.div>
        <motion.div
          className="reports-container"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <label className="reports-header-label">
            {ReportsLabel[props.applicationSettings.locale]}
          </label>
          <div className="report-buttons-row-container">
            {pollStatus.pdfReportGenerating ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="generating-report-container"
              >
                <label>
                  {GeneratingReportLabel[props.applicationSettings.locale]}
                </label>
                <Loader
                  size={"xs"}
                  color={
                    props.applicationSettings.theme === "dark"
                      ? "yellow"
                      : "dark"
                  }
                />
              </motion.div>
            ) : (
              <label>
                {GenerateReportLabel[props.applicationSettings.locale]}
              </label>
            )}
            {!pollStatus.pdfReportGenerating && (
              <>
                {years.map((year, idx) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 + idx * 0.1 }}
                      className="report-button-container"
                      key={idx}
                    >
                      <Button
                        key={idx}
                        className="cs-theme-button"
                        radius={"xs"}
                        leftIcon={<FiFileText />}
                        loading={listenForFinishedReportGeneration}
                        onClick={() => {
                          generateReport(year);
                        }}
                      >
                        {year}
                      </Button>
                    </motion.div>
                  );
                })}
              </>
            )}
          </div>
          {props.dashboardInfo.pdfReportsArray.length > 0 && (
            <GeneratedReports
              generatedReports={props.dashboardInfo.pdfReportsArray}
            />
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
