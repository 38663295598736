import { motion } from "framer-motion";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { CurrencyOut, Fee } from "../../../interfaces/Report/TransactionData";
import "./transaction-item-types.scss";
import { TransactionType } from "../TransactionTypeLabels/TransactionTypeLabel";
import { Currency } from "../../../interfaces/Import/Currency";

interface Props {
  applicationSettings: ApplicationSettings;
  currencyOut?: CurrencyOut;
  currencies?:
    | {
        [k: string]: Currency;
      }
    | undefined;
  currencyNames?:
    | {
        [k: string]: string | undefined;
      }
    | undefined;
  showExtras?: boolean;
  focus?: boolean;
  fee?: Fee;
}
export function CancelledTransactionHeader(
  props: React.PropsWithChildren<Props>
) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.1 }}
      className="cancelled-transaction-type-container"
    >
      <TransactionType type={"Cancelled"} focus={props.focus} />
    </motion.div>
  );
}
