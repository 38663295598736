import "../components/Dashboard/dashboard.scss";

import { useCallback, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { AuthenticationContext } from "../App";
import { DashboardHeader } from "../components/Dashboard/DashboardHeader";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { DashboardInfo } from "../interfaces/Dashboard/DashboardInfo";
import DashboardService from "../service/DashboardService";
import { Dashboard } from "../components/Dashboard/Dashboard";
import { useDocumentTitle } from "@mantine/hooks";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}

export function DashboardPage(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);

  const subtitle = "Dashboard";
  useDocumentTitle(`CryptoSkat | ${subtitle}`);

  const fetchDashboardInfo = useCallback(async () => {
    if (!authenticationData) return;
    return (await DashboardService.getDashboard(
      authenticationData.token
    )) as DashboardInfo;
  }, [authenticationData]);

  const { data, isLoading, refetch } = useQuery(
    "dashboard",
    fetchDashboardInfo,
    {
      cacheTime: 5000,
      staleTime: 5000,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (error: any) => {
        console.log("dashboard fetch error");
        if (error.response.status === 401) {
          props.handleLogout();
        }
      },
    }
  );

  useEffect(() => {
    if (!data && !isLoading) {
      refetch();
    }
  }, [data, isLoading, refetch]);

  return (
    <div className="page-card-container">
      <DashboardHeader applicationSettings={props.applicationSettings} />
      {data ? (
        <Dashboard
          dashboardInfo={data}
          applicationSettings={props.applicationSettings}
          refetchDashboardInfo={() => refetch()}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
