import axios from "axios";
import { createOptions, handleResponse } from "./CreateOptions";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const getListOfAccounts = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/accounts", options);
  return handleResponse(response);
};

const getUsersExhangesList = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-users-exchanges-list", options);
  return handleResponse(response);
};

const deleteAccount = async (token: string, accountId: number) => {
  const options = createOptions(token);
  const response = await http.get(`/accounts/delete/${accountId}`, options);
  return handleResponse(response);
};

const getDecodeJSKey = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-decode-js-key", options);
  return handleResponse(response);
};

const getUserAccountsListByExchange = async (
  token: string,
  exchangeId: number
) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-users-accounts-list-by-exchange/${exchangeId}`,
    options
  );
  return handleResponse(response);
};

const requestApiGetAccountTransactions = async (
  token: string,
  account: string | undefined
) => {
  const options = createOptions(token);
  const response = await http.get(
    `upload-account-transactions/${account}`,
    options
  );
  return handleResponse(response);
};

const getAccountsWithApiIntegration = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-accounts-with-api", options);
  return handleResponse(response);
};

const getAccountAPIKeys = async (token: string, accountId: number) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-account-api-data/${accountId}`,
    options
  );
  return handleResponse(response);
};

const editExchangeAccount = async (
  token: string,
  accountId: string,
  accountName: string | undefined,
  exchangeId: string | undefined,
  apiKey: string,
  apiSecret: string,
  passphrase: string | undefined
) => {
  const options = createOptions(token);
  const body: any = {};
  if (accountName) body["account_name"] = accountName;
  if (exchangeId) body["exchange_id"] = exchangeId;
  body["encrypted_api_key"] = apiKey;
  body["encrypted_api_secret"] = apiSecret;
  if (passphrase) body["encrypted_api_passphrase"] = passphrase;
  const response = await http.post(
    `/edit-exchange-account/${accountId}`,
    body,
    options
  );
  return handleResponse(response);
};

const AccountService = {
  getListOfAccounts,
  deleteAccount,
  getUsersExhangesList,
  getUserAccountsListByExchange,
  requestApiGetAccountTransactions,
  getDecodeJSKey,
  getAccountsWithApiIntegration,
  getAccountAPIKeys,
  editExchangeAccount,
};

export default AccountService;
