import "./user-menu-item.scss";

interface Props {
  label: string;
  path: string;
  icon: React.ReactNode;
  handlePathClick: (path: string) => void;
  isSelected: boolean;
  compact?: boolean;
}
export function UserMenuItem(props: React.PropsWithChildren<Props>) {
  return (
    <div
      onClick={() => {
        props.handlePathClick(props.path);
      }}
      className={
        props.isSelected
          ? "menu-item-container-selected"
          : "menu-item-container"
      }
    >
      <div className="menu-item-container-inner">
        {props.icon}
        {!props.compact && <label>{props.label}</label>}
      </div>
    </div>
  );
}
