import { NumberInput, SegmentedControl, Space, Text } from "@mantine/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  AcquisitionCostLabel,
  GainLabel,
  LossLabel,
  SalesProceedsLabel,
} from "../../../utils/labels";
import {
  SetLocalEditedTransactionItemContext,
  GetLocalEditedTransactionItemContext,
} from "./ReportRowItem";
import {
  ExchangeRate,
  ExchangeRateValues,
} from "../../../interfaces/Report/ExchangeRates";
import { ensureFloat } from "../../../utils/formatter";
import { ExchangeRateDescription } from "./ExchangeRateDescription";

interface Props {
  applicationSettings: ApplicationSettings;
  salesUsd: string;
  salesNc: string;
  gainUsd: string | undefined;
  gainNc: string | undefined;
  lossUsd: string | undefined;
  lossNc: string | undefined;
  editMode: boolean;
  showGain?: boolean;
  disableSalesProceeds?: boolean;
  blockToEditKey: "fee" | "currencyIn" | "currencyOut";
  exchangeRate: ExchangeRate | undefined;
}
export function SalesSection(props: React.PropsWithChildren<Props>) {
  const setLocalEditedTransactionItem = useContext(
    SetLocalEditedTransactionItemContext
  );
  const localEditedTransactionItem = useContext(GetLocalEditedTransactionItemContext);

  const [selectedExchangeRateCurrency, setSelectedExchangeRate] = useState<
    "DKK" | "USD" | "EUR"
  >("DKK");

  const [salesProceedsValues, setSalesProceedsValues] =
    useState<ExchangeRateValues>({
      DKK: ensureFloat(props.salesNc) ?? 0,
      EUR:
        props.salesNc && props.exchangeRate
          ? ensureFloat(props.salesNc) / props.exchangeRate.EUR
          : 0,
      USD:
        props.salesNc && props.exchangeRate
          ? ensureFloat(props.salesNc) / props.exchangeRate.USD
          : 0,
    });

  const handleSalesNCChange = useCallback(
    (
      value: number | undefined,
      _selectedExchangeRateCurrency: "DKK" | "USD" | "EUR"
    ) => {
      if (!value) {
        setSalesProceedsValues({
          DKK: 0,
          USD: 0, // Converting DKK to USD
          EUR: 0, // Converting DKK to EUR
        });
        return;
      }
      if (!localEditedTransactionItem || !props.exchangeRate) return;

      const rateToDKK = props.exchangeRate[_selectedExchangeRateCurrency]; // How many DKK 1 unit of selected currency is

      const updatedValueInDKK =
        _selectedExchangeRateCurrency === "DKK" ? value : value * rateToDKK; // Converting to DKK

      setSalesProceedsValues({
        DKK: updatedValueInDKK,
        USD: updatedValueInDKK / props.exchangeRate.USD, // Converting DKK to USD
        EUR: updatedValueInDKK / props.exchangeRate.EUR, // Converting DKK to EUR
      });

      const _blockToEdit = localEditedTransactionItem[props.blockToEditKey];
      if (!_blockToEdit) return;
      _blockToEdit.salesProceedInNC = updatedValueInDKK.toFixed(2);
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        [props.blockToEditKey]: _blockToEdit,
      });
    },
    [
      setLocalEditedTransactionItem,
      localEditedTransactionItem,
      props.blockToEditKey,
      props.exchangeRate,
      setSalesProceedsValues,
    ]
  );

  useEffect(() => {
    if (!props.editMode && selectedExchangeRateCurrency !== "DKK") {
      setSelectedExchangeRate("DKK");
    }
  }, [props.editMode]);

  return (
    <div className="sales-section">
      <div className="outer-column-section">
        <Space w={"xs"} />
        <div className="column-section">
          <NumberInput
            icon={
              <Text>
                <label>{selectedExchangeRateCurrency}</label>
              </Text>
            }
            hideControls
            size="xs"
            precision={2}
            description={
              props.editMode && (
                <ExchangeRateDescription
                  applicationSettings={props.applicationSettings}
                  dkkValue={salesProceedsValues.DKK}
                />
              )
            }
            className={
              props.editMode && props.salesNc?.length === 0
                ? "transaction-input-missing"
                : "transaction-input"
            }
            label={
              <Text className="sales-section-label" color={"dimmed"}>
                <label>
                  {SalesProceedsLabel[props.applicationSettings.locale]}
                </label>
              </Text>
            }
            disabled={
              props.disableSalesProceeds
                ? props.disableSalesProceeds
                : !props.editMode
            }
            value={salesProceedsValues[selectedExchangeRateCurrency]}
            onChange={(value) => {
              handleSalesNCChange(value, selectedExchangeRateCurrency);
            }}
            defaultValue={
              props.salesNc?.length > 0 ? parseFloat(props.salesNc) : 0
            }
          />
          {props.editMode && props.exchangeRate ? (
            <SegmentedControl
              value={selectedExchangeRateCurrency}
              onChange={(value) => {
                if (["DKK", "USD", "EUR"].includes(value))
                  setSelectedExchangeRate(value as any);
              }}
              data={[
                { label: "DKK", value: "DKK" },
                { label: "USD", value: "USD" },
                { label: "EUR", value: "EUR" },
              ]}
            />
          ) : undefined}
        </div>
      </div>

      {props.gainNc && (
        <div className="outer-column-section">
          <Space w={"xs"} />
          <div className="column-section-gain">
            <NumberInput
              icon={
                <Text>
                  <label>DKK</label>
                </Text>
              }
              label={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Text
                    className="sales-section-label"
                    color={"dimmed"}
                    size={"md"}
                  >
                    <label>{GainLabel[props.applicationSettings.locale]}</label>
                  </Text>
                  <Text
                    className="sales-section-label"
                    color={"dimmed"}
                    size={"xs"}
                  >
                    <label>
                      ({SalesProceedsLabel[props.applicationSettings.locale]} -{" "}
                      {AcquisitionCostLabel[props.applicationSettings.locale]})
                    </label>
                  </Text>
                </div>
              }
              hideControls
              size="xs"
              precision={2}
              className={"transaction-input"}
              disabled
              value={parseFloat(props.gainNc)}
            />
          </div>
        </div>
      )}
      {props.lossNc && (
        <div className="outer-column-section">
          <Space w={"xs"} />
          <div className="column-section-loss">
            <NumberInput
              icon={
                <Text>
                  <label>DKK</label>
                </Text>
              }
              label={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Text
                    className="sales-section-label"
                    color={"dimmed"}
                    size={"md"}
                  >
                    <label>{LossLabel[props.applicationSettings.locale]}</label>
                  </Text>
                  <Text
                    className="sales-section-label"
                    color={"dimmed"}
                    size={"xs"}
                  >
                    <label>
                      ({SalesProceedsLabel[props.applicationSettings.locale]} -{" "}
                      {AcquisitionCostLabel[props.applicationSettings.locale]})
                    </label>
                  </Text>
                </div>
              }
              hideControls
              size="xs"
              precision={2}
              className={"transaction-input"}
              disabled
              value={parseFloat(props.lossNc)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
