import "./transaction-item-types.scss";
import { Tooltip, Space } from "@mantine/core";
import { motion } from "framer-motion";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { CurrencyOut, Fee } from "../../../interfaces/Report/TransactionData";
import { CurrencyIcon } from "../CurrencyIcon";
import { PreviewAmountLabel } from "../ReportBodyComponents/PreviewAmountLabel";
import { TransactionType } from "../TransactionTypeLabels/TransactionTypeLabel";
import { useContext, useMemo } from "react";
import { CurrencyContext } from "../../../App";

interface Props {
  applicationSettings: ApplicationSettings;
  currencyOut?: CurrencyOut;
  showExtras?: boolean;
  focus?: boolean;
  fee?: Fee;
}
export function WithdrawalHeader(props: React.PropsWithChildren<Props>) {
  const currencyContext = useContext(CurrencyContext);

  const outCurrency = useMemo(() => {
    if (!props.currencyOut || !currencyContext) return undefined;
    return currencyContext[props.currencyOut.id];
  }, [currencyContext, props.currencyOut]);

  const feeCurrency = useMemo(() => {
    if (!props.fee || !currencyContext) return undefined;
    return currencyContext[props.fee.id];
  }, [currencyContext, props.fee]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.1 }}
      className="withdrawal-type-container"
    >
      <TransactionType type={"Withdrawal"} focus={props.focus} />
      {props.currencyOut && outCurrency && props.showExtras && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="transaction-data-preview"
        >
          <Tooltip
            style={{ display: "flex", alignItems: "center" }}
            withArrow
            label={`${outCurrency.full_name} | - ${props.currencyOut.amount} `}
            position="bottom"
            transition={"fade"}
          >
            <CurrencyIcon currency={outCurrency} />
            <Space w="xs" />
            <PreviewAmountLabel
              withdrawal
              // show absolute value
              amount={Math.abs(parseFloat(props.currencyOut.amount)).toString()}
            />
          </Tooltip>
        </motion.div>
      )}
      {feeCurrency && !props.currencyOut && props.fee && props.showExtras && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="transaction-data-preview"
        >
          <Tooltip
            style={{ display: "flex", alignItems: "center" }}
            withArrow
            label={`${feeCurrency.full_name} | - ${props.fee.amount} `}
            position="bottom"
            transition={"fade"}
          >
            <CurrencyIcon currency={feeCurrency} />
            <Space w="xs" />
            <PreviewAmountLabel
              withdrawal
              // show absolute value
              amount={Math.abs(parseFloat(props.fee.amount)).toString()}
            />
          </Tooltip>
        </motion.div>
      )}
    </motion.div>
  );
}
