import { SegmentedControl, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { WalletApiInput } from "../../WalletApiInput";
import { PlatformLink } from "../PlatformLink";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { EtherscanApiInput } from "./EtherscanApiInput";

interface Props {
  applicationSettings: ApplicationSettings;
  platformId: string;
  closeDrawer: () => void;
}

export function Trezor(props: React.PropsWithChildren<Props>) {
  const [controlValue, setControlValue] = useState<string>("etherscan");

  const platformLink = (
    <>
      <PlatformLink href="https://trezor.io/" label="Trezor Online Wallet" />
    </>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} Online Wallet.
      </label>,
      <label className="instruction-label">
        Klik på <strong>Transactions</strong> i toppen.
      </label>,
      <label className="instruction-label">
        Upload CSV filen til CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log into your {platformLink}.
      </label>,
      <label className="instruction-label">
        Click on <strong>Transactions</strong> on the top.
      </label>,
      <label className="instruction-label">Upload the CSV file here</label>,
    ],
  };

  /* const instructionsClassic: any = {
    da: [
      <label className="instruction-label">
        Vælg <strong>Ethereum (ETH)</strong> eller{" "}
        <strong>Ethereum Classic (ETC)</strong> på {platformLink}. Tryk herefter{" "}
        <strong>Go to Trezor Ethereum Wallet</strong>.
      </label>,
      <label className="instruction-label">
        Tryk på <strong>See full transaction history</strong> på den side, der
        åbner.
      </label>,
      <label className="instruction-label">
        Kopier din wallet adresse fra den side, der åbner.
      </label>,
      <label className="instruction-label">
        Følg herefter https://etherscan.io/address/
        <strong>
          {"<"}Your wallet address{">"}
        </strong>{" "}
        linket på siden.
      </label>,
      <label className="instruction-label">
        Inde på Etherscan, vælg <strong>Download CSV Export</strong> på bunden
        af siden.
      </label>,
      <label className="instruction-label">
        Vælg startdatoen og slutdatoen for dataeksport. Bemærk at det ikke er
        muligt at eksportere mere end 5000 transaktioner indenfor den tidsramme
        du angiver.
      </label>,
      <label className="instruction-label">
        Download CSV filen og upload den efterfølgende til CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Select <strong>Ethereum (ETH)</strong> or{" "}
        <strong>Ethereum Classic (ETC)</strong> on {platformLink}. Click{" "}
        <strong>Go to Trezor Ethereum Wallet</strong>.
      </label>,
      <label className="instruction-label">
        Click <strong>See full transaction history</strong> on the page that
        opens.
      </label>,
      <label className="instruction-label">
        Copy your wallet address from the page that opens.
      </label>,
      <label className="instruction-label">
        Follow the link https://etherscan.io/address/
        <strong>
          {"<"}Your wallet address{">"}
        </strong>
        on the page that opens.
      </label>,
      <label className="instruction-label">
        In Etherscan, click <strong>Download CSV Export</strong> at the bottom
        of the page.
      </label>,
      <label className="instruction-label">
        Choose the start date and end date for data export. Note that it is not
        possible to export more than 5000 transactions within the time range you
        specify.
      </label>,
      <label className="instruction-label">
        Download the CSV file and upload it afterwards to CryptoSkat.
      </label>,
    ],
  }; */
  return (
    <div className="intructions-container-special">
      <SegmentedControl
        value={controlValue}
        onChange={(value: string) => {
          setControlValue(value);
        }}
        size="sm"
        data={[
          {
            value: "etherscan",
            label: "Etherscan API",
          },
          {
            value: "csv",
            label: "CSV",
          }
        ]}
      />
      <AnimatePresence exitBeforeEnter>
        {controlValue === "etherscan" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className="wallet-import-control-container"
          >
            <EtherscanApiInput
              applicationSettings={props.applicationSettings}
              walletId={props.platformId}
              closeDrawer={props.closeDrawer}
            />
          </motion.div>
        )}
        {controlValue === "csv" && ( // this is not used - keep for a while though
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className="wallet-import-control-container"
          >
            <label className="subheader">
              <strong>
                {props.applicationSettings.locale === "da"
                  ? "Instruktioner"
                  : "Instructions"}
              </strong>
            </label>
            <Space h={"xs"} />

            <div>
              <ul>
                {instructions[props.applicationSettings.locale].map(
                  (instruction: JSX.Element, index: number) => {
                    return (
                      <li key={index}>
                        <label>
                          <strong>{index + 1}.</strong>
                        </label>
                        {instruction}
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          <Space h={"xl"} />
        
            <WalletApiInput
              applicationSettings={props.applicationSettings}
              walletId={props.platformId}
              closeDrawer={props.closeDrawer}
              trezorOverride
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
