import { Button, PasswordInput, Progress, Space } from "@mantine/core";
import { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TriggerNotificationContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import AuthService from "../../service/AuthService";
import {
  getStrength,
  PasswordRequirement,
  requirements,
} from "../Registration/RegistrationForm";
import {
  ChangePasswordLabel,
  ConfirmYourNewPasswordLabel,
  PasswordRequirementLabel,
  YourNewPasswordLabel,
} from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  email: string;
  resetToken: string;
}
export function NewPasswordForm(props: React.PropsWithChildren<Props>) {
  const triggerNotification = useContext(TriggerNotificationContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const strength = getStrength(newPassword);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={
        props.applicationSettings.locale === "en"
          ? requirement.en_label
          : requirement.da_label
      }
      meets={requirement.re.test(newPassword)}
    />
  ));

  const resetPassword = useCallback(async () => {
    if (!props.resetToken || !props.email) return;
    if (newPassword !== confirmPassword) return;
    try {
      const response = await AuthService.resetPassword(
        props.resetToken,
        props.email,
        newPassword,
        confirmPassword
      );
      triggerNotification(response["message"], "info");
      navigate("/login");
    } catch (err: any) {
      const msg = err.response.data.message;
      triggerNotification(msg, "error");
    }
  }, [props, newPassword, confirmPassword, navigate, triggerNotification]);

  return (
    <div className="login-registration-form-container">
      <div className="password-reset-container">
        <PasswordInput
          className="input-field"
          id="password-input"
          label={YourNewPasswordLabel[props.applicationSettings.locale]}
          placeholder={YourNewPasswordLabel[props.applicationSettings.locale]}
          description={
            PasswordRequirementLabel[props.applicationSettings.locale]
          }
          value={newPassword}
          onChange={(e: any) => setNewPassword(e.currentTarget.value)}
          required
        />
        <PasswordInput
          className="input-field"
          id="password-input"
          label={ConfirmYourNewPasswordLabel[props.applicationSettings.locale]}
          placeholder={
            ConfirmYourNewPasswordLabel[props.applicationSettings.locale]
          }
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.currentTarget.value)}
          required
        />
        <Space h="xl" />
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        <PasswordRequirement
          label={PasswordRequirementLabel[props.applicationSettings.locale]}
          meets={newPassword.length > 7}
        />
        {checks}
        <Button
          className="cs-theme-button"
          radius={"xs"}
          disabled={newPassword !== confirmPassword || newPassword === ""}
          color={props.applicationSettings.theme === "dark" ? "gray" : "dark"}
          style={{ marginTop: 10 }}
          onClick={resetPassword}
        >
          {ChangePasswordLabel[props.applicationSettings.locale]}
        </Button>
      </div>
    </div>
  );
}
