import axios from "axios";
import { createOptions, handleResponse } from "./CreateOptions";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const getDashboard = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-dashboard-info", options);
  return handleResponse(response);
};

const DashboardService = {
  getDashboard,
};

export default DashboardService;
