import axios from "axios";
import { TransactionFilterParameters } from "../interfaces/Report/TransactionFilterParameters";
import { TransactionUpdateBody } from "../interfaces/Report/TransactionUpdateBody";
import { createOptions, handleResponse } from "./CreateOptions";
import { ExchangeRates } from "../interfaces/Report/ExchangeRates";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const getTransactionFilters = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-filters-for-transaction-page", options);
  return handleResponse(response);
};

const getErrorCodes = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-error-codes", options);
  return handleResponse(response);
};

const getServiceCodes = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-service-codes", options);
  return handleResponse(response);
};

const linkTransactions = async (
  token: string,
  transactionId: number,
  linkedToId: number | undefined | null
) => {
  const options = createOptions(token);
  const body = {
    transactionId: transactionId,
    linkedTo: linkedToId === undefined ? null : linkedToId,
  };
  return await http.post(
    "/link-transaction-to-another-transaction",
    body,
    options
  );
};

const updateTransaction = async (
  token: string,
  transactionId: string,
  transactionPayload: TransactionUpdateBody
) => {
  const options = createOptions(token);
  const body = {
    ...transactionPayload,
  };
  const response = await http.post(
    `/update-transaction/${transactionId}`,
    body,
    options
  );
  return handleResponse(response);
};

const deleteTransaction = async (token: string, transactionId: number) => {
  const options = createOptions(token);
  const response = await http.delete(
    `/delete-transaction/${transactionId}`,
    options
  );
  return handleResponse(response);
};

const deleteAllTransactions = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/delete-all-transactions", options);
  return handleResponse(response);
};

const updateSpecialTransactionType = async (
  token: string,
  transactionId: string,
  specialTransactionTypeId: string | null
) => {
  const options = createOptions(token);
  const body = {
    special_type_id: specialTransactionTypeId,
  };
  const response = await http.post(
    `/update-special-type/${transactionId}`,
    body,
    options
  );
  return handleResponse(response);
};

const getQueryFromFilterParameters = (
  _filterParameters: TransactionFilterParameters
) => {
  const filterParameters: any = { ..._filterParameters };
  const query = Object.keys(filterParameters)
    .map((key) => {
      if (
        filterParameters[key] === null ||
        filterParameters[key] === undefined
      ) {
        return "";
      }
      if (Array.isArray(filterParameters[key])) {
        return filterParameters[key]
          .map((value: any) => `${key}=${value}`)
          .join("&");
      }
      return `${key}=${filterParameters[key]}`;
    })
    .filter((key) => key !== "")
    .join("&");
  return query;
};

const getTransactionsList = async (
  token: string,
  transactionFilterParameters: TransactionFilterParameters
) => {
  const options = createOptions(token);
  const filterParameters = getQueryFromFilterParameters(
    transactionFilterParameters
  );
  const response = await http.get(
    `/getTransactionsList?${filterParameters}`,
    options
  );
  return handleResponse(response);
};

const getSingleTransaction = async (token: string, transactionId: number) => {
  const options = createOptions(token);
  const response = await http.get(`/transactions/${transactionId}`, options);
  return handleResponse(response);
};

/* const testUpdateUserTransactionInfo = async (token: string, userId: number) => {
  const options = createOptions(token);

  const response = await http.get(`/updateUserTransactionInfo`, options);
  return handleResponse(response);
}; */

const recalculateTransactions = async (token: string) => {
  const options = createOptions(token);

  const response = await http.get(`/recalculateTransactions`, options);
  return handleResponse(response);
};

const getTotalLossGainPerYear = async (
  token: string,
  startDate?: string,
  endDate?: string
) => {
  const options = createOptions(token);
  const startDateParam = startDate ? `?startDate=${startDate}` : "";
  const endDateParam = endDate && startDate ? `&endDate=${endDate}` : "";
  const response = await http.get(
    `/get-total-profit-by-period${startDateParam}${endDateParam}`,
    options
  );
  return handleResponse(response);
};

const generatePdfReport = async (
  token: string,
  startDate?: string,
  endDate?: string
) => {
  const options = createOptions(token);
  const body = {
    startDate: startDate,
    endDate: endDate,
  };
  const response = await http.post(`/generate-pdf-report`, body, options);
  return handleResponse(response);
};

const GetLinkingRuleSet = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-rules-for-linking-transactions`,
    options
  );
  return handleResponse(response);
};

const getReportDownloadUrl = async (token: string, reportId: string) => {
  const options = createOptions(token);
  const response = await http.get(`/get-pdf-report-link/${reportId}`, options);
  return handleResponse(response);
};

const downloadPdfReport = async (filename: string, blobStorageLink: string) => {
  const response = await fetch(blobStorageLink, {
    headers: {
      "x-ms-blob-type": "BlockBlob", // specify the blob type
      "x-ms-version": "2019-12-12", // specify the storage version
    },
  });

  if (response.ok) {
    const blob = await response.blob(); // convert the response to a blob object
    const url = URL.createObjectURL(blob); // create a URL for the blob object
    const link = document.createElement("a"); // create a link element
    link.href = url;
    link.download = `${filename}.pdf`; // specify the filename
    document.body.appendChild(link); // add the link to the DOM
    link.click(); // simulate a click on the link to trigger the download
    document.body.removeChild(link); // remove the link from the DOM
  } else {
    console.error(`Failed to download PDF: ${response.statusText}`);
  }
};

const getExchangeRates = async (token: string, year?: string) => {
  const options = createOptions(token);
  const yearExtension = year ? `/${year}` : "";
  const response = await http.get<ExchangeRates>(
    `/get-exchange-rates${yearExtension}`,
    options
  );
  return handleResponse(response);
};

const TransactionService = {
  getTransactionFilters,
  getErrorCodes,
  getServiceCodes,
  linkTransactions,
  updateTransaction,
  deleteTransaction,
  deleteAllTransactions,
  updateSpecialTransactionType,
  getTransactionsList,
  getTotalLossGainPerYear,
  recalculateTransactions,
  getSingleTransaction,
  generatePdfReport,
  downloadPdfReport,
  GetLinkingRuleSet,
  getReportDownloadUrl,
  getExchangeRates,
};

export default TransactionService;
