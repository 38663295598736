import "./special-customer-logo.scss";
import { useMediaQuery } from "@mantine/hooks";
import { Avatar } from "@mantine/core";

interface Props {
  specialCustomer: string;
}
export function SpecialCustomerLogo(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");

  const customerSlugs: { [key: string]: string } = {
    "Bitcoin Suisse": "bitcoin-suisse",
    Penning: "penning",
  };

  return (
    <div className="special-customer-logo">
      {!compact && <label>{props.specialCustomer}</label>}
      <Avatar
        size={"md"}
        radius="xs"
        src={`/assets/other/${customerSlugs[props.specialCustomer]}.png`}
      />
    </div>
  );
}
