interface Props {
  title: string;
  activeTheme: string;
  platform: "wallets" | "exchanges" | "other";
  compact: boolean;
  onClick?: () => void;
}
export function PlatformLogo(props: React.PropsWithChildren<Props>) {
  const title = props.title.toLowerCase().replace(/\s/g, "");

  const url = `url(/assets/${props.platform}/${title}/${
    props.compact
      ? `compact/${title}.svg)`
      : `normal/${title}-${props.activeTheme}.svg)`
  }`;

  return (
    <div
      className="logo-container"
      onClick={props.onClick}
      style={{
        backgroundImage: url,
        height: `1.1rem`,
        width: props.compact ? "1.1rem" : `6rem`,
        cursor: "pointer",
      }}
    ></div>
  );
}
