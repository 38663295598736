import { Space, Text, Tooltip } from "@mantine/core";
import { Account } from "../../../interfaces/Accounts/AccountsList";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { AuthenticationData } from "../../../interfaces/Authentication/AuthenticationData";
import { AccountsControlsRow } from "../AccountControlsRow";
import { useClipboard } from "@mantine/hooks";
import {
  AccountNameLabel,
  CopiedLabel,
  CopyLabel,
  TransactionsLabel,
} from "../../../utils/labels";
import { useContext } from "react";
import { FetchedExchangesContext, FetchedWalletsContext } from "../../../App";
import { PlatformLogo } from "../../Import/Instructions/manual/PlatformLogo";

interface Props {
  account: Account;
  compact: boolean;
  tariffInactive: boolean;
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  triggerAccountRefetch: () => void;
}
export function AccountsTableRow(props: React.PropsWithChildren<Props>) {
  const exchanges = useContext(FetchedExchangesContext);
  const wallets = useContext(FetchedWalletsContext);

  // create a concatenated map for exchanges and wallets with the id as key and slug as value
  const exchangesMap = new Map<string, string>();
  exchanges?.data.forEach((exchange) => {
    exchangesMap.set(exchange.id.toString(), exchange.slug);
  });
  const walletsMap = new Map<string, string>();
  wallets?.data.forEach((wallet) => {
    walletsMap.set(wallet.id.toString(), wallet.slug);
  });
  // merge the two maps
  const platformMap = new Map([...exchangesMap, ...walletsMap]);
  const platformKeys = [...exchangesMap.keys(), ...walletsMap.keys()];

  const clipboard = useClipboard({ timeout: 800 });

  return (
    <div className="accounts-table-row">
      <div className="account-name-container">
        <label>
          <Text color={"dimmed"}>
            <label>{AccountNameLabel[props.applicationSettings.locale]}</label>
          </Text>
          <Tooltip label={CopyLabel[props.applicationSettings.locale]}>
            <strong
              className="copy-label"
              onClick={() => clipboard.copy(props.account.account_name)}
            >
              {clipboard.copied
                ? CopiedLabel[props.applicationSettings.locale]
                : props.account.account_name}
            </strong>
          </Tooltip>
        </label>
        {platformKeys.includes(props.account.exchange_id.toString()) &&
          platformMap.get(props.account.exchange_id.toString()) && (
            <PlatformLogo
              activeTheme={props.applicationSettings.theme}
              compact={props.compact}
              platform={
                exchangesMap.get(props.account.exchange_id.toString())
                  ? "exchanges"
                  : "wallets"
              }
              title={platformMap.get(props.account.exchange_id.toString())!}
            />
          )}
      </div>
      <div className="transaction-count-container">
        <label>
          {props.account.transactions_count}{" "}
          {TransactionsLabel[props.applicationSettings.locale]}{" "}
        </label>
      </div>
      <Space />
      <div className="account-controls-row-container">
        <AccountsControlsRow
          applicationSettings={props.applicationSettings}
          account={props.account}
          authenticationData={props.authenticationData}
          compact={props.compact}
          triggerAccountRefetch={props.triggerAccountRefetch}
          tariffInactive={props.tariffInactive}
        />
      </div>
    </div>
  );
}
