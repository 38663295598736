import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { TransactionServiceIndicator } from "./TransactionServiceIndicator";

interface Props {
  applicationSettings: ApplicationSettings
  errorInformation: number[];
  serviceInformation: number[];
  innerId: number;
  edited: boolean;
  handleFix: (innerId: number) => void;
  enableLinkMode: (fromTransactionId: number) => void;
}
export function PreviewExtras(props: React.PropsWithChildren<Props>) {
  return (
    <>
      {props.serviceInformation.length > 0 && (
        <TransactionServiceIndicator
          applicationSettings={props.applicationSettings}
          handleFix={() => props.handleFix(props.innerId)}
          enableLinkMode={() => props.enableLinkMode(props.innerId)}
          serviceCodes={props.serviceInformation}
          errorCodes={props.errorInformation}
        />
      )}
    </>
  );
}
