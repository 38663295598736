import axios from "axios";
import { VoucherPrices } from "../interfaces/Membership/VoucherPrices";
import { createOptions, handleResponse } from "./CreateOptions";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const getUserInvoices = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-user-invoices", options);
  return handleResponse(response);
};

export const downloadPDFInvoice = async (token: string, invoiceId: number) => {
  http
    .get(`/download-pdf-invoice/${invoiceId}`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Cryptoskat_invoice_${invoiceId}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
};

export const getPlans = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-plans-info", options);
  return handleResponse(response);
};

const getPricePlan = async (
  token: string,
  planHash: string,
  voucherCode?: string
) => {
  const options = createOptions(token);
  if (!options) return;
  let urlParams = `?plan_hash=${planHash}`;
  if (voucherCode) {
    urlParams += `&voucher_code=${voucherCode}`;
  }
  const response = await http.get(`/get-price-of-plan${urlParams}`, options);
  return handleResponse(response);
};

const getPricePlanWithVoucher = async (
  token: string,
  planId: number,
  voucherCode: string
) => {
  const options = createOptions(token);
  if (!options) return;
  const urlParams = `?plan_id=${planId}&voucher_code=${voucherCode}`;
  return await http.get<VoucherPrices>(
    `/get-price-with-voucher${urlParams}`,
    options
  );
};

const getStripeSetupIntent = async (
  token: string,
  planId: number,
  voucher?: string
) => {
  const options = createOptions(token);
  if (!options) return;
  const body = {
    plan_id: planId,
    voucher_code: voucher,
  };
  const response = await http.post("/get-setup-intent-and-key", body, options);
  return handleResponse(response);
};

const getPriceWithVoucher = async (
  token: string,
  planId: string,
  voucher: string
) => {
  const options = createOptions(token);
  if (!options) return;
  const body = {
    plan_id: planId,
    voucher_code: voucher,
  };
  const response = await http.post("/get-price-with-voucher", body, options);
  return handleResponse(response);
};

const MembershipService = {
  getUserInvoices,
  getPricePlanWithVoucher,
  getPricePlan,
  getStripeSetupIntent,
  downloadPDFInvoice,
  getPlans,
  getPriceWithVoucher,
};

export default MembershipService;
