import { SegmentedControl } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { InstructionsLabel } from "../../../../utils/labels";
import { WalletApiInput } from "../../WalletApiInput";
import { PlatformLink } from "../PlatformLink";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { EtherscanApiInput } from "./EtherscanApiInput";

interface Props {
  applicationSettings: ApplicationSettings;
  platformId: string;
  closeDrawer: () => void;
}

export function Metamask(props: React.PropsWithChildren<Props>) {
  const [controlValue, setControlValue] = useState<string>("etherscan");

  const chromeExtensionLink = (
    <>
      <PlatformLink
        href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
        label="Chrome Extension"
      />
    </>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Vælg <strong>View on Etherscan</strong> på dit Metamask account. (Brug{" "}
        {chromeExtensionLink})
      </label>,
      <label className="instruction-label">
        Herefter åbnes https://etherscan.io/address/
        <strong>
          {"<"}Your wallet address{">"}
        </strong>
        .
      </label>,
      <label className="instruction-label">
        Klik <strong>Download CSV Export</strong> i bunden af siden.
      </label>,
      <label className="instruction-label">
        Vælg en start og en slut dato. Bemærk at det ikke er muligt at
        eksportere mere end 5000 transaktioner indenfor den tidsramme du
        angiver.
      </label>,
      <label className="instruction-label">
        Download CSV filen og upload den efterfølgende til CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Select <strong>View on Etherscan</strong> on your Metamask account. (Use{" "}
        {chromeExtensionLink})
      </label>,
      <label className="instruction-label">
        https://etherscan.io/address/
        <strong>
          {"<"}Your wallet address{">"}
        </strong>{" "}
        is opened afterwards.
      </label>,
      <label className="instruction-label">
        Click <strong>Download CSV Export</strong> in the bottom of the page.
      </label>,
      <label className="instruction-label">
        Choose the start date and end date for data export. Note that it is not
        possible to export more than 5000 transactions within the time range you
        specify.
      </label>,
      <label className="instruction-label">
        Download the CSV file and upload it here.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <SegmentedControl
        value={controlValue}
        onChange={(value: string) => {
          setControlValue(value);
        }}
        size="sm"
        data={[
          {
            value: "etherscan",
            label: "Etherscan API",
          },
        ]}
      />
      <AnimatePresence exitBeforeEnter>
        {controlValue === "etherscan" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className="wallet-import-control-container"
          >
            <EtherscanApiInput
              applicationSettings={props.applicationSettings}
              walletId={props.platformId}
              closeDrawer={props.closeDrawer}
            />
          </motion.div>
        )}
        {controlValue === "csv" && ( // this is not used - keep for a while though
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className="wallet-import-control-container"
          >
            <label className="subheader">
              <strong>
                {InstructionsLabel[props.applicationSettings.locale]}
              </strong>
            </label>
            <ul>
              {instructions[props.applicationSettings.locale].map(
                (instruction: JSX.Element, index: number) => {
                  return (
                    <li key={index}>
                      <label>
                        <strong>{index + 1}.</strong>
                      </label>
                      {instruction}
                    </li>
                  );
                }
              )}
            </ul>

            <WalletApiInput
              applicationSettings={props.applicationSettings}
              walletId={props.platformId}
              closeDrawer={props.closeDrawer}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
