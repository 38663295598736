import { Tooltip, Space } from "@mantine/core";
import { motion } from "framer-motion";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { CurrencyIn } from "../../../interfaces/Report/TransactionData";
import { CurrencyIcon } from "../CurrencyIcon";
import { PreviewAmountLabel } from "../ReportBodyComponents/PreviewAmountLabel";
import "./transaction-item-types.scss";
import { TransactionType } from "../TransactionTypeLabels/TransactionTypeLabel";
import { CurrencyContext } from "../../../App";
import { useContext, useMemo } from "react";

interface Props {
  applicationSettings: ApplicationSettings;
  currencyIn?: CurrencyIn;

  showExtras?: boolean;
  focus?: boolean;
}
export function DepositHeader(props: React.PropsWithChildren<Props>) {
  const currencyContext = useContext(CurrencyContext);

  const inCurrency = useMemo(() => {
    if (!props.currencyIn || !currencyContext) return undefined;
    return currencyContext[props.currencyIn.id];
  }, [currencyContext, props.currencyIn]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.1 }}
      className="deposit-type-container"
    >
      <TransactionType type={"Deposit"} focus={props.focus} />

      {inCurrency && props.currencyIn && props.showExtras && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="transaction-data-preview"
        >
          <Tooltip
            style={{ display: "flex", alignItems: "center" }}
            withArrow
            label={`${props.currencyIn.currencyFullName} | + ${props.currencyIn.amount} `}
            position="bottom"
            transition={"fade"}
          >
            <CurrencyIcon currency={inCurrency} />
            <Space w="xs" />
            <PreviewAmountLabel amount={props.currencyIn.amount} />
          </Tooltip>
        </motion.div>
      )}
    </motion.div>
  );
}
