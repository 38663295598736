export const transactionTypeIdToSnakeCase = (transactionTypeId: string) => {
  
  switch (transactionTypeId) {
    // withdrawal ids
    case "18":
      return "add_to_staking";
    case "19":
      return "add_to_lending";
    case "20":
      return "add_to_liq_pool";
    case "21":
      return "sent_gift";
    case "22":
      return "add_to_ico";

    // deposit ids
    case "5":
      return "airdrop";
    case "6":
      return "gift";
    case "11":
      return "staking_reward";
    case "12":
      return "lending_interest";
    case "13":
      return "mining_reward";
    case "14":
      return "hard_fork";
    case "15":
      return "out_from_staking";
    case "16":
      return "out_from_lending";
    case "17":
      return "out_from_liq_pool";
    case "23":
      return "out_from_ico";
    case "24":
      return "liquidity_pool_yield";
    default:
      return "no_tag";
  }
};
