import { Button, Drawer, Space, Tooltip } from "@mantine/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AuthenticationContext } from "../../App";
import MembershipService from "../../service/MembershipService";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";

import {
  AdjustedPriceLabel,
  AllTaxYearsLabel,
  BuyLabel,
  ExtendPlanLabel,
  MembershipLabel,
  PlanPriceSuffixLabel,
  PriceLabel,
  RemainingDaysOnPlanLabel,
  TransactionsLabel,
  UnlimitedExchangesAndWalletsLabel,
} from "../../utils/labels";
import { motion } from "framer-motion";
import { PaymentContent } from "./payment-content/PaymentContent";
import { SubscriptionPlans } from "../../interfaces/Membership/SubscriptionPlans";
import { VoucherContent } from "./payment-content/VoucherContent";
import { FiShoppingCart } from "react-icons/fi";
import { PlanToBuy } from "../../interfaces/Membership/PricePlan";
import { PaymentDetailsLabel } from "./payment-content/PaymentDetailsLabel";

interface Props {
  applicationSettings: ApplicationSettings;
}

export function MembershipContent(props: React.PropsWithChildren<Props>) {
  const membershipTransactions: { [key: string]: number } = {
    Intro: 25,
    Investor: 1000,
    Trader: 3000,
    Daytrader: 10000,
  };

  const authenticationData = useContext(AuthenticationContext);

  const [opened, setOpened] = useState(false);
  const [voucherCode, setVoucherCode] = useState<string | undefined>(undefined);
  const [voucherPhase, setVoucherPhase] = useState(false);
  const [payButtonLabel, setPayButtonLabel] = useState<string | undefined>();

  const [selectedPlan, setSelectedPlan] = useState<PlanToBuy | undefined>();

  const parseStringToNumber = (value: string | null) => {
    if (!value) return 0;
    return parseFloat(value.replace(",", "."));
  };

  const getUserPlans = useCallback(async () => {
    if (!authenticationData) return;
    return (await MembershipService.getPlans(
      authenticationData.token
    )) as SubscriptionPlans;
  }, [authenticationData]);

  const plansQuery = useQuery("userPlans", getUserPlans, {
    cacheTime: 60000,
    staleTime: 60000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!plansQuery.data && !plansQuery.isLoading) {
      plansQuery.refetch();
    }
    return () => {};
  }, [plansQuery]);

  const showRemainingDays = plansQuery.data
    ? plansQuery.data.remainingDays > 0 &&
      plansQuery.data.plan.title !== "Intro"
    : false;

  return (
    <div className="membership-content-container">
      <div className="content-header">
        <label>{MembershipLabel[props.applicationSettings.locale]}</label>
      </div>
      <div className="memberships-cards-container">
        {plansQuery.data && (
          <>
            <div className="current-membership-plan-container">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="membership-plan-container"
                id={"active-membership-plan"}
              >
                <div className="membership-plan-header">
                  <label>{plansQuery.data.plan.title}</label>
                </div>
                <div className="membership-plan-body">
                  <label>
                    {
                      UnlimitedExchangesAndWalletsLabel[
                        props.applicationSettings.locale
                      ]
                    }
                  </label>
                  <label>
                    {AllTaxYearsLabel[props.applicationSettings.locale]}
                  </label>
                  <label>
                    {TransactionsLabel[props.applicationSettings.locale]}:{" "}
                    {membershipTransactions[plansQuery.data.plan.title]}
                  </label>
                </div>
                <div className="membership-plan-footer">
                  {plansQuery.data.plan.adjustedPrice && (
                    <Tooltip
                      disabled={plansQuery.data.plan.paymentDetails === null}
                      withArrow
                      position="bottom"
                      label={
                        <>
                          {plansQuery.data.plan.paymentDetails !== null && (
                            <PaymentDetailsLabel
                              applicationSettings={props.applicationSettings}
                              paymentDetails={
                                plansQuery.data.plan.paymentDetails!
                              }
                              originalPrice={plansQuery.data.plan.price}
                            />
                          )}
                        </>
                      }
                    >
                      <>
                        <label>
                          {AdjustedPriceLabel[props.applicationSettings.locale]}
                          : {plansQuery.data.plan.adjustedPrice} DKK
                        </label>
                        <Space h="xs" />
                      </>
                    </Tooltip>
                  )}

                  <label
                    id={plansQuery.data.plan.adjustedPrice ? "old-value" : ""}
                  >
                    {PriceLabel[props.applicationSettings.locale]}:{" "}
                    {plansQuery.data.plan.price}{" "}
                    {PlanPriceSuffixLabel[props.applicationSettings.locale]}
                  </label>

                  {showRemainingDays && (
                    <>
                      <Space h="xs" />
                      <label>
                        {plansQuery.data.remainingDays}{" "}
                        {
                          RemainingDaysOnPlanLabel[
                            props.applicationSettings.locale
                          ]
                        }
                      </label>
                    </>
                  )}

                  {parseStringToNumber(plansQuery.data.plan.adjustedPrice) >
                    0 &&
                    plansQuery.data.plan &&
                    plansQuery.data.plan.title !== "Intro" && (
                      <div className="membership-action-container">
                        <Space h="xs" />
                        <Button
                          radius={"xs"}
                          leftIcon={<FiShoppingCart />}
                          className="cs-theme-button"
                          onClick={() => {
                            if (plansQuery.data) {
                              setSelectedPlan({
                                planId: plansQuery.data?.plan.id,
                                planHash: plansQuery.data?.plan.plan_hash,
                                planTitle: plansQuery.data?.plan.title,
                              });
                              setVoucherPhase(true);
                              setOpened(true);
                            }
                          }}
                        >
                          {ExtendPlanLabel[props.applicationSettings.locale]}{" "}
                          {plansQuery.data?.plan.title}
                        </Button>
                      </div>
                    )}
                </div>
              </motion.div>
            </div>
            {plansQuery.data.otherPlans.length > 0 && (
              <div className="membership-plans-container">
                {plansQuery.data.otherPlans.map((plan, idx) => (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, delay: idx * 0.1 }}
                    className="membership-plan-container"
                    key={idx}
                  >
                    <div className="membership-plan-header">
                      <label>{plan.title}</label>
                    </div>
                    <div className="membership-plan-body">
                      <label>
                        {
                          UnlimitedExchangesAndWalletsLabel[
                            props.applicationSettings.locale
                          ]
                        }
                      </label>
                      <label>
                        {AllTaxYearsLabel[props.applicationSettings.locale]}
                      </label>
                      <label>
                        {TransactionsLabel[props.applicationSettings.locale]}:{" "}
                        {membershipTransactions[plan.title]}
                      </label>
                    </div>
                    <div className="membership-plan-footer">
                      {plan.adjustedPrice && (
                        <Tooltip
                          disabled={plan.paymentDetails === null}
                          withArrow
                          position="bottom"
                          label={
                            <>
                              {plan.paymentDetails !== null && (
                                <PaymentDetailsLabel
                                  applicationSettings={
                                    props.applicationSettings
                                  }
                                  paymentDetails={plan.paymentDetails!}
                                  originalPrice={plan.price}
                                />
                              )}
                            </>
                          }
                        >
                          <>
                            <label>
                              {
                                AdjustedPriceLabel[
                                  props.applicationSettings.locale
                                ]
                              }
                              : {plan.adjustedPrice} DKK
                            </label>
                            <Space h="xs" />
                          </>
                        </Tooltip>
                      )}
                      <label id={plan.adjustedPrice ? "old-value" : ""}>
                        {PriceLabel[props.applicationSettings.locale]}:{" "}
                        {plan.price}{" "}
                        {PlanPriceSuffixLabel[props.applicationSettings.locale]}
                      </label>
                      <Space h={"sm"} />
                      <div className="membership-action-container">
                        <Button
                          radius={"xs"}
                          leftIcon={<FiShoppingCart />}
                          className="cs-theme-button"
                          onClick={() => {
                            setSelectedPlan({
                              planId: plan.id,
                              planHash: plan.plan_hash,
                              planTitle: plan.title,
                              planPreviewPrice: plan.adjustedPrice,
                            });
                            setVoucherPhase(true);
                            setOpened(true);
                          }}
                        >
                          {BuyLabel[props.applicationSettings.locale]}{" "}
                          {plan.title}
                        </Button>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
          setVoucherPhase(false);
          setVoucherCode(undefined);
          setSelectedPlan(undefined);
        }}
        title={
          selectedPlan && (
            <label className="plan-title">{selectedPlan.planTitle} Plan</label>
          )
        }
        position="right"
        size="xl"
        padding="xl"
        overlayOpacity={0.55}
        overlayBlur={3}
      >
        {voucherPhase && selectedPlan && (
          <VoucherContent
            applicationSettings={props.applicationSettings}
            selectedPlan={selectedPlan}
            voucherCode={voucherCode}
            setVoucherCode={setVoucherCode}
            setVoucherPhase={setVoucherPhase}
            setPayButtonLabel={setPayButtonLabel}
            showVoucherField={plansQuery.data?.showVoucherField ?? false}
          />
        )}
        {!voucherPhase && selectedPlan && payButtonLabel && (
          <PaymentContent
            applicationSettings={props.applicationSettings}
            planId={selectedPlan.planId}
            voucherCode={voucherCode}
            buttonLabel={payButtonLabel}
          />
        )}
      </Drawer>
    </div>
  );
}
