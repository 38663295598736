import { ActionIcon, Button, Modal, Tooltip } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import {
  FiDownloadCloud,
  FiEdit,
  FiTrash,
  FiTrash2,
  FiX,
} from "react-icons/fi";
import { FetchedExchangesContext } from "../../App";
import { Account } from "../../interfaces/Accounts/AccountsList";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AuthenticationData } from "../../interfaces/Authentication/AuthenticationData";
import AccountService from "../../service/AccountService";
import {
  CancelLabel,
  CollectDataLabel,
  CollectingDataLabel,
  ConfirmDeletionOf,
  DeleteAccount,
  DeleteLabel,
  ChangeApiKeysLabel,
} from "../../utils/labels";
import { RenewApiKeysContainer } from "./RenewApiKeysContainer";

interface Props {
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  account: Account;
  triggerAccountRefetch: () => void;
  compact: boolean;
  tariffInactive: boolean;
}
export function AccountsControlsRow(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);

  const handleAccountDelete = useCallback(async () => {
    if (!props.authenticationData) return;
    const response = await AccountService.deleteAccount(
      props.authenticationData.token,
      props.account.id
    );
    if (response["status"] === "success") {
      props.triggerAccountRefetch();
      setOpened(false);
    }
  }, [props, setOpened]);

  const handleCollectDataClick = useCallback(async () => {
    if (!props.authenticationData) return;
    try {
      await AccountService.requestApiGetAccountTransactions(
        props.authenticationData.token,
        props.account.id.toString()
      );
    } finally {
      props.triggerAccountRefetch();
    }
  }, [props]);

  const [modalMode, setModalMode] = useState<"delete" | "edit">("delete");

  return (
    <div className="account-controls-container">
      {props.account.updating_with_api && (
        <Tooltip label={ChangeApiKeysLabel[props.applicationSettings.locale]}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              setModalMode("edit");
              setOpened(true);
            }}
            size={props.compact ? "sm" : "lg"}
            disabled={props.account.updating_now || props.tariffInactive}
          >
            <FiEdit />
          </ActionIcon>
        </Tooltip>
      )}
      {props.account.updating_with_api && (
        <Tooltip
          label={
            props.account.updating_now
              ? CollectingDataLabel[props.applicationSettings.locale]
              : CollectDataLabel[props.applicationSettings.locale]
          }
        >
          <ActionIcon
            variant="outline"
            disabled={!props.account.updating_with_api || props.tariffInactive}
            onClick={handleCollectDataClick}
            loading={props.account.updating_now}
            size={props.compact ? "sm" : "lg"}
          >
            <FiDownloadCloud />
          </ActionIcon>
        </Tooltip>
      )}

      <Tooltip label={DeleteAccount[props.applicationSettings.locale]}>
        <ActionIcon
          variant="outline"
          color={"red"}
          disabled={
            props.account.updating_with_api && props.account.updating_now
          }
          size={props.compact ? "sm" : "lg"}
          onClick={() => {
            setModalMode("delete");
            setOpened(true);
          }}
        >
          <FiTrash />
        </ActionIcon>
      </Tooltip>

      <Modal
        opened={opened}
        centered
        size={"xs"}
        onClose={() => {
          setOpened(false);
        }}
        title={
          modalMode === "delete" ? (
            <label>
              {ConfirmDeletionOf[props.applicationSettings.locale]} <br />
              <strong>{props.account.account_name}</strong>
            </label>
          ) : (
            <label>
              {ChangeApiKeysLabel[props.applicationSettings.locale]}
            </label>
          )
        }
        overlayColor="rgba(0, 0, 0, 0.5)"
        overlayBlur={2}
      >
        {modalMode === "delete" && (
          <div className="account-deletion-confirmation-modal-button-container">
            <Button
              leftIcon={<FiTrash2 />}
              onClick={handleAccountDelete}
              color="red"
            >
              {DeleteLabel[props.applicationSettings.locale]}
            </Button>
            <Button
              leftIcon={<FiX />}
              onClick={() => setOpened(false)}
              className="cs-theme-button"
            >
              {CancelLabel[props.applicationSettings.locale]}
            </Button>
          </div>
        )}
        {modalMode === "edit" && (
          <RenewApiKeysContainer
            accountName={props.account.account_name}
            platformId={props.account.exchange_id.toString()}
            applicationSettings={props.applicationSettings}
            accountId={props.account.id}
            closeModal={() => setOpened(false)}
          />
        )}
      </Modal>
    </div>
  );
}
