import "./ReportHeaderComponents/report-header.scss";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UseQueryResult } from "react-query";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AuthenticationData } from "../../interfaces/Authentication/AuthenticationData";
import { LossGain } from "../../interfaces/Report/LossGainStats";
import { TransactionFilterContext } from "../../pages/ReportPage";
import { ReportCenterSection } from "./ReportHeaderComponents/ReportCenterSection";
import { ReportFilterMenu } from "./ReportHeaderComponents/ReportFilterMenu";
import { ReportStats } from "./ReportHeaderComponents/ReportStats";
import { PollContext } from "../../App";
import { BarLoader } from "../CustomLoaders/BarLoader";
import { TransactionListResponse } from "../../interfaces/Report/TransactionListResponse";
import { CalculatingTransactionsLabel } from "../../utils/labels";
import { useMediaQuery } from "@mantine/hooks";
import { CompactReportHeader } from "./CompactReportHeader";
import { Skeleton } from "@mantine/core";

interface Props {
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  walletsWithLogos: string[] | undefined;
  exchangesWithLogos: string[] | undefined;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  totalLossGainQuery: UseQueryResult<LossGain | undefined, unknown>;
  popTransactionBodyUpdate: (transactionId: string) => void;
  popTransactionTagUpdate: (transactionId: string) => void;
}
/**
 *
 *
 * @export
 * @param {React.PropsWithChildren<Props>} props
 * @return {*}
 */
export function ReportHeader(props: React.PropsWithChildren<Props>) {
  const transactionFilters = useContext(TransactionFilterContext);
  const pollStatus = useContext(PollContext);

  const [width, setWidth] = useState(0);

  const compactHeader = useMediaQuery("(max-width: 1274px)");

  const [uploadProgress, setUploadProgress] = useState(0);
  const [hideBar, setHideBar] = useState(false);

  const handleSetUploadProgress = useCallback(
    (progress: number) => {
      setUploadProgress(progress);
    },
    [setUploadProgress]
  );

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    if (uploadProgress === 100) {
      setTimeout(() => {
        setHideBar(true);
      }, 1000);
    }
    if (hideBar) {
      setTimeout(() => {
        setUploadProgress(0);
        setHideBar(false);
      }, 2000);
    }
  }, [ref, uploadProgress, hideBar, setUploadProgress, setHideBar]);

  return pollStatus.reportUpdating && pollStatus.reportUpdating ? (
    <>
      {!compactHeader ? (
        <div className="report-header-container" ref={ref}>
          <BarLoader
            applicationSettings={props.applicationSettings}
            label={
              CalculatingTransactionsLabel[props.applicationSettings.locale]
            }
          />
        </div>
      ) : (
        <Skeleton className="compact-report-loading-container" />
      )}
    </>
  ) : (
    <>
      {compactHeader ? (
        <CompactReportHeader
          applicationSettings={props.applicationSettings}
          popTransactionBodyUpdate={props.popTransactionBodyUpdate}
          popTransactionTagUpdate={props.popTransactionTagUpdate}
          handleSetUploadProgress={handleSetUploadProgress}
          transactionsQuery={props.transactionsQuery}
          totalLossGainQuery={props.totalLossGainQuery}
          exchangesWithLogos={props.exchangesWithLogos}
          transactionFilters={transactionFilters}
          walletsWithLogos={props.walletsWithLogos}
        />
      ) : (
        <div className="report-header-container" ref={ref}>
          <div
            className="upload-bar-container"
            style={{ width: `${width - 4}px` }}
          >
            <div
              className="upload-bar"
              style={{
                width: `${uploadProgress}%`,
                visibility: uploadProgress === 0 ? "hidden" : "visible",
                opacity: hideBar ? 0 : uploadProgress / 100,
              }}
            ></div>
          </div>
          <ReportStats
            applicationSettings={props.applicationSettings}
            totalLossGainQuery={props.totalLossGainQuery}
          />
          <ReportCenterSection
            applicationSettings={props.applicationSettings}
            popTransactionBodyUpdate={props.popTransactionBodyUpdate}
            popTransactionTagUpdate={props.popTransactionTagUpdate}
            handleSetUploadProgress={handleSetUploadProgress}
            transactionsQuery={props.transactionsQuery}
            totalLossGainQuery={props.totalLossGainQuery}
          />
          <ReportFilterMenu
            walletsWithLogos={props.walletsWithLogos}
            exchangesWithLogos={props.exchangesWithLogos}
            transactionFilters={transactionFilters}
            applicationSettings={props.applicationSettings}
          />
        </div>
      )}
    </>
  );
}
