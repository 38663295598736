import { Divider, SegmentedControl, Space, TextInput } from "@mantine/core";
import { useCallback, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  AuthenticationContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { WalletOptions } from "../../interfaces/Import/WalletOptions";
import ImportService from "../../service/ImportService";
import {
  CSVHasBeenUploadedLabel,
  EnterWalletAddressLabel,
  WalletAddressLabel,
} from "../../utils/labels";
import { UploadDropzoneButton } from "./Instructions/UploadDropzoneButton";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  walletId: string;
  applicationSettings: ApplicationSettings;
  trezorOverride?: boolean;
  closeDrawer: () => void;
}
export function WalletApiInput(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);
  const compact = useMediaQuery("(max-width: 900px");

  const [file, setFile] = useState<any>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [exportType, setExportType] = useState<
    string | "etherscan" | "original" | "both" | undefined
  >();
  const [hasWalletOptions, setHasWalletOptions] = useState<boolean>(false);

  const fetchWalletOptions = useCallback(async () => {
    if (!authenticationData) return;
    return (await ImportService.getWalletImportFormats(
      authenticationData.token,
      props.walletId
    )) as WalletOptions;
  }, [authenticationData, props.walletId]);

  const { data, isLoading, refetch } = useQuery(
    "walletOptions",
    fetchWalletOptions,
    {
      cacheTime: 1,
      staleTime: 1,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLoading(false);
        if (!data) return;
        if (data.etherscan_export === 1 && data.original_export === 1) {
          setHasWalletOptions(true);
          setExportType("etherscan");
          return;
        }
        setExportType(data.etherscan_export === 1 ? "etherscan" : "original");
        return;
      },
      onError: (error: any) => {
        setLoading(false);
        setExportType("etherscan");
      },
    }
  );

  const uploadCsv = useCallback(async () => {
    if (!authenticationData) return;
    if (!file) return;
    if (!walletAddress) return;

    const response = await ImportService.importWalletTransactionsFromCSV(
      authenticationData.token,
      file,
      props.walletId,
      walletAddress,
      props.trezorOverride ? false : exportType === "etherscan"
    );
    if (response.status === "success") {
      triggerNotification(
        CSVHasBeenUploadedLabel[props.applicationSettings.locale],
        "success"
      );
      props.closeDrawer();
      setPollingStatus({ needRequest: true, reportUpdating: true });
    }
  }, [
    authenticationData,
    file,
    walletAddress,
    exportType,
    props,
    triggerNotification,
    setPollingStatus,
  ]);

  useEffect(() => {
    if (!data && !isLoading) {
      refetch();
    }
  }, [data, isLoading, refetch]);

  return (
    <>
      <Divider />
      {hasWalletOptions ? (
        <div>
          <Space h="xs" />
          <label>Format: </label>
          <SegmentedControl
            data={[
              /* { label: "Etherscan", value: "etherscan" }, */
              { label: "Original", value: "original" },
            ]}
            onChange={(e) => setExportType(e)}
          />
        </div>
      ) : undefined}
      <div className="wallet-input-container">
        {loading ? undefined : (
          <>
            <TextInput
              size={compact ? "xs" : "sm"}
              className="input-field-hash"
              id="wallet-address-input"
              placeholder={WalletAddressLabel[props.applicationSettings.locale]}
              label={EnterWalletAddressLabel[props.applicationSettings.locale]}
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value.trim())}
            />
            <UploadDropzoneButton
              applicationSettings={props.applicationSettings}
              onSuccesfulDrop={(file) => {
                setFile(file);
              }}
              onClick={uploadCsv}
              buttonDisabled={!file || !walletAddress}
            />
          </>
        )}
      </div>
    </>
  );
}
