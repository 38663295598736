import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string; 
  applicationSettings: ApplicationSettings;
}

export function Bitstamp(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor
      href="https://www.bitstamp.net/settings/access-control/api/"
      target="_blank"
    >
      <strong>Bitstamp</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto, tryk på account ikonet i øvre højre
        hjørne og vælg <strong>Settings</strong>.
      </label>,
      <label className="instruction-label">
        Vælg <strong>API access</strong> i venstre side af skærmen for at tilgå{" "}
        <strong>API</strong>-siden.
      </label>,
      <label className="instruction-label">
        Generér en ny API nøgle og tildel kun følgende rettigheder <br />
        <strong>account balance</strong> og <strong>user transactions</strong>.
      </label>,
      <label className="instruction-label">
        Aktiver den genererede nøgle hos Bitstamp
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account, click on your{" "}
        <strong>Account icon</strong> (top right corner) and select{" "}
        <strong>Settings</strong>.
      </label>,
      <label className="instruction-label">
        Select <strong>API access</strong> on the left hand side to access the{" "}
        <strong>API</strong>-page.
      </label>,
      <label className="instruction-label">
        Generate a new API key and give the following privileges: <br />
        <strong>account balance</strong> and <strong>user transactions</strong>.
      </label>,
      <label className="instruction-label">
        Activate the generated key on Bitstamp
      </label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput applicationSettings={props.applicationSettings} platformId={props.platformId}  />

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
