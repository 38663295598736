import { motion } from "framer-motion";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import "./../components/Settings/payment-content/payment-successful.scss";
import { PaymentSuccessfulLabel } from "../utils/labels";
import { FiCheckCircle } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function PaymentSuccessfulPage(props: React.PropsWithChildren<Props>) {
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      setTimeout(() => {
        window.location.href = "/#/dashboard";
        navigate("/dashboard");
      }, 5000);
    }
  }, [loaded, setLoaded, navigate]);

  return (
    <div className="page-card-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="payment-successful-container"
      >
        <FiCheckCircle className="checkmark" size={84} />
        <label>
          {PaymentSuccessfulLabel[props.applicationSettings.locale]}
        </label>
      </motion.div>
    </div>
  );
}
