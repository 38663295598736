import axios from "axios";
import { createOptions, handleResponse } from "./CreateOptions";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const login = async (email: string, password: string) => {
  const body = {
    email: email,
    password: password,
  };
  try {
    const response = await http.post("/login", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verify = async (token: string, code: number) => {
  const options = createOptions(token);

  const response = await http.post(
    `/verify?two_factor_code=${code}`,
    undefined,
    options
  );
  return handleResponse(response);
};

export const logout = async (token: string) => {
  const options = createOptions(token);
  const response = await http.post("/logout", undefined, options);
  return handleResponse(response);
};

export const resendCode = async (token: string) => {
  const options = createOptions(token);
  const response = await http.post("/verify/resend", undefined, options);
  return handleResponse(response);
};

export const refreshToken = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/refresh", options);
  return handleResponse(response);
};

const resetPassword = async (
  resetToken: string,
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  const body = {
    token: resetToken,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
  };
  const response = await http.post("/password/reset", body);
  return handleResponse(response);
};

const changePassword = async (
  token: string,
  email: string,
  currentPassword: string,
  password: string,
  passwordConfirmation: string
) => {
  const body = {
    email: email,
    current_password: currentPassword,
    new_password: password,
    new_confirm_password: passwordConfirmation,
    cron_updating: true,
  };

  const options = createOptions(token);
  const response = await http.post("/update-user-data", body, options);
  return handleResponse(response);
};

const requestResetPassword = async (email: string) => {
  const body = {
    email: email,
    siteUrl: `${window.location.href}`,
  };
  const response = await http.post("/sendResetLinkEmail", body);
  return handleResponse(response);
};

const deleteUser = async (token: string, email: string) => {
  const options = createOptions(token);
  const body = {
    email: email,
  };
  const response = await http.post("/delete-user", body, options);
  return handleResponse(response);
};

const AuthService = {
  login,
  verify,
  logout,
  resendCode,
  refreshToken,
  resetPassword,
  requestResetPassword,
  changePassword,
  deleteUser,
};

export default AuthService;
