import { ScrollArea, Space, Tooltip } from "@mantine/core";
import { useCallback, useContext } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { AuthenticationContext } from "../../App";
import { PdfReportItem } from "../../interfaces/Dashboard/DashboardInfo";
import TransactionService from "../../service/TransactionService";

interface Props {
  generatedReports: PdfReportItem[];
}
export function GeneratedReports(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);

  const formatTitle = (title: string): string => {
    const split = title.split("(generated ");
    return split[0];
  };

  const extractGeneratedDate = (title: string): string => {
    const split = title.split("(generated ");
    return split[1].replace(")", "");
  };

  const extractReportId = (link: string): string => {
    const split = link.split("/");
    return split[split.length - 1];
  };

  const createFileName = useCallback(
    (title: string, reportId: string): string => {
      const split = title.split("(generated ");
      const stringToBeFormatted = split[0].trim();
      const dateStrings = stringToBeFormatted.split("to");
      const endDate = dateStrings[1].trim();
      const startDate = dateStrings[0].replace("From", "").trim();
      return `CryptoSkat-report-${reportId}_${startDate}_to_${endDate}`;
    },
    []
  );

  const downloadReport = useCallback(
    async (_link: string, _title: string) => {
      if (!authenticationData) return;
      const reportId = extractReportId(_link);
      const filename = createFileName(_title, reportId);
      const response = await TransactionService.getReportDownloadUrl(
        authenticationData.token,
        reportId
      );
      await TransactionService.downloadPdfReport(filename, response["link"]);
    },
    [authenticationData, createFileName]
  );

  return (
    <ScrollArea
      className="generated-reports-container"
    >
      {props.generatedReports.map((report, idx) => {
        return (
          <div className="generated-report-item-container" key={idx}>
            <div
              className="report-title"
              onClick={() => downloadReport(report.link, report.title)}
            >
              <Space w="xs" />
              <label>CS Report: {formatTitle(report.title)}</label>
              <Space w="xs" />
              <FiDownloadCloud />
              <Space w="xs" />
            </div>
          </div>
        );
      })}
    </ScrollArea>
  );
}
