import axios from "axios";
import { RegistrationInput } from "../interfaces/Registration/RegistrationInput";
import { RegistrationResponse } from "../interfaces/Registration/RegistrationResponse";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const register = async (registrationInput: RegistrationInput) => {
  const body = {
    first_name: registrationInput.firstName,
    last_name: registrationInput.lastName,
    email: registrationInput.email,
    password: registrationInput.password,
    agree_with_conditions: registrationInput.agreeToTerms,
    email_confirmation: registrationInput.emailConfirmation,
    password_confirmation: registrationInput.passwordConfirmation,
    plan_hash: undefined,
  };
  try {
    const response = await http.post<RegistrationResponse>("/register", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getStripePlans = async () => {
  try {
    const response = await http.get("/plans");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const RegistrationService = {
  register,
  getStripePlans,
};

export default RegistrationService;
