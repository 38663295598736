import "./report-row-item.scss";
import {
  Badge,
  Button,
  Drawer,
  Loader,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Space,
} from "@mantine/core";
import { UseQueryResult } from "react-query";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { TransactionListResponse } from "../../../interfaces/Report/TransactionListResponse";
import { ReportRowItem } from "./ReportRowItem";
import { motion } from "framer-motion";
import {
  useForceUpdate,
  useMediaQuery,
  useScrollIntoView,
} from "@mantine/hooks";
import {
  createContext,
  LegacyRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { FiFilter, FiLink, FiPlus, FiX } from "react-icons/fi";
import TransactionService from "../../../service/TransactionService";
import {
  AuthenticationContext,
  CurrencyContext,
  PollContext,
} from "../../../App";
import {
  EditedTransactionsContext,
  GetPendingTransactionBodyUpdatesContext,
  GetTransactionBodyUpdatesContext,
  GetTransactionDeletionsContext,
  SetFilterParametersContext,
  SetTransactionDeletionsContext,
  SetTransactionLinkUpdatesContext,
  TransactionFilterParametersContext,
  TransactionLinkingRuleSet,
} from "../../../pages/ReportPage";
import { Currency } from "../../../interfaces/Import/Currency";
import {
  FeeHistory,
  TransactionHistory,
  TransactionItem,
} from "../../../interfaces/Report/TransactionData";
import { transactionTypeIds } from "../../../utils/transactionTypeHelper";
import { capitalizeString } from "../../../utils/formatter";
import { ReportTabBarItem } from "../../../interfaces/Report/ReportTabBarItem";
import { ReportPopupTabBar } from "./ReportPopupTabBar";
import {
  FromTransactionLink,
  LinkingRuleSet,
  TransactionTypeLinkingRuleSet,
} from "../../../interfaces/Report/LinkingRuleSet";
import { MdAccountTree, MdFilterAlt } from "react-icons/md";
import { CgImport } from "react-icons/cg";
import {
  AddTransactionLabel,
  ClearFiltersLabel,
  ImportFromExistingAccountLabel,
  ImportFromNewAccountLabel,
  ImportInProgressLabel,
  NoTransactionsLabel,
  NoTransactionsWithThisFilterLabel,
  ShowLabel,
} from "../../../utils/labels";
import { useNavigate } from "react-router-dom";
import { Manual } from "../../Import/Instructions/manual/Manual";
import { ReportInspectedItem } from "./ReportInspectedItem";
import { BarLoader } from "../../CustomLoaders/BarLoader";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  expandedId: number | undefined;
  walletsWithLogos: string[] | undefined;
  exchangesWithLogos: string[] | undefined;
  inspectedTransactionItem: TransactionItem | undefined;
  setInspectedTransactionItem: (item: TransactionItem | undefined) => void;
  mobile: boolean;
  handleClicked: (id: number) => void;
  collapse: () => void;
  handleLogout: () => void;
  popTransactionTagUpdate: (transactionId: string) => void;
}

export function ReportScrollableBody(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const editedTransactionIds = useContext(EditedTransactionsContext);
  const setTransactionFilterParameters = useContext(SetFilterParametersContext);
  const transactionFilterParameters = useContext(
    TransactionFilterParametersContext
  );
  const transactionLinkingRules = useContext(TransactionLinkingRuleSet);
  const pollStatus = useContext(PollContext);
  const setTransactionDeletions = useContext(SetTransactionDeletionsContext);
  const transactionDeletions = useContext(GetTransactionDeletionsContext);

  const transactionBodyUpdates = useContext(GetTransactionBodyUpdatesContext);
  const pendingTransactionBodyUpdates = useContext(
    GetPendingTransactionBodyUpdatesContext
  );
  const setLinkUpdates = useContext(SetTransactionLinkUpdatesContext);
  const currencies = useContext(CurrencyContext);

  const currenciesList = useMemo(() => {
    if (!currencies) return [];
    return Object.values(currencies);
  }, [currencies]);

  const forceUpdate = useForceUpdate();
  const scrollIntoView = useScrollIntoView();
  const compact = useMediaQuery("(max-width: 1168px)");
  const navigate = useNavigate();

  const handleClicked = useCallback(
    (id: number, ref?: LegacyRef<HTMLDivElement>) => {
      if (transactionDeletions?.[id]) return;
      props.handleClicked(id);
      scrollIntoView.scrollIntoView();
      props.setInspectedTransactionItem(
        props.transactionsQuery.data?.transactions.find((t) => t.id === id)
      );
    },
    [props, scrollIntoView, transactionDeletions]
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [deletedTransactionIds, setDeletedTransactionIds] = useState<number[]>(
    []
  );
  const [fromTransactionId, setFromTransactionId] = useState<
    number | undefined
  >();

  const [fromTransactionLinkParameters, setFromTransactionParameters] =
    useState<FromTransactionLink | undefined>();

  const [linkingRules, setLinkingRules] = useState<
    LinkingRuleSet | undefined
  >();

  const [toTransactionId, setToTransactionId] = useState<number | undefined>();
  const [modalOpened, setModalOpened] = useState(false);

  const [historyBadges, setHistoryBadges] = useState<ReportTabBarItem[]>([]);
  const [inspectedTransactions, setInspectedTransactions] = useState<{
    [key: number]: JSX.Element;
  }>({});
  const [selectedInspectedHistoryId, setSelectedInspectedHistoryId] =
    useState<number>();
  const [loadingHistoryItem, setLoadingHistoryItem] = useState(false);

  const getLinkableableType = useCallback((_transactionType: string) => {
    if (!_transactionType) return;
    if (_transactionType === "deposit") return "withdrawal";
    if (_transactionType === "withdrawal") return "deposit";
  }, []);

  const handleSetLinkableTypes = useCallback(
    (fromTransactionId: number) => {
      if (!props.transactionsQuery.data) return;
      if (!transactionLinkingRules) return;
      const fromTransaction = props.transactionsQuery.data.transactions.find(
        (t) => t.innerId === fromTransactionId
      );
      if (!fromTransaction) return;
      const transactionType = fromTransaction.transactionType;
      const amount =
        transactionType === "deposit"
          ? fromTransaction.currencyIn!.amount
          : fromTransaction.currencyOut!.amount || "0";
      const currencyShortName =
        transactionType === "deposit"
          ? fromTransaction.currencyIn!.currency
          : fromTransaction.currencyOut!.currency;

      const _fromTransactionLinkParameters: FromTransactionLink = {
        transactionId: fromTransaction.innerId.toString(),
        transactionType: transactionType,
        amount: amount,
        currencyShortName: currencyShortName,
        specialTypeId: fromTransaction.specialType,
      };
      setFromTransactionParameters(_fromTransactionLinkParameters);
      const _linkingRules =
        transactionType === "deposit"
          ? transactionLinkingRules.linkFromWithdrawal
          : transactionLinkingRules.linkFromDeposit;
      setLinkingRules(_linkingRules);
    },
    [
      props.transactionsQuery.data,
      setLinkingRules,
      setFromTransactionParameters,
      transactionLinkingRules,
    ]
  );

  const enableLinkMode = useCallback(
    (_fromTransactionId: number) => {
      setToTransactionId(undefined);
      handleSetLinkableTypes(_fromTransactionId);
      setFromTransactionId(_fromTransactionId);
    },
    [setFromTransactionId, setToTransactionId, handleSetLinkableTypes]
  );

  const linkToTransaction = useCallback(
    async (toTransactionId: number) => {
      if (!fromTransactionId) return;
      setLinkUpdates(fromTransactionId, toTransactionId);
      setFromTransactionId(undefined);
    },
    [setLinkUpdates, setFromTransactionId, fromTransactionId]
  );

  const disableLinkMode = useCallback(() => {
    setFromTransactionId(undefined);
  }, [setFromTransactionId]);

  const markAsDeleted = useCallback(
    (id: number) => {
      setTransactionDeletions(id);
      forceUpdate();
    },
    [forceUpdate, setTransactionDeletions]
  );

  const getTransactionPopupItem = useCallback(
    (
      _inspectedTransaction: TransactionItem,
      _currencies: Currency[],
      _transactionLinkingRules: TransactionTypeLinkingRuleSet
    ) => {
      return (
        <ReportRowItem
          walletsWithLogos={props.walletsWithLogos}
          exchangesWithLogos={props.exchangesWithLogos}
          popTransactionTagUpdate={props.popTransactionTagUpdate}
          refetchTransactions={() => props.transactionsQuery.refetch()}
          id={_inspectedTransaction.id}
          applicationSettings={props.applicationSettings}
          transactionItem={_inspectedTransaction}
          expanded={true}
          handleClicked={() => {}}
          collapse={props.collapse}
          targetRef={scrollIntoView.targetRef}
          markAsDeleted={markAsDeleted}
          deleted={deletedTransactionIds?.includes(_inspectedTransaction.id)}
          enableLinkMode={enableLinkMode}
          fromTransactionId={fromTransactionId}
          linkToTransaction={linkToTransaction}
          cancelEditMode={disableLinkMode}
          linkingRules={linkingRules}
          fromTransactionParameters={fromTransactionLinkParameters}
          pendingDeletion={transactionDeletions?.[_inspectedTransaction.id]}
          editedTransaction={
            editedTransactionIds?.includes(_inspectedTransaction.id.toString())
              ? JSON.parse(localStorage.getItem("transactionUpdates")!)[
                  _inspectedTransaction.id.toString()
                ]
              : undefined
          }
          currencies={_currencies}
          allTransactionLinkingRules={_transactionLinkingRules}
          isPopup
        />
      );
    },
    [
      props,
      deletedTransactionIds,
      fromTransactionId,
      editedTransactionIds,
      disableLinkMode,
      enableLinkMode,
      linkToTransaction,
      markAsDeleted,
      scrollIntoView.targetRef,
      fromTransactionLinkParameters,
      linkingRules,
      transactionDeletions,
    ]
  );

  const handleClickExistingHistoryTab = useCallback(
    (id: number) => {
      setLoadingHistoryItem(true);
      setSelectedInspectedHistoryId(id);
      forceUpdate();
      setTimeout(() => {
        setLoadingHistoryItem(false);
      }, 1);
    },
    [setSelectedInspectedHistoryId, setLoadingHistoryItem, forceUpdate]
  );

  const openModal = useCallback(
    async (
      history: TransactionHistory | FeeHistory,
      historyBadge: JSX.Element
    ) => {
      if (!authenticationData) return;
      if (!modalOpened) setModalOpened(true);
      setLoadingHistoryItem(true);
      const singleTransactionResponse =
        await TransactionService.getSingleTransaction(
          authenticationData.token,
          history.id
        );
      const transactionItem: TransactionItem | undefined =
        singleTransactionResponse as TransactionItem;
      if (!transactionItem || !transactionLinkingRules) return;
      if (transactionItem.id) setSelectedInspectedHistoryId(transactionItem.id);
      const _tabBarItem: ReportTabBarItem = {
        globalId: history.id,
        badgeElement: historyBadge,
      };

      if (!historyBadges.find((x) => x.globalId === history.id)) {
        historyBadges.push(_tabBarItem);
      }
      setHistoryBadges(historyBadges);
      inspectedTransactions[history.id] = getTransactionPopupItem(
        transactionItem,
        currenciesList,
        transactionLinkingRules
      );
      setInspectedTransactions(inspectedTransactions);
      forceUpdate();
      setTimeout(() => {
        setLoadingHistoryItem(false);
      }, 200);
    },
    [
      setModalOpened,
      authenticationData,
      historyBadges,
      inspectedTransactions,
      setInspectedTransactions,
      setSelectedInspectedHistoryId,
      currenciesList,
      forceUpdate,
      modalOpened,
      getTransactionPopupItem,
      setLoadingHistoryItem,
      transactionLinkingRules,
    ]
  );

  const switchTransactionTypeFilter = useCallback(
    (transactionTypeName: string) => {
      const linkableTypeId =
        transactionTypeIds[capitalizeString(transactionTypeName)].toString();
      setTransactionFilterParameters({
        ...transactionFilterParameters,
        transactionTypeId: linkableTypeId,
      });
    },
    [transactionFilterParameters, setTransactionFilterParameters]
  );

  return (
    <OpenModalContext.Provider value={openModal}>
      <ScrollArea
        className="report-scrollable-body-container"
        ref={scrollIntoView.scrollableRef}
      >
        <>
          {fromTransactionId && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15, delay: 0.15 }}
              className="link-mode-overlay"
            >
              <div className="close-button" onClick={disableLinkMode}>
                <FiX />
              </div>
              <div className="link-mode-overlay-row">
                <label>Link: </label>
                <Badge
                  radius={"xs"}
                  className="cs-theme-button"
                  size="lg"
                  leftSection={
                    <div style={{ display: "flex" }}>
                      <FiLink />
                    </div>
                  }
                >
                  {fromTransactionId}
                </Badge>{" "}
              </div>
              <div className="link-mode-overlay-row-loader">
                {fromTransactionLinkParameters &&
                  getLinkableableType(
                    fromTransactionLinkParameters.transactionType
                  ) && (
                    <Button
                      size="xs"
                      variant="outline"
                      color={"gray"}
                      onClick={() =>
                        switchTransactionTypeFilter(
                          getLinkableableType(
                            fromTransactionLinkParameters.transactionType
                          )!
                        )
                      }
                      leftIcon={<FiFilter />}
                    >
                      {ShowLabel[props.applicationSettings.locale]}{" "}
                      {getLinkableableType(
                        fromTransactionLinkParameters.transactionType
                      )}
                      s
                    </Button>
                  )}
              </div>
            </motion.div>
          )}

          <LoadingOverlay
            visible={props.transactionsQuery.isLoading}
            loader={
              <BarLoader applicationSettings={props.applicationSettings} />
            }
          />
          {props.mobile &&
          props.inspectedTransactionItem &&
          currencies &&
          props.expandedId !== undefined ? (
            <ReportInspectedItem
              walletsWithLogos={props.walletsWithLogos}
              exchangesWithLogos={props.exchangesWithLogos}
              applicationSettings={props.applicationSettings}
              transactionItem={props.inspectedTransactionItem}
              popTransactionTagUpdate={props.popTransactionTagUpdate}
              transactionsQuery={props.transactionsQuery}
              editedTransactionIds={editedTransactionIds}
              deletedTransactionIds={deletedTransactionIds}
              fromTransactionId={fromTransactionId}
              linkingRules={linkingRules}
              fromTransactionLinkParameters={fromTransactionLinkParameters}
              currencies={currenciesList}
              linkToTransaction={linkToTransaction}
              markAsDeleted={markAsDeleted}
              enableLinkMode={enableLinkMode}
              disableLinkMode={disableLinkMode}
            />
          ) : (
            <>
              {props.transactionsQuery.data?.transactions &&
              props.transactionsQuery.data?.transactions.length > 0 ? (
                props.transactionsQuery.data.transactions.map(
                  (transactionItem, index) => {
                    const editedTransaction =
                      pendingTransactionBodyUpdates?.[
                        transactionItem.id.toString()
                      ] ??
                      transactionBodyUpdates?.[transactionItem.id.toString()] ??
                      undefined;

                    return (
                      <motion.div
                        key={transactionItem.id}
                        initial={{ opacity: 0, scaleY: 0 }}
                        animate={{ opacity: 1, scaleY: 1 }}
                        transition={{ duration: 0.45, delay: index * 0.045 }}
                      >
                        {currencies &&
                          transactionLinkingRules &&
                          !deletedTransactionIds?.includes(
                            transactionItem.id
                          ) && (
                            <ReportRowItem
                              pendingDeletion={
                                transactionDeletions?.[transactionItem.id]
                              }
                              walletsWithLogos={props.walletsWithLogos}
                              exchangesWithLogos={props.exchangesWithLogos}
                              popTransactionTagUpdate={
                                props.popTransactionTagUpdate
                              }
                              refetchTransactions={() =>
                                props.transactionsQuery.refetch()
                              }
                              key={transactionItem.id}
                              id={transactionItem.id}
                              applicationSettings={props.applicationSettings}
                              transactionItem={transactionItem}
                              expanded={props.expandedId === transactionItem.id}
                              handleClicked={handleClicked}
                              collapse={props.collapse}
                              targetRef={scrollIntoView.targetRef}
                              markAsDeleted={markAsDeleted}
                              deleted={deletedTransactionIds?.includes(
                                transactionItem.id
                              )}
                              enableLinkMode={enableLinkMode}
                              fromTransactionId={fromTransactionId}
                              linkToTransaction={linkToTransaction}
                              cancelEditMode={disableLinkMode}
                              linkingRules={linkingRules}
                              fromTransactionParameters={
                                fromTransactionLinkParameters
                              }
                              allTransactionLinkingRules={
                                transactionLinkingRules
                              }
                              editedTransaction={editedTransaction}
                              currencies={currenciesList}
                            />
                          )}
                      </motion.div>
                    );
                  }
                )
              ) : (
                <>
                  {props.transactionsQuery.data?.transactions.length === 0 && (
                    <div className="empty-transactions-container">
                      <label>
                        {transactionFilterParameters.startDate ||
                        transactionFilterParameters.transactionTypeId ||
                        transactionFilterParameters.exchangeId
                          ? `${
                              NoTransactionsWithThisFilterLabel[
                                props.applicationSettings.locale
                              ]
                            }`
                          : `${
                              NoTransactionsLabel[
                                props.applicationSettings.locale
                              ]
                            }`}
                      </label>

                      {pollStatus.reportUpdating || pollStatus.needRequest ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            gap: "4px",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                        >
                          <label>
                            {
                              ImportInProgressLabel[
                                props.applicationSettings.locale
                              ]
                            }
                          </label>
                          <Loader
                            size={"xs"}
                            color={
                              props.applicationSettings.theme === "dark"
                                ? "gray"
                                : "dark"
                            }
                            variant="dots"
                          />
                        </div>
                      ) : undefined}

                      {transactionFilterParameters.startDate ||
                      transactionFilterParameters.transactionTypeId ||
                      transactionFilterParameters.exchangeId ? (
                        <Button
                          onClick={() =>
                            setTransactionFilterParameters({
                              ...transactionFilterParameters,
                              startDate: undefined,
                              endDate: undefined,
                              exchangeId: undefined,
                              transactionTypeId: undefined,
                            })
                          }
                          radius={"xs"}
                          className="cs-theme-button"
                          leftIcon={<MdFilterAlt />}
                        >
                          {ClearFiltersLabel[props.applicationSettings.locale]}
                        </Button>
                      ) : undefined}

                      <Button
                        onClick={() => navigate("/accounts")}
                        radius={"xs"}
                        className="cs-theme-button"
                        leftIcon={<MdAccountTree />}
                      >
                        {
                          ImportFromExistingAccountLabel[
                            props.applicationSettings.locale
                          ]
                        }
                      </Button>

                      <Button
                        onClick={() => navigate("/import")}
                        leftIcon={<CgImport />}
                        radius={"xs"}
                        className="cs-theme-button"
                      >
                        {
                          ImportFromNewAccountLabel[
                            props.applicationSettings.locale
                          ]
                        }
                      </Button>

                      <Button
                        radius={"xs"}
                        leftIcon={<FiPlus />}
                        className="cs-theme-button"
                        onClick={() => setDrawerOpen(true)}
                      >
                        {AddTransactionLabel[props.applicationSettings.locale]}
                      </Button>
                    </div>
                  )}
                </>
              )}

              <Modal
                onClose={() => {
                  setModalOpened(false);
                  setHistoryBadges([]);
                  setInspectedTransactions({});
                }}
                opened={modalOpened}
                overlayBlur={1}
                style={{ height: "100%" }}
                size={"90%"}
              >
                <ReportPopupTabBar
                  historyBadges={historyBadges}
                  handleClickExistingHistoryTab={handleClickExistingHistoryTab}
                  selectedInspectedHistoryId={selectedInspectedHistoryId}
                />
                <Space h={"md"} />
                {!loadingHistoryItem ? (
                  <>
                    {selectedInspectedHistoryId &&
                      inspectedTransactions[selectedInspectedHistoryId] &&
                      inspectedTransactions[selectedInspectedHistoryId]}
                  </>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    id="popup-item"
                    className="blank-transaction-item"
                    style={{ opacity: 0 }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2, delay: 1 }}
                    >
                      <Space h="lg" />
                      <label>No transaction found</label>
                    </motion.div>
                    <Space h={"md"} />
                    <Space h={"md"} />
                  </motion.div>
                )}
              </Modal>
            </>
          )}
        </>

        <Drawer
          opened={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          title={
            <div className="drawer-title-container">
              <label>
                {AddTransactionLabel[props.applicationSettings.locale]}
              </label>
            </div>
          }
          position="right"
          padding="xl"
          size={compact ? "xl" : 800}
          overlayOpacity={0.55}
          overlayBlur={3}
        >
          <Manual
            closeDrawer={() => setDrawerOpen(false)}
            applicationSettings={props.applicationSettings}
            QueryKeySuffix={"fromReport"}
          />
        </Drawer>
      </ScrollArea>
    </OpenModalContext.Provider>
  );
}

export const OpenModalContext = createContext<
  (
    history: TransactionHistory | FeeHistory,
    historyBadge: JSX.Element,
    exchange?: string
  ) => void
>(() => {});
