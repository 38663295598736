import { Drawer, Loader, Menu, Modal, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import {
  FiAlertCircle,
  FiCheck,
  FiDownloadCloud,
  FiPlus,
  FiTrash2,
  FiUploadCloud,
  FiX,
} from "react-icons/fi";
import { RxUpdate } from "react-icons/rx";
import { TbRefreshDot } from "react-icons/tb";
import { AiOutlineMore } from "react-icons/ai";
import {
  AuthenticationContext,
  SetPollingStatusContext,
  PollContext,
  TriggerNotificationContext,
} from "../../../App";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { TransactionItem } from "../../../interfaces/Report/TransactionData";
import { TransactionUpdateBody } from "../../../interfaces/Report/TransactionUpdateBody";
import TransactionService from "../../../service/TransactionService";
import { Manual } from "../../Import/Instructions/manual/Manual";
import {
  GetTransactionBodyUpdatesContext,
  GetTransactionDeletionsContext,
  GetTransactionLinkUpdatesContext,
  GetTransactionPostUpdateErrorsContext,
  GetTransactionTagUpdatesContext,
  SetTransactionBodyUpdatesContext,
  SetPendingTransactionBodyUpdatesContext,
  SetTransactionDeletionsContext,
  SetTransactionLinkUpdatesContext,
  SetTransactionPostUpdateErrorsContext,
  SetTransactionTagUpdatesContext,
  TransactionFilterParametersContext,
} from "../../../pages/ReportPage";
import { UseQueryResult } from "react-query";
import { TransactionListResponse } from "../../../interfaces/Report/TransactionListResponse";
import { LossGain } from "../../../interfaces/Report/LossGainStats";
import {
  AddTransactionLabel,
  AllTransactionsUpdated,
  CalculateLabel,
  CalculateReportLabel,
  DangerZoneLabel,
  DeleteAllTransactionsLabel,
  ErrorsLabel,
  GenerateReportLabel,
  GeneratingReportLabel,
  MoreOptionsLabel,
  PleaseWaitLabel,
  PostingUpdatesLabel,
  ReportUpToDateLabel,
  SubmittedUpdatesAmountLabel,
  TransactionUpdatesAmountLabel,
  UndoTransactionUpdatesLabel,
  UpToDateLabel,
  UpdateLabel,
  UpdateReportLabel,
  YourTransactionsAreUpToDateLabel,
} from "../../../utils/labels";
import { ErrorsModalContent } from "./ErrorsModalContent";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  totalLossGainQuery: UseQueryResult<LossGain | undefined, unknown>;
  popTransactionBodyUpdate: (transactionId: string) => void;
  popTransactionTagUpdate: (transactionId: string) => void;
  handleSetUploadProgress: (progress: number) => void;
  compact?: boolean;
}
export function ReportCenterSection(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollStatus = useContext(SetPollingStatusContext);
  const pollStatus = useContext(PollContext);
  const transactionBodyUpdates = useContext(GetTransactionBodyUpdatesContext);
  const transactionLinkUpdates = useContext(GetTransactionLinkUpdatesContext);
  const transactionTagUpdates = useContext(GetTransactionTagUpdatesContext);
  const transactionDeletions = useContext(GetTransactionDeletionsContext);
  const transactionUpdateErrors = useContext(
    GetTransactionPostUpdateErrorsContext
  );
  const setTransactionTagUpdates = useContext(SetTransactionTagUpdatesContext);
  const setTransactionLinkUpdates = useContext(
    SetTransactionLinkUpdatesContext
  );
  const setTransactionUpdateErrors = useContext(
    SetTransactionPostUpdateErrorsContext
  );
  const setTransactionDeletions = useContext(SetTransactionDeletionsContext);
  const setTransactionBodyUpdates = useContext(
    SetTransactionBodyUpdatesContext
  );
  const setPendingTransactionBodyUpdates = useContext(
    SetPendingTransactionBodyUpdatesContext
  );
  const filterParameters = useContext(TransactionFilterParametersContext);

  const compact = useMediaQuery("(max-width: 1168px)");
  const mobile = useMediaQuery("(max-width: 768px)");
  const updatesSubmittedKey = "updatesSubmitted";

  const [drawerOpen, setDrawerOpen] = useState(false);

  const tryGetUpdatesSubmitted = useCallback(() => {
    const updatesSubmitted = localStorage.getItem(updatesSubmittedKey);
    return updatesSubmitted ? parseInt(updatesSubmitted) : undefined;
  }, []);

  const [updatesSubmitted, setUpdatesSubmitted] = useState<number | undefined>(
    () => tryGetUpdatesSubmitted()
  );

  const purgeUpdatesSubmitted = useCallback(() => {
    localStorage.removeItem(updatesSubmittedKey);
    setUpdatesSubmitted(0);
  }, [setUpdatesSubmitted]);

  const [errorsModalOpened, setErrorsModalOpened] = useState(false);
  const [isSubmittingUpdates, setIsSubmittingUpdates] = useState(false);

  const recalculateTransactions = useCallback(async () => {
    if (!authenticationData) return;
    setPollStatus({ needRequest: false, reportUpdating: true });
    await TransactionService.recalculateTransactions(authenticationData.token);
    purgeUpdatesSubmitted();
    setIsSubmittingUpdates(false);
    props.transactionsQuery.remove();
    props.transactionsQuery.refetch();
    props.totalLossGainQuery.remove();
    setPendingTransactionBodyUpdates("delete");
  }, [
    authenticationData,
    setPollStatus,
    props.transactionsQuery,
    props.totalLossGainQuery,
    setIsSubmittingUpdates,
    purgeUpdatesSubmitted,
    setPendingTransactionBodyUpdates,
  ]);

  const getUpdateCount = useCallback(() => {
    const bodyCount = Object.keys(transactionBodyUpdates ?? 0).length;
    const tagCount = Object.keys(transactionTagUpdates ?? 0).length;
    const linkCount = Object.keys(transactionLinkUpdates ?? 0).length;
    const deletionCount = Object.keys(transactionDeletions ?? 0).length;
    return bodyCount + tagCount + linkCount + deletionCount;
  }, [
    transactionBodyUpdates,
    transactionTagUpdates,
    transactionLinkUpdates,
    transactionDeletions,
  ]);

  const updateCount = getUpdateCount();

  const isGeneratingReport = pollStatus?.pdfReportGenerating ?? false;

  const handleGenerateReport = useCallback(async () => {
    if (!authenticationData) return;
    setPollStatus({
      needRequest: true,
      reportUpdating: false,
      pdfReportGenerating: true,
    });
    try {
      const response = await TransactionService.generatePdfReport(
        authenticationData.token,
        filterParameters.startDate,
        filterParameters.endDate
      );
      triggerNotification(response["message"], "info");
    } catch (error: any) {
      triggerNotification(error["response"]["data"]["message"], "error");
    }
  }, [
    authenticationData,
    setPollStatus,
    triggerNotification,
    filterParameters,
  ]);

  const noUnsavedUpdate =
    updatesSubmitted && updatesSubmitted > 0 && updateCount === 0
      ? true
      : false;

  const getTransactionUpdateBodyFromTransaction = useCallback(
    (_transaction: TransactionItem): TransactionUpdateBody | undefined => {
      if (!_transaction?.transactionDate) return undefined;
      const specialType =
        _transaction?.specialType ?? _transaction?.specialType ?? undefined;
      return {
        transaction_date: _transaction.transactionDate
          .replace("-", ".")
          .replace("-", "."),
        currency_out_id: _transaction.currencyOut?.id.toString() ?? undefined,
        currency_in_id: _transaction.currencyIn?.id.toString() ?? undefined,
        amount_out: _transaction.currencyOut?.amount
          ? parseFloat(_transaction.currencyOut?.amount)
          : undefined,
        amount_in: _transaction.currencyIn?.amount
          ? parseFloat(_transaction.currencyIn?.amount)
          : undefined,
        fee_currency_id: _transaction.fee?.id.toString() ?? undefined,
        fee: _transaction.fee?.amount
          ? parseFloat(_transaction.fee?.amount)
          : undefined,
        nc_fee: _transaction.fee?.salesProceedInNC
          ? parseFloat(_transaction.fee?.salesProceedInNC)
          : undefined,
        crypto_tx_id: _transaction.txId ?? "",
        nc_acquisition_cost: _transaction.currencyIn?.acquisitionCostInNC
          ? parseFloat(_transaction.currencyIn?.acquisitionCostInNC)
          : 0,
        nc_sales_proceed:
          _transaction.currencyOut && _transaction.currencyOut?.salesProceedInNC
            ? parseFloat(_transaction.currencyOut?.salesProceedInNC)
            : undefined,
        special_transaction_type_id: specialType ? specialType : undefined,
      };
    },
    []
  );

  const getTransactionsNotificationMessage = useCallback(
    (_updateCount: number, _errorCount: number) => {
      // if applicationSettings.locale === "en" then use english labels
      // else use danish labels
      const locale = props.applicationSettings.locale;
      const messages = [];
      if (_updateCount > 1) {
        messages.push(
          locale === "en"
            ? `${_updateCount} transactions updated`
            : `${_updateCount} transaktioner opdateret`
        );
      } else if (_updateCount === 1) {
        messages.push(
          locale === "en"
            ? `${_updateCount} transaction updated`
            : `${_updateCount} transaktion opdateret`
        );
      }
      if (_errorCount > 1) {
        messages.push(
          locale === "en"
            ? `${_errorCount} transactions failed to update`
            : `${_errorCount} transaktioner kunne ikke opdateres`
        );
      } else if (_errorCount === 1) {
        messages.push(
          locale === "en"
            ? `${_errorCount} transaction failed to update`
            : `${_errorCount} transaktion kunne ikke opdateres`
        );
      }
      return messages;
    },
    [props.applicationSettings.locale]
  );

  const handleSubmitUpdatedTransactions = useCallback(async () => {
    if (isSubmittingUpdates) return;
    if (!authenticationData) return;
    if (!transactionBodyUpdates) return;
    if (!transactionTagUpdates) return;
    if (!transactionLinkUpdates) return;
    if (!transactionDeletions) return;

    let progress = 0;
    let _updateCount = updatesSubmitted ?? 0;
    let _errorCount = 0;
    const progressIncrement = Math.round(100 / _updateCount);
    try {
      setIsSubmittingUpdates(true);
      const bodyPromises: Promise<any>[] = [];
      // post all body updates
      Object.keys(transactionBodyUpdates).forEach((transactionId) => {
        setPendingTransactionBodyUpdates(transactionBodyUpdates[transactionId]);
        const bodyPromise = TransactionService.updateTransaction(
          authenticationData.token,
          transactionId,
          getTransactionUpdateBodyFromTransaction(
            transactionBodyUpdates[transactionId]
          )!
        )
          .catch((error: any) => {
            _errorCount++;
            const errorMessage = error["response"]["data"]["message"];
            setTransactionUpdateErrors({
              error: errorMessage,
              transactionId: parseInt(transactionId),
              updateType: "body",
            });
          })
          .finally(() => {
            progress += progressIncrement;
            props.handleSetUploadProgress(progress);
          });
        bodyPromises.push(bodyPromise);
      });

      _updateCount += (await Promise.all(bodyPromises)).length;

      const tagPromises: Promise<any>[] = [];

      // post all tag updates
      Object.keys(transactionTagUpdates).forEach((transactionId) => {
        const tagPromise = TransactionService.updateSpecialTransactionType(
          authenticationData.token,
          transactionId,
          transactionTagUpdates[transactionId]
        )
          .catch((error: any) => {
            _errorCount++;
            const errorMessage = error["response"]["data"]["message"];
            setTransactionUpdateErrors({
              error: errorMessage,
              transactionId: parseInt(transactionId),
              updateType: "tag",
            });
          })
          .finally(() => {
            progress += progressIncrement;
            props.handleSetUploadProgress(progress);
          });
        tagPromises.push(tagPromise);
      });

      _updateCount += (await Promise.all(tagPromises)).length;

      // post all link updates
      const linkPromises: Promise<any>[] = [];

      Object.keys(transactionLinkUpdates).forEach((transactionId) => {
        const linkPromise = TransactionService.linkTransactions(
          authenticationData.token,
          parseInt(transactionId),
          transactionLinkUpdates[transactionId]
        )
          .catch((error: any) => {
            _errorCount++;
            const errorMessage = error["response"]["data"]["message"];
            setTransactionUpdateErrors({
              error: errorMessage,
              transactionId: parseInt(transactionId),
              updateType: "link",
            });
          })
          .finally(() => {
            progress += progressIncrement;
            props.handleSetUploadProgress(progress);
          });
        linkPromises.push(linkPromise);
      });

      _updateCount += (await Promise.all(linkPromises)).length;

      const deletionPromises: Promise<any>[] = [];
      // post all deletions
      Object.keys(transactionDeletions).forEach((transactionId) => {
        const deletionPromise = TransactionService.deleteTransaction(
          authenticationData.token,
          parseInt(transactionId)
        )
          .catch((error: any) => {
            _errorCount++;
            const errorMessage = error["response"]["data"]["message"];
            setTransactionUpdateErrors({
              error: errorMessage,
              transactionId: parseInt(transactionId),
              updateType: "deletion",
            });
          })
          .finally(() => {
            progress += progressIncrement;
            props.handleSetUploadProgress(progress);
          });
        deletionPromises.push(deletionPromise);
      });

      _updateCount += (await Promise.all(deletionPromises)).length;
    } finally {
      localStorage.setItem(updatesSubmittedKey, _updateCount.toString());
      props.handleSetUploadProgress(100);
      setTransactionTagUpdates(0, "delete");
      setTransactionBodyUpdates("delete");
      setTransactionLinkUpdates(0, "delete");
      setTransactionDeletions("delete");
      setUpdatesSubmitted(_updateCount - _errorCount);
      setIsSubmittingUpdates(false);

      if (_errorCount === 0) {
        triggerNotification(
          AllTransactionsUpdated[props.applicationSettings.locale],
          "success"
        );
      } else {
        triggerNotification(
          getTransactionsNotificationMessage(_updateCount, _errorCount),
          "error"
        );
      }
    }
  }, [
    authenticationData,
    props,
    triggerNotification,
    getTransactionUpdateBodyFromTransaction,
    transactionBodyUpdates,
    transactionTagUpdates,
    transactionLinkUpdates,
    transactionDeletions,
    updatesSubmitted,
    setUpdatesSubmitted,
    setTransactionBodyUpdates,
    setTransactionTagUpdates,
    setTransactionLinkUpdates,
    setTransactionUpdateErrors,
    setTransactionDeletions,
    isSubmittingUpdates,
    setIsSubmittingUpdates,
    setPendingTransactionBodyUpdates,
    getTransactionsNotificationMessage,
  ]);

  const deleteAllTransactions = useCallback(async () => {
    if (!authenticationData) return;
    try {
      await TransactionService.deleteAllTransactions(authenticationData.token);
      purgeUpdatesSubmitted();
      setTransactionTagUpdates(0, "delete");
      setTransactionBodyUpdates("delete");
      setPendingTransactionBodyUpdates("delete");
      setTransactionLinkUpdates(0, "delete");
      setTransactionDeletions("delete");
      triggerNotification(
        [
          "All transactions deleted",
          "You can import them again from the Accounts page",
        ],
        "success"
      );
    } catch (error: any) {
      triggerNotification(JSON.parse(error), "error");
    } finally {
      setPollStatus({ needRequest: true, reportUpdating: true });
    }
  }, [
    authenticationData,
    triggerNotification,
    setPollStatus,
    setTransactionTagUpdates,
    setTransactionBodyUpdates,
    setPendingTransactionBodyUpdates,
    setTransactionLinkUpdates,
    setTransactionDeletions,
    purgeUpdatesSubmitted,
  ]);

  return (
    <div className="report-center-section-container">
      <div
        className={!props.compact ? "center-buttons" : "compact-center-buttons"}
      >
        <div
          typeof="button"
          className={
            !props.compact
              ? "transactions-submit-button"
              : "compact-transactions-submit-button"
          }
          style={{ opacity: noUnsavedUpdate ? 1 : 0.8 }}
          onClick={() => {
            if (isSubmittingUpdates) return;
            if (pollStatus.reportUpdating) return;
            if (updateCount > 0) {
              return handleSubmitUpdatedTransactions();
            }
            if (noUnsavedUpdate) {
              setPollStatus({ needRequest: true, reportUpdating: true });
              return recalculateTransactions();
            }
          }}
        >
          {pollStatus.reportUpdating ? ( //I dont think this is ever being rendered
            <>
              {!props.compact ? (
                <>
                  <div className="fill-up-bar"></div>
                  <Loader
                    color={
                      props.applicationSettings.theme === "dark"
                        ? "yellow"
                        : "indigo"
                    }
                    className="button-icon"
                  />
                  <label className="submit-button-header">
                    {PleaseWaitLabel[props.applicationSettings.locale]}
                  </label>
                  <label className="submit-button-body">
                    {
                      SubmittedUpdatesAmountLabel[
                        props.applicationSettings.locale
                      ]
                    }
                  </label>
                </>
              ) : (
                <div className="compact-submit-button">
                  <TbRefreshDot fontSize={40} />
                  <label>
                    {CalculateLabel[props.applicationSettings.locale]}
                  </label>
                </div>
              )}
            </>
          ) : (
            <>
              {isSubmittingUpdates ? (
                <>
                  {!props.compact ? (
                    <>
                      <div className="fill-up-bar"></div>
                      <Loader
                        color={
                          props.applicationSettings.theme === "dark"
                            ? "yellow"
                            : "indigo"
                        }
                        className="button-icon"
                      />
                      <label className="submit-button-header">
                        {PostingUpdatesLabel[props.applicationSettings.locale]}
                      </label>
                      <label className="submit-button-body">
                        {PleaseWaitLabel[props.applicationSettings.locale]}
                      </label>
                    </>
                  ) : (
                    <div className="compact-submit-button">
                      <Loader
                        size={"md"}
                        color={
                          props.applicationSettings.locale === "dark"
                            ? "yellow"
                            : "dark"
                        }
                      />
                      <label>
                        {PleaseWaitLabel[props.applicationSettings.locale]}
                      </label>
                    </div>
                  )}
                </>
              ) : noUnsavedUpdate && !isSubmittingUpdates ? (
                <>
                  {!props.compact ? (
                    <>
                      <div className="fill-up-bar"></div>
                      <TbRefreshDot size={28} className="button-icon" />
                      <label className="submit-button-header">
                        {CalculateReportLabel[props.applicationSettings.locale]}
                      </label>
                      <label className="submit-button-body">
                        {
                          SubmittedUpdatesAmountLabel[
                            props.applicationSettings.locale
                          ]
                        }
                        {updatesSubmitted}
                      </label>
                    </>
                  ) : (
                    <div className="compact-submit-button">
                      <TbRefreshDot fontSize={40} />
                      <label>
                        {CalculateLabel[props.applicationSettings.locale]}
                      </label>
                    </div>
                  )}
                </>
              ) : updateCount > 0 ? (
                <>
                  {!props.compact ? (
                    <>
                      <div className="fill-up-bar"></div>
                      <FiUploadCloud className="button-icon" />
                      <label className="submit-button-header">
                        {UpdateReportLabel[props.applicationSettings.locale]}
                      </label>
                      <label className="submit-button-body">
                        {
                          TransactionUpdatesAmountLabel[
                            props.applicationSettings.locale
                          ]
                        }
                        {updateCount}
                      </label>
                    </>
                  ) : (
                    <div className="compact-submit-button">
                      <RxUpdate fontSize={40} />
                      <label>
                        {UpdateLabel[props.applicationSettings.locale]} (
                        {updateCount})
                      </label>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!props.compact ? (
                    <>
                      <div className="fill-up-bar"></div>
                      <FiCheck className="button-icon" />
                      <label className="submit-button-header">
                        {ReportUpToDateLabel[props.applicationSettings.locale]}
                      </label>
                      <label className="submit-button-body">
                        {
                          YourTransactionsAreUpToDateLabel[
                            props.applicationSettings.locale
                          ]
                        }
                      </label>
                    </>
                  ) : (
                    <div className="compact-submit-button">
                      <FiCheck fontSize={40} />
                      <label>
                        {UpToDateLabel[props.applicationSettings.locale]}
                      </label>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {pollStatus.reportUpdating ? (
          <div className="more-options-button-disabled">
            <div>
              <AiOutlineMore size={34} />
            </div>
          </div>
        ) : (
          <Menu
            className="more-options-button"
            control={
              <div>
                <AiOutlineMore size={34} />
              </div>
            }
          >
            <Menu.Label>
              {MoreOptionsLabel[props.applicationSettings.locale]}
            </Menu.Label>
            <Menu.Item
              rightSection={<FiPlus size={18} />}
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              {AddTransactionLabel[props.applicationSettings.locale]}
            </Menu.Item>
            <Menu.Item
              disabled={isGeneratingReport}
              onClick={handleGenerateReport}
              rightSection={
                isGeneratingReport ? (
                  <Loader
                    size={"xs"}
                    color={
                      props.applicationSettings.theme === "dark"
                        ? "yellow"
                        : "indigo"
                    }
                  />
                ) : (
                  <FiDownloadCloud size={18} />
                )
              }
            >
              {isGeneratingReport
                ? `${GeneratingReportLabel[props.applicationSettings.locale]}`
                : `${GenerateReportLabel[props.applicationSettings.locale]}`}
            </Menu.Item>
            <Menu.Label>
              {DangerZoneLabel[props.applicationSettings.locale]}
            </Menu.Label>
            <Menu.Item
              onClick={() => {
                deleteAllTransactions();
                props.transactionsQuery.remove();
                props.totalLossGainQuery.remove();
              }}
              color={"red"}
              rightSection={<FiTrash2 size={18} />}
            >
              {DeleteAllTransactionsLabel[props.applicationSettings.locale]}
            </Menu.Item>
          </Menu>
        )}
      </div>
      {!props.compact && (
        <Text color={"dimmed"} size={compact ? "xs" : "sm"}>
          <label>
            {props.applicationSettings.locale === "en"
              ? "Submitting updates will trigger a new calculation of your report"
              : "Ved indsendelse af opdateringer vil der blive udført en ny beregning af din rapport"}
          </label>
          <br />
        </Text>
      )}

      {updateCount > 0 && (
        <motion.div
          initial={{ scale: 0, y: 80 }}
          animate={{ scale: 1, y: mobile ? 50 : 71, x: mobile ? -150 : 0 }}
          transition={{ duration: 0.15, delay: 0.2 }}
          className="cancel-updates-container"
          onClick={() => {
            setTransactionTagUpdates(0, "delete");
            setTransactionBodyUpdates("delete");
            setTransactionLinkUpdates(0, "delete");
            setTransactionDeletions("delete");
            props.handleSetUploadProgress(0);
          }}
        >
          <FiX />
          <label>
            {UndoTransactionUpdatesLabel[props.applicationSettings.locale]}
          </label>
        </motion.div>
      )}
      {transactionUpdateErrors &&
        transactionUpdateErrors.errors &&
        transactionUpdateErrors.errors.length > 0 && (
          <motion.div
            initial={{ scale: 0, x: 150, y: 80 }}
            animate={{ scale: 1, x: 150, y: mobile ? 50 : 71 }}
            transition={{ duration: 0.15, delay: 0.2 }}
            className="update-errors-container"
            onClick={() => setErrorsModalOpened(true)}
          >
            <FiAlertCircle />
            <label>
              {transactionUpdateErrors.errors.length}{" "}
              {ErrorsLabel[props.applicationSettings.locale]}
            </label>
          </motion.div>
        )}
      {transactionUpdateErrors &&
        transactionUpdateErrors.errors &&
        transactionUpdateErrors.errors.length > 0 && (
          <Modal
            opened={errorsModalOpened}
            onClose={() => setErrorsModalOpened(false)}
            title={ErrorsLabel[props.applicationSettings.locale]}
            size={compact ? "xl" : 800}
            centered
          >
            <ErrorsModalContent
              applicationSettings={props.applicationSettings}
              transactionUpdateErrors={transactionUpdateErrors}
              clearErrors={() => {
                setTransactionUpdateErrors("delete");
                setErrorsModalOpened(false);
              }}
            />
          </Modal>
        )}

      <Drawer
        opened={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        title={
          <div className="drawer-title-container">
            <label>
              {AddTransactionLabel[props.applicationSettings.locale]}
            </label>
          </div>
        }
        position="right"
        padding="xl"
        size={compact ? "xl" : 800}
        overlayOpacity={0.55}
        overlayBlur={3}
      >
        <Manual
          closeDrawer={() => setDrawerOpen(false)}
          applicationSettings={props.applicationSettings}
          QueryKeySuffix={"fromReport"}
        />
      </Drawer>
    </div>
  );
}
