import { Badge } from "@mantine/core";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
  platformName: string;
}
export function PlatformBadge(props: React.PropsWithChildren<Props>) {
  const maxLength = 15

  const platformName =
    props.platformName.length > maxLength
      ? props.platformName.substring(0, maxLength) + "..."
      : props.platformName;

  return (
    <div className="platform-badge-container">
      <Badge
        style={{
          minWidth: "6rem",
        }}
        variant="outline"
        size="md"
        radius={"xs"}
        color={props.applicationSettings.theme === "dark" ? "gray" : "blue"}
      >
        {platformName}
      </Badge>
    </div>
  );
}
