import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  FeeHistory,
  TransactionHistory,
} from "../../../interfaces/Report/TransactionData";
import { formatTimestampToString } from "../../../utils/formatter";
import {
  AcquisitionCostLabel,
  AmountLabel,
  ClickToViewOriginalLabel,
  CurrencyLabel,
  DateLabel,
  DifferenceLabel,
} from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  history: TransactionHistory | FeeHistory;
  parsedTimestamp: number;
  showDiff?: boolean;
}
export function HistoryDetailsTooltip(props: React.PropsWithChildren<Props>) {
  return (
    <div className="history-details-tooltip-container">
      <label>
        {DateLabel[props.applicationSettings.locale]}:{" "}
        <strong className="value-container">
          {formatTimestampToString(props.parsedTimestamp)}
        </strong>
      </label>
      <br />
      <label>
        {CurrencyLabel[props.applicationSettings.locale]}:{" "}
        <strong className="value-container">
          {props.history.currencyFullName}
        </strong>
      </label>
      <br />

      <label>
        {AmountLabel[props.applicationSettings.locale]}:{" "}
        <strong className="value-container">{props.history.amount}</strong>
      </label>
      <br />
      <label>
        {AcquisitionCostLabel[props.applicationSettings.locale]} DKK:{" "}
        <strong className="value-container">
          {props.history.acquisitionCostInNC} DKK
        </strong>
      </label>
      {!props.showDiff ||
        (props.showDiff !== undefined && props.showDiff && (
          <>
            <br />
            <label>
              {DifferenceLabel[props.applicationSettings.locale]} DKK:{" "}
              <strong className="value-container">
                {props.history.ncDiff} DKK
              </strong>
            </label>
          </>
        ))}
      {!props.history.virtualDeposit && (
        <>
          <br />
          <label>
            <strong>
              {ClickToViewOriginalLabel[props.applicationSettings.locale]}
            </strong>
          </label>
        </>
      )}
    </div>
  );
}
