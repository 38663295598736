import { Text } from "@mantine/core";
import { motion } from "framer-motion";
import { useCallback, useContext, useMemo, useState } from "react";
import { FiArrowDown, FiArrowRight } from "react-icons/fi";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  ClickToEditLabel,
  PressToEditLabel,
  SearchableCurrencySelectLabel,
} from "../../../utils/labels";
import { CurrencySelect } from "../../Import/Instructions/manual/manualComponents/CurrencySelect";
import { CurrencyIcon } from "../CurrencyIcon";
import {
  GetLocalEditedTransactionItemContext,
  SetLocalEditedTransactionItemContext,
} from "./ReportRowItem";
import { useMediaQuery } from "@mantine/hooks";
import { CurrencyContext } from "../../../App";

interface Props {
  applicationSettings: ApplicationSettings;
  activeTheme: string;
  currencyId: string | number;
  iconSizeRem?: number;
  editMode: boolean;
  label: string;
  blockToEditKey: "fee" | "currencyIn" | "currencyOut";
}
export function CurrencySection(props: React.PropsWithChildren<Props>) {
  const currencyContext = useContext(CurrencyContext);
  const setLocalEditedTransactionItem = useContext(
    SetLocalEditedTransactionItemContext
  );
  const mobile = useMediaQuery("(max-width: 768px)");
  const localEditedTransactionItem = useContext(
    GetLocalEditedTransactionItemContext
  );

  const localEditedCurrency = useMemo(() => {
    if (!localEditedTransactionItem) return undefined;
    return currencyContext?.[
      localEditedTransactionItem[props.blockToEditKey]!.id
    ];
  }, [props.blockToEditKey, localEditedTransactionItem, currencyContext]);

  const [localUneditedCurrency, setLocalUneditedCurrency] =
    useState(localEditedCurrency);

  const handleCurrencyChange = useCallback(
    (currencyId: string) => {
      if (!localEditedTransactionItem) return;
      const currency = currencyContext?.[currencyId];
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        [props.blockToEditKey]: {
          ...localEditedTransactionItem[props.blockToEditKey],
          id: currency?.id,
          currency: currency?.code,
          currencyFullName: currency?.full_name,
        },
      });
    },
    [
      localEditedTransactionItem,
      props.blockToEditKey,
      setLocalEditedTransactionItem,
    ]
  );

  return (
    <div className="currency-section">
      {props.editMode ? (
        <>
          <CurrencySelect
            label={props.label}
            cleareable
            transactionMode
            searchable
            placeholder={
              localEditedCurrency?.full_name ||
              localUneditedCurrency?.full_name ||
              SearchableCurrencySelectLabel[props.applicationSettings.locale]
            }
            onChange={(_currencyId) => {
              handleCurrencyChange(_currencyId);
            }}
            rightSection={<CurrencyIcon currency={localEditedCurrency} />}
          />
        </>
      ) : (
        <div
          className="currency-section-read-only-outer-container"
          onClick={() => {
            if (!props.editMode) return;
          }}
        >
          {props.editMode && (
            <Text color={"dimmed"}>
              {mobile ? (
                <label>
                  {PressToEditLabel[props.applicationSettings.locale]}{" "}
                  {props.label}
                </label>
              ) : (
                <label>
                  {ClickToEditLabel[props.applicationSettings.locale]}{" "}
                  {props.label}
                </label>
              )}
            </Text>
          )}

          {localEditedCurrency && (
            <div className="currency-section-read-only-container">
              {localUneditedCurrency && (
                <div
                  className="original"
                  id={props.editMode ? "editable" : undefined}
                >
                  <CurrencyIcon currency={localUneditedCurrency} />
                  <div className="text-section">
                    <Text>{localUneditedCurrency?.full_name}</Text>
                    <Text color={"dimmed"}>{localUneditedCurrency?.code}</Text>
                  </div>
                </div>
              )}
              {localEditedCurrency?.id !== localUneditedCurrency?.id && (
                <motion.div
                  className="changed"
                  initial={{ opacity: 0, x: 10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 10 }}
                  transition={{ duration: 0.2 }}
                >
                  {mobile ? <FiArrowDown /> : <FiArrowRight />}
                  <div className="new">
                    <CurrencyIcon currency={localEditedCurrency} />
                    <div className="text-section">
                      <Text>{localEditedCurrency.full_name}</Text>
                      <Text color={"dimmed"}>{localEditedCurrency.code}</Text>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
