import { Divider } from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ExchangeIntegrationsLabel } from "../../utils/labels";
import { PlatformLogo } from "../Import/Instructions/manual/PlatformLogo";

interface Props {
  applicationSettings: ApplicationSettings;
  exchanges: string[];
}
export function ExchangeIntegrations(props: React.PropsWithChildren<Props>) {
  const lowercaseExchanges = props.exchanges.map((exchange) =>
    exchange.toLowerCase()
  );

  return (
    <div className="integrations-container">
      <label>
        {ExchangeIntegrationsLabel[props.applicationSettings.locale]}
      </label>
      <div className="divider"></div>
      <div className="platform-columns">
        {lowercaseExchanges.map((exchangeName) => {
          return (
            <PlatformLogo
              key={exchangeName}
              activeTheme={props.applicationSettings.theme}
              platform={"exchanges"}
              title={exchangeName}
              compact={false}
            />
          );
        })}
      </div>
    </div>
  );
}
