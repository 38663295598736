import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string;

  applicationSettings: ApplicationSettings;
}

export function Bittrex(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor href="https://global.bittrex.com/Manage?view=api" target="_blank">
      <strong>Bittrex</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på dit {platformLink} account og navigér til{" "}
        <strong>Account</strong> {"->"} <strong>API Keys.</strong>
      </label>,
      <label className="instruction-label">
        Generér en ny API nøgle ved at trykke på <strong>Add new key.</strong>
      </label>,
      <label className="instruction-label">
        Giv kun nøglen <strong>READ INFO</strong> rettigheder.
      </label>,
      <label className="instruction-label">
        Tryk på <strong>Save</strong>
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account and navigate to{" "}
        <strong>My Account</strong> {"->"} <strong>API Keys.</strong>
      </label>,
      <label className="instruction-label">
        Create a new key by clicking on <strong>Add new key.</strong>
      </label>,
      <label className="instruction-label">
        Only enable <strong>READ INFO</strong>.
      </label>,
      <label className="instruction-label">
        Click <strong>Save</strong>
      </label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput applicationSettings={props.applicationSettings} platformId={props.platformId} />

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
