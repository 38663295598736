import "./page-layout.scss";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { ResetPasswordRequestForm } from "../components/Authentication/ResetPasswordRequestForm";
import { NewPasswordForm } from "../components/Authentication/NewPasswordForm";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function ResetPasswordPage(props: React.PropsWithChildren<Props>) {
  const urlHasParameters = window.location.href.indexOf("?") > -1;
  const parameterRequirements = ["?", "&", "="];
  const emailAndToken = () => {
    const url = window.location.href;
    if (!parameterRequirements.every((x) => url.includes(x))) {
      return { token: "", email: "" };
    }
    const urlParameters = url.split("?")[1];
    const token = urlParameters.split("&")[0].split("=")[1];
    const email = urlParameters.split("&")[1].split("=")[1];
    return { token, email };
  };

  const { token, email } = emailAndToken();

  return (
    <>
      {urlHasParameters && token && email ? (
        <NewPasswordForm
          applicationSettings={props.applicationSettings}
          resetToken={token}
          email={email}
        />
      ) : (
        <>
          <ResetPasswordRequestForm
            applicationSettings={props.applicationSettings}
          />
        </>
      )}
    </>
  );
}
