import "./compact-report-header.scss";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ReportCenterSection } from "./ReportHeaderComponents/ReportCenterSection";
import { UseQueryResult } from "react-query";
import { LossGain } from "../../interfaces/Report/LossGainStats";
import { TransactionListResponse } from "../../interfaces/Report/TransactionListResponse";
import { ReportFilterMenu } from "./ReportHeaderComponents/ReportFilterMenu";
import { TransactionFilters } from "../../interfaces/Report/TransactionFilters";
import { ReportStats } from "./ReportHeaderComponents/ReportStats";

interface Props {
  applicationSettings: ApplicationSettings;
  walletsWithLogos: string[] | undefined;
  exchangesWithLogos: string[] | undefined;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  totalLossGainQuery: UseQueryResult<LossGain | undefined, unknown>;
  transactionFilters: TransactionFilters | undefined;
  popTransactionBodyUpdate: (transactionId: string) => void;
  popTransactionTagUpdate: (transactionId: string) => void;
  handleSetUploadProgress: (progress: number) => void;
}
export function CompactReportHeader(props: React.PropsWithChildren<Props>) {
  return (
    <div className="compact-report-header-container">
      <ReportStats
        applicationSettings={props.applicationSettings}
        totalLossGainQuery={props.totalLossGainQuery}
        compact
      />
      <ReportCenterSection
        applicationSettings={props.applicationSettings}
        popTransactionBodyUpdate={props.popTransactionBodyUpdate}
        popTransactionTagUpdate={props.popTransactionTagUpdate}
        handleSetUploadProgress={props.handleSetUploadProgress}
        transactionsQuery={props.transactionsQuery}
        totalLossGainQuery={props.totalLossGainQuery}
        compact
      />
      <ReportFilterMenu
        walletsWithLogos={props.walletsWithLogos}
        exchangesWithLogos={props.exchangesWithLogos}
        transactionFilters={props.transactionFilters}
        applicationSettings={props.applicationSettings}
        compact
      />
    </div>
  );
}
