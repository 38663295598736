import packageJson from "../../package.json";

export function getPackageInfo(): PackageInfo {
  return { version: packageJson.version, name: packageJson.name };
}

export interface PackageInfo {
  version: string;
  name: string;
}
