import { FiEdit2, FiLink, FiTag, FiTrash2 } from "react-icons/fi";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { PostReportUpdateError } from "../../../interfaces/Report/PostReportUpdateError";
import { ErrorsLabel } from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  errorItem: PostReportUpdateError;
}
export function ErrorRow(props: React.PropsWithChildren<Props>) {
  const errorIcon: { [key: string]: JSX.Element } = {
    deletion: <FiTrash2 />,
    tag: <FiTag />,
    body: <FiEdit2 />,
    link: <FiLink />,
  };

  return (
    <div className="error-item-row-container">
      {errorIcon[props.errorItem.updateType]}
      <label>
        {ErrorsLabel[props.applicationSettings.locale]}: {props.errorItem.error}
      </label>
      <label>Id: {props.errorItem.transactionId}</label>
    </div>
  );
}
