import { Button, Space, TextInput } from "@mantine/core";
import { useState } from "react";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
  isExchange: boolean;
  onSubmit: (value: string) => void;
}
export function CustomAccountAndExchangeInput(
  props: React.PropsWithChildren<Props>
) {
  const type = props.isExchange ? "exchange" : "account";

  const [value, setValue] = useState<string>("");

  return (
    <div className="custom-account-exchange-popup-container">
      <TextInput
        className="select-import"
        placeholder={
          props.applicationSettings.locale === "en"
            ? `Enter ${type} name`
            : `Indtast ${type} navn`
        }
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
      />
      <Space w={"xs"} />
      <Button
        className="cs-theme-button"
        radius="xs"
        disabled={value === ""}
        onClick={() => {
          props.onSubmit(value);
          setValue("");
        }}
      >
        {props.applicationSettings.locale === "en"
          ? `Add ${type}`
          : `Tilføj ${type}`}
      </Button>
    </div>
  );
}
