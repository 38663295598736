import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { CurrencyIn } from "../../../interfaces/Report/TransactionData";
import { DepositHeader } from "../ReportSharedComponents/DepositHeader";

interface Props {
  applicationSettings: ApplicationSettings;
  innerId: number;
  transactionId: number;
  currencyIn: CurrencyIn | undefined;
  errorInformation: number[];
  serviceInformation: number[];
  expanded: boolean;
  editMode: boolean;
  edited: boolean;
  enableLinkMode: (fromTransactionId: number) => void;
  handleClicked: (id: number) => void;
  setEditModeValue: (isEditMode: boolean) => void;
}
export function ReportDepositContainer(props: React.PropsWithChildren<Props>) {

  return (
    <>
      {props.currencyIn && (
        <div
          className="report-deposit-container"
          onClick={() => props.handleClicked(props.transactionId)}
        >
          <DepositHeader
            applicationSettings={props.applicationSettings}
            currencyIn={props.currencyIn}
            showExtras
            focus={props.expanded}
          />
        </div>
      )}
    </>
  );
}
