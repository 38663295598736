export const apiSecurityIntructions: any = {
  en: (
    <>
      <label className="intruction-label">
        Incorrectly configured API keys can lead to the loss of all funds at an
        exchange. Please pay attention to the following points and handle API
        keys very carefully, just like your passwords.
      </label>
      <ul>
        <li>
          <label>
            When creating a new API key, always assign only the permissions that
            are required (see above)
          </label>
        </li>
        <li>
          <label>Our site only requires keys with read-only permission</label>
        </li>
        <li>
          <label>
            Make sure that your keys have no trade or withdrawal permissions
          </label>
        </li>
        <li>
          <label>
            Use an API key only with a single service (like our site). Using one
            key for multiple services will always result in errors.
          </label>
        </li>
        <li>
          <label>
            All API secrets stored at our site are encrypted and cannot be
            viewed or decrypted by our employees. We will never ask you for keys
            with permissions other than 'read-only'.
          </label>
        </li>
        <li>
          <label>
            Check your keys permission and change your keys from time to time
            just like your passwords
          </label>
        </li>
        <li>
          <label>
            Do not write down API keys and secrets locally on your computer or
            in the cloud
          </label>
        </li>
        <li>
          <label>
            If your browser automatic saves form data and passwords, make sure
            that your API secrets are not stored
          </label>
        </li>
        <li>
          <label> Never share your keys with services you don't trust</label>
        </li>
        <li>
          <label>
            A few exchanges do not have any API permissions and each key has
            full access. Avoid such insecure exchanges and switch to trustworthy
            exchanges.
          </label>
        </li>
      </ul>
    </>
  ),
  da: (
    <>
      <label className="intruction-label">
        Forkert konfigurerede API nøgler kan føre til tab af alle dine midler på
        en exchange. Vær derfor opmærksom på følgende og håndter altid dine API
        nøgler med forsigtighed, ligesom du gør med dine passwords og anden
        sikkerhedsinformation.
      </label>
      <ul>
        <li>
          <label>
            Når du genererer en ny API nøgle, bør du altid kun generere den med
            de nødvendige rettigheder.
          </label>
        </li>
        <li>
          <label>
            CryptoSkat kræver kun brug af API nøgler med rettighed til at læse
            transaktionsdata ("read only"-rettigheder).
          </label>
        </li>
        <li>
          <label>
            Du bør sikre dig at dine API nøgle ikke har rettigheder til at
            handle eller hæve (withdraw) valuta
          </label>
        </li>
        <li>
          <label>
            Generer og brug en API nøgle til hvert et enkelt formål - generer en
            ny nøgle til din brug af CryptoSkat og separate API nøgler til andre
            formål. At bruge den samme API nøgle til flere formål vil altid
            resultere i fejl.
          </label>
        </li>
        <li>
          <label>
            Alle "hemmelige passwords" til dine API nøgler lagret hos CryptoSkat
            er krypterede og kan ikke ses eller tilgås af vores ansatte. Vi vil
            aldrig bede dig om nøgler med andre rettigheder end "read only".
          </label>
        </li>
        <li>
          <label>
            Kontroller løbende dine API nøglers rettigheder og udskift dem
            løbende, ligesom du bør gøre med dine passwords.
          </label>
        </li>
        <li>
          <label>
            Gem ikke dine API nøgler and API nøglernes "hemmelige passwords"
            lokalt på din computer eller i skyen.
          </label>
        </li>
        <li>
          <label>
            Hvis din browser automatisk gemmer formulardata og passwords, bør du
            sikre dig at dine API nøgler ikke automatisk gemmes.
          </label>
        </li>
        <li>
          <label>
            Del aldrig dine API nøgler med services som du ikke stoler på.
          </label>
        </li>
        <li>
          <label>
            Nogle få exchanges opererer ikke med "read only" rettigheder ved API
            nøgle oprettelse. Det er efter vores opfattelse usikkert at bruge
            sådanne exchanges. Undgå disse og brug i stedet andre exchanges.
          </label>
        </li>
      </ul>
    </>
  ),
};
