import { Button, Checkbox } from "@mantine/core";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import {
  EnterPaymentDetailsLabel,
  PaymentFailedLabel,
  PaymentIsProcessingLabel,
  PaymentSuccessfulLabel,
} from "../../utils/labels";
import { TOS } from "../SharedContext/TOS";

interface Props {
  applicationSettings: ApplicationSettings;
  clientSecret: string;
  buttonLabel: string;
}

export function CheckoutForm(props: React.PropsWithChildren<Props>) {
  const stripe = useStripe();
  const elements = useElements();
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      setLoading(true);
      try {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/#/payment-successful`,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(PaymentFailedLabel[props.applicationSettings.locale]);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, setLoading, setMessage, props.applicationSettings.locale]
  );

  useEffect(() => {
    if (!stripe) return;

    stripe
      .retrievePaymentIntent(props.clientSecret)
      .then(({ paymentIntent }) => {
        if (!paymentIntent) return;
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage(
              PaymentSuccessfulLabel[props.applicationSettings.locale]
            );
            break;
          case "processing":
            setMessage(
              PaymentIsProcessingLabel[props.applicationSettings.locale]
            );
            break;
          case "requires_payment_method":
            setMessage(
              EnterPaymentDetailsLabel[props.applicationSettings.locale]
            );
            break;
          default:
            setMessage(PaymentFailedLabel[props.applicationSettings.locale]);
            break;
        }
      });
  }, [
    props.clientSecret,
    stripe,
    setMessage,
    props.applicationSettings.locale,
  ]);

  return (
    <form className="checkout-form-container">
      <div className="checkout-label-container">
        <label>{message}</label>
      </div>
      <PaymentElement />
      <motion.div
        className="checkout-button-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <div className="terms-container">
          <Checkbox
            label={<TOS applicationSettings={props.applicationSettings} />}
            checked={agreeToTerms}
            onChange={(e: any) => setAgreeToTerms(e.target.checked)}
            onClick={() => setAgreeToTerms(!agreeToTerms)}
            color={props.applicationSettings.theme === "dark" ? "gray" : "dark"}
          />
        </div>

        <Button
          onClick={(e: any) => handleSubmit(e)}
          loading={loading}
          disabled={!stripe || !elements || !agreeToTerms}
          className="cs-theme-button"
          size={"lg"}
          leftIcon={<FiShoppingCart />}
        >
          {props.buttonLabel}
        </Button>
      </motion.div>
    </form>
  );
}
