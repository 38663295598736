import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { InstructionsLabel } from "../../../../utils/labels";
import { WalletApiInput } from "../../WalletApiInput";
import { PlatformLink } from "../PlatformLink";

interface Props {
  platformId: string;
  applicationSettings: ApplicationSettings;
  closeDrawer: () => void;
}

export function Exodus(props: React.PropsWithChildren<Props>) {
  const guideLine = (
    <>
      <PlatformLink
        href="https://support.exodus.com/article/79-how-do-i-open-the-developer-menu"
        label="Exodus Guide"
      />
    </>
  );

  const platformLink = (
    <>
      <PlatformLink href="https://exodus.com/" label="Exodus Wallet" />
    </>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Kontrollér at {guideLine} er slået til i din Exodus wallet.
      </label>,
      <label className="instruction-label">
        Åben din {platformLink} og eksporter transaktionshistorikken ved at gå
        til <strong>Developer Menu</strong> og derefter vælg{" "}
        <strong>Export all transaktions</strong>.
      </label>,
      <label className="instruction-label">
        Upload CSV filen til CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Ensure that the {guideLine} is enabled in your Exodus wallet.
      </label>,
      <label className="instruction-label">
        Open your {platformLink} and export the transaction history (
        <strong>Developer</strong> {"->"}{" "}
        <strong>Export All Transactions</strong>)
      </label>,
      <label className="instruction-label">Upload the CSV file here</label>,
    ],
  };
  return (
    <div className="intructions-container">
      <label className="subheader">
        <strong>
          {InstructionsLabel[props.applicationSettings.locale]}
        </strong>
      </label>
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <WalletApiInput
        applicationSettings={props.applicationSettings}
        walletId={props.platformId}
        closeDrawer={props.closeDrawer}
      />
    </div>
  );
}
