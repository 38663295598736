import "./menu.scss";
import { useMediaQuery } from "@mantine/hooks";
import { Button, Menu, Modal } from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { FiLogIn, FiLogOut, FiUserPlus, FiUser } from "react-icons/fi";
import { useCallback, useContext, useState } from "react";
import { LoginComponent } from "../Authentication/LoginComponent";
import { RegistrationComponent } from "../Registration/RegistrationComponent";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../App";
import {
  LoginLabel,
  LogoutLabel,
  RegisterLabel,
  SignInOrRegisterLabel,
} from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => void;
  handleButtonClick?: (route: string) => void;
}
export function AuthenticationMenu(props: React.PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const authenticationData = useContext(AuthenticationContext);

  const signedIn = authenticationData !== undefined;
  const [loginRegistrationModalOpened, setLoginRegistrationModalOpened] =
    useState(false);

  const maxUsernameLength = 10;
  const previewUsername = authenticationData
    ? `${authenticationData.email.slice(0, maxUsernameLength)}${
        authenticationData.email.length > maxUsernameLength ? "..." : ""
      }`
    : "User";
  const username = authenticationData ? authenticationData.email : "User";

  const [registrationFormSelected, setRegistrationFormSelected] =
    useState(false);
  const compact = useMediaQuery("(max-width: 768px)");

  const handleButtonClick = useCallback(
    (route: string) => {
      if (props.handleButtonClick) {
        props.handleButtonClick(route);
      }
    },
    [props]
  );

  const closeModal = useCallback(() => {
    setLoginRegistrationModalOpened(false);
  }, [setLoginRegistrationModalOpened]);

  const createUserLabel = (_email?: string) => {
    if (!_email) return undefined;
    return _email.split("@")[0];
  };

  if (compact) {
    return (
      <div className="authentication-controls-container-compact">
        {signedIn ? (
          <>
            <Button
              onClick={props.handleLogout}
              className="cs-theme-button"
              radius="xs"
              leftIcon={<FiLogOut />}
              uppercase
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => handleButtonClick("login")}
              className="cs-theme-button"
              radius="xs"
              uppercase
              leftIcon={<FiLogIn />}
            >
              {LoginLabel[props.applicationSettings.locale]}
            </Button>
            <Button
              onClick={() => handleButtonClick("register")}
              radius="xs"
              className="cs-theme-button"
              uppercase
              leftIcon={<FiUserPlus />}
            >
              {RegisterLabel[props.applicationSettings.locale]}
            </Button>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <Menu
        closeOnItemClick
        control={
          <div className="authentication-menu-control">
            {signedIn ? (
              <label className="user-label">
                {createUserLabel(previewUsername)}
              </label>
            ) : undefined}
            <FiUser className="icon" size={24} />
          </div>
        }
      >
        <Menu.Label>
          {signedIn
            ? username
            : SignInOrRegisterLabel[props.applicationSettings.locale]}
        </Menu.Label>
        {signedIn ? (
          <>
            <Menu.Item icon={<FiLogOut />} onClick={props.handleLogout}>
              <div className="authentication-menu-item">
                <label>{LogoutLabel[props.applicationSettings.locale]}</label>
              </div>
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item
              icon={<FiLogIn />}
              onClick={() => {
                navigate("/login");
              }}
            >
              <div className="authentication-menu-item">
                <label>{LoginLabel[props.applicationSettings.locale]}</label>
              </div>
            </Menu.Item>
            <Menu.Item
              icon={<FiUserPlus />}
              onClick={() => {
                navigate("/register");
              }}
            >
              <div className="authentication-menu-item">
                <label>{RegisterLabel[props.applicationSettings.locale]}</label>
              </div>
            </Menu.Item>
          </>
        )}
      </Menu>
      <Modal
        opened={loginRegistrationModalOpened}
        centered
        size={registrationFormSelected ? "xl" : "md"}
        onClose={() => setLoginRegistrationModalOpened(false)}
        title={
          registrationFormSelected
            ? RegisterLabel[props.applicationSettings.locale]
            : LoginLabel[props.applicationSettings.locale]
        }
        overlayColor="rgba(0, 0, 0, 0.5)"
        overlayBlur={2}
      >
        {registrationFormSelected ? (
          <RegistrationComponent
            applicationSettings={props.applicationSettings}
            closeModal={closeModal}
          />
        ) : (
          <LoginComponent
            applicationSettings={props.applicationSettings}
            closeModal={closeModal}
          />
        )}
      </Modal>
    </>
  );
}
