import { Badge } from "@mantine/core";
import { useContext } from "react";
import { FetchedExchangesContext, FetchedWalletsContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ManualExchangeIntegrationsLabel } from "../../utils/labels";
import { PlatformLogo } from "../Import/Instructions/manual/PlatformLogo";
import {
  getLowercaseSpecialCustomers,
  getSpecialCustomerFromLowercase,
  getSpecialCustomers,
} from "../../utils/specialCustomersUtil";
import { SpecialCustomerLogo } from "../Import/Instructions/manual/CustomCustomerLogo";

interface Props {
  applicationSettings: ApplicationSettings;
  manualIntegrationPlatforms: string[];
}
export function ManualIntegrations(props: React.PropsWithChildren<Props>) {
  const fetchedWallets = useContext(FetchedWalletsContext);
  const fetchedExchanges = useContext(FetchedExchangesContext);

  const lowercaseWallets = fetchedWallets?.data.map((wallet) => wallet.slug);
  const lowercaseExchanges = fetchedExchanges?.data.map(
    (exchange) => exchange.slug
  );

  const lowercaseManualIntegrationPlatforms =
    props.manualIntegrationPlatforms.map((exchange) => exchange.toLowerCase());

  const manualWalletIntegrations = lowercaseManualIntegrationPlatforms.filter(
    (platform) => lowercaseWallets?.includes(platform)
  );
  const manualExchangeIntegrations = lowercaseManualIntegrationPlatforms.filter(
    (platform) => lowercaseExchanges?.includes(platform)
  );

  const lowercaseSpecialCustomers = getLowercaseSpecialCustomers();

  const specialCustomerIntegrations =
    lowercaseManualIntegrationPlatforms.filter((ex) =>
      lowercaseSpecialCustomers.includes(ex)
    );

  const remainingManualIntegrations =
    lowercaseManualIntegrationPlatforms.filter(
      (platform) =>
        !lowercaseWallets?.includes(platform) &&
        !lowercaseExchanges?.includes(platform) &&
        !specialCustomerIntegrations.includes(platform)
    );

  return (
    <div className="integrations-container">
      <label>
        {ManualExchangeIntegrationsLabel[props.applicationSettings.locale]}
      </label>
      <div className="divider"></div>
      <div className="platform-columns">
        {manualExchangeIntegrations.length > 0 && (
          <>
            {manualExchangeIntegrations.map((exchangeName) => {
              return (
                <PlatformLogo
                  key={exchangeName}
                  activeTheme={props.applicationSettings.theme}
                  platform={"exchanges"}
                  title={exchangeName}
                  compact={false}
                />
              );
            })}
          </>
        )}
        {manualWalletIntegrations.length > 0 && (
          <>
            {manualWalletIntegrations.map((walletName) => {
              return (
                <PlatformLogo
                  key={walletName}
                  activeTheme={props.applicationSettings.theme}
                  platform={"wallets"}
                  title={walletName}
                  compact={false}
                />
              );
            })}
          </>
        )}

        {specialCustomerIntegrations.length > 0 && (
          <>
            {specialCustomerIntegrations.map((specialCustomer) => {
              return (
                <SpecialCustomerLogo
                  specialCustomer={getSpecialCustomerFromLowercase(
                    specialCustomer
                  )}
                />
              );
            })}
          </>
        )}

        {remainingManualIntegrations.map((platformName) => {
          return (
            <Badge
              radius={"xs"}
              variant="outline"
              size="lg"
              style={{
                cursor: "pointer",
              }}
              color={
                props.applicationSettings.theme === "dark" ? "gray" : "dark"
              }
              key={platformName}
            >
              {platformName}
            </Badge>
          );
        })}
      </div>
    </div>
  );
}
