import {
  ExchangeRate,
  ExchangeRates,
} from "../interfaces/Report/ExchangeRates";
import { ensureFloat, parseTransactionDateToDatestring } from "./formatter";

// getExchangeRate() returns the exchange rate for a given transaction date.
// If the transaction date is undefined, it returns the exchange rate for the current date.
// If the exchange rate for the transaction date is not available, it rounds the transaction date to the first of the month and returns the exchange rate for that date.
//If the exchange rate for the first of the month is not available, it rounds the transaction date to January 1st and returns the exchange rate for that date.
// If the exchange rate for January 1st is not available, it returns 1 for both USD and EUR.
// transactionDate: the date of the transaction, as a string in the format "DD-MM-YYYY HH:MM:SS"
// exchangeRates: an object containing exchange rates for various dates, in the format { "DD-MM-YYYY": { USD: <number>, EUR: <number> }, ... }
// add documentation for the return value

export const getExchangeRate = (
  transactionDate: string | undefined,
  exchangeRates: ExchangeRates
): ExchangeRate => {
  
  // if transactionDate is undefined, use current date
  let exchangeRateKey = transactionDate
    ? transactionDate.split(" ")[0]
    : parseTransactionDateToDatestring(new Date()).split(" ")[0];
  const exchangeRateKeys = Object.keys(exchangeRates);
  let exchangeRate;
  if (exchangeRateKeys.includes(exchangeRateKey)) {
    exchangeRate = exchangeRates[exchangeRateKey];
    return {
      USD: ensureFloat(exchangeRate.USD),
      EUR: ensureFloat(exchangeRate.EUR),
      DKK: 1,
      dateKey: exchangeRateKey,
    };
  }
  // round to the 1st of the month
  const dateFragments = exchangeRateKey.split("-");
  const year = dateFragments[2];
  const month = dateFragments[1];
  const day = "01";
  exchangeRateKey = `${day}-${month}-${year}`;
  if (exchangeRateKeys.includes(exchangeRateKey)) {
    exchangeRate = exchangeRates[exchangeRateKey];
    return {
      USD: ensureFloat(exchangeRate.USD),
      EUR: ensureFloat(exchangeRate.EUR),
      DKK: 1,
      dateKey: exchangeRateKey,
    };
  }

  // round to january 1st
  exchangeRateKey = `01-01-${year}`;
  if (exchangeRateKeys.includes(exchangeRateKey)) {
    exchangeRate = exchangeRates[exchangeRateKey];
    return {
      USD: ensureFloat(exchangeRate.USD),
      EUR: ensureFloat(exchangeRate.EUR),
      DKK: 1,
      dateKey: exchangeRateKey,
    };
  }

  return { USD: 1, EUR: 1, DKK: 1, dateKey: undefined };
};

export const getSelectedExchangeRate = (
  selectedExchangeRate: string,
  exchangeRate: ExchangeRate
) => {
  switch (selectedExchangeRate) {
    case "USD":
      return exchangeRate.USD;
    case "EUR":
      return exchangeRate.EUR;
    case "DKK":
      return exchangeRate.DKK;
    default:
      return 1;
  }
};
