import { CgArrowsExchangeAlt } from "react-icons/cg";
import { HiOutlineMinusSm } from "react-icons/hi";
import { RxPlus } from "react-icons/rx";
import { TiCancel } from "react-icons/ti";

interface Props {
  type: TransactionType;
  focus?: boolean;
}
export function TransactionType(props: React.PropsWithChildren<Props>) {
  const transactionTypeLabels: { [key: string]: JSX.Element } = {
    trade: (
      <div
        className="transaction-type-label"
        id={props.focus ? "trade-type-label-focus" : "trade-type-label"}
      >
        <CgArrowsExchangeAlt />
        Trade
      </div>
    ),
    withdrawal: (
      <div
        className="transaction-type-label"
        id={
          props.focus ? "withdrawal-type-label-focus" : "withdrawal-type-label"
        }
      >
        <HiOutlineMinusSm />
        Withdrawal
      </div>
    ),
    deposit: (
      <div
        className="transaction-type-label"
        id={props.focus ? "deposit-type-label-focus" : "deposit-type-label"}
      >
        <RxPlus />
        Deposit
      </div>
    ),
    cancelled: (
      <div
        className="transaction-type-label"
        id={props.focus ? "cancelled-type-label-focus" : "cancelled-type-label"}
      >
        <TiCancel />
        Cancelled
      </div>
    ),
  };

  return <>{transactionTypeLabels[props.type.toLowerCase()]}</>;
}

export type TransactionType = "Trade" | "Withdrawal" | "Deposit" | "Cancelled";
