import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ExchangeApiInput } from "../Import/ExchangeApiInput";

interface Props {
  applicationSettings: ApplicationSettings;
  accountName: string;
  accountId: number;
  platformId: string;
  closeModal: () => void;
}
export function RenewApiKeysContainer(props: React.PropsWithChildren<Props>) {
  return (
    <div className="renew-api-keys-container">
      <ExchangeApiInput
        applicationSettings={props.applicationSettings}
        platformId={props.platformId}
        existingAccountName={props.accountName}
        accountId={props.accountId}
        closeModal={props.closeModal}
      />
    </div>
  );
}
