import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { CurrencyOut, Fee } from "../../../interfaces/Report/TransactionData";
import { WithdrawalHeader } from "../ReportSharedComponents/WithdrawalHeader";

interface Props {
  applicationSettings: ApplicationSettings;
  innerId: number;
  transactionId: number;
  currencyOut: CurrencyOut | undefined;
  errorInformation: number[];
  serviceInformation: number[];
  fee: Fee;
  expanded: boolean;
  handleClicked: (id: number) => void;
  enableLinkMode: (fromTransactionId: number) => void;
  edited: boolean;
  setEditModeValue: (isEditMode: boolean) => void;
}

export function ReportWithdrawalContainer(
  props: React.PropsWithChildren<Props>
) {
  return (
    <>
      {props.currencyOut && (
        <div
          className="report-withdrawal-container"
          onClick={() => props.handleClicked(props.transactionId)}
        >
          <WithdrawalHeader
            currencyOut={props.currencyOut}
            applicationSettings={props.applicationSettings}
            showExtras
            focus={props.expanded}
            fee={props.fee}
          />
        </div>
      )}
      {props.fee && !props.currencyOut && (
        <div
          className="report-withdrawal-container"
          onClick={() => props.handleClicked(props.transactionId)}
        >
          <WithdrawalHeader
            applicationSettings={props.applicationSettings}
            showExtras
            focus={props.expanded}
            fee={props.fee}
          />
        </div>
      )}
    </>
  );
}
