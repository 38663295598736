import { ceil, log10 } from "mathjs";

export const capitalizeString = (str?: string) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertFileSizeToReadable = (size: number) => {
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const power = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, power)).toFixed(2)} ${units[power]}`;
};

export const formatNumberForPreview = (
  amountString: string,
  fixedLevel: number
) => {
  const amount = parseFloat(amountString);
  return amount.toFixed(fixedLevel);
};

export const formatTimestampToString = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const dayString = day < 10 ? `0${day}` : day;
  const monthString = month < 10 ? `0${month}` : month;
  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;
  return `${dayString}.${monthString}.${year} ${hoursString}:${minutesString}:${secondsString}`;
};

export const parseTransactionDateStringToDate = (dateString: string) => {
  const dateObjectFragments = dateString.split(" ");
  const dateFragments = dateObjectFragments[0].split("-");
  const time = dateObjectFragments[1].split(":");
  const date = new Date();
  date.setHours(parseInt(time[0]));
  date.setMinutes(parseInt(time[1]));
  date.setSeconds(parseInt(time[2]));
  date.setDate(parseInt(dateFragments[0]));
  date.setMonth(parseInt(dateFragments[1]) - 1);
  date.setFullYear(parseInt(dateFragments[2]));
  return date;
};

export const ensureFloat = (value: string | number) => {
  return typeof value === "string" ? parseFloat(value) : value;
};

export const parseTransactionDateToDatestring = (
  date: Date,
  timestring?: string
) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const dayString = day < 10 ? `0${day}` : day;
  const monthString = month < 10 ? `0${month}` : month;
  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;
  if (timestring) {
    return `${dayString}-${monthString}-${year} ${timestring}`;
  }
  return `${dayString}-${monthString}-${year} ${hoursString}:${minutesString}:${secondsString}`;
};

export const parseDateToFilterParameterDateString = (date: Date) => {
  // 2021-01-01
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const dayString = day < 10 ? `0${day}` : day;
  const monthString = month < 10 ? `0${month}` : month;
  return `${year}-${monthString}-${dayString}`;
};

export const parseFilterParameterDateStringToDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const parsedFloatIsNan = (value: string) => {
  const parsedFloat = parseFloat(value);
  return isNaN(parsedFloat);
};

export const getConversionRate = (usdAmount: number, dkkAmount: number) => {
  return parseFloat((dkkAmount / usdAmount).toFixed(2));
};

export const determineDecimals = (value: number | null | undefined) => {
  if (value === null || value === undefined) return 0;
  if (value === 0) return 0;
  const append = value > 1000 ? 0 : 2;
  return Math.max(0, ceil(-log10(value))) + append;
};