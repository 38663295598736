import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../App";

interface Props {
  children: any;
}

export const ProtectedRoute: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const authenticationData = useContext(AuthenticationContext);
  if (!authenticationData || !authenticationData.verified) {
    navigate("/login");
  }

  return props.children;
};
