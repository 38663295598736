import "./payment-details.scss";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { PaymentDetails } from "../../../interfaces/Membership/SubscriptionPlans";
import {
  AdjustedPriceLabel,
  AlreadyPaidLabel,
  PriceLabel,
  VatLabel,
} from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  paymentDetails: PaymentDetails;
  originalPrice: number;
}
export function PaymentDetailsLabel(props: React.PropsWithChildren<Props>) {

  const getAbsoluteValue = (value: number | undefined) => {
    return value ? Math.abs(value) : 0;
  };
  return (
    <div className="payment-details-container">
      <label>
        {AlreadyPaidLabel[props.applicationSettings.locale]}{" "}
        {PriceLabel[props.applicationSettings.locale]}:{" "}
        <strong>{props.paymentDetails.alreadyPaid?.details_text}</strong> ={" "}
        {props.paymentDetails.alreadyPaid?.value}
      </label>
      <label>
        {AlreadyPaidLabel[props.applicationSettings.locale]}{" "}
        {VatLabel[props.applicationSettings.locale]}:{" "}
        <strong>{props.paymentDetails.alreadyPaidVat?.details_text}</strong> ={" "}
        {props.paymentDetails.alreadyPaidVat?.value}
      </label>
      <label>
        {AdjustedPriceLabel[props.applicationSettings.locale]}:{" "}
        <strong>
          {props.originalPrice} - ({getAbsoluteValue(props.paymentDetails.alreadyPaid?.value)} +{" "}
          {getAbsoluteValue(props.paymentDetails.alreadyPaidVat?.value)}) ={" "}
        </strong>
        {props.paymentDetails.total} DKK
      </label>
    </div>
  );
}
