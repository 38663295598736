import { Button, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
  existingAccountName: string;
  customAccountName: string;
  setPickedValue: (
    _selectedUserAccount: string | undefined,
    _customAccountName: string | undefined
  ) => void;
}
export function PromptPopoverContent(props: React.PropsWithChildren<Props>) {
  return (
    <div>
      <label>
        {props.applicationSettings.locale === "en"
          ? "Select account"
          : "Vælg konto"}
      </label>
      <div className="popover-content-container">
        <Button
          variant="outline"
          className="cs-theme-button"
          radius="xs"
          onClick={() =>
            props.setPickedValue(props.existingAccountName, undefined)
          }
        >
          {props.existingAccountName}
        </Button>
        <Space w="xs" />
        <Button
          variant="outline"
          className="cs-theme-button"
          radius="xs"
          onClick={() =>
            props.setPickedValue(undefined, props.customAccountName)
          }
        >
          {props.customAccountName}
        </Button>
      </div>
    </div>
  );
}
