import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { InstructionsLabel } from "../../../../utils/labels";
import { WalletApiInput } from "../../WalletApiInput";
import { PlatformLink } from "../PlatformLink";

interface Props {
  applicationSettings: ApplicationSettings;
  platformId: string;
  closeDrawer: () => void;
}

export function Ledger(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <>
      <PlatformLink
        href="https://www.ledgerwallet.com/"
        label="Ledger Live application"
      />
    </>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">Åbn din {platformLink}.</label>,
      <label className="instruction-label">
        Gå til <strong>Accounts</strong> og tryk på <strong>Settings</strong> og
        derefter <strong>Operation history</strong>.
      </label>,
      <label className="instruction-label">
        Vælg{" "}
        <strong>
          Save a CSV file of your account operations on your computer
        </strong>
        .
      </label>,
      <label className="instruction-label">
        Upload CSV filen til CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Open your {platformLink} is enabled in your Exodus wallet.
      </label>,
      <label className="instruction-label">
        Click <strong>Settings</strong> in the <strong>Account tab</strong> and
        select <strong>Operation history</strong>.
      </label>,
      <label className="instruction-label">
        Select{" "}
        <strong>
          Save a CSV file of your account operations on your computer
        </strong>
        .
      </label>,
      <label className="instruction-label">Upload the CSV file here</label>,
    ],
  };
  return (
    <div className="intructions-container">
      <label className="subheader">
        <strong>{InstructionsLabel[props.applicationSettings.locale]}</strong>
      </label>
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <WalletApiInput
        applicationSettings={props.applicationSettings}
        walletId={props.platformId}
        closeDrawer={props.closeDrawer}
      />
    </div>
  );
}
