import "./loginform.scss";
import { Button, Loader, PasswordInput, TextInput } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import {
  FiArrowRight,
  FiAtSign,
  FiCheck,
  FiLock,
  FiLogIn,
  FiX,
} from "react-icons/fi";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import AuthService from "../../service/AuthService";
import { AuthenticationResponse } from "../../interfaces/Authentication/AuthenticationResponse";
import { SetAuthenticationDataContext } from "../../App";
import { decodeToken } from "../../utils/decoder";
import { validateEmail } from "../../utils/validation";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ForgotPasswordLabel,
  InvalidEmailOrPasswordLabel,
  LoginLabel,
  OrLabel,
  PasswordLabel,
  RegisterLabel,
} from "../../utils/labels";
import { darkTheme, lightTheme } from "../../style/Themes";

interface Props {
  applicationSettings: ApplicationSettings;
  handleSuccesfulLogin: () => void;
  closeModal?: () => void;
}

export function LoginForm(props: Props) {
  const navigate = useNavigate();
  const theme =
    props.applicationSettings.theme === "dark" ? darkTheme : lightTheme;
  const setAuthenticationData = useContext(SetAuthenticationDataContext);
  const location = useLocation();

  const locationState = location.state;

  const [didFetch, setDidFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const attachToken = useCallback(
    (authenticationResponse: AuthenticationResponse) => {
      const decodedToken = decodeToken(authenticationResponse.access_token);
      setAuthenticationData({
        expiry: decodedToken.exp,
        token: authenticationResponse.access_token,
        verified: false,
        email: decodedToken.email,
        claims: decodedToken?.claims,
      });
    },
    [setAuthenticationData]
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleForgotPasswordClick = useCallback(() => {
    if (props.closeModal) {
      props.closeModal();
    }
    navigate("/resetPassword");
  }, [props, navigate]);

  const isValidInput = useCallback(() => {
    return validateEmail(email) && password.length > 0;
  }, [email, password]);

  const validInput = isValidInput();

  const handleLogin = useCallback(async () => {
    if (!validInput) return;
    try {
      setLoading(true);
      setDidFetch(true);
      const response = await AuthService.login(email, password);
      setError(undefined);
      props.handleSuccesfulLogin();
      attachToken(response);
      if (props.closeModal) {
        props.closeModal();
        navigate("/login");
      }
    } catch (error: any) {
      setError(InvalidEmailOrPasswordLabel[props.applicationSettings.locale]);
    } finally {
      setLoading(false);
      return;
    }
  }, [
    props,
    validInput,
    email,
    password,
    setLoading,
    setError,
    attachToken,
    navigate,
  ]);

  return (
    <div className="login-form-container">
      <div
        className="forgot-password-container"
        typeof="button"
        onClick={handleForgotPasswordClick}
      >
        <label>{ForgotPasswordLabel[props.applicationSettings.locale]}?</label>
      </div>
      <TextInput
        placeholder="Email"
        label="Email"
        icon={<FiAtSign size={14} />}
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
        onKeyPress={handleKeyPress}
      />
      <PasswordInput
        placeholder={PasswordLabel[props.applicationSettings.locale]}
        label={PasswordLabel[props.applicationSettings.locale]}
        icon={<FiLock size={14} />}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <div className="login-button-container">
        <label>{error}</label>
        <br />
        <Button
          className="cs-theme-button"
          radius={"xs"}
          disabled={!validInput || loading}
          onClick={handleLogin}
          rightIcon={
            loading ? (
              <Loader size="sm" />
            ) : didFetch ? (
              error ? (
                <FiX
                  size={22}
                  color={didFetch ? theme.transactionOut : "transparent"}
                />
              ) : (
                <FiCheck
                  size={22}
                  color={didFetch ? theme.transactionIn : "transparent"}
                />
              )
            ) : (
              <FiLogIn size={22} />
            )
          }
        >
          {LoginLabel[props.applicationSettings.locale]}
        </Button>
        <label>{OrLabel[props.applicationSettings.locale]}</label>
        <Button
          className="cs-theme-button"
          radius={"xs"}
          leftIcon={<FiArrowRight />}
          onClick={() => navigate("/register")}
        >
          {RegisterLabel[props.applicationSettings.locale]}
        </Button>
      </div>
    </div>
  );
}
