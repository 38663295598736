import { useMediaQuery } from "@mantine/hooks";
import { formatNumberForPreview } from "../../../utils/formatter";

interface Props {
  amount: string;
  withdrawal?: true;
}
export function PreviewAmountLabel(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 968px)");
  const portable = useMediaQuery("(max-width: 768px)");
  const narrow = useMediaQuery("(max-width: 500px)");

  return (
    <>
      <label>
        <strong>
          {props.withdrawal ? "-" : "+"}{" "}
          {formatNumberForPreview(props.amount, narrow ? 1 : portable ? 3 : compact ? 3 : 3)}
        </strong>
      </label>
    </>
  );
}
