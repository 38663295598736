import { useContext } from "react";
import { FetchedWalletsContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ImportItem } from "./ImportItem";

interface Props {
  applicationSettings: ApplicationSettings;
  handlePlatformClick: (
    type: string,
    platformName: string,
    platformId: string
  ) => void;
  handleLogout: () => Promise<void>;
}
export function WalletsOverview(props: React.PropsWithChildren<Props>) {
  const fetchedWallets = useContext(FetchedWalletsContext);

  return (
    <>
      {fetchedWallets ? (
        <div className="import-selection-container">
          {fetchedWallets.data.map((exchange, index) => {
            return (
              <ImportItem
                key={exchange.id}
                applicationSettings={props.applicationSettings}
                id={exchange.id.toString()}
                index={index}
                name={exchange.slug}
                type="wallets"
                handlePlatformClick={props.handlePlatformClick}
              />
            );
          })}
        </div>
      ) : undefined}
    </>
  );
}
