export const AddAccountLabel: { [key: string]: string } = {
  da: "Tilføj konto",
  en: "Add account",
};

export const UploadTransactionsLabel: { [key: string]: string } = {
  da: "Upload transaktioner",
  en: "Upload transactions",
};

export const AccountLabel: { [key: string]: string } = {
  da: "Konto",
  en: "Account",
};

export const AccountsLabel: { [key: string]: string } = {
  da: "Konti",
  en: "Accounts",
};

export const ActiveLabel: { [key: string]: string } = {
  da: "Aktiv",
  en: "Active",
};

export const BuyLabel: { [key: string]: string } = {
  da: "Køb",
  en: "Buy",
};

export const ExtendPlanLabel: { [key: string]: string } = {
  da: "Forny",
  en: "Extend",
};

export const PlanPriceSuffixLabel: { [key: string]: string } = {
  da: " DKK / År",
  en: " DKK / Year",
};

export const PaidTodayLabel: { [key: string]: string } = {
  da: "Betalt i dag",
  en: "Paid today",
};

export const AdjustedPriceLabel: { [key: string]: string } = {
  da: "Justeret pris",
  en: "Adjusted price",
};

export const TariffPlanExpiredLabel: { [key: string]: string } = {
  da: "Abonnement udløbet. Forlæng for at fortsætte med at bruge appen.",
  en: "Subscription expired. Extend to continue using the app.",
};

export const RemainingDaysOnPlanLabel: { [key: string]: string } = {
  da: "Dage tilbage på planen",
  en: "Remaining days on plan",
};

export const TaxLawLabel: { [key: string]: string } = {
  da: "Skatteregler",
  en: "Tax law",
};

export const OrLabel: { [key: string]: string } = {
  da: "eller",
  en: "or",
};

export const LoginLabel: { [key: string]: string } = {
  da: "Log ind",
  en: "Login",
};

export const LogoutLabel: { [key: string]: string } = {
  da: "Log ud",
  en: "Logout",
};

export const RegisterLabel: { [key: string]: string } = {
  da: "Opret",
  en: "Register",
};

export const SignInOrRegisterLabel: { [key: string]: string } = {
  da: "Log ind eller opret",
  en: "Sign in or register",
};

export const InstructionsLabel: { [key: string]: string } = {
  da: "Instruktioner",
  en: "Instructions",
};

export const UpgradeMembershipLabel: { [key: string]: string } = {
  da: "Opgrader medlemskab",
  en: "Upgrade membership",
};

export const ChangeApiKeysLabel: { [key: string]: string } = {
  da: "Skift API nøgler",
  en: "Change API keys",
};

export const PostingUpdatesLabel: { [key: string]: string } = {
  da: "Sender opdateringer...",
  en: "Posting updates...",
};

export const PleaseWaitLabel: { [key: string]: string } = {
  da: "Vent venligst",
  en: "Please wait",
};

export const CalculatingTransactionsLabel: { [key: string]: string } = {
  da: "Udregner transaktioner...",
  en: "Calculating transactions...",
};

export const SubmittedUpdatesAmountLabel: { [key: string]: string } = {
  da: "Sendte opdateringer: ",
  en: "Submitted updates: ",
};

export const UpdateReportLabel: { [key: string]: string } = {
  da: "Opdater rapport",
  en: "Update report",
};

export const TransactionUpdatesAmountLabel: { [key: string]: string } = {
  da: "Antal opdateringer: ",
  en: "Update count: ",
};

export const ReportUpToDateLabel: { [key: string]: string } = {
  da: "Rapporten er opdateret",
  en: "Report is up to date",
};

export const YourTransactionsAreUpToDateLabel: { [key: string]: string } = {
  da: "Dine transaktioner er opdateret",
  en: "Your transactions are up to date",
};

export const CalculateReportLabel: { [key: string]: string } = {
  da: "Udregn rapport",
  en: "Calculate report",
};

export const UpdateLabel: { [key: string]: string } = {
  da: "Opdater",
  en: "Update",
};

export const CalculateLabel: { [key: string]: string } = {
  da: "Udregn",
  en: "Calculate",
};

export const ContinueToPaymentLabel: { [key: string]: string } = {
  da: "Fortsæt til betaling",
  en: "Continue to payment",
};

export const EnterPaymentDetailsLabel: { [key: string]: string } = {
  da: "Indtast betalingsoplysninger",
  en: "Enter payment details",
};

export const PaymentIsProcessingLabel: { [key: string]: string } = {
  da: "Betalingen behandles",
  en: "Payment is processing",
};

export const PaymentSuccessfulLabel: { [key: string]: string } = {
  da: "Betalingen er gennemført",
  en: "Payment successful",
};

export const PaymentFailedLabel: { [key: string]: string } = {
  da: "Betalingen er fejlet",
  en: "Payment failed",
};

export const APIKeyLabel: { [key: string]: string } = {
  da: "API-nøgle",
  en: "API key",
};

export const APISecretLabel: { [key: string]: string } = {
  da: "API-hemmelighed",
  en: "API secret",
};

export const APIPassphraseLabel: { [key: string]: string } = {
  da: "API-adgangskode",
  en: "API passphrase",
};

export const ManualTransactionLabel: { [key: string]: string } = {
  da: "Manuel transaktion",
  en: "Manual transaction",
};

export const CollectDataFromAllAccountsLabel: { [key: string]: string } = {
  da: "Hent data fra alle konti",
  en: "Collect data from all accounts",
};

export const CollectDataLabel: { [key: string]: string } = {
  da: "Hent data",
  en: "Collect data",
};

export const CollectingDataLabel: { [key: string]: string } = {
  da: "Henter data",
  en: "Collecting data",
};

export const DeleteAccount: { [key: string]: string } = {
  da: "Slet konto",
  en: "Delete account",
};

export const ConfirmDeletionOf: { [key: string]: string } = {
  da: "Bekræft sletning af",
  en: "Confirm deletion of",
};

export const AcquisitionCostLabel: { [key: string]: string } = {
  da: "Købspris",
  en: "Acquisition Cost",
};
export const AcquisitionDateLabel: { [key: string]: string } = {
  da: "Købsdato",
  en: "Acquisition Date",
};

export const MissingLabel: { [key: string]: string } = {
  da: "Mangler",
  en: "Missing",
};

export const ReportedAsLabel: { [key: string]: string } = {
  da: "Rapporteret som",
  en: "Reported as",
};

export const AmountLabel: { [key: string]: string } = {
  da: "Beløb",
  en: "Amount",
};

export const CurrencyLabel: { [key: string]: string } = {
  da: "Valuta",
  en: "Currency",
};

export const ReplaceCurrencyLabel: { [key: string]: string } = {
  da: "Erstat valuta",
  en: "Replace currency",
};

export const SearchableCurrencySelectLabel: { [key: string]: string } = {
  da: "Søg efter valuta",
  en: "Search for currency",
};

export const CurrencyInLabel: { [key: string]: string } = {
  da: "Valuta ind",
  en: "Currency in",
};

export const CurrencyOutLabel: { [key: string]: string } = {
  da: "Valuta ud",
  en: "Currency out",
};

export const ImportLabel: { [key: string]: string } = {
  da: "Importer",
  en: "Import",
};

export const ImportDataLabel: { [key: string]: string } = {
  da: "Importer data",
  en: "Import data",
};

export const ImportFromLabel: { [key: string]: string } = {
  da: "Importer fra",
  en: "Import from",
};

export const ConfirmDeletionLabel: { [key: string]: string } = {
  da: "Bekræft sletning",
  en: "Confirm deletion",
};

export const SaveChangesLabel: { [key: string]: string } = {
  da: "Gem ændringer",
  en: "Save changes",
};

export const UndoTransactionUpdatesLabel: { [key: string]: string } = {
  da: "Fjern opdateringer",
  en: "Undo transaction updates",
};

export const ClearFiltersLabel: { [key: string]: string } = {
  da: "Ryd filtre",
  en: "Clear filters",
};

export const ErrorsLabel: { [key: string]: string } = {
  da: "Fejl",
  en: "Errors",
};

export const DangerZoneLabel: { [key: string]: string } = {
  da: "Farezone",
  en: "Danger zone",
};

export const EtherscanInstructionsLabel: { [key: string]: string } = {
  da: "Indtast din wallet-adresse nedenfor.",
  en: "Paste your wallet address below.",
};

export const NoTransactionsLabel: { [key: string]: string } = {
  da: "Ingen transaktioner",
  en: "No transactions",
};
export const NoTransactionsWithThisFilterLabel: { [key: string]: string } = {
  da: "Ingen transaktioner med dette filter",
  en: "No transactions with this filter",
};

export const MoreOptionsLabel: { [key: string]: string } = {
  da: "Flere muligheder",
  en: "More options",
};

export const CancelLabel: { [key: string]: string } = {
  da: "Fortryd",
  en: "Cancel",
};

export const ClickToViewOriginalLabel: { [key: string]: string } = {
  da: "Klik for at se original",
  en: "Click to view origin",
};

export const ClearErrorsLabel: { [key: string]: string } = {
  da: "Ryd fejl",
  en: "Clear errors",
};

export const SelectExchangeOrWalletLabel: { [key: string]: string } = {
  da: "Vælg exchange eller wallet",
  en: "Pick exchange or wallet",
};

export const SelectAccountOrCreateNewLabel: { [key: string]: string } = {
  da: "Vælg konto eller opret ny",
  en: "Pick account or create new",
};

export const CreateNewAccountLabel: { [key: string]: string } = {
  da: "Opret ny konto",
  en: "Create new account",
};

export const SelectCurrencyLabel: { [key: string]: string } = {
  da: "Vælg valuta",
  en: "Pick currency",
};

export const TransactionDateLabel: { [key: string]: string } = {
  da: "Transaktionsdato",
  en: "Transaction date",
};

export const TimeLabel: { [key: string]: string } = {
  da: "tidspunkt",
  en: "time",
};

export const SpecialTransactionTypeLabel: { [key: string]: string } = {
  da: "Speciel transaktionstype",
  en: "Special transaction type",
};

export const PickDateLabel: { [key: string]: string } = {
  da: "Vælg dato",
  en: "Pick date",
};

export const AcquisitionDateDescriptionLabel: { [key: string]: string } = {
  en: "Select a date of acquisition. Must be earlier than or equal to the deposit date.",
  da: "Vælg en købsdato. Skal være tidligere end eller lig med indbetalingsdatoen.",
};

export const PickTimeLabel: { [key: string]: string } = {
  da: "Vælg tidspunkt",
  en: "Pick time",
};

export const YourInvoicesLabel: { [key: string]: string } = {
  da: "Dine fakturaer",
  en: "Your invoices",
};

export const AccountSettingsLabel: { [key: string]: string } = {
  da: "Kontoindstillinger",
  en: "Account settings",
};

export const ChangePasswordLabel: { [key: string]: string } = {
  da: "Skift kodeord",
  en: "Change password",
};

export const MyPageLabel: { [key: string]: string } = {
  da: "Min side",
  en: "My page",
};

export const PickTypeLabel: { [key: string]: string } = {
  da: "Vælg type",
  en: "Pick type",
};

export const ClickToEditLabel: { [key: string]: string } = {
  da: "Klik for at redigere",
  en: "Click to edit",
};
export const PressToEditLabel: { [key: string]: string } = {
  da: "Tryk for at redigere",
  en: "Press to edit",
};

export const DifferenceLabel: { [key: string]: string } = {
  da: "Difference",
  en: "Difference",
};

export const DarkThemeLabel: { [key: string]: string } = {
  da: "Mørk",
  en: "Dark",
};

export const LightThemeLabel: { [key: string]: string } = {
  da: "Lys",
  en: "Light",
};

export const LocaleNameLabel: { [key: string]: string } = {
  da: "Dansk",
  en: "English",
};

export const AppSettingsLabel: { [key: string]: string } = {
  da: "App indstillinger",
  en: "Application settings",
};

export const ReportLabel: { [key: string]: string } = {
  da: "Rapport",
  en: "Report",
};

export const GenerateReportLabel: { [key: string]: string } = {
  da: "Generer rapport",
  en: "Generate report",
};

export const ReportsLabel: { [key: string]: string } = {
  da: "Rapporter",
  en: "Reports",
};

export const AddTransactionLabel: { [key: string]: string } = {
  da: "Tilføj transaktion",
  en: "Add transaction",
};

export const ImportFromNewAccountLabel: { [key: string]: string } = {
  da: "Importer fra ny konto",
  en: "Import from new account",
};

export const ImportFromExistingAccountLabel: { [key: string]: string } = {
  da: "Importer fra eksisterende konto",
  en: "Import from existing account",
};

export const DeleteAllTransactionsLabel: { [key: string]: string } = {
  da: "Slet alle transaktioner",
  en: "Delete all transactions",
};

export const TransactionsLabel: { [key: string]: string } = {
  da: "Transaktioner",
  en: "Transactions",
};

export const SubmitTransactionLabel: { [key: string]: string } = {
  da: "Gem transaktion",
  en: "Submit transaction",
};

export const SubmitTransactionsLabel: { [key: string]: string } = {
  da: "Gem transaktioner",
  en: "Submit transactions",
};

export const EmptyCustomTransactionsInputLabel: { [key: string]: string } = {
  da: "Dine transaktioner vil blive vist her, når du har uploadet en transaktionsfil",
  en: "Your transactions will be displayed here once you have uploaded a transaction file",
};

export const SubmitVoucherLabel: { [key: string]: string } = {
  da: "Indsend voucher",
  en: "Submit voucher",
};

export const VatLabel: { [key: string]: string } = {
  da: "Moms",
  en: "VAT",
};

export const VoucherAppliedLabel: { [key: string]: string } = {
  da: "Voucher anvendt",
  en: "Voucher applied",
};

export const VoucherNotFound: { [key: string]: string } = {
  da: "Voucher findes ikke",
  en: "Voucher not found",
};

export const AccountNameLabel: { [key: string]: string } = {
  da: "Kontonavn",
  en: "Account name",
};

export const AccountsAreUpToDateLabel: { [key: string]: string } = {
  da: "Konti er opdateret",
  en: "Accounts are up to date",
};

export const UpToDateLabel: { [key: string]: string } = {
  da: "Opdateret",
  en: "Up to date",
};

export const AccountIsUpToDateLabel: { [key: string]: string } = {
  da: "Konto er opdateret",
  en: "Account is up to date",
};

export const InvalidInputLabel: { [key: string]: string } = {
  da: "Ugyldig input",
  en: "Invalid input",
};

export const SaveAccountLabel: { [key: string]: string } = {
  da: "Gem konto",
  en: "Save account",
};

export const DataCollectionInfoLabel: { [key: string]: string } = {
  da: "Indsamling af data kan mellem 1-120 minutter afhængig af antal af transaktioner og valgte exchanges.",
  en: "Data collecting can take between 1-120 minutes depending on the number of transactions and selected exchanges.",
};

export const CopyLabel: { [key: string]: string } = {
  da: "Kopier",
  en: "Copy",
};

export const ForgotPasswordLabel: { [key: string]: string } = {
  da: "Glemt password",
  en: "Forgot password",
};

export const EnterEmailForResetLabel: { [key: string]: string } = {
  da: "Indtast email for at nulstille password",
  en: "Enter email to reset password",
};

export const GeneratingReportForLabel: { [key: string]: string } = {
  da: "Genererer rapport for",
  en: "Generating report for",
};

export const GeneratingReportLabel: { [key: string]: string } = {
  da: "Genererer rapport",
  en: "Generating report",
};

export const ImportTransactionsLabel: { [key: string]: string } = {
  da: "Importer transaktioner",
  en: "Import transactions",
};

export const ImportTransactionFromApiLabel: { [key: string]: string } = {
  da: "Importer transaktioner fra API",
  en: "Import transactions from API",
};

export const ImportTransactionFromCSVLabel: { [key: string]: string } = {
  da: "Importer transaktioner fra CSV",
  en: "Import transactions from CSV",
};

export const ImportTransactionsManuallyLabel: { [key: string]: string } = {
  da: "Importer transaktioner manuelt",
  en: "Import transactions manually",
};

export const EditTransactionLabel: { [key: string]: string } = {
  da: "Rediger transaktion",
  en: "Edit transaction",
};

export const WalletAddressLabel: { [key: string]: string } = {
  da: "Wallet adresse",
  en: "Wallet address",
};

export const EnterWalletAddressLabel: { [key: string]: string } = {
  da: "Indtast wallet adresse",
  en: "Enter wallet address",
};

export const ImportInProgressLabel: { [key: string]: string } = {
  da: "Importerer",
  en: "Import in progress",
};

export const TransactionsRequireManualActionLabel: { [key: string]: string } = {
  da: "Transaktion(er) kræver manuel handling",
  en: "Transaction(s) require manual action",
};

export const CSVHasBeenUploadedLabel: { [key: string]: string } = {
  da: "CSV er blevet uploadet. Begynder importering...",
  en: "CSV has been uploaded. Importing...",
};

export const NotACSVFileLabel: { [key: string]: string } = {
  da: "Filen er ikke en CSV fil",
  en: "his is not a CSV-file",
};

export const RemoveLabel: { [key: string]: string } = {
  da: "Fjern",
  en: "Remove",
};

export const PriceLabel: { [key: string]: string } = {
  da: "Pris",
  en: "Price",
};

export const AlreadyPaidLabel: { [key: string]: string } = {
  da: "Allerede betalt",
  en: "Already paid",
};

export const AllTaxYearsLabel: { [key: string]: string } = {
  da: "Understøtter alle skatteår",
  en: "Supports all tax years",
};

export const YearLabel: { [key: string]: string } = {
  da: "År",
  en: "Year",
};

export const UnlimitedExchangesAndWalletsLabel: { [key: string]: string } = {
  da: "Ubegrænset antal exchanges og wallets",
  en: "Unlimited number of exchanges and wallets",
};

export const UploadLabel: { [key: string]: string } = {
  da: "Upload",
  en: "Upload",
};

export const DropFileHereLabel: { [key: string]: string } = {
  da: "Drop filen her",
  en: "Drop the file here",
};

export const SelectPeriodLabel: { [key: string]: string } = {
  da: "Vælg periode",
  en: "Select period",
};

export const MoreReportOptionsLabel: { [key: string]: string } = {
  da: "For flere muligheder, gå til rapport-siden",
  en: "For more options, go to the report page",
};

export const ReportCountLabel: { [key: string]: string } = {
  da: "Antal rapporter",
  en: "Report count",
};

export const DashboardWelcomeLabel: { [key: string]: string } = {
  da: "Velkommen til dit CryptoSkat",
  en: "Welcome to your CryptoSkat",
};

export const GetStartedLabel: { [key: string]: string } = {
  da: "Kom i gang",
  en: "Get started",
};

export const ExchangeIntegrationsLabel: { [key: string]: string } = {
  da: "Exchange integrationer",
  en: "Exchange integrations",
};

export const ManualExchangeIntegrationsLabel: { [key: string]: string } = {
  da: "Manuelle integrationer",
  en: "Manual integrations",
};

export const WalletIntegrationsLabel: { [key: string]: string } = {
  da: "Wallet integrationer",
  en: "Wallet integrations",
};

export const PickCustomDateRangeLabel: { [key: string]: string } = {
  da: "Brugerdefineret interval",
  en: "Pick custom date range",
};

export const DateLabel: { [key: string]: string } = {
  da: "Dato",
  en: "Date",
};

export const DeleteLabel: { [key: string]: string } = {
  da: "Slet",
  en: "Delete",
};

export const DeletedLabel: { [key: string]: string } = {
  da: "Slettet",
  en: "Deleted",
};

export const EditLabel: { [key: string]: string } = {
  da: "Rediger",
  en: "Edit",
};

export const FeeLabel: { [key: string]: string } = {
  da: "Gebyr",
  en: "Fee",
};

export const NTransactionsAddedLabel: { [key: string]: string } = {
  da: "Transkation(er) tilføjet",
  en: "Transaction(s) added",
};

export const HangTightWhileWeProcessLabel: { [key: string]: string } = {
  da: "Vent venligst mens vi behandler dine transaktioner",
  en: "Hang tight while we process your transactions",
};

export const FeeCurrencyLabel: { [key: string]: string } = {
  da: "Gebyr valuta",
  en: "Fee currency",
};

export const CurrencyFeeLabel: { [key: string]: string } = {
  da: "Valuta gebyr",
  en: "Currency fee",
};

export const FeeAmountLabel: { [key: string]: string } = {
  da: "Gebyr beløb",
  en: "Fee amount",
};

export const FromLabel: { [key: string]: string } = {
  da: "Fra",
  en: "From",
};

export const GainLabel: { [key: string]: string } = {
  da: "Gevinst",
  en: "Gain",
};

export const HelpLabel: { [key: string]: string } = {
  da: "Hjælp",
  en: "Help",
};

export const InvoicesLabel: { [key: string]: string } = {
  da: "Betalinger",
  en: "Invoices",
};

export const LanguageLabel: { [key: string]: string } = {
  da: "Sprog",
  en: "Language",
};

export const LossLabel: { [key: string]: string } = {
  da: "Tab",
  en: "Loss",
};

export const MembershipLabel: { [key: string]: string } = {
  da: "Medlemskab",
  en: "Membership",
};

export const SalesProceedsLabel: { [key: string]: string } = {
  da: "Salgspris",
  en: "Sales Proceeds",
};

export const SwitchToLabel: { [key: string]: string } = {
  da: "Skift til",
  en: "Switch to",
};

export const TagTransactionLabel: { [key: string]: string } = {
  da: "Tag transaktion",
  en: "Tag transaction",
};

export const ThemeLabel: { [key: string]: string } = {
  da: "Tema",
  en: "Theme",
};

export const TransactionTypeLabel: { [key: string]: string } = {
  da: "Transaktionstype",
  en: "Transaction Type",
};

export const TransactionTypeDescriptionLabel: { [key: string]: string } = {
  da: "Beskrivelse",
  en: "Description",
};

export const ToLabel: { [key: string]: string } = {
  da: "Til",
  en: "To",
};

export const TotalInLabel: { [key: string]: string } = {
  da: "I alt i",
  en: "Total in",
};

export const CopiedLabel: { [key: string]: string } = {
  da: "Kopieret",
  en: "Copied",
};

export const TaggedAsLabel: { [key: string]: string } = {
  da: "Tagget som",
  en: "Tagged as",
};

export const UpdatePendingLabel: { [key: string]: string } = {
  da: "Afventer opdatering",
  en: "Update pending",
};

export const LinkedToLabel: { [key: string]: string } = {
  da: "Linket til",
  en: "Linked to",
};

export const LinkTransactionLabel: { [key: string]: string } = {
  da: "Link transaktion",
  en: "Link transaction",
};

export const LinkedToTransactionLabel: { [key: string]: string } = {
  da: "Linket til transaktion",
  en: "Linked to transaction",
};

export const UpdateYourChangesToLinkLabel: { [key: string]: string } = {
  da: "Opdater dine ændringer for at linke igen",
  en: "Update your changes to link this transaction again",
};

export const UnlinkedFromTransactionLabel: { [key: string]: string } = {
  da: "Unlinket fra transaktion",
  en: "Unlinked from transaction",
};

export const EtherscanAnnouncementLabel: { [key: string]: string } = {
  da: "Transaktioner på Metamask, Trezor og MyEtherWallet kan nu importeres direkte fra Etherscan.",
  en: "Transactions on Metamask, Trezor and MyEtherWallet can now be imported directly from Etherscan.",
};

export const NewDesignAnnouncementLabel: { [key: string]: string } = {
  da: "Nyt design af brugerfladen - velkommen til en forbedret og lettere vej til overholdelse af skattereglerne.",
  en: "New user interface released - welcome to a much improved and easier road to tax compliance.",
};

export const ExchangeRateAnnouncementLabel: { [key: string]: string } = {
  da: "Valutaomregning mellem USD/EUR/DKK efter Nationalbankens vekselkurs er nu tilgængelig direkte når en transaktion rettes.",
  en: "Currency conversion between USD/EUR/DKK according to the Danish National Bank conversion rates now available directly when editing a transaction.",
};

export const SessionRefreshedLabel: { [key: string]: string } = {
  da: "Din session er blevet opdateret",
  en: "Your session has been refreshed",
};

export const AddedAccountLabel: { [key: string]: string } = {
  da: "Konto tilføjet",
  en: "Successfully added exchange account",
};

export const InvalidFileFormatLabels: { [key: string]: string[] } = {
  da: ["Ugyldigt filformat", "Please check the file and try again"],
  en: ["Invalid file format", "Venligst kontroller filen og prøv igen"],
};

export const CSVUploadedLabels: { [key: string]: string[] } = {
  da: ["CSV er blevet uploadet", "Begynder importering..."],
  en: ["CSV has been uploaded", "Importing..."],
};

export const AllTransactionsUpdated: { [key: string]: string } = {
  da: "Alle transaktioner opdateret",
  en: "All transactions updated",
};

export const CouldNotGetExchangeAPIFieldsLabel: { [key: string]: string } = {
  da: "Kunne ikke hente felter fra exchange API",
  en: "Could not get fields from exchange API",
};

export const TagHasBeenAddedLabel: { [key: string]: string } = {
  da: "Tag er blevet tilføjet",
  en: "Tag has been added",
};

export const ShowLabel: { [key: string]: string } = {
  da: "Vis",
  en: "Show",
};

export const FirstNameLabel: { [key: string]: string } = {
  da: "Fornavn",
  en: "First name",
};

export const LastNameLabel: { [key: string]: string } = {
  da: "Efternavn",
  en: "Last name",
};

export const EmailLabel: { [key: string]: string } = {
  da: "Email",
  en: "Email",
};

export const PasswordLabel: { [key: string]: string } = {
  da: "Kodeord",
  en: "Password",
};

export const ConfirmPasswordLabel: { [key: string]: string } = {
  da: "Bekræft kodeord",
  en: "Confirm password",
};

export const ConfirmEmailLabel: { [key: string]: string } = {
  da: "Bekræft email",
  en: "Confirm email",
};

export const PasswordRequirementLabel: { [key: string]: string } = {
  da: "Kodeordet skal være mindst 8 tegn langt",
  en: "Password must be at least 8 characters long",
};

export const PasswordsMustMatchLabel: { [key: string]: string } = {
  da: "Kodeordene skal være ens",
  en: "Passwords must match",
};

export const RegistationSuccessLabel: { [key: string]: string } = {
  da: "Du er nu registreret",
  en: "You are now registered",
};

export const RegistrationSuccessMessageLabel: { [key: string]: string } = {
  da: "Du kan nu logge ind",
  en: "You can now log in",
};

export const InvalidEmailOrPasswordLabel: { [key: string]: string } = {
  da: "Ugyldig email eller kodeord",
  en: "Invalid email or password",
};

export const EmailHasBeenSentWithCodeLabel: { [key: string]: string } = {
  da: "En email er blevet sendt med en kode",
  en: "An email has been sent with a code",
};
export const ANewEmailHasBeenSentWithCodeLabel: { [key: string]: string } = {
  da: "En ny email er blevet sendt med en kode. Tjek din indbakke",
  en: "A new email has been sent with a code. Check your inbox",
};

export const ClickHereToResendLabel: { [key: string]: string } = {
  da: "Klik her for at sende en ny email",
  en: "Click here to send a new email",
};

export const ResetPasswordLabel: { [key: string]: string } = {
  da: "Nulstil kodeord",
  en: "Reset password",
};

export const CheckYourEmailLabel: { [key: string]: string } = {
  da: "Tjek din email",
  en: "Check your email",
};

export const EmailHasBeenSentWithAResetLinkLabel: { [key: string]: string } = {
  da: "En email er blevet sendt med et link til at nulstille dit kodeord",
  en: "An email has been sent with a link to reset your password",
};

export const YourNewPasswordLabel: { [key: string]: string } = {
  da: "Dit nye kodeord",
  en: "Your new password",
};

export const ConfirmYourNewPasswordLabel: { [key: string]: string } = {
  da: "Bekræft dit nye kodeord",
  en: "Confirm your new password",
};

export const OldPasswordLabel: { [key: string]: string } = {
  da: "nuværende kodeord",
  en: "Old password",
};

export const UpdatePasswordLabel: { [key: string]: string } = {
  da: "Opdater kodeord",
  en: "Update password",
};

export const DidntReceiveEmailLabel: { [key: string]: string } = {
  da: "Modtog du ikke en email?",
  en: "Didn't receive an email?",
};

export const VerifyLabel: { [key: string]: string } = {
  da: "Verificer",
  en: "Verify",
};

export const EmptyInvoicesLabel: { [key: string]: string } = {
  da: "Ingen fakturaer",
  en: "No invoices",
};

export const InvoiceLabel: { [key: string]: string } = {
  da: "Faktura",
  en: "Invoice",
};

export const DownloadAsPDFLabel: { [key: string]: string } = {
  da: "Download som PDF",
  en: "Download as PDF",
};

export const DownloadInvoiceLabel: { [key: string]: string } = {
  da: "Download faktura",
  en: "Download invoice",
};

export const GoToHomeLabel: { [key: string]: string } = {
  da: "Gå til forsiden",
  en: "Go to frontpage",
};

export const BackToLoginLabel: { [key: string]: string } = {
  da: "Tilbage til login",
  en: "Back to login",
};

export const ReturnLabel: { [key: string]: string } = {
  da: "Tilbage",
  en: "Return",
};

export const GenericErrorHeaderLabel: { [key: string]: string } = {
  da: "Ups... Noget gik galt!",
  en: "Oops... Something Went Wrong!",
};

export const GenericErrorMessageLabel: { [key: string]: string } = {
  da: "Vi beklager ulejligheden. Hvis problemet fortsætter, så tøv ikke med at kontakte vores support team. Vi er her for at hjælpe dig med at løse eventuelle problemer og sikre en problemfri oplevelse. Tak for din forståelse og tålmodighed.",
  en: "We're sorry for the inconvenience. If this issue persists, please don't hesitate to reach out to our support team. We're here to help you resolve any problems and ensure a smooth experience. Thank you for your understanding and patience.",
};
