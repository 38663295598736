import "dayjs/locale/en";
import "dayjs/locale/da";
import "./expanded-body-style.scss";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BiGasPump } from "react-icons/bi";
import { FiArrowLeft } from "react-icons/fi";
import { CurrencySection } from "./CurrencySection";
import { stringHasValue } from "../../../utils/validation";
import { motion } from "framer-motion";
import {
  Divider,
  NumberInput,
  SegmentedControl,
  Space,
  Text,
} from "@mantine/core";
import { Currency } from "../../../interfaces/Import/Currency";
import { HistorySection } from "./HistorySection";
import { SalesSection } from "./SalesSection";
import { useMediaQuery } from "@mantine/hooks";
import {
  ensureFloat,
  parseTransactionDateStringToDate,
  parseTransactionDateToDatestring,
} from "../../../utils/formatter";
import {
  AcquisitionCostLabel,
  AcquisitionDateDescriptionLabel,
  AcquisitionDateLabel,
  AmountLabel,
  CurrencyInLabel,
  FeeCurrencyLabel,
  FeeLabel,
  GainLabel,
  MissingLabel,
  PickDateLabel,
} from "../../../utils/labels";
import {
  SetLocalEditedTransactionItemContext,
  GetLocalEditedTransactionItemContext,
} from "./ReportRowItem";
import {
  CurrencyContext,
  CurrencyNameContext,
  ExchangeRatesContext,
} from "../../../App";
import { getExchangeRate } from "../../../utils/exchangeRateUtil";
import { ExchangeRateValues } from "../../../interfaces/Report/ExchangeRates";
import { ExchangeRateDescription } from "./ExchangeRateDescription";
import { DatePicker } from "@mantine/dates";

interface Props {
  applicationSettings: ApplicationSettings;
  currencies: Currency[];
  editMode: boolean;
  exchange: string;
  inspectionMode?: boolean;
}
export function ExpandedDepositBody(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 968px)");
  const currencies = useContext(CurrencyContext);
  const setLocalEditedTransactionItem = useContext(
    SetLocalEditedTransactionItemContext
  );
  const localEditedTransactionItem = useContext(
    GetLocalEditedTransactionItemContext
  );
  const localEditedTransactionDate =
    localEditedTransactionItem &&
    parseTransactionDateStringToDate(
      localEditedTransactionItem?.transactionDate
    );
  const [acquisitionDate, setAcquisitionDate] = useState<Date | undefined>(
    localEditedTransactionDate
  );

  const exchangeRates = useContext(ExchangeRatesContext);

  const exchangeRate = useMemo(() => {
    return exchangeRates && acquisitionDate
      ? getExchangeRate(
          parseTransactionDateToDatestring(acquisitionDate),
          exchangeRates
        )
      : undefined;
  }, [acquisitionDate, exchangeRates]);

  const [selectedExchangeRateCurrency, setSelectedExchangeRate] = useState<
    "DKK" | "USD" | "EUR"
  >("DKK");

  const acquisitionCostInNC = localEditedTransactionItem?.currencyIn
    ?.acquisitionCostInNC
    ? ensureFloat(localEditedTransactionItem?.currencyIn?.acquisitionCostInNC)
    : 0;

  const [acquisitionCostValues, setAcquisitionCostValues] =
    useState<ExchangeRateValues>({
      DKK: acquisitionCostInNC,
      EUR:
        acquisitionCostInNC && exchangeRate
          ? acquisitionCostInNC / exchangeRate.EUR
          : 0,
      USD:
        acquisitionCostInNC && exchangeRate
          ? acquisitionCostInNC / exchangeRate.USD
          : 0,
    });

  const handleAcquisitionChange = useCallback(
    (
      value: number | undefined,
      _selectedExchangeRateCurrency: "DKK" | "USD" | "EUR"
    ) => {
      if (!value) {
        setAcquisitionCostValues({
          DKK: 0,
          USD: 0, // Converting DKK to USD
          EUR: 0, // Converting DKK to EUR
        });
        return;
      }
      if (!localEditedTransactionItem || !exchangeRate) return;
      const _newCurrencyIn = localEditedTransactionItem.currencyIn;

      const rateToDKK = exchangeRate[_selectedExchangeRateCurrency]; // How many DKK 1 unit of selected currency is

      const updatedValueInDKK =
        _selectedExchangeRateCurrency === "DKK" ? value : value * rateToDKK; // Converting to DKK

      setAcquisitionCostValues({
        DKK: updatedValueInDKK,
        USD: updatedValueInDKK / exchangeRate.USD, // Converting DKK to USD
        EUR: updatedValueInDKK / exchangeRate.EUR, // Converting DKK to EUR
      });

      if (!_newCurrencyIn) return;
      _newCurrencyIn.acquisitionCostInNC = updatedValueInDKK.toFixed(2);
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        currencyIn: _newCurrencyIn,
      });

      return;
    },
    [
      setLocalEditedTransactionItem,
      localEditedTransactionItem,
      exchangeRate,
      setAcquisitionCostValues,
    ]
  );

  const handleCurrencyInAmountChange = useCallback(
    (value: number | undefined) => {
      if (!value && value !== 0) return;
      if (!localEditedTransactionItem) return;
      const _newCurrencyIn = localEditedTransactionItem.currencyIn;
      if (!_newCurrencyIn) return;
      _newCurrencyIn.amount = value.toString().replace(",", ".");
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        currencyIn: _newCurrencyIn,
      });
    },
    [setLocalEditedTransactionItem, localEditedTransactionItem]
  );
  const handleFeeAmountChange = useCallback(
    (value: number | undefined) => {
      if (!value && value !== 0) return;
      if (!localEditedTransactionItem) return;
      const _newFee = localEditedTransactionItem.fee;
      if (!_newFee) return;
      _newFee.amount = value.toString().replace(",", ".");
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        fee: _newFee,
      });
    },
    [setLocalEditedTransactionItem, localEditedTransactionItem]
  );

  useEffect(() => {
    if (!props.editMode && selectedExchangeRateCurrency !== "DKK") {
      setSelectedExchangeRate("DKK");
    }

    if (
      localEditedTransactionItem &&
      localEditedTransactionItem.currencyIn?.acquisitionCostInNC
    ) {
      handleAcquisitionChange(
        ensureFloat(localEditedTransactionItem.currencyIn.acquisitionCostInNC),
        "DKK"
      );
    }
  }, [exchangeRate, props.editMode, selectedExchangeRateCurrency]); // these are the correct dependencies

  return (
    <div className="expanded-body-container">
      {currencies && (
        <>
          {localEditedTransactionItem?.currencyIn && (
            <motion.div
              className="transaction-in-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
            >
              <div className="transaction-left-section">
                <div className="transaction-outer-column">
                  {!props.editMode && (
                    <motion.div
                      className="currency-in-label-container"
                      initial={{ opacity: 0.6, filter: "grayscale(20%)" }}
                      animate={{ opacity: 1, filter: "grayscale(70%)" }}
                      transition={{ duration: 0.1, delay: 0.2 }}
                    >
                      <label>
                        {CurrencyInLabel[props.applicationSettings.locale]}
                      </label>
                    </motion.div>
                  )}
                  <div className="transaction-column">
                    <CurrencySection
                      label={CurrencyInLabel[props.applicationSettings.locale]}
                      applicationSettings={props.applicationSettings}
                      activeTheme={props.applicationSettings.theme}
                      currencyId={localEditedTransactionItem.currencyIn.id}
                      iconSizeRem={compact ? 1.1 : 1.5}
                      editMode={props.editMode}
                      blockToEditKey={"currencyIn"}
                    />
                  </div>
                </div>
                <div className="transaction-column">
                  <div className="transaction-in-out-row">
                    {stringHasValue(
                      localEditedTransactionItem.currencyIn?.amount
                    ) && (
                      <NumberInput
                        label={
                          <Text color={"dimmed"}>
                            <label>
                              {AmountLabel[props.applicationSettings.locale]}
                            </label>
                          </Text>
                        }
                        icon={
                          <FiArrowLeft
                            className="arrow-transaction-in"
                            size={18}
                          />
                        }
                        precision={6}
                        hideControls
                        className={"transaction-input"}
                        disabled={!props.editMode}
                        value={
                          localEditedTransactionItem?.currencyIn
                            ? parseFloat(
                                localEditedTransactionItem?.currencyIn.amount
                              )
                            : 0
                        }
                        onChange={(value) => {
                          handleCurrencyInAmountChange(value);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Divider orientation="vertical" />
              <div className="sales-section">
                <div className="outer-column-section">
                  <Space w={"xs"} />
                  <div className="column-section">
                    <NumberInput
                      icon={
                        <Text>
                          <label>{selectedExchangeRateCurrency}</label>
                        </Text>
                      }
                      description={
                        props.editMode && (
                          <ExchangeRateDescription
                            applicationSettings={props.applicationSettings}
                            dkkValue={acquisitionCostValues.DKK}
                          />
                        )
                      }
                      label={
                        <Text className="sales-section-label" color={"dimmed"}>
                          <label>
                            {
                              AcquisitionCostLabel[
                                props.applicationSettings.locale
                              ]
                            }
                          </label>
                        </Text>
                      }
                      hideControls
                      size="xs"
                      className={
                        props.editMode &&
                        localEditedTransactionItem.currencyIn
                          .acquisitionCostInNC?.length === 0
                          ? "transaction-input-missing"
                          : "transaction-input"
                      }
                      disabled={!props.editMode}
                      placeholder={
                        MissingLabel[props.applicationSettings.locale]
                      }
                      value={
                        acquisitionCostValues![selectedExchangeRateCurrency]
                      }
                      onChange={(value) => {
                        handleAcquisitionChange(
                          value,
                          selectedExchangeRateCurrency
                        );
                      }}
                      precision={2}
                    />
                    {props.editMode && exchangeRate ? (
                      <SegmentedControl
                        value={selectedExchangeRateCurrency}
                        onChange={(value) => {
                          if (["DKK", "USD", "EUR"].includes(value))
                            setSelectedExchangeRate(value as any);
                        }}
                        data={[
                          { label: "DKK", value: "DKK" },
                          { label: "USD", value: "USD" },
                          { label: "EUR", value: "EUR" },
                        ]}
                      />
                    ) : undefined}
                  </div>
                  {localEditedTransactionDate && props.editMode && (
                    <>
                      <motion.div
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.2, delay: 0.1 }}
                        className="acquisition-box-divider"
                      />
                      <motion.div
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.2, delay: 0.2 }}
                        className="acquisition-date-container"
                      >
                        <DatePicker
                          clearable={false}
                          locale={props.applicationSettings.locale}
                          inputFormat="DD-MM-YYYY"
                          size="xs"
                          placeholder={
                            PickDateLabel[props.applicationSettings.locale]
                          }
                          label={
                            <Text
                              className="sales-section-label"
                              color={"dimmed"}
                            >
                              <label>
                                {
                                  AcquisitionDateLabel[
                                    props.applicationSettings.locale
                                  ]
                                }
                              </label>
                            </Text>
                          }
                          disabled={!props.editMode}
                          value={acquisitionDate ?? localEditedTransactionDate}
                          maxDate={localEditedTransactionDate}
                          onChange={(date) => {
                            date
                              ? setAcquisitionDate(date)
                              : setAcquisitionDate(localEditedTransactionDate);
                          }}
                        />
                        <label className="acquisition-date-description-label">
                          {
                            AcquisitionDateDescriptionLabel[
                              props.applicationSettings.locale
                            ]
                          }
                        </label>
                      </motion.div>
                    </>
                  )}

                  {localEditedTransactionItem.currencyIn.showGain !==
                    undefined &&
                    localEditedTransactionItem.currencyIn.showGain &&
                    localEditedTransactionItem.currencyIn
                      .acquisitionCostInNC && (
                      <>
                        <Space w={"xs"} />
                        <div className="column-section-gain">
                          <NumberInput
                            icon={
                              <Text>
                                <label>DKK</label>
                              </Text>
                            }
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "3px",
                                }}
                              >
                                <Text
                                  className="sales-section-label"
                                  color={"dimmed"}
                                  size={"md"}
                                >
                                  <label>
                                    {
                                      GainLabel[
                                        props.applicationSettings.locale
                                      ]
                                    }
                                  </label>
                                </Text>
                              </div>
                            }
                            hideControls
                            size="xs"
                            precision={2}
                            className={"transaction-input"}
                            disabled
                            value={parseFloat(
                              localEditedTransactionItem.currencyIn
                                .acquisitionCostInNC
                            )}
                          />
                        </div>
                      </>
                    )}
                </div>
              </div>
              {!props.inspectionMode &&
                localEditedTransactionItem.currencyIn.history?.length > 0 && (
                  <>
                    <Space w={"xs"} />
                    <Divider orientation="vertical" />
                    <Space w={"xs"} />
                    <HistorySection
                      historyItems={
                        localEditedTransactionItem.currencyIn.history
                      }
                      applicationSettings={props.applicationSettings}
                      exchange={props.exchange}
                    />
                    <Divider />
                  </>
                )}
            </motion.div>
          )}
          {props.inspectionMode &&
            localEditedTransactionItem &&
            localEditedTransactionItem.currencyIn &&
            localEditedTransactionItem?.currencyIn?.history?.length > 0 && (
              <HistorySection
                historyItems={localEditedTransactionItem.currencyIn.history}
                applicationSettings={props.applicationSettings}
                exchange={props.exchange}
              />
            )}
          {!props.inspectionMode && <Divider />}

          {localEditedTransactionItem?.fee && (
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
              className="transaction-fee-container"
            >
              <div className="transaction-left-section">
                <div className="transaction-outer-column">
                  {!props.editMode && (
                    <motion.div
                      className="currency-fee-label-container"
                      initial={{ opacity: 0.6, filter: "grayscale(20%)" }}
                      animate={{ opacity: 1, filter: "grayscale(70%)" }}
                      transition={{ duration: 0.1, delay: 0.2 }}
                    >
                      <label>
                        {FeeLabel[props.applicationSettings.locale]}
                      </label>
                    </motion.div>
                  )}

                  <div className="transaction-column">
                    <CurrencySection
                      label={FeeCurrencyLabel[props.applicationSettings.locale]}
                      applicationSettings={props.applicationSettings}
                      activeTheme={props.applicationSettings.theme}
                      currencyId={localEditedTransactionItem.fee.id}
                      iconSizeRem={1.5}
                      editMode={props.editMode}
                      blockToEditKey={"fee"}
                    />
                  </div>
                </div>
                <div className="transaction-column">
                  <div className="transaction-in-out-row">
                    {stringHasValue(localEditedTransactionItem.fee?.amount) && (
                      <NumberInput
                        icon={
                          <BiGasPump
                            className="arrow-transaction-fee"
                            size={18}
                          />
                        }
                        label={
                          <Text
                            className="sales-section-label"
                            color={"dimmed"}
                          >
                            <label>
                              {AmountLabel[props.applicationSettings.locale]}
                            </label>
                          </Text>
                        }
                        precision={6}
                        hideControls
                        className={"transaction-input"}
                        disabled={!props.editMode}
                        value={
                          localEditedTransactionItem?.fee
                            ? parseFloat(localEditedTransactionItem?.fee.amount)
                            : 0
                        }
                        onChange={(value) => {
                          handleFeeAmountChange(value);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Divider orientation="vertical" />
              <SalesSection
                applicationSettings={props.applicationSettings}
                salesUsd={localEditedTransactionItem.fee.salesProceedInUsd}
                salesNc={localEditedTransactionItem.fee.salesProceedInNC}
                gainUsd={localEditedTransactionItem.fee.usdGain}
                gainNc={localEditedTransactionItem.fee.ncGain}
                lossNc={localEditedTransactionItem.fee.ncLoss}
                lossUsd={localEditedTransactionItem.fee.usdLoss}
                editMode={props.editMode}
                blockToEditKey={"fee"}
                exchangeRate={exchangeRate}
              />
              {!props.inspectionMode &&
                localEditedTransactionItem.fee.history?.length > 0 && (
                  <>
                    <Space w={"xs"} />
                    <Divider orientation="vertical" />
                    <Space w={"xs"} />
                    <HistorySection
                      historyItems={localEditedTransactionItem.fee.history}
                      applicationSettings={props.applicationSettings}
                      exchange={props.exchange}
                    />
                  </>
                )}
            </motion.div>
          )}
          {props.inspectionMode &&
            localEditedTransactionItem &&
            localEditedTransactionItem.fee &&
            localEditedTransactionItem.fee.history?.length > 0 && (
              <>
                <HistorySection
                  historyItems={localEditedTransactionItem.fee.history}
                  applicationSettings={props.applicationSettings}
                  exchange={props.exchange}
                />
              </>
            )}
        </>
      )}
    </div>
  );
}
