import "./menu.scss";
import {
  AppShell,
  Burger,
  Divider,
  Header,
  Navbar,
  Space,
} from "@mantine/core";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { RoutesComponent } from "./Routes";
import { menuItems } from "./Routes";
import { AuthenticationMenu } from "./AuthenticationMenu";
import { AuthenticationData } from "../../interfaces/Authentication/AuthenticationData";

interface Props {
  handleSwitchLocale: () => void;
  handleSwitchTheme: () => void;
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  handleLogout: () => Promise<void>;
}

export function CompactMenu(props: React.PropsWithChildren<Props>) {

  const navigate = useNavigate();

  const loggedIn = props.authenticationData?.verified;
  const [opened, setOpened] = useState(false);

  const handleButtonClick = useCallback(
    (route: string) => {
      navigate(route);
      setOpened(false);
    },
    [setOpened, navigate]
  );

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      padding={0}
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Link
            to="/"
            className="menu-label"
            onClick={() => {
              setOpened(false);
            }}
          >
            <div className="home-button-container">
              <img src="/assets/header_logo.png" alt="logo" className="logo" />
              <label>Cryptoskat</label>
            </div>
          </Link>
          <Divider />

          <Divider />
          {loggedIn ? (
            <>
              {menuItems.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <Link
                      className={"menu-label"}
                      to={item.link}
                      onClick={() => {
                        setOpened(false);
                      }}
                    >
                      <div className="icon-container">{item.icon}</div>
                      <label>
                        {item[`${props.applicationSettings.locale}Name`]}
                      </label>
                    </Link>
                    <Divider />
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}
          <Space h="lg" />
          <AuthenticationMenu
            applicationSettings={props.applicationSettings}
            handleLogout={props.handleLogout}
            handleButtonClick={handleButtonClick}
          />
        </Navbar>
      }
      header={
        <Header height={70} p="xl">
          <div className="compact-menu-header-container">
            <Burger
              opened={opened}
              onClick={() => setOpened((o: boolean) => !o)}
              size="sm"
              mr="xl"
            />
          </div>
        </Header>
      }
    >
      <RoutesComponent
        handleSwitchLocale={props.handleSwitchLocale}
        handleSwitchTheme={props.handleSwitchTheme}
        applicationSettings={props.applicationSettings}
        handleLogout={props.handleLogout}
      />
    </AppShell>
  );
}
