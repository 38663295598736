import "../components/Import/import.scss";
import { useDocumentTitle } from "@mantine/hooks";
import { ImportBody } from "../components/Import/ImportBody";
import { ImportHeader } from "../components/Import/ImportHeader";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { ImportDataLabel } from "../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}

export function ImportPage(props: React.PropsWithChildren<Props>) {
  const subtitle = ImportDataLabel[props.applicationSettings.locale];
  useDocumentTitle(`CryptoSkat | ${subtitle}`);

  return (
    <div className="page-card-container">
      <ImportHeader applicationSettings={props.applicationSettings} />
      <ImportBody
        applicationSettings={props.applicationSettings}
        handleLogout={props.handleLogout}
      />
    </div>
  );
}
