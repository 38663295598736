import { Anchor, Popover, Space } from "@mantine/core";
import { useState } from "react";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { apiSecurityIntructions } from "../../apiSecurityInstructions";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string;
  applicationSettings: ApplicationSettings;
}

export function Binance(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor
      href="https://www.binance.com/en/usercenter/settings/api-management"
      target="_blank"
    >
      <strong>Binance</strong>
    </Anchor>
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const notice: any = {
    da: (
      <>
        <label>
          <strong>Advarsel: </strong>Binance eksporterer ikke din komplette
          handelshistorik via API. Du kan derfor opleve behovet for at
          kontrollere de uploadede transaktionsdata og korrigere dem manuelt.
          For eksempel eksporterer Binance ikke data vedrørende transaktioner,
          der er gennemført via Binance's "konverterings" funktion.{" "}
          <Popover
            position="top"
            opened={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            target={
              <strong className="popover-trigger">
                API sikkerhedsanbefalinger.
              </strong>
            }
          >
            <div className="security-popover-container">
              {apiSecurityIntructions["da"]}
            </div>
          </Popover>{" "}
          {/* <br />
          Hvis du har vanskeligheder ved at importere transaktioner via API, kan
          du altid importerede dem via CSV eller manuel registrering af
          transaktioner */}
        </label>
      </>
    ),
    en: (
      <>
        <label>
          <strong>Warning: </strong>Binance does not export your complete
          trading history via API. You may therefore experience the need to
          check the uploaded transaction data and correct it manually. For
          example, Binance does not export data regarding transactions completed
          via Binance's "conversion" function.{" "}
          <Popover
            position="top"
            opened={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            target={
              <strong className="popover-trigger">
                API security instructions.
              </strong>
            }
          >
            <div className="security-popover-container">
              {apiSecurityIntructions["en"]}
            </div>
          </Popover>
          {/* <br />
          If you have any difficulties with importing transactions, you can
          import them via csv or the input form */}
        </label>
      </>
    ),
  };

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto, flyt musen til account ikonet i
        øverste højre hjørne og vælg API management.
      </label>,
      <label className="instruction-label">
        Noter et navn/en label for din API nøgle og tryk på Create.
      </label>,
      <label className="instruction-label">
        Tryk på Edit restrictions og fjern alle rettigheder, bortset fra "Can
        Read" <strong> (KUN "Can Read" kræves).</strong>
      </label>,
      <label className="instruction-label">
        Bevar IP access markeret som unrestricted.
      </label>,
      <label className="instruction-label">
        Gem dine API nøgle rettigheder, kopier din API nøglekode og dit API
        "hemmelige password" og udfyld det her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account, hover the account icon (top right
        corner) and select API Management.
      </label>,
      <label className="instruction-label">
        Enter a name/label for your API key and click on Create.
      </label>,
      <label className="instruction-label">
        Click on Edit restrictions and disable all permissions except "Can Read"{" "}
        <strong>(only "Can Read" is required).</strong>
      </label>,
      <label className="instruction-label">
        Keep the IP access unrestricted.
      </label>,
      <label className="instruction-label">
        Save your permissions, copy your Key and your Secret and paste them
        below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput
        applicationSettings={props.applicationSettings}
        platformId={props.platformId}
      />

      <div className="notice-container">
        <Space h={"md"} />
        {notice[props.applicationSettings.locale]}
      </div>
    </div>
  );
}
