import "./page-layout.scss";
import { LoginComponent } from "../components/Authentication/LoginComponent";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { useDocumentTitle } from "@mantine/hooks";
import { LoginLabel } from "../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function LoginPage(props: React.PropsWithChildren<Props>) {
  const subtitle = LoginLabel[props.applicationSettings.locale];
  useDocumentTitle(`CryptoSkat | ${subtitle}`);
  return (
    <div className="page-card-container">
      <LoginComponent applicationSettings={props.applicationSettings} />
    </div>
  );
}
