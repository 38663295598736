import "../components/Fallback/fallback.scss";
import { Button } from "@mantine/core";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { FiHome } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { GoToHomeLabel } from "../utils/labels";
import { useContext } from "react";
import { AuthenticationContext } from "../App";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function FallbackPage(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const navigate = useNavigate();
  return (
    <div className="page-card-container">
      <div className="fallback-container">
        <label className="fallback-header">404 - Not found</label>
        <Button
          className="cs-theme-button"
          radius={"xs"}
          variant="subtle"
          leftIcon={<FiHome />}
          onClick={() => navigate(authenticationData ? "/dashboard" : "/login")}
        >
          {GoToHomeLabel[props.applicationSettings.locale]}
        </Button>
      </div>
    </div>
  );
}
