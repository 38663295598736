import { Space } from "@mantine/core";
import { motion } from "framer-motion";
import { useContext, useCallback, useState, useEffect } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { useQuery } from "react-query";
import { AuthenticationContext, TriggerNotificationContext } from "../../App";
import {
  Account,
  AccountsOverviewData,
} from "../../interfaces/Accounts/AccountsList";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import AccountService from "../../service/AccountService";
import {
  AccountsAreUpToDateLabel,
  TariffPlanExpiredLabel,
} from "../../utils/labels";
import { AccountBodyControls } from "./AccountBodyControls";
import { AccountsOverview } from "./AccountsOverview";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}

export function AccountBody(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const notifactions = useContext(TriggerNotificationContext);
  const fetchAccounts = useCallback(async () => {
    if (!authenticationData) return;
    const response = await AccountService.getListOfAccounts(
      authenticationData.token
    );
    return response as AccountsOverviewData;
  }, [authenticationData, props]);

  const [accounts, setAccounts] = useState<Account[] | undefined>();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(10000);
  const [didShowNotification, setDidShowNotification] = useState(false);

  const cancelInterval = useCallback(() => {
    setRefetchInterval(false);
  }, [setRefetchInterval]);

  const { data, isLoading, refetch } = useQuery("accounts", fetchAccounts, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: refetchInterval,
    onSuccess: (data: AccountsOverviewData) => {
      if (!data) return;
      setAccounts(data.accounts);
      cancelInterval();
    },
    onError: (error: any) => {
      if (error.response.status === 401) {
        props.handleLogout();
      }
    },
  });

  const tariffInactive = data?.active_subscription === false ? true : false;

  const updateMessage =
    AccountsAreUpToDateLabel[props.applicationSettings.locale];

  useEffect(() => {
    if (!accounts && !isLoading) {
      refetch();
    }

    if (!refetchInterval) {
      if (accounts?.some((account) => account.updating_now)) {
        setRefetchInterval(10000);
      } else {
        if (!didShowNotification) {
          setDidShowNotification(true);
          notifactions(updateMessage, "success");
        }
      }
    }
  }, [
    data,
    isLoading,
    refetch,
    accounts,
    refetchInterval,
    notifactions,
    updateMessage,
    didShowNotification,
    setDidShowNotification,
  ]);

  const setAccountLoading = useCallback(
    (accountId: number) => {
      if (!accounts) return;
      const account = accounts.find((a) => a.id === accountId);
      if (!account) return;
      account.updating_now = true;
      setAccounts([...accounts]);
    },
    [accounts, setAccounts]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      className="account-body-container"
    >
      {tariffInactive && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.75, delay: 0.5 }}
        >
          <label className="information-label">
            <AiFillExclamationCircle />
            {TariffPlanExpiredLabel[props.applicationSettings.locale]}
          </label>
        </motion.div>
      )}
      <AccountBodyControls
        applicationSettings={props.applicationSettings}
        handleLogout={props.handleLogout}
        accounts={accounts}
        triggerAccountRefetch={refetch}
        setAccountLoading={setAccountLoading}
      />
      <Space h="xs" />
      <AccountsOverview
        tariffInactive={tariffInactive}
        applicationSettings={props.applicationSettings}
        handleLogout={props.handleLogout}
        accounts={accounts}
        triggerAccountRefetch={refetch}
      />
    </motion.div>
  );
}
