import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { WalletIntegrationsLabel } from "../../utils/labels";
import { PlatformLogo } from "../Import/Instructions/manual/PlatformLogo";

interface Props {
  applicationSettings: ApplicationSettings;
  wallets: string[];
}
export function WalletIntegrations(props: React.PropsWithChildren<Props>) {
  const lowercaseWallets = props.wallets.map((exchange) =>
    exchange.toLowerCase()
  );
  return (
    <div className="integrations-container">
      <label>{WalletIntegrationsLabel[props.applicationSettings.locale]}</label>
      <div className="divider"></div>

      <div className="platform-columns">
        {lowercaseWallets.map((walletName) => {
          return (
            <PlatformLogo
              key={walletName}
              activeTheme={props.applicationSettings.theme}
              platform={"wallets"}
              title={walletName}
              compact={false}
            />
          );
        })}
      </div>
    </div>
  );
}
