export const validateEmail = (email: string) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  return emailRegex.test(email);
};

export const stringHasValue = (value: string | undefined) => {
  return value && value.length > 0;
};

export const floatHasValue = (value: number | undefined) => {
  // check if float value is not zero but under or over
  return value && value !== 0;
};

export function isEqual(obj1: any | undefined, obj2: any | undefined) {
  var props1 = Object.getOwnPropertyNames(obj1);
  var props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (var i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]];
    let val2 = obj2[props1[i]];
    let isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}
function isObject(object: any) {
  return object != null && typeof object === "object";
}
