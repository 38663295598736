import { Button, Loader, Space } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import { FiX, FiCheck, FiUnlock } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext, SetAuthenticationDataContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { VerificationResponse } from "../../interfaces/Authentication/AuthenticationResponse";
import AuthService from "../../service/AuthService";
import { decodeToken } from "../../utils/decoder";
import {
  ANewEmailHasBeenSentWithCodeLabel,
  ClickHereToResendLabel,
  DidntReceiveEmailLabel,
  EmailHasBeenSentWithCodeLabel,
  VerifyLabel,
} from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}

export function MfaForm(props: React.PropsWithChildren<Props>) {
  const navigate = useNavigate();

  const setAuthenticationData = useContext(SetAuthenticationDataContext);
  const authenticationData = useContext(AuthenticationContext);
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [didResend, setDidResend] = useState(false);

  const handleSubmit = useCallback(
    async (_code: string) => {
      if (!authenticationData) return;
      try {
        if (_code.length < 6) {
          setError("Code must be 6 digits long");
          return;
        }
        setLoading(true);
        const response = (await AuthService.verify(
          authenticationData.token,
          parseInt(_code)
        )) as VerificationResponse;

        const decodedToken = decodeToken(response.token);

        setIsSuccess(true);
        setTimeout(async () => {
          setAuthenticationData({
            expiry: decodedToken.exp,
            token: response.token,
            verified: true,
            email: decodedToken.email,
            claims: decodedToken?.claims,
          });
          setError(undefined);
          navigate("/dashboard");
        }, 900);
      } catch (error: any) {
        setError(error.response.data.errors.two_factor_code);
      } finally {
        setTimeout(() => {
          setLoading(false);
          setCode("");
        }, 1000);
      }
    },
    [authenticationData, setAuthenticationData, navigate, setCode, setError]
  );

  const handleResend = useCallback(async () => {
    if (didResend) return;
    if (!authenticationData) return;
    try {
      await AuthService.resendCode(authenticationData?.token);
      setDidResend(true);
    } catch (error: any) {}
  }, [authenticationData, didResend, setDidResend]);

  const handleSetCode = useCallback(
    (value: number | undefined) => {
      if (value === undefined) return;
      const codeString = value.toString();
      setCode(codeString);
      if (codeString.length === 6) handleSubmit(codeString);
    },
    [handleSubmit, setCode]
  );

  return (
    <div className="login-form-container">
      {!didResend && (
        <label>
          {EmailHasBeenSentWithCodeLabel[props.applicationSettings.locale]}{" "}
        </label>
      )}
      <br />
      {didResend ? (
        <label>
          {ANewEmailHasBeenSentWithCodeLabel[props.applicationSettings.locale]}
        </label>
      ) : (
        <label>
          {DidntReceiveEmailLabel[props.applicationSettings.locale]}{" "}
          <strong>
            <div
              className="resend-button"
              typeof="button"
              onClick={handleResend}
            >
              {ClickHereToResendLabel[props.applicationSettings.locale]}
            </div>
          </strong>
        </label>
      )}

      <Space h={"lg"} />
      <input
        autoFocus
        className="mfa-input"
        disabled={loading}
        type="number"
        maxLength={6}
        minLength={6}
        value={code}
        onChange={(e) => handleSetCode(parseInt(e.target.value))}
        placeholder="------"
      />

      <div className="login-button-container">
        {error ? <label>{error}</label> : <br />}
        <br />
        <Button
          className="cs-theme-button"
          radius={"xs"}
          disabled={loading || isSuccess}
          onClick={() => handleSubmit(code)}
          rightIcon={
            loading ? (
              <Loader size="sm" />
            ) : isSuccess ? (
              <FiCheck size={22} color={"green"} />
            ) : error ? (
              <FiX size={22} color={"red"} />
            ) : (
              <FiUnlock size={22} />
            )
          }
        >
          {VerifyLabel[props.applicationSettings.locale]}
        </Button>
      </div>
    </div>
  );
}
