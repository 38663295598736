import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { CurrencyOut, Fee } from "../../../interfaces/Report/TransactionData";
import { useContext } from "react";
import { CancelledTransactionHeader } from "../ReportSharedComponents/CancelledTransactionHeader";
import { CurrencyContext, CurrencyNameContext } from "../../../App";

interface Props {
  applicationSettings: ApplicationSettings;
  innerId: number;
  transactionId: number;
  currencyOut: CurrencyOut | undefined;
  errorInformation: number[];
  serviceInformation: number[];
  fee: Fee;
  expanded: boolean;
  handleClicked: (id: number) => void;
  enableLinkMode: (fromTransactionId: number) => void;
  edited: boolean;
  setEditModeValue: (isEditMode: boolean) => void;
}
export function ReportCancelledTransactionContainer(
  props: React.PropsWithChildren<Props>
) {
  const currencies = useContext(CurrencyContext);
  const currencyNames = useContext(CurrencyNameContext);

  return (
    <>
      {props.fee && (
        <div
          className="report-withdrawal-container"
          onClick={() => props.handleClicked(props.transactionId)}
        >
          <CancelledTransactionHeader
            currencies={currencies}
            currencyNames={currencyNames}
            applicationSettings={props.applicationSettings}
            showExtras
            focus={props.expanded}
            fee={props.fee}
          />
        </div>
      )}
    </>
  );
}
