import { ReactElement } from "react";
import {
  AiOutlineUsergroupDelete,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { BiAddToQueue, BiCoinStack } from "react-icons/bi";
import { CgGitFork } from "react-icons/cg";
import {
  FaParachuteBox,
  FaHandHoldingMedical,
  FaHandHoldingWater,
} from "react-icons/fa";
import { FiGift, FiTag } from "react-icons/fi";
import { GiMining } from "react-icons/gi";
import {
  HiOutlineDocumentArrowUp,
  HiOutlineDocumentArrowDown,
} from "react-icons/hi2";
import { RiHandCoinFill } from "react-icons/ri";
import { TbAnchorOff, TbAnchor } from "react-icons/tb";

interface Props {
  typeID: number | string | null;
  iconSize: number;
  theme?: any;
}

export function SpecialTypeIcon(props: React.PropsWithChildren<Props>) {
  const specialTransactionTypesIcons: { [key: string]: ReactElement } = {
    "5": <FaParachuteBox size={props.iconSize} />,
    "6": (
      <FiGift
        color={props.theme ? props.theme.transactionIn : null}
        size={props.iconSize}
      />
    ),
    "21": <CgGitFork size={props.iconSize} />,
    "15": <TbAnchorOff size={props.iconSize} />, // 'Out from staking'
    "12": <HiOutlineDocumentArrowUp size={props.iconSize} />, // 'Out from lending'
    "13": <AiOutlineUsergroupDelete size={props.iconSize} />, // 'Out from liq. Pool'
    "14": <TbAnchor size={props.iconSize} />, // 'Add to staking'
    "11": <HiOutlineDocumentArrowDown size={props.iconSize} />, // 'out_from_staking'
    "17": <FiGift />, // 'out_from_liq_pool'
    "18": <FaHandHoldingMedical size={props.iconSize} />,
    "19": <BiAddToQueue size={props.iconSize} />,
    "20": <GiMining size={props.iconSize} />, // 'Mining reward'
    "16": <AiOutlineUsergroupAdd size={props.iconSize} />, // 'Add to liq. Pool'
    "28": (
      <FiGift
        color={props.theme ? props.theme.transactionOut : null}
        size={props.iconSize}
      />
    ), // 'Sent gift'
    "22": <BiCoinStack size={props.iconSize} />, // 'Add to ICO'
    "29": <BiCoinStack size={props.iconSize} />, // 'Add to ICO'
    "23": <RiHandCoinFill size={props.iconSize} />, // Out from ICO
    "24": <FaHandHoldingWater size={props.iconSize} />, // 'Mining reward'
  };

  return props.typeID === null ? (
    <FiTag />
  ) : (
    <>{specialTransactionTypesIcons[props.typeID]}</>
  );
}
