import { useCallback, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { AllTransactionTypesContext, AuthenticationContext } from "../../App";
import { AllTransactionTypes } from "../../interfaces/Import/SpecialTransactions";
import ImportService from "../../service/ImportService";
import { transactionTypeIds } from "../../utils/transactionTypeHelper";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
  setAllTransactionTypes: (allTransactionTypes: AllTransactionTypes) => void;
}
export function TransactionTypesContextContainer(
  props: React.PropsWithChildren<Props>
) {
  const authenticationData = useContext(AuthenticationContext);
  const allTransactionTypes = useContext(AllTransactionTypesContext);

  const fetchTransactionTypes = useCallback(async () => {
    if (!authenticationData) return;
    const response = await ImportService.getTransactionTypes(
      authenticationData.token
    );
    return response;
  }, [authenticationData]);

  const fetchSpecialTransactionTypes = useCallback(
    async (typeId: number) => {
      if (!authenticationData) return;
      const response = await ImportService.getSpecialTransactionTypes(
        authenticationData.token,
        typeId
      );
      return response;
    },
    [authenticationData]
  );

  const getTransactionTypes =
    useCallback(async (): Promise<AllTransactionTypes> => {
      setTimeout(() => {}, 2000);

      const transactionTypesResponse = await fetchTransactionTypes();
      const depositTransactionTypesResponse =
        await fetchSpecialTransactionTypes(transactionTypeIds["Deposit"]);
      const depositTransactionTypes = Object.keys(
        depositTransactionTypesResponse
      ).map((innerKey) => {
        return {
          specialTransactionTypeId: innerKey,
          transactionName: depositTransactionTypesResponse[innerKey],
        };
      });

      const withdrawalTransactionTypesResponse =
        await fetchSpecialTransactionTypes(transactionTypeIds["Withdrawal"]);
      const withdrawalTransactionTypes = Object.keys(
        withdrawalTransactionTypesResponse
      ).map((innerKey) => {
        return {
          specialTransactionTypeId: innerKey,
          transactionName: withdrawalTransactionTypesResponse[innerKey],
        };
      });

      const tradeTransactionTypesResponse = await fetchSpecialTransactionTypes(
        transactionTypeIds["Trade"]
      );
      const tradeTransactionTypes = Object.keys(
        tradeTransactionTypesResponse
      ).map((innerKey) => {
        return {
          specialTransactionTypeId: innerKey,
          transactionName: tradeTransactionTypesResponse[innerKey],
        };
      });

      return {
        transactionTypes: transactionTypesResponse.data,
        deposit: depositTransactionTypes,
        withdrawal: withdrawalTransactionTypes,
        trade: tradeTransactionTypes,
      };
    }, [fetchTransactionTypes, fetchSpecialTransactionTypes]);

  const getAllTransactionTypesQuery = useQuery(
    ["allTransactionTypesQuery", props.applicationSettings.locale],
    getTransactionTypes,
    {
      cacheTime: 60000,
      staleTime: 60000,
    }
  );

  useMemo(() => {
    if (
      !getAllTransactionTypesQuery.data &&
      !getAllTransactionTypesQuery.isLoading &&
      !allTransactionTypes
    ) {
      getAllTransactionTypesQuery.refetch();
    }
    if (getAllTransactionTypesQuery.data && !allTransactionTypes) {
      props.setAllTransactionTypes(getAllTransactionTypesQuery.data);
    }
  }, [getAllTransactionTypesQuery, allTransactionTypes, props]);

  return <></>;
}
