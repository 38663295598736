import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  CurrencyOut,
  CurrencyIn,
  Fee,
} from "../../../interfaces/Report/TransactionData";
import { TradeHeader } from "../ReportSharedComponents/TradeHeader";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionId: number;
  innerId: number;
  currencyOut: CurrencyOut | undefined;
  currencyIn: CurrencyIn | undefined;
  fee: Fee;
  expanded: boolean;
  handleClicked: (id: number) => void | undefined;
  enableLinkMode: (fromTransactionId: number) => void;
  errorInformation: number[];
  serviceInformation: number[];
  edited: boolean;
  setEditModeValue: (isEditMode: boolean) => void;
}
export function ReportTradeContainer(props: React.PropsWithChildren<Props>) {
  //collect all errorInformation from credit, debit and fee, split on comma and remove duplicates

  return (
    <>
      {props.currencyOut && props.currencyIn && (
        <div
          className="report-trade-container"
          onClick={() => props.handleClicked(props.transactionId)}
        >
          <TradeHeader
            currencyOut={props.currencyOut}
            currencyIn={props.currencyIn}
            applicationSettings={props.applicationSettings}
            showExtras
            focus={props.expanded}
          />
        </div>
      )}
    </>
  );
}
