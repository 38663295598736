import {
  TextInput,
  PasswordInput,
  Space,
  Button,
  Loader,
  Divider,
} from "@mantine/core";
import { AxiosError } from "axios";
import { useCallback, useContext, useState } from "react";
import { FiCheck, FiPlus, FiUploadCloud } from "react-icons/fi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  AuthenticationContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ExhangeInputField } from "../../interfaces/Import/APIInputField";
import { CSCryptoKeys } from "../../interfaces/Import/CSCryptoKeys";
import AccountService from "../../service/AccountService";
import ImportService from "../../service/ImportService";
import {
  AccountIsUpToDateLabel,
  AccountNameLabel,
  AddAccountLabel,
  AddedAccountLabel,
  APIKeyLabel,
  APIPassphraseLabel,
  APISecretLabel,
  ChangeApiKeysLabel,
  CouldNotGetExchangeAPIFieldsLabel,
} from "../../utils/labels";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  platformId: string;
  applicationSettings: ApplicationSettings;
  existingAccountName?: string;
  accountId?: number;
  closeModal?: () => void;
}

export function ExchangeApiInput(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);
  const compact = useMediaQuery("(max-width: 900px");

  const navigate = useNavigate();

  const [apiKey, setApiKey] = useState(" ");
  const [apiSecret, setApiSecret] = useState(" ");
  const [apiPassphrase, setApiPassphrase] = useState(" ");
  const [accountName, setAccountName] = useState(" ");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getExhangeAPIFields = useCallback(async () => {
    if (!authenticationData) return;
    setLoading(true);
    try {
      const response = await ImportService.getExchangeAPIFields(
        authenticationData.token,
        props.platformId
      );
      const inputFields: ExhangeInputField[] = [];
      Object.keys(response).forEach((key) => {
        inputFields.push({
          key: response[key]["key"],
          title: response[key]["title"],
        });
      });
      return inputFields;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        navigate("/login");
      } else {
        triggerNotification(
          CouldNotGetExchangeAPIFieldsLabel[props.applicationSettings.locale],
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  }, [
    authenticationData,
    navigate,
    props.platformId,
    triggerNotification,
    props.applicationSettings,
  ]);

  const getExhangeAPIFieldsQuery = useQuery("apiFields", getExhangeAPIFields, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const editExchangeAccount = useCallback(async () => {
    if (!authenticationData) return;
    if (!props.accountId) return;
    if (!props.existingAccountName) return;
    try {
      setLoading(true);
      const { encryptedApi, encryptedSecret, encryptedPassphrase } =
        await encryptValues(
          authenticationData.token,
          apiKey,
          apiSecret,
          apiPassphrase
        );

      const editResponse = await AccountService.editExchangeAccount(
        authenticationData.token,
        props.accountId.toString(),
        props.existingAccountName,
        props.platformId,
        encryptedApi,
        encryptedSecret,
        getExhangeAPIFieldsQuery.data?.[2] ? encryptedPassphrase : undefined
      );
      if (editResponse["status"] === "success") {
        setLoading(false);
        triggerNotification(
          AccountIsUpToDateLabel[props.applicationSettings.locale],
          "success"
        );
        if (props.closeModal) {
          props.closeModal();
        }
      }
    } catch (error: AxiosError | any) {
      setError(
        error["response"]["data"]["message"] ||
          "Something went wrong, please try again later"
      );
    } finally {
      setLoading(false);
    }
  }, [
    authenticationData,
    apiKey,
    apiSecret,
    apiPassphrase,
    setLoading,
    triggerNotification,
    props,
    getExhangeAPIFieldsQuery,
  ]);

  const addExchangeAccount = useCallback(async () => {
    if (!authenticationData) return;
    try {
      setLoading(true);

      const { encryptedApi, encryptedSecret, encryptedPassphrase } =
        await encryptValues(
          authenticationData.token,
          apiKey,
          apiSecret,
          apiPassphrase
        );

      await ImportService.createExchangeAccount(
        authenticationData.token,
        props.applicationSettings.locale,
        props.platformId,
        accountName,
        encryptedApi,
        encryptedSecret,
        getExhangeAPIFieldsQuery.data?.[2] ? encryptedPassphrase : undefined
      );
      triggerNotification(
        AddedAccountLabel[props.applicationSettings.locale],
        "success"
      );
      navigate("/accounts");
      setPollingStatus({ needRequest: true, reportUpdating: true });
    } catch (error: AxiosError | any) {
      setError(
        error["response"]["data"]["message"] ||
          "Something went wrong, please try again later"
      );
    } finally {
      setApiKey("");
      setApiSecret("");
      setAccountName("");
      setLoading(false);
    }
  }, [
    apiKey,
    apiSecret,
    apiPassphrase,
    accountName,
    props,
    setLoading,
    setApiKey,
    setApiSecret,
    setAccountName,
    setError,
    authenticationData,
    triggerNotification,
    navigate,
    setPollingStatus,
    getExhangeAPIFieldsQuery,
  ]);

  const exchangeInputFields: any = {
    api_key: (
      <TextInput
        size={compact ? "xs" : "sm"}
        autoComplete="off"
        className="input-field"
        id="api-key-input"
        placeholder={APIKeyLabel[props.applicationSettings.locale]}
        label={APIKeyLabel[props.applicationSettings.locale]}
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value.trim())}
        rightSection={
          <FiCheck
            className={apiKey.length > 1 ? "checkmark-valid" : "checkmark"}
          />
        }
        required
      />
    ),
    api_secret: (
      <>
        {apiSecret.length > 1 ? (
          <PasswordInput
            size={compact ? "xs" : "sm"}
            autoFocus={true}
            autoComplete="off"
            className="input-field"
            id="api-secret-input"
            label={APISecretLabel[props.applicationSettings.locale]}
            placeholder={APISecretLabel[props.applicationSettings.locale]}
            value={apiSecret}
            onChange={(event) => setApiSecret(event.currentTarget.value.trim())}
            required
          />
        ) : (
          <TextInput
            size={compact ? "xs" : "sm"}
            autoComplete="off"
            className="input-field"
            id="api-secret-input"
            label={APISecretLabel[props.applicationSettings.locale]}
            placeholder={APISecretLabel[props.applicationSettings.locale]}
            value={apiSecret}
            onChange={(event) => setApiSecret(event.currentTarget.value.trim())}
            required
          />
        )}
      </>
    ),
    api_passphrase: (
      <>
        {apiPassphrase.length > 1 ? (
          <PasswordInput
            size={compact ? "xs" : "sm"}
            autoFocus={true}
            autoComplete="off"
            className="input-field"
            id="api-secret-input"
            label={APIPassphraseLabel[props.applicationSettings.locale]}
            placeholder={APIPassphraseLabel[props.applicationSettings.locale]}
            value={apiPassphrase}
            onChange={(event) =>
              setApiPassphrase(event.currentTarget.value.trim())
            }
            required
          />
        ) : (
          <TextInput
            size={compact ? "xs" : "sm"}
            autoComplete="off"
            className="input-field"
            id="api-secret-input"
            label={APIPassphraseLabel[props.applicationSettings.locale]}
            placeholder={APIPassphraseLabel[props.applicationSettings.locale]}
            value={apiPassphrase}
            onChange={(event) =>
              setApiPassphrase(event.currentTarget.value.trim())
            }
            required
          />
        )}
      </>
    ),
  };

  return (
    <div className="input-container">
      <Divider orientation="horizontal" />
      <form autoComplete="none">
        {!props.existingAccountName && (
          <TextInput
            size={compact ? "xs" : "sm"}
            autoComplete="off"
            className="input-field"
            id="account-name-input"
            placeholder={AccountNameLabel[props.applicationSettings.locale]}
            label={AccountNameLabel[props.applicationSettings.locale]}
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            rightSection={
              <FiCheck
                className={
                  accountName.length > 1 ? "checkmark-valid" : "checkmark"
                }
              />
            }
            required
          />
        )}
        {getExhangeAPIFieldsQuery.data && (
          <>
            {getExhangeAPIFieldsQuery.data.map(
              (field) => exchangeInputFields[field.key]
            )}
          </>
        )}
      </form>
      <Space h="xs" />
      <div className="submit-api-button-container">
        {props.existingAccountName ? (
          <Button
            onClick={() => editExchangeAccount()}
            className="cs-theme-button"
            radius="xs"
            leftIcon={!loading ? <FiUploadCloud /> : <Loader />}
            uppercase
            disabled={
              loading ||
              accountName.length < 1 ||
              apiKey.length < 1 ||
              apiSecret.length < 1
            }
          >
            {ChangeApiKeysLabel[props.applicationSettings.locale]}
          </Button>
        ) : (
          <Button
            onClick={() => addExchangeAccount()}
            className="cs-theme-button"
            radius="xs"
            leftIcon={!loading ? <FiPlus /> : <Loader />}
            uppercase
            disabled={
              loading ||
              accountName.length < 1 ||
              apiKey.length < 1 ||
              apiSecret.length < 1
            }
          >
            {AddAccountLabel[props.applicationSettings.locale]}
          </Button>
        )}

        <Space w={"md"} />
        <label>{error}</label>
      </div>
    </div>
  );

  async function encryptValues(
    _token: string,
    _apiKey: string,
    _apiSecret: string,
    _apiPassphrase?: string
  ) {
    const response = (await ImportService.getEncodeJSKey(
      _token
    )) as CSCryptoKeys;
    var CryptoJS = require("crypto-js");
    var keyBytes = CryptoJS.enc.Hex.parse(response.key);
    var ivBytes = CryptoJS.enc.Hex.parse(response.ivKey);

    const encryptedApi = CryptoJS.AES.encrypt(_apiKey, keyBytes, {
      iv: ivBytes,
      padding: CryptoJS.pad.ZeroPadding,
    }).ciphertext.toString(CryptoJS.enc.Base64);

    const encryptedSecret = CryptoJS.AES.encrypt(_apiSecret, keyBytes, {
      iv: ivBytes,
      padding: CryptoJS.pad.ZeroPadding,
    }).ciphertext.toString(CryptoJS.enc.Base64);

    const encryptedPassphrase = _apiPassphrase
      ? CryptoJS.AES.encrypt(_apiPassphrase, keyBytes, {
          iv: ivBytes,
          padding: CryptoJS.pad.ZeroPadding,
        }).ciphertext.toString(CryptoJS.enc.Base64)
      : undefined;
    return { encryptedApi, encryptedSecret, encryptedPassphrase };
  }
}
