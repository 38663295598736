import { Anchor } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  href: string;
  label: string;
}
export function PlatformLink(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");

  return (
    <Anchor href={props.href} target="_blank" size={compact ? "xs" : "md"}>
      <strong>{props.label}</strong>
    </Anchor>
  );
}
