import { Badge, Tooltip } from "@mantine/core";
import { useContext, useMemo } from "react";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  FeeHistory,
  TransactionHistory,
} from "../../../interfaces/Report/TransactionData";
import { CurrencyIcon } from "../CurrencyIcon";
import { HistoryDetailsTooltip } from "./HistoryDetailsTooltip";
import { OpenModalContext } from "./ReportScrollableBody";
import { CurrencyContext, CurrencyNameContext } from "../../../App";

interface Props {
  applicationSettings: ApplicationSettings;
  history: TransactionHistory | FeeHistory;
  parsedTimestamp: number;
  exchange: string;
  showDiff?: boolean;
}
export function HistoryItem(props: React.PropsWithChildren<Props>) {
  const currencyContext = useContext(CurrencyContext);
  const openModal = useContext(OpenModalContext);

  const historicalCurrency = useMemo(() => {
    if (!currencyContext) return undefined;
    return currencyContext[props.history.currencyId];
  }, [currencyContext, props.history]);

  const historyBadge = (
    <>
      {currencyContext && historicalCurrency && (
        <Tooltip
          label={
            <HistoryDetailsTooltip
              history={props.history}
              applicationSettings={props.applicationSettings}
              parsedTimestamp={props.parsedTimestamp}
              showDiff={props.showDiff}
            />
          }
        >
          <Badge
            color={"gray"}
            radius={"xs"}
            size={"md"}
            variant={props.history.virtualDeposit ? "outline" : "light"}
            rightSection={
              <CurrencyIcon currency={historicalCurrency} size="xs" />
            }
          >
            {props.history.transactionType}
          </Badge>
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      {currencyContext && (
        <div
          className="history-item-container"
          id={`history-${props.history.innerId}`}
          onClick={(e: any) => {
            e.stopPropagation();
            if (props.history.virtualDeposit) return;
            openModal(props.history, historyBadge, props.exchange);
          }}
        >
          {historyBadge}
        </div>
      )}
    </>
  );
}
