import {
  Anchor,
  Select,
  TextInput,
  SegmentedControl,
  Popover,
} from "@mantine/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";

import {
  AuthenticationContext,
  FetchedExchangesContext,
  FetchedWalletsContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../../../../App";
import AccountService from "../../../../service/AccountService";
import { useQuery } from "react-query";
import { ExchangeAccounts } from "../../../../interfaces/Accounts/ExchangeAccounts";
import ImportService from "../../../../service/ImportService";

import { AuthenticationData } from "../../../../interfaces/Authentication/AuthenticationData";
import { PromptPopoverContent } from "./PromptPopoverContent";
import { UploadDropzoneButton } from "../UploadDropzoneButton";
import {
  getManualTransactionPlatformSelectItems,
  SelectItem,
} from "../../../../utils/selectDataUtil";
import {
  AccountNameLabel,
  CSVUploadedLabels,
  InvalidFileFormatLabels,
} from "../../../../utils/labels";
import {
  ManualTransactionPlatforms,
  ManualTransactionPlatform,
} from "../../../../interfaces/Import/Exchanges";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
  closeDrawer: () => void;
}

export function CSV(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const fetchedExchanges = useContext(FetchedExchangesContext);
  const fetchedWallets = useContext(FetchedWalletsContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);

  const downloadLink = (
    <Anchor
      href="https://api.cryptoskat.dk/assets/importfile.csv"
      target="_blank"
    >
      CSV
    </Anchor>
  );

  const [file, setFile] = useState<any>();
  const [selectedPlatformId, setSelectedPlatformId] = useState<string>();

  const [selectedExistingUserAccount, setSelectedExistingUserAccount] =
    useState<string>();

  const [userAccountsByExchange, setUserAccountsByExchange] =
    useState<ExchangeAccounts[]>();

  const [customAccountName, setCustomAccountName] = useState<
    string | undefined
  >();

  const [accountType, setAccountType] = useState<string>("fixed");

  const [customExchange, setCustomExchange] = useState<string>();
  const [showPopover, setShowPopover] = useState(false);

  const fetchUserExchanges = useCallback(async (): Promise<
    ManualTransactionPlatforms | undefined
  > => {
    if (!authenticationData) return;
    const response = await AccountService.getUsersExhangesList(
      authenticationData.token
    );
    const _manualTransactionExchanges: ManualTransactionPlatform[] = [];
    const _manualTransactionWallets: ManualTransactionPlatform[] = [];
    const _manualTransactionOtherExchanges: ManualTransactionPlatform[] = [];
    Object.keys(response["exchanges"]).forEach((key: string) => {
      const parsedKey = parseInt(key);
      _manualTransactionExchanges.push({
        id: parsedKey,
        name: response["exchanges"][parsedKey],
      });
    });
    Object.keys(response["wallets"]).forEach((key: string) => {
      const parsedKey = parseInt(key);
      _manualTransactionWallets.push({
        id: parsedKey,
        name: response["wallets"][parsedKey],
      });
    });
    Object.keys(response["otherExchange"]).forEach((key: string) => {
      const parsedKey = parseInt(key);
      _manualTransactionOtherExchanges.push({
        id: parsedKey,
        name: response["otherExchange"][parsedKey],
      });
    });
    const _manualTransactionPlatforms: ManualTransactionPlatforms = {
      exchanges: _manualTransactionExchanges,
      wallets: _manualTransactionWallets,
      otherExchanges: _manualTransactionOtherExchanges,
    };
    return _manualTransactionPlatforms;
  }, [authenticationData]);

  const userExchangesQuery = useQuery("platforms", fetchUserExchanges, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onError: (error: any) => {
      if (error.response.status === 401) {
        props.handleLogout && props.handleLogout();
      }
    },
  });

  const createSelectData = useCallback(() => {
    if (!fetchedExchanges || !fetchedWallets) {
      return [];
    }
    if (!userExchangesQuery.data) return;
    const selectData = [];
    selectData.push(
      ...getManualTransactionPlatformSelectItems(
        userExchangesQuery.data.exchanges,
        "exchanges"
      )
    );
    selectData.push(
      ...getManualTransactionPlatformSelectItems(
        userExchangesQuery.data.wallets,
        "wallets"
      )
    );
    selectData.push(
      ...getManualTransactionPlatformSelectItems(
        userExchangesQuery.data.otherExchanges,
        "other"
      )
    );
    return selectData as {
      label: string;
      value: string;
      group: string;
      key: string;
      image: any;
    }[];
  }, [fetchedExchanges, fetchedWallets, userExchangesQuery]);

  const selectData = createSelectData();

  const fetchUsersAccountByExchange = useCallback(
    async (exchangeId: number) => {
      if (!authenticationData) return;
      try {
        setUserAccountsByExchange(undefined);
        setSelectedExistingUserAccount(undefined);
        const data = await AccountService.getUserAccountsListByExchange(
          authenticationData.token,
          exchangeId
        );
        const _accounts = data["accounts"];
        const exchangeAccounts: ExchangeAccounts[] = [];
        Object.keys(_accounts).forEach((key) => {
          exchangeAccounts.push({
            id: key,
            name: _accounts[key],
          });
        });
        setUserAccountsByExchange(exchangeAccounts);
      } catch (error: any) {}
    },
    [
      authenticationData,
      setUserAccountsByExchange,
      setSelectedExistingUserAccount,
    ]
  );

  const [walletSelected, setWalletSelected] = useState(false);

  const handleAccountSelect = useCallback(
    (item?: any) => {
      if (item === null) {
        setSelectedPlatformId(undefined);
        setWalletSelected(false);
        return;
      }
      setSelectedPlatformId(item.toString());
      const selectedDataItem = selectData?.find(
        (selectDataItem) => selectDataItem["value"] === item
      );
      setWalletSelected(
        selectedDataItem !== undefined && selectedDataItem.group === "Wallets"
      );
      fetchUsersAccountByExchange(item);
    },
    [
      setSelectedPlatformId,
      fetchUsersAccountByExchange,
      setWalletSelected,
      selectData,
    ]
  );

  const makeFileUploadRequest = useCallback(
    async (
      authenticationData: AuthenticationData,
      file: any,
      selectedPlatformId: string | undefined,
      customExchange: string | undefined,
      selectedUserAccountId: string | undefined,
      customAccountName: string | undefined
    ) => {
      return await ImportService.importTransactionFromCSV(
        authenticationData.token,
        file,
        selectedPlatformId,
        customExchange,
        selectedUserAccountId,
        customAccountName
      );
    },
    []
  );

  const finishRequest = useCallback(
    (hasError?: boolean) => {
      if (hasError) {
        triggerNotification(
          InvalidFileFormatLabels[props.applicationSettings.locale],
          "error"
        );
        props.closeDrawer();
        return;
      }
      triggerNotification(
        CSVUploadedLabels[props.applicationSettings.locale],
        "info"
      );
      props.closeDrawer();
      return;
    },
    [props, triggerNotification]
  );

  const handleImportClick = useCallback(
    async (
      _selectedExistingUserAccount: string | undefined,
      _customAccountName: string | undefined,
      popupPrompt?: boolean
    ) => {
      if (!authenticationData) return;
      if (!selectedPlatformId && !customExchange) return;

      if (popupPrompt) {
        setShowPopover(true);
        return;
      }
      try {
        const response = await makeFileUploadRequest(
          authenticationData,
          file,
          selectedPlatformId,
          customExchange,
          _selectedExistingUserAccount,
          _customAccountName
        );
        setPollingStatus({ needRequest: true, reportUpdating: true });

        if (response.status === "success") {
          finishRequest();
          return;
        }
      } catch (error: any) {
        finishRequest(true);
      }
    },
    [
      authenticationData,
      selectedPlatformId,
      makeFileUploadRequest,
      file,
      setShowPopover,
      customExchange,
      finishRequest,
      setPollingStatus,
    ]
  );

  const handleSetButtonDisabled = useCallback(
    (_accountType: string) => {
      if (!file) return true;
      if (_accountType === "custom") {
        if (customExchange && customAccountName) {
          return false;
        }
        return true;
      } else {
        if (
          selectedPlatformId &&
          (selectedExistingUserAccount || customAccountName)
        ) {
          return false;
        }
        return true;
      }
    },
    [
      customAccountName,
      customExchange,
      selectedExistingUserAccount,
      selectedPlatformId,
      file,
    ]
  );

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(
    handleSetButtonDisabled(accountType)
  );

  useEffect(() => {
    if (!userExchangesQuery.isLoading && !userExchangesQuery.data) {
      userExchangesQuery.refetch();
    }
    setButtonDisabled(handleSetButtonDisabled(accountType));
  }, [
    userExchangesQuery,
    handleSetButtonDisabled,
    setButtonDisabled,
    accountType,
  ]);

  const ImportWithPickedValue = useCallback(
    (
      _selectedUserAccount: string | undefined,
      _customAccountName: string | undefined
    ) => {
      setSelectedExistingUserAccount(
        _selectedUserAccount
          ? userAccountsByExchange?.find(
              (account) => account.name === _selectedUserAccount
            )?.id
          : undefined
      );
      setCustomAccountName(_customAccountName ?? undefined);
      setShowPopover(false);
      handleImportClick(_selectedUserAccount, _customAccountName, false);
    },
    [
      setSelectedExistingUserAccount,
      userAccountsByExchange,
      setCustomAccountName,
      handleImportClick,
      setShowPopover,
    ]
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Generér en CSV-fil med semikolon separering - brug filformatet som gjort
        i dette <strong>{downloadLink}</strong> eksempel.
      </label>,
      <label className="instruction-label">
        Bemærk at det er nødvendigt at angive transaktionstidspunktet per dato,
        timer, minutter og sekunder.
      </label>,
      <label className="instruction-label">
        Felterne <strong>transaktionsdato</strong>,{" "}
        <strong>transaktionstype</strong>, <strong>USD anskaffelsessum</strong>
        og <strong>USD afståelsessum</strong> skal altid udfyldes.
      </label>,
      <label className="instruction-label">
        Du kan importere tre typer transaktioner: <strong>Deposit</strong>,{" "}
        <strong>handel</strong> og <strong>withdrawal</strong>.
      </label>,
      <label className="instruction-label">
        For deposit transaktioner skal felterne <strong>valuta til</strong> og{" "}
        <strong>antal til</strong>
        altid udfyldes.
      </label>,
      <label className="instruction-label">
        For withdrawal transaktioner skal felterne <strong>valuta fra</strong>{" "}
        og <strong>antal fra</strong>
        altid udfyldes.
      </label>,
      <label className="instruction-label">
        For handel transaktioner skal felterne <strong>valuta til</strong>,{" "}
        <strong>antal til</strong>,<strong>valuta fra</strong> og{" "}
        <strong>antal fra</strong> altid udfyldes.
      </label>,
      <label className="instruction-label">
        Hvis feltet <strong>gebyr</strong> udfyldes, skal du også huske at
        udfylde felterne
        <strong>gebyr valuta</strong> og tilhørende <strong>USD gebyr</strong>.
      </label>,
      <label className="instruction-label">
        For både deposit og withdrawal transaktioner skal feltet{" "}
        <strong>Tx ID</strong> altid udfyldes. Tx ID angiver transaktionens
        unikke transaktions ID og gør CryptoSkat beregneren i stand til at
        tracke og adskille transaktionen i forhold til senere og beslægtede
        transaktioner.
      </label>,
      <label className="instruction-label">
        Skal du angive tal i decimaler, skal du separere tallet ved anvendelse
        af et punktum (f.eks. 123.45)
      </label>,
      <label className="instruction-label">Upload CSV filen her.</label>,
    ],
    en: [
      <label className="instruction-label">
        Create a CSV file with a semicolon-separation like showed in this{" "}
        <strong>{downloadLink}</strong> example.
      </label>,
      <label className="instruction-label">
        It's necessary to enter the time of transactions with seconds.
      </label>,
      <label className="instruction-label">
        The <strong>Transaction date</strong>, <strong>Transaction type</strong>
        , <strong>USD cost</strong> are mandatory.
      </label>,
      <label className="instruction-label">
        You can import three types of transactions: <strong>deposit</strong>,{" "}
        <strong>trade</strong> and
        <strong>withdrawal</strong>.
      </label>,
      <label className="instruction-label">
        The deposit transaction should have the <strong>Currency to</strong> and{" "}
        <strong>Amount to</strong>
        fields filled.
      </label>,
      <label className="instruction-label">
        The withdrawal transaction should have the{" "}
        <strong>Currency from</strong> and
        <strong>Amount from</strong> fields filled
      </label>,
      <label className="instruction-label">
        The trade transaction should have the <strong>Currency from</strong>,{" "}
        <strong>Amount from</strong>,<strong>Currency to</strong> and{" "}
        <strong>Amount to</strong> fields filled.
      </label>,
      <label className="instruction-label">
        If the field <strong>Fee</strong> is not empty, it's necessary to fill
        in the <strong>Fee currency</strong> and <strong>USD fee</strong>{" "}
        fields.
      </label>,
      <label className="instruction-label">
        The deposit or withdrawal transaction should have the{" "}
        <strong>Tx ID</strong> field filled
      </label>,
      <label className="instruction-label">
        Separate a fractional part of the number with a point (e.g. 123.45)
      </label>,
      <label className="instruction-label">Upload the file below.</label>,
    ],
  };

  return (
    <>
      <div className="intructions-container-csv">
        <ul>
          {instructions[props.applicationSettings.locale].map(
            (instruction: JSX.Element, index: number) => {
              return (
                <li key={index}>
                  <label>
                    <strong>{index + 1}.</strong>
                  </label>
                  {instruction}
                </li>
              );
            }
          )}
        </ul>

        <SegmentedControl
          size="sm"
          value={accountType}
          data={[
            {
              value: "fixed",
              label:
                props.applicationSettings.locale === "en"
                  ? "Existing Platform"
                  : "Eksisterende Platform",
            } /* ,
            {
              value: "custom",
              label:
                props.applicationSettings.locale === "en"
                  ? "Custom Platform"
                  : "Ny Platform",
            }, */,
          ]}
          onChange={(value: string) => {
            setAccountType(value);
          }}
          disabled={walletSelected}
        />
        {selectData && (
          <div className="platform-select-container">
            <Select
              label={
                props.applicationSettings.locale === "en"
                  ? "Pick an exchange"
                  : "Vælg en exchange"
              }
              placeholder={
                props.applicationSettings.locale === "en"
                  ? "Pick one"
                  : "Vælg én"
              }
              data={selectData as any[]}
              itemComponent={SelectItem}
              className="select-import"
              onChange={handleAccountSelect}
              required={accountType === "fixed"}
              disabled={accountType === "custom"}
              clearable
            />
            {/*  <br /> */}
            {/* <TextInput
              className="input-field"
              id="accountname-input"
              placeholder={
                props.applicationSettings.locale === "en"
                  ? "Name of exchange"
                  : "Navn på exchange"
              }
              label={
                props.applicationSettings.locale === "en"
                  ? "Custom Exchange"
                  : "Ny exchange"
              }
              value={customExchange}
              onChange={(e) => setCustomExchange(e.target.value)}
              required={accountType === "custom"}
              disabled={accountType !== "custom"}
            /> */}
          </div>
        )}

        <div className="platform-select-container">
          <>
            <Select
              label={
                props.applicationSettings.locale === "en"
                  ? "Pick an existing account"
                  : "Vælg en eksisterende konto"
              }
              placeholder={
                props.applicationSettings.locale === "en"
                  ? "Pick one"
                  : "Vælg én"
              }
              value={
                userAccountsByExchange?.find(
                  (account) => account.id === undefined
                )?.name
              }
              disabled={
                accountType === "custom" ||
                !userAccountsByExchange ||
                userAccountsByExchange.length === 0
              }
              clearable
              data={
                userAccountsByExchange
                  ? userAccountsByExchange.map(
                      (userExchange: ExchangeAccounts) => {
                        return {
                          label: userExchange.name,
                          value: userExchange.id,
                        };
                      }
                    )
                  : []
              }
              className="select-import"
              onChange={(item: any) => {
                setSelectedExistingUserAccount(item);
              }}
            />
            <Popover
              opened={showPopover}
              onClose={() => setShowPopover(false)}
              position="bottom"
              withArrow
              shadow="md"
              target={
                <label>
                  {props.applicationSettings.locale === "en" ? "or" : "eller"}
                </label>
              }
            >
              {userAccountsByExchange &&
              selectedExistingUserAccount &&
              customAccountName ? (
                <PromptPopoverContent
                  applicationSettings={props.applicationSettings}
                  existingAccountName={
                    userAccountsByExchange.find(
                      (account) => account.id === selectedExistingUserAccount
                    )!.name
                  }
                  customAccountName={customAccountName}
                  setPickedValue={ImportWithPickedValue}
                />
              ) : undefined}
            </Popover>

            <TextInput
              className="input-field"
              id="accountname-input"
              placeholder={AccountNameLabel[props.applicationSettings.locale]}
              label={
                props.applicationSettings.locale === "en"
                  ? "Create new account"
                  : "Lav ny konto"
              }
              value={customAccountName}
              onChange={(e) => setCustomAccountName(e.target.value.trim())}
            />
          </>
        </div>
      </div>

      <div className="dropzone-container">
        <UploadDropzoneButton
          applicationSettings={props.applicationSettings}
          onSuccesfulDrop={(file) => {
            setFile(file);
          }}
          onClick={() =>
            handleImportClick(
              selectedExistingUserAccount,
              customAccountName,
              selectedExistingUserAccount !== undefined &&
                customAccountName !== undefined
            )
          }
          buttonDisabled={buttonDisabled}
        />
      </div>
    </>
  );
}
