import "dayjs/locale/en";
import "dayjs/locale/da";
import {
  Badge,
  Button,
  Loader,
  Modal,
  NumberInput,
  SegmentedControl,
  Select,
  Space,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";

import { motion } from "framer-motion";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { FiArrowDown, FiPlus, FiX } from "react-icons/fi";
import { useQuery } from "react-query";
import {
  AuthenticationContext,
  CurrencyContext,
  FetchedExchangesContext,
  FetchedWalletsContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../../../../App";

import { ExchangeAccounts } from "../../../../interfaces/Accounts/ExchangeAccounts";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { Currency } from "../../../../interfaces/Import/Currency";
import {
  ManualTransactionPlatform,
  ManualTransactionPlatforms,
} from "../../../../interfaces/Import/Exchanges";
import { ManualDepositPayload } from "../../../../interfaces/Import/ManualDepositPayload";
import { ManualTradePayload } from "../../../../interfaces/Import/ManualTradePayload";
import { ManualWithdrawalPayload } from "../../../../interfaces/Import/ManualWithdrawalPayload";
import { SpecialTransactionItem } from "../../../../interfaces/Import/SpecialTransactions";
import AccountService from "../../../../service/AccountService";
import ImportService from "../../../../service/ImportService";
import {
  AddAccountLabel,
  CreateNewAccountLabel,
  DateLabel,
  FeeAmountLabel,
  FeeCurrencyLabel,
  PickDateLabel,
  PickTypeLabel,
  SelectAccountOrCreateNewLabel,
  SelectCurrencyLabel,
  SelectExchangeOrWalletLabel,
  SpecialTransactionTypeLabel,
  SubmitTransactionLabel,
  TimeLabel,
} from "../../../../utils/labels";
import {
  getManualTransactionPlatformSelectItems,
  getSpecialTypeDataItems,
  SelectItem,
  SpecialTypeSelectItem,
} from "../../../../utils/selectDataUtil";
import { transactionTypeIds } from "../../../../utils/transactionTypeHelper";
import { CustomAccountAndExchangeInput } from "./CustomAccountAndExchangeInput";

import { CurrencySelect } from "./manualComponents/CurrencySelect";
import { TransactionIn } from "./manualComponents/TransactionIn";
import { TransactionOut } from "./manualComponents/TransactionOut";
import { PlatformLogo } from "./PlatformLogo";

interface Props {
  applicationSettings: ApplicationSettings;
  closeDrawer: () => void;
  handleLogout?: () => Promise<void>;
  QueryKeySuffix?: string;
}
export function Manual(props: React.PropsWithChildren<Props>) {
  const now = useMemo(() => new Date(), []);
  const yesterday = useMemo(
    () => new Date(now.getTime() - 60 * 60 * 24 * 1000),
    [now]
  );

  const authenticationData = useContext(AuthenticationContext);
  const fetchedExchanges = useContext(FetchedExchangesContext);
  const fetchedWallets = useContext(FetchedWalletsContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);
  const currencies = useContext(CurrencyContext);

  const currenciesList: Currency[] | undefined = useMemo(() => {
    if (!currencies) return [];
    return Object.values(currencies);
  }, [currencies]);

  const [loading, setLoading] = useState(false);
  const [accountValue, setAccountValue] = useState<string>();
  const [platformValue, setPlatformValue] = useState<string>();
  const [customExchangePopup, setCustomExchangePopup] = useState(false);
  const [customAccountPopup, setCustomAccountPopup] = useState(false);
  const [loadingTransactionTypes, setLoadingTransactionTypes] = useState(false);
  const [userAccountsByExchange, setUserAccountsByExchange] =
    useState<ExchangeAccounts[]>();

  const transactionTypes = ["Deposit", "Withdrawal", "Trade"];

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<string>("Deposit");

  const transactionTypeId = transactionTypeIds[selectedTransactionType];

  const [selectedSpecialTransactionType, setSelectedSpecialTransactionType] =
    useState<string>();
  const [txHash, setTxHash] = useState<string>("");
  const [fee, setFee] = useState<number | undefined>(1.0);
  const [feeId, setFeeId] = useState<string>();

  const [transactionDate, setTransactionDate] = useState<Date>();
  const [transactionTime, setTransactionTime] = useState<string>();

  const [currencyOut, setCurrencyOut] = useState<string>();
  const [currencyIn, setCurrencyIn] = useState<string>();
  const [currencyOutAmount, setCurrencyOutAmount] = useState<number>(1.0);
  const [currencyInAmount, setCurrencyInAmount] = useState<number>(1.0);

  const valueIsDigit = (_value: string) => {
    return _value.match(/^\d+$/);
  };

  const fetchUserExchanges = useCallback(async (): Promise<
    ManualTransactionPlatforms | undefined
  > => {
    if (!authenticationData) return;
    const response = await AccountService.getUsersExhangesList(
      authenticationData.token
    );
    const _manualTransactionExchanges: ManualTransactionPlatform[] = [];
    const _manualTransactionWallets: ManualTransactionPlatform[] = [];
    const _manualTransactionOtherExchanges: ManualTransactionPlatform[] = [];
    Object.keys(response["exchanges"]).forEach((key: string) => {
      const parsedKey = parseInt(key);
      _manualTransactionExchanges.push({
        id: parsedKey,
        name: response["exchanges"][parsedKey],
      });
    });
    Object.keys(response["wallets"]).forEach((key: string) => {
      const parsedKey = parseInt(key);
      _manualTransactionWallets.push({
        id: parsedKey,
        name: response["wallets"][parsedKey],
      });
    });
    Object.keys(response["otherExchange"]).forEach((key: string) => {
      const parsedKey = parseInt(key);
      _manualTransactionOtherExchanges.push({
        id: parsedKey,
        name: response["otherExchange"][parsedKey],
      });
    });
    const _manualTransactionPlatforms: ManualTransactionPlatforms = {
      exchanges: _manualTransactionExchanges,
      wallets: _manualTransactionWallets,
      otherExchanges: _manualTransactionOtherExchanges,
    };
    return _manualTransactionPlatforms;
  }, [authenticationData]);

  const userExchangesQueryKey =
    `userExchangesList${props.QueryKeySuffix}` ?? "userExchangesList";

  const { data } = useQuery(userExchangesQueryKey, fetchUserExchanges, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onError: (error: any) => {
      if (error.response.status === 401) {
        props.handleLogout && props.handleLogout();
      }
    },
  });

  const fetchUsersAccountByExchange = useCallback(
    async (exchangeId: number) => {
      if (!authenticationData) return;
      try {
        setUserAccountsByExchange(undefined);
        const data = await AccountService.getUserAccountsListByExchange(
          authenticationData.token,
          exchangeId
        );
        const _accounts = data["accounts"];
        const exchangeAccounts: ExchangeAccounts[] = [];
        Object.keys(_accounts).forEach((key) => {
          exchangeAccounts.push({
            id: key,
            name: _accounts[key],
          });
        });
        setUserAccountsByExchange(exchangeAccounts);
      } catch (error: any) {}
    },
    [authenticationData, setUserAccountsByExchange]
  );

  const createSelectData = useCallback(() => {
    if (!fetchedExchanges || !fetchedWallets) {
      return [];
    }
    if (!data) return;
    const selectData = [];
    selectData.push(
      ...getManualTransactionPlatformSelectItems(data.exchanges, "exchanges")
    );
    selectData.push(
      ...getManualTransactionPlatformSelectItems(data.wallets, "wallets")
    );
    selectData.push(
      ...getManualTransactionPlatformSelectItems(data.otherExchanges, "other")
    );
    return selectData;
  }, [fetchedExchanges, fetchedWallets, data]);

  const selectData = createSelectData();

  const handleAccountSelect = useCallback(
    async (item?: string | number | null) => {
      if (!item) return;

      // check if string is a number

      if (typeof item === "string") {
        setPlatformValue(item);

        await fetchUsersAccountByExchange(parseInt(item));
        return;
      }
    },
    [setPlatformValue, fetchUsersAccountByExchange]
  );

  const fetchTransactionTypes = useCallback(async () => {
    if (!authenticationData) return;
    const response = await ImportService.getTransactionTypes(
      authenticationData.token
    );
    return response;
  }, [authenticationData]);

  const fetchSpecialTransactionTypes = useCallback(async () => {
    if (!authenticationData) return;
    const response = await ImportService.getSpecialTransactionTypes(
      authenticationData.token,
      transactionTypeId
    );
    return response;
  }, [authenticationData, transactionTypeId]);

  const getTransactionTypes = useCallback(async () => {
    try {
      setLoadingTransactionTypes(true);
      const transactionTypesResponse = await fetchTransactionTypes();
      const specialTransactionTypesResponse =
        await fetchSpecialTransactionTypes();

      return {
        transactionTypes: transactionTypesResponse.data,
        specialTransactionTypes: Object.keys(
          specialTransactionTypesResponse
        ).map((key) => {
          return {
            specialTransactionTypeId: key,
            transactionName: specialTransactionTypesResponse[key],
          };
        }),
      } as {
        transactionTypes: string[];
        specialTransactionTypes: SpecialTransactionItem[];
      };
    } finally {
      setLoadingTransactionTypes(false);
    }
  }, [
    fetchTransactionTypes,
    fetchSpecialTransactionTypes,
    setLoadingTransactionTypes,
  ]);

  const transactionTypesQueryKey =
    `transactionTypes${props.QueryKeySuffix}` ?? "transactionTypes";

  const transactionTypesQuery = useQuery(
    [transactionTypesQueryKey, transactionTypeId],
    getTransactionTypes,
    {
      cacheTime: 5000,
      staleTime: 5000,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );

  const getRoundedTimestamp = useCallback((timestamp: number) => {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }, []);

  const setHourAndMinuteOnCapIfAbove = useCallback(
    (hourCap: number, minuteCap: number, hour: number, minute: number) => {
      const adjustedHour = hour > hourCap ? hourCap : hour;
      const adjustedMinute = (minute = minute > minuteCap ? minuteCap : minute);
      return { adjustedHour, adjustedMinute };
    },
    []
  );

  const handleTransactionTimeChange = useCallback(
    (date: Date) => {
      let hour = date.getHours();
      let minute = date.getMinutes();
      const hourCap = yesterday.getHours();
      const minuteCap = yesterday.getMinutes();
      if (
        getRoundedTimestamp(yesterday.getTime()) ===
        getRoundedTimestamp(transactionDate!.getTime())
      ) {
        const { adjustedHour, adjustedMinute } = setHourAndMinuteOnCapIfAbove(
          hourCap,
          minuteCap,
          hour,
          minute
        );
        hour = adjustedHour;
        minute = adjustedMinute;
      }
      const hourString = hour < 10 ? "0" + hour : hour;
      const minuteString = minute < 10 ? "0" + minute : minute;
      setTransactionTime(`${hourString}:${minuteString}:00`);
    },
    [
      setTransactionTime,
      transactionDate,
      getRoundedTimestamp,
      setHourAndMinuteOnCapIfAbove,
    ]
  );

  const formatTransactionDate = useCallback(() => {
    const day =
      transactionDate!.getDate() < 10
        ? "0" + transactionDate!.getDate()
        : transactionDate!.getDate();
    const month =
      transactionDate!.getMonth() + 1 < 10
        ? "0" + (transactionDate!.getMonth() + 1)
        : transactionDate!.getMonth() + 1;
    return `${day}.${month}.${transactionDate!.getFullYear()} ${
      transactionTime ?? "00:00:00"
    }`;
  }, [transactionDate, transactionTime]);

  const handleSubmit = useCallback(async () => {
    if (!authenticationData) return;

    const account_id = userAccountsByExchange?.find(
      (account) => account.name === accountValue
    )?.id;

    const custom_account = account_id ? undefined : accountValue;
    let response;
    try {
      setLoading(true);
      if (selectedTransactionType === "Deposit") {
        const transactionPayload: ManualDepositPayload = {
          currency_in_id: currencyIn!,
          amount_in: currencyInAmount,
          transaction_date: formatTransactionDate(),
          transaction_type_id: transactionTypeIds[selectedTransactionType],
          exchange_id: platformValue,
          custom_account: custom_account,
          account_id: account_id,
          crypto_tx_id: txHash.length > 0 ? txHash : undefined,
          fee_currency_id: feeId,
          fee: feeId ? fee : undefined,
          special_transaction_type_id: selectedSpecialTransactionType,
        };
        response = await ImportService.createManualTransaction(
          authenticationData.token,
          transactionPayload
        );
      }
      if (selectedTransactionType === "Withdrawal") {
        const transactionPayload: ManualWithdrawalPayload = {
          currency_out_id: currencyOut!,
          amount_out: currencyOutAmount,
          transaction_date: formatTransactionDate(),
          transaction_type_id: transactionTypeIds[selectedTransactionType],
          exchange_id: platformValue,
          custom_account: custom_account,
          account_id: account_id,
          crypto_tx_id: txHash!,
          fee_currency_id: feeId,
          fee: feeId ? fee : undefined,
          special_transaction_type_id: selectedSpecialTransactionType,
        };
        response = await ImportService.createManualTransaction(
          authenticationData.token,
          transactionPayload
        );
      }
      if (selectedTransactionType === "Trade") {
        const transactionPayload: ManualTradePayload = {
          currency_in_id: currencyIn!,
          amount_in: currencyInAmount,
          currency_out_id: currencyOut!,
          amount_out: currencyOutAmount,
          transaction_date: formatTransactionDate(),
          transaction_type_id: transactionTypeIds[selectedTransactionType],
          exchange_id: platformValue,
          custom_account: custom_account,
          account_id: account_id,
          crypto_tx_id: txHash!,
          fee_currency_id: feeId,
          fee: feeId ? fee : undefined,
          special_transaction_type_id: selectedSpecialTransactionType,
        };
        response = await ImportService.createManualTransaction(
          authenticationData.token,
          transactionPayload
        );
      }

      triggerNotification(
        response["message"],
        response["success"] ? "success" : "error"
      );
      setPollingStatus({ needRequest: true, reportUpdating: true });
    } catch (error: any) {
      const errors = error.response.data.errors;
      const errorDetails = Object.keys(errors).map((key) => error.response.data.errors[key])
      const errorMessages = [error.response.data.message, ...errorDetails]
      triggerNotification(errorMessages, "error");
    } finally {
      setLoading(false);
      props.closeDrawer();
    }
  }, [
    authenticationData,
    selectedTransactionType,
    currencyOut,
    currencyOutAmount,
    currencyIn,
    currencyInAmount,
    formatTransactionDate,
    accountValue,
    userAccountsByExchange,
    fee,
    feeId,
    props,
    triggerNotification,
    txHash,
    setLoading,
    selectedSpecialTransactionType,
    setPollingStatus,
    platformValue,
  ]);

  const buttonDisabled = useMemo(() => {
    if (selectedTransactionType === "Deposit") {
      if (currencyIn === undefined) return true;
      if (currencyInAmount === undefined) return true;
      if (platformValue === "" || !platformValue) return true;
      if (transactionDate === undefined) return true;
      if (accountValue === undefined) return true;
      if (txHash.length === 0) return true;
      return false;
    }
    if (selectedTransactionType === "Withdrawal") {
      if (currencyOut === undefined) return true;
      if (currencyOutAmount === undefined) return true;
      if (platformValue === "" || !platformValue) return true;
      if (accountValue === undefined) return true;
      if (transactionDate === undefined) return true;
      if (txHash.length === 0) return true;
      return false;
    }
    if (selectedTransactionType === "Trade") {
      if (fee === undefined) return true;
      if (feeId === undefined) return true;
      if (currencyOut === undefined) return true;
      if (currencyOutAmount === undefined) return true;
      if (currencyIn === undefined) return true;
      if (currencyInAmount === undefined) return true;
      if (platformValue === "" || !platformValue) return true;
      if (accountValue === undefined) return true;
      if (transactionDate === undefined) return true;
      return false;
    }
    return true;
  }, [
    selectedTransactionType,
    fee,
    feeId,
    currencyIn,
    currencyInAmount,
    transactionDate,
    accountValue,
    currencyOut,
    currencyOutAmount,
    txHash,
    platformValue,
  ]);

  const customBadgeColor =
    props.applicationSettings.theme === "dark" ? "gray" : "dark";

  const tryGetPlatformLogo = useCallback(
    (_value: string) => {
      if (_value === "") return undefined;
      let platform = data?.exchanges.find((e) => e.id === parseInt(_value));
      if (platform === undefined)
        platform = data?.wallets.find((e) => e.id === parseInt(_value));
      if (platform === undefined)
        data?.otherExchanges.find((e) => e.id === parseInt(_value));
      if (platform === undefined)
        return (
          <PlatformLogo
            activeTheme={props.applicationSettings.theme}
            compact={false}
            platform={"other"}
            title={"otherexchanges"}
          />
        );
      const platformName = platform.name.toLowerCase().split(" ").join("");
      const platformType =
        fetchedWallets?.data.find((wallet) => wallet.slug === platformName) ===
        undefined
          ? "exchanges"
          : "wallets";

      return (
        <PlatformLogo
          activeTheme={props.applicationSettings.theme}
          compact={false}
          platform={platformType}
          title={platformName}
        />
      );
    },
    [props.applicationSettings, fetchedWallets, data]
  );

  const getSpecialTransactionDataItems = useCallback(() => {
    if (!transactionTypesQuery.data) return;
    return getSpecialTypeDataItems(
      transactionTypesQuery.data.specialTransactionTypes
    );
  }, [transactionTypesQuery.data]);

  const specialTransactionsSelectData: any = getSpecialTransactionDataItems();

  return (
    <div className="manual-transaction-container">
      <div className="manual-transaction-select-container">
        {transactionTypes ? (
          <SegmentedControl
            size="lg"
            data={transactionTypes}
            onChange={(e) => {
              setLoadingTransactionTypes(true);
              setSelectedTransactionType(e === null ? "Deposit" : e);
              setTimeout(() => {
                setLoadingTransactionTypes(false); // artificial loading to render special transaction types again
              }, 250);
            }}
            defaultValue={"Deposit"}
          />
        ) : undefined}
      </div>
      <div className="manual-transaction-select-container">
        {transactionTypesQuery.data?.specialTransactionTypes &&
        !loadingTransactionTypes ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {selectedTransactionType !== "Trade" && (
              <Select
                label={
                  SpecialTransactionTypeLabel[props.applicationSettings.locale]
                }
                placeholder={PickTypeLabel[props.applicationSettings.locale]}
                data={specialTransactionsSelectData}
                disabled={
                  transactionTypesQuery.data?.specialTransactionTypes.length ===
                  0
                }
                onChange={(e) =>
                  setSelectedSpecialTransactionType(e === null ? undefined : e)
                }
                itemComponent={SpecialTypeSelectItem}
                clearable
              />
            )}
          </motion.div>
        ) : undefined}
        {selectedTransactionType !== "Trade" && <Space w={"xs"} />}
        <TextInput
          label="Tx hash"
          required={selectedTransactionType !== "Trade"}
          placeholder="0xP4573Y0ur7r4n54c710nH45hH3r31fY0uW0uldB350K1ndk7hxbb"
          onChange={(e) => setTxHash(e.target.value)}
          value={txHash}
          style={{ width: "80%" }}
        />
      </div>
      <div className="manual-transaction-select-container">
        <DatePicker
          inputFormat="DD-MM-YYYY"
          locale={props.applicationSettings.locale}
          placeholder={PickDateLabel[props.applicationSettings.locale]}
          label={DateLabel[props.applicationSettings.locale]}
          maxDate={now}
          onChange={(e) => {
            setTransactionDate(e ?? undefined);
            setTransactionTime(undefined);
          }}
          required
        />
        <Space w={"xs"} />
        <TimeInput
          disabled={transactionDate === undefined}
          label={TimeLabel[props.applicationSettings.locale]}
          format="24"
          onChange={handleTransactionTimeChange}
          value={transactionDate}
          required
        />
      </div>

      <div className="manual-transaction-select-container">
        {
          // EXHCHANGE SELECT
        }
        {platformValue ? (
          <>
            {valueIsDigit(platformValue) ? (
              <div
                className="platform-logo-container"
                onClick={() => {
                  setPlatformValue(undefined);
                }}
              >
                {tryGetPlatformLogo(platformValue)}
                <Space w={"xs"} />
                <FiX size={18} />
              </div>
            ) : (
              <Badge
                className="selected-account-badge"
                size="xl"
                radius={"xs"}
                variant="outline"
                color={customBadgeColor}
                rightSection={
                  <div
                    className="selected-account-badge-button"
                    onClick={() => {
                      setPlatformValue(undefined);
                    }}
                  >
                    <FiX size={18} />
                  </div>
                }
              >
                {platformValue}
              </Badge>
            )}
          </>
        ) : (
          <>
            {selectData && (
              <Select
                className="select-import"
                label={
                  SelectExchangeOrWalletLabel[props.applicationSettings.locale]
                }
                placeholder={
                  SelectExchangeOrWalletLabel[props.applicationSettings.locale]
                }
                data={selectData}
                itemComponent={SelectItem}
                onChange={handleAccountSelect}
                nothingFound="Nothing found"
                required
                clearable
                searchable
              />
            )}
          </>
        )}
      </div>
      <div className="manual-transaction-select-container">
        {
          // ACCOUNT SELECT
        }
        {accountValue ? (
          <Badge
            className="selected-account-badge"
            size="xl"
            radius={"xs"}
            variant="outline"
            color={customBadgeColor}
            rightSection={
              <div
                className="selected-account-badge-button"
                onClick={() => {
                  setAccountValue(undefined);
                }}
              >
                <FiX size={18} />
              </div>
            }
          >
            {accountValue}
          </Badge>
        ) : (
          <Select
            disabled={platformValue === ""}
            label={
              SelectAccountOrCreateNewLabel[props.applicationSettings.locale]
            }
            placeholder={
              SelectAccountOrCreateNewLabel[props.applicationSettings.locale]
            }
            value={accountValue}
            nothingFound="Nothing found"
            data={
              userAccountsByExchange
                ? userAccountsByExchange.map(
                    (userExchange: ExchangeAccounts) => {
                      return {
                        label: userExchange.name,
                        value: userExchange.id,
                      };
                    }
                  )
                : []
            }
            className="select-import"
            onChange={(item: any) => {
              setAccountValue(
                userAccountsByExchange?.find((account) => account.id === item)
                  ?.name ?? ""
              );
            }}
            style={{ width: "80%" }}
            searchable
            clearable
          />
        )}

        <Space w={"xs"} />
        <Tooltip
          style={{
            alignSelf: "flex-end",
          }}
          label={CreateNewAccountLabel[props.applicationSettings.locale]}
        >
          <Button
            radius={"xs"}
            className="cs-theme-button"
            onClick={() => {
              setCustomAccountPopup(true);
            }}
          >
            <FiPlus />
          </Button>
        </Tooltip>
      </div>

      <div className="manual-transaction-select-container">
        <CurrencySelect
          required={selectedTransactionType === "Trade"}
          label={FeeCurrencyLabel[props.applicationSettings.locale]}
          cleareable
          searchable
          placeholder={SelectCurrencyLabel[props.applicationSettings.locale]}
          onChange={setFeeId}
        />
        <Space w={"xs"} />
        <NumberInput
          value={fee}
          onChange={(value: number) => setFee(value)}
          label={FeeAmountLabel[props.applicationSettings.locale]}
          min={0}
          defaultValue={1.0}
          precision={6}
          step={0.05}
          disabled={feeId === undefined}
        />
      </div>
      {selectedTransactionType === "Deposit" ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="manual-transaction-select-container"
        >
          <TransactionIn
            handleAmountChange={(value: number) =>
              setCurrencyInAmount(parseFloat(value.toFixed(6)))
            }
            applicationSettings={props.applicationSettings}
            currencies={currenciesList}
            setTransactionInCurrencyId={(c: string) => {
              setCurrencyIn(c);
            }}
            amountDisabled={currencyIn === undefined}
            value={currencyInAmount}
          />
        </motion.div>
      ) : undefined}
      {selectedTransactionType === "Withdrawal" ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="manual-transaction-select-container"
        >
          <TransactionOut
            handleAmountChange={(value: number) =>
              setCurrencyOutAmount(parseFloat(value.toFixed(6)))
            }
            applicationSettings={props.applicationSettings}
            currencies={currenciesList}
            setTransactionOutCurrencyId={(c: any) => setCurrencyOut(c)}
            amountDisabled={currencyOut === undefined}
            value={currencyOutAmount}
          />
        </motion.div>
      ) : undefined}
      {selectedTransactionType === "Trade" ? (
        <>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            className="manual-transaction-select-container"
          >
            <TransactionOut
              handleAmountChange={(value: number) =>
                setCurrencyOutAmount(parseFloat(value.toFixed(6)))
              }
              applicationSettings={props.applicationSettings}
              currencies={currenciesList}
              setTransactionOutCurrencyId={(c: any) => setCurrencyOut(c)}
              blacklistedCurrencyId={currencyIn}
              amountDisabled={currencyOut === undefined}
              value={currencyOutAmount}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, delay: 0.1 }}
            className="arrow-container"
          >
            <FiArrowDown size={24} />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, delay: 0.15 }}
            className="manual-transaction-select-container"
          >
            <TransactionIn
              handleAmountChange={(value: number) =>
                setCurrencyInAmount(parseFloat(value.toFixed(6)))
              }
              value={currencyInAmount}
              applicationSettings={props.applicationSettings}
              currencies={currenciesList}
              setTransactionInCurrencyId={(c: any) => setCurrencyIn(c)}
              amountDisabled={currencyIn === undefined}
              blacklistedCurrencyId={currencyOut}
            />
          </motion.div>
        </>
      ) : undefined}
      <div className="manual-transaction-submit-button-container">
        <Button
          onClick={() => handleSubmit()}
          className="cs-theme-button"
          radius="xs"
          leftIcon={!loading ? <FiPlus /> : <Loader />}
          uppercase
          disabled={loading || buttonDisabled}
        >
          {SubmitTransactionLabel[props.applicationSettings.locale]}
        </Button>
      </div>
      <Modal
        onClose={() => {
          setCustomAccountPopup(false);
          setCustomExchangePopup(false);
        }}
        opened={customAccountPopup || customExchangePopup}
        centered
        title={AddAccountLabel[props.applicationSettings.locale]}
      >
        <CustomAccountAndExchangeInput
          applicationSettings={props.applicationSettings}
          isExchange={customExchangePopup}
          onSubmit={(value: string) => {
            if (customExchangePopup) {
              setPlatformValue(value);
            } else {
              setAccountValue(value);
            }
            setCustomAccountPopup(false);
            setCustomExchangePopup(false);
          }}
        />
      </Modal>
    </div>
  );
}
