import { Button, Space } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useCallback, useState } from "react";
import { FiDownloadCloud, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../App";
import { Account } from "../../interfaces/Accounts/AccountsList";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import AccountService from "../../service/AccountService";
import {
  AddAccountLabel,
  CollectDataFromAllAccountsLabel,
} from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
  accounts: Account[] | undefined;
  triggerAccountRefetch: () => void;
  setAccountLoading: (accountId: number) => void;
}
export function AccountBodyControls(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const compact = useMediaQuery("(max-width: 1280px)");

  const navigate = useNavigate();

  const availableApiAccounts = props.accounts?.filter(
    (account) => account.updating_with_api
  );

  const [loading, setLoading] = useState(
    availableApiAccounts
      ? availableApiAccounts.filter((account) => account.updating_now).length >
          0
      : false
  );

  const handleBulkDataCollecting = useCallback(async () => {
    if (!authenticationData) return;
    if (availableApiAccounts?.length === 0) {
      return;
    }
    setLoading(true);
    try {
      availableApiAccounts?.forEach(async (account) => {
        await AccountService.requestApiGetAccountTransactions(
          authenticationData.token,
          account.id.toString()
        );
        props.setAccountLoading(account.id);
      });
    } catch (e) {
      console.log(e);
    } finally {
      props.triggerAccountRefetch();
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }, [authenticationData, availableApiAccounts, props, setLoading]);

  return (
    <div className="account-body-controls-container">
      <Button
        onClick={() => {
          navigate("/import");
        }}
        className="cs-theme-button"
        radius="xs"
        leftIcon={<FiPlus />}
        size={compact ? "xs" : "md"}
        uppercase
      >
        {AddAccountLabel[props.applicationSettings.locale]}
      </Button>
      <Space w="xs" />
      <Button
        onClick={handleBulkDataCollecting}
        className="cs-theme-button"
        radius="xs"
        leftIcon={<FiDownloadCloud />}
        size={compact ? "xs" : "md"}
        uppercase
        disabled={loading || availableApiAccounts?.length === 0}
        loading={loading}
      >
        {CollectDataFromAllAccountsLabel[props.applicationSettings.locale]}
      </Button>
    </div>
  );
}
