import { Space } from "@mantine/core";
import { useDocumentTitle, useForceUpdate } from "@mantine/hooks";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import {
  AuthenticationContext,
  FetchedExchangesContext,
  FetchedWalletsContext,
  PollContext,
  SetPollingStatusContext,
  TriggerNotificationContext,
} from "../App";
import "../components/Report/report.scss";
import { ReportBody } from "../components/Report/ReportBody";
import { ReportHeader } from "../components/Report/ReportHeader";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import {
  LinkingCriteria,
  LinkingRuleSet,
  TransactionTypeLinkingRuleSet,
} from "../interfaces/Report/LinkingRuleSet";
import {
  LocalTransactionBodyUpdates,
  LocalTransactionDeletions,
  LocalTransactionLinkUpdates,
  LocalTransactionTagUpdates,
} from "../interfaces/Report/LocalTransactionUpdates";
import { LossGain } from "../interfaces/Report/LossGainStats";
import {
  PostReportUpdateError,
  PostReportUpdateErrors,
} from "../interfaces/Report/PostReportUpdateError";
import { TransactionItem } from "../interfaces/Report/TransactionData";
import {
  TransactionErrors,
  ServiceCodes,
} from "../interfaces/Report/TransactionErrors";
import { TransactionFilterParameters } from "../interfaces/Report/TransactionFilterParameters";
import {
  FilterCurrency,
  FilterExchange,
  FilterTransactionType,
  TransactionFilters,
} from "../interfaces/Report/TransactionFilters";
import { TransactionListResponse } from "../interfaces/Report/TransactionListResponse";
import TransactionService from "../service/TransactionService";
import {
  CalculatingTransactionsLabel,
  TagHasBeenAddedLabel,
  TransactionsLabel,
} from "../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}

export function ReportPage(props: React.PropsWithChildren<Props>) {
  const subtitle = TransactionsLabel[props.applicationSettings.locale];
  const idleTitle = `CryptoSkat | ${subtitle}`;
  const [title, setTitle] = useState(idleTitle);
  useDocumentTitle(title);

  const authenticationData = useContext(AuthenticationContext);
  const triggerNotification = useContext(TriggerNotificationContext);
  const pollStatus = useContext(PollContext);
  const setPollStatus = useContext(SetPollingStatusContext);
  const forceUpdate = useForceUpdate();

  const fetchedWallets = useContext(FetchedWalletsContext);
  const fetchedExchanges = useContext(FetchedExchangesContext);

  const exchangesWithLogos = fetchedExchanges?.data.map((e) => e.slug);
  const walletsWithLogos = fetchedWallets?.data.map((w) => w.slug);

  const originalTransactionsStorgeKey = "originalTransactions";
  const transactionFiltersStorageKey = "transactionFilterParameters";
  const transactionBodyUpdatesStorageKey = "transactionBodyUpdates";
  const pendingTransactionBodyUpdatesStorageKey =
    "pendingTransactionBodyUpdates";
  const transactionTagUpdatesStorageKey = "transactionTagUpdates";
  const transactionLinkUpdatesStorageKey = "transactionLinkUpdates";
  const transactionDeletionsStorageKey = "transactionDeletions";
  const unlinkedPairingsStorageKey = "unlinkedPairings";
  const reportUpdateErrorsStorageKey = "reportUpdateErrors";

  const tryGetTransactionFilterParameters = useCallback(() => {
    const _transactionFilterParameters = localStorage.getItem(
      transactionFiltersStorageKey
    );
    if (_transactionFilterParameters) {
      const parsedTransactionFilterParameters = JSON.parse(
        _transactionFilterParameters
      );

      return parsedTransactionFilterParameters as TransactionFilterParameters;
    }
    const initializedTransactionFilterParameters: TransactionFilterParameters =
      {
        page: "1",
      };
    localStorage.setItem(
      transactionFiltersStorageKey,
      JSON.stringify(initializedTransactionFilterParameters)
    );
    return initializedTransactionFilterParameters;
  }, [transactionFiltersStorageKey]);

  const [transactionFilterParameters, setTransactionFilterParameters] =
    useState<TransactionFilterParameters>(() =>
      tryGetTransactionFilterParameters()
    );

  const tryGetPendingTransactionBodyUpdates = useCallback(() => {
    if (pollStatus.reportUpdating && pollStatus.reportUpdating) {
      return {};
    }
    const _pendingTransactionBodyUpdates = localStorage.getItem(
      pendingTransactionBodyUpdatesStorageKey
    );
    if (_pendingTransactionBodyUpdates !== null) {
      const parsedTransactionUpdates = JSON.parse(
        _pendingTransactionBodyUpdates
      );
      return parsedTransactionUpdates as LocalTransactionBodyUpdates;
    }
    localStorage.setItem(
      pendingTransactionBodyUpdatesStorageKey,
      JSON.stringify({})
    );
    return {};
  }, [pendingTransactionBodyUpdatesStorageKey, pollStatus]);

  const tryGetTransactionBodyUpdates = useCallback(() => {
    if (pollStatus.reportUpdating && pollStatus.reportUpdating) {
      return {};
    }
    const _transactionBodyUpdates = localStorage.getItem(
      transactionBodyUpdatesStorageKey
    );
    if (_transactionBodyUpdates !== null) {
      const parsedTransactionUpdates = JSON.parse(_transactionBodyUpdates);
      return parsedTransactionUpdates as LocalTransactionBodyUpdates;
    }
    localStorage.setItem(transactionBodyUpdatesStorageKey, JSON.stringify({}));
    return {};
  }, [transactionBodyUpdatesStorageKey, pollStatus]);

  const tryGetTransactionTagUpdates = useCallback(() => {
    if (pollStatus.reportUpdating && pollStatus.reportUpdating) {
      return {};
    }
    const transactionTagUpdates = localStorage.getItem(
      transactionTagUpdatesStorageKey
    );
    if (transactionTagUpdates) {
      const parsedTransactionUpdates = JSON.parse(transactionTagUpdates);
      return parsedTransactionUpdates as LocalTransactionTagUpdates;
    }
    localStorage.setItem(transactionTagUpdatesStorageKey, JSON.stringify({}));
    return {};
  }, [transactionTagUpdatesStorageKey, pollStatus]);

  const tryGetTransactionLinkUpdates = useCallback(() => {
    if (pollStatus.reportUpdating && pollStatus.reportUpdating) {
      return {};
    }
    const transactionLinkUpdates = localStorage.getItem(
      transactionLinkUpdatesStorageKey
    );
    if (transactionLinkUpdates) {
      const parsedTransactionUpdates = JSON.parse(transactionLinkUpdates);
      return parsedTransactionUpdates as LocalTransactionLinkUpdates;
    }
    localStorage.setItem(transactionLinkUpdatesStorageKey, JSON.stringify({}));
    return {};
  }, [transactionLinkUpdatesStorageKey, pollStatus]);

  const tryGetTransactionDeletions = useCallback(() => {
    if (pollStatus.reportUpdating && pollStatus.reportUpdating) {
      return {} as LocalTransactionDeletions;
    }
    const transactionDeletions = localStorage.getItem(
      transactionDeletionsStorageKey
    );
    if (transactionDeletions) {
      const parsedTransactionUpdates = JSON.parse(transactionDeletions);
      return parsedTransactionUpdates as LocalTransactionDeletions;
    }
    localStorage.setItem(transactionDeletionsStorageKey, JSON.stringify({}));
    return {} as LocalTransactionDeletions;
  }, [transactionDeletionsStorageKey, pollStatus]);

  const tryGetUnlinkedPairs = useCallback(() => {
    const _emptyPairings = [] as string[];
    if (pollStatus.reportUpdating && pollStatus.reportUpdating) {
      return _emptyPairings;
    }
    const unlinkedPairs = localStorage.getItem(unlinkedPairingsStorageKey);
    if (unlinkedPairs) {
      const parsedTransactionUpdates = JSON.parse(unlinkedPairs);
      return parsedTransactionUpdates as string[];
    }
    localStorage.setItem(
      unlinkedPairingsStorageKey,
      JSON.stringify(_emptyPairings)
    );
    return _emptyPairings;
  }, [unlinkedPairingsStorageKey, pollStatus]);

  const tryGetUpdateErrors = useCallback(() => {
    const updateErrors = localStorage.getItem(reportUpdateErrorsStorageKey);
    if (updateErrors) {
      const parsedUpdateErrors = JSON.parse(updateErrors);
      return parsedUpdateErrors as PostReportUpdateErrors;
    }
    localStorage.setItem(reportUpdateErrorsStorageKey, JSON.stringify({}));
    return { errors: [] };
  }, [reportUpdateErrorsStorageKey]);

  const [transactionBodyUpdates, setTransactionBodyUpdates] =
    useState<LocalTransactionBodyUpdates>(() => tryGetTransactionBodyUpdates());

  const [pendingTransactionBodyUpdates, setPendingTransactionBodyUpdates] =
    useState<LocalTransactionBodyUpdates>(() =>
      tryGetPendingTransactionBodyUpdates()
    );

  const [transactionTagUpdates, setTransactionTagUpdates] =
    useState<LocalTransactionTagUpdates>(() => tryGetTransactionTagUpdates());

  const [transactionLinkUpdates, setTransactionLinkUpdates] =
    useState<LocalTransactionLinkUpdates>(() => tryGetTransactionLinkUpdates());

  const [unlinkedPairings, setUnlinkedPairings] = useState<string[]>(() =>
    tryGetUnlinkedPairs()
  );
  const [transactionDeletions, setTransactionDeletions] =
    useState<LocalTransactionDeletions>(() => tryGetTransactionDeletions());

  const [reportErrors, setReportErrors] = useState<PostReportUpdateErrors>(() =>
    tryGetUpdateErrors()
  );

  const handleSetReportUpdateErrors = useCallback(
    (updateError: PostReportUpdateError | "delete") => {
      if (updateError === "delete") {
        localStorage.setItem(reportUpdateErrorsStorageKey, JSON.stringify({}));
        setReportErrors({ errors: [] });
        return;
      }
      const reportErrors = tryGetUpdateErrors();
      if (!reportErrors.errors) reportErrors.errors = [];
      reportErrors.errors.push(updateError);
      localStorage.setItem(
        reportUpdateErrorsStorageKey,
        JSON.stringify(reportErrors)
      );
      setReportErrors(reportErrors);
    },
    [tryGetUpdateErrors]
  );

  const handleSetNewTransactionFilterParameters = useCallback(
    (newTransactionFilterParameters: TransactionFilterParameters) => {
      newTransactionFilterParameters.page = "1";
      setTransactionFilterParameters(newTransactionFilterParameters);
      localStorage.setItem(
        transactionFiltersStorageKey,
        JSON.stringify(newTransactionFilterParameters)
      );
      forceUpdate();
    },
    [forceUpdate, setTransactionFilterParameters]
  );

  const handleSetPage = useCallback(
    (_page: number) => {
      transactionFilterParameters.page = _page.toString();
      setTransactionFilterParameters(transactionFilterParameters);
      forceUpdate();
    },
    [forceUpdate, setTransactionFilterParameters, transactionFilterParameters]
  );

  const fetchTransactions = useCallback(async () => {
    if (!authenticationData) return;
    if (pollStatus.reportUpdating) return;
    return (await TransactionService.getTransactionsList(
      authenticationData.token,
      transactionFilterParameters
    )) as TransactionListResponse;
  }, [authenticationData, transactionFilterParameters, pollStatus]);

  const fetchTotalLossGainPerYear = useCallback(async () => {
    if (!authenticationData) return;
    return (await TransactionService.getTotalLossGainPerYear(
      authenticationData.token,
      transactionFilterParameters.startDate,
      transactionFilterParameters.endDate
    )) as LossGain;
  }, [authenticationData, transactionFilterParameters]);

  const totalLossGainQuery = useQuery(
    [
      "totalLossGain",
      transactionFilterParameters.startDate,
      transactionFilterParameters.endDate,
    ],
    fetchTotalLossGainPerYear,

    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      retry: 1,
    }
  );

  const recalculateTransactions = useCallback(async () => {
    if (!authenticationData) return;
    if (pollStatus.reportUpdating) return;
    try {
      await TransactionService.recalculateTransactions(
        authenticationData.token
      );
    } catch (error: any) {
      triggerNotification(JSON.parse(error), "error");
    } finally {
      setPollStatus({ needRequest: true, reportUpdating: true });
      forceUpdate();
    }
  }, [
    authenticationData,
    triggerNotification,
    setPollStatus,
    pollStatus,
    forceUpdate,
  ]);

  const transactionsQuery = useQuery(
    ["transactions", transactionFilterParameters],
    fetchTransactions,
    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      retryDelay(failureCount, error) {
        return 5000 * failureCount;
      },
      onError: (error) => {
        if (pollStatus.reportUpdating) return;
        if (pollStatus.needRequest) return;
        recalculateTransactions();
      },
      onSuccess: (data) => {
        if (!data) return;
        if (data.transactions.length === 0) return;
        localStorage.setItem(
          originalTransactionsStorgeKey,
          JSON.stringify(data)
        );
        totalLossGainQuery.refetch();
      },
    }
  );

  // TODO: Have a look at this, as it might have a big impact on performance

  const handleRestoreTransaction = useCallback(
    async (transactionId: number) => {
      if (pollStatus.reportUpdating) return;
      const transactionUpdates = tryGetTransactionBodyUpdates();
      // remove the transaction from the updates
      delete transactionUpdates[transactionId];
      localStorage.setItem(
        transactionBodyUpdatesStorageKey,
        JSON.stringify(transactionUpdates)
      );
      setTransactionBodyUpdates(transactionUpdates);
      forceUpdate();
      transactionsQuery.refetch();
    },
    [
      forceUpdate,
      tryGetTransactionBodyUpdates,
      transactionsQuery,
      transactionBodyUpdatesStorageKey,
      pollStatus,
      setTransactionBodyUpdates,
    ]
  );

  const editedTransactionIds = Object.keys(transactionBodyUpdates);

  const handleSetPendingTransactionBodyUpdates = useCallback(
    (transaction: TransactionItem | "delete") => {
      if (pollStatus.reportUpdating) return;
      if (transaction === "delete") {
        const emptyTransactionUpdates = {};
        localStorage.setItem(
          pendingTransactionBodyUpdatesStorageKey,
          JSON.stringify(emptyTransactionUpdates)
        );
        setPendingTransactionBodyUpdates(emptyTransactionUpdates);
        return;
      }
      const _pendingTransactionBodyUpdates =
        tryGetPendingTransactionBodyUpdates();
      _pendingTransactionBodyUpdates[transaction.id.toString()] = transaction;
      localStorage.setItem(
        pendingTransactionBodyUpdatesStorageKey,
        JSON.stringify(_pendingTransactionBodyUpdates)
      );
      setPendingTransactionBodyUpdates(_pendingTransactionBodyUpdates);
      forceUpdate();
    },
    [
      setPendingTransactionBodyUpdates,
      tryGetPendingTransactionBodyUpdates,
      forceUpdate,
      pendingTransactionBodyUpdatesStorageKey,
      pollStatus,
    ]
  );

  const handleSetTransactionBodyUpdates = useCallback(
    (transaction: TransactionItem | "delete") => {
      if (pollStatus.reportUpdating) return;
      if (transaction === "delete") {
        const emptyTransactionUpdates = {};
        localStorage.setItem(
          transactionBodyUpdatesStorageKey,
          JSON.stringify(emptyTransactionUpdates)
        );
        setTransactionBodyUpdates(emptyTransactionUpdates);
        return;
      }
      const _transactionBodyUpdates = tryGetTransactionBodyUpdates();
      _transactionBodyUpdates[transaction.id.toString()] = transaction;
      localStorage.setItem(
        transactionBodyUpdatesStorageKey,
        JSON.stringify(_transactionBodyUpdates)
      );
      setTransactionBodyUpdates(_transactionBodyUpdates);
      const message = "Transaction has been added to updates";
      triggerNotification(message, "info");
      forceUpdate();
    },
    [
      setTransactionBodyUpdates,
      tryGetTransactionBodyUpdates,
      triggerNotification,
      forceUpdate,
      transactionBodyUpdatesStorageKey,
      pollStatus,
    ]
  );

  const handleSetTransactionTagUpdates = useCallback(
    (
      transactionId: number,
      specialTypeId: string | number | null | "delete"
    ) => {
      if (pollStatus.reportUpdating) return;
      if (specialTypeId === "delete") {
        const emptyTransactionUpdates = {};

        localStorage.setItem(
          transactionTagUpdatesStorageKey,
          JSON.stringify(emptyTransactionUpdates)
        );

        setTransactionTagUpdates(emptyTransactionUpdates);

        forceUpdate();
        setTimeout(() => {
          transactionsQuery.refetch();
        }, 200);
        return;
      }
      const _transactionTagUpdates = tryGetTransactionTagUpdates();
      _transactionTagUpdates[transactionId.toString()] = specialTypeId;
      localStorage.setItem(
        transactionTagUpdatesStorageKey,
        JSON.stringify(_transactionTagUpdates)
      );
      setTransactionTagUpdates(_transactionTagUpdates);
      triggerNotification(
        TagHasBeenAddedLabel[props.applicationSettings.locale],
        "info"
      );
      forceUpdate();
    },
    [
      setTransactionTagUpdates,
      tryGetTransactionTagUpdates,
      triggerNotification,
      forceUpdate,
      transactionTagUpdatesStorageKey,
      transactionsQuery,
      pollStatus,
      props.applicationSettings.locale,
    ]
  );

  const handleSetTransactionDeletions = useCallback(
    (transactionId: number | "delete") => {
      if (pollStatus.reportUpdating) return;
      if (transactionId === "delete") {
        const emptyTransactionDeletions: LocalTransactionDeletions = {};
        localStorage.setItem(
          transactionDeletionsStorageKey,
          JSON.stringify(emptyTransactionDeletions)
        );
        setTransactionDeletions(emptyTransactionDeletions);
        transactionsQuery.remove();
        transactionsQuery.refetch();
        return;
      }
      const _transactionDeletions = tryGetTransactionDeletions();
      _transactionDeletions[transactionId.toString()] = true;
      localStorage.setItem(
        transactionDeletionsStorageKey,
        JSON.stringify(_transactionDeletions)
      );
      setTransactionDeletions(_transactionDeletions);
      const message = "Transaction has been added to deletions";
      triggerNotification(message, "info");
      forceUpdate();
    },
    [
      setTransactionDeletions,
      tryGetTransactionDeletions,
      triggerNotification,
      forceUpdate,
      transactionDeletionsStorageKey,
      pollStatus,
      transactionsQuery,
    ]
  );

  const handleSetTransactionLinkUpdates = useCallback(
    (
      transactionId: number,
      linkedToId: number | undefined | "delete",
      unlinkedFrom?: string
    ) => {
      if (pollStatus.reportUpdating) return;

      if (linkedToId === "delete") {
        const emptyTransactionUpdates = {};

        localStorage.setItem(
          transactionLinkUpdatesStorageKey,
          JSON.stringify(emptyTransactionUpdates)
        );
        localStorage.setItem(unlinkedPairingsStorageKey, JSON.stringify([]));
        setUnlinkedPairings([]);

        setTransactionLinkUpdates(emptyTransactionUpdates);

        forceUpdate();
        setTimeout(() => {
          transactionsQuery.refetch();
        }, 200);
        return;
      }
      const _transactionLinkUpdates = tryGetTransactionLinkUpdates();
      _transactionLinkUpdates[transactionId.toString()] = linkedToId ?? null;
      localStorage.setItem(
        transactionLinkUpdatesStorageKey,
        JSON.stringify(_transactionLinkUpdates)
      );
      setTransactionLinkUpdates(_transactionLinkUpdates);
      if (unlinkedFrom) {
        var _unlinkedPairs = tryGetUnlinkedPairs();
        if (_unlinkedPairs.length > 0) {
          _unlinkedPairs.push(unlinkedFrom.toString());
        } else {
          _unlinkedPairs = [unlinkedFrom.toString()];
        }
        _unlinkedPairs.push(transactionId.toString());
        setUnlinkedPairings(_unlinkedPairs);
        localStorage.setItem(
          unlinkedPairingsStorageKey,
          JSON.stringify(_unlinkedPairs)
        );
      }

      forceUpdate();
    },
    [
      tryGetUnlinkedPairs,
      setUnlinkedPairings,
      unlinkedPairingsStorageKey,
      setTransactionLinkUpdates,
      tryGetTransactionLinkUpdates,
      forceUpdate,
      transactionLinkUpdatesStorageKey,
      transactionsQuery,
      pollStatus,
    ]
  );

  const fetchLinkingRules = useCallback(async () => {
    if (!authenticationData) return;
    const response = await TransactionService.GetLinkingRuleSet(
      authenticationData.token
    );
    const linkToWithdrawalRules: LinkingRuleSet = {};
    const linkToDepositRules: LinkingRuleSet = {};

    Object.keys(response).forEach((key) => {
      if (key.includes("deposit")) {
        Object.keys(response[key]).forEach((innerKey) => {
          linkToDepositRules[innerKey] = response[key][
            innerKey
          ] as LinkingCriteria;
        });
      } else {
        Object.keys(response[key]).forEach((innerKey) => {
          linkToWithdrawalRules[innerKey] = response[key][
            innerKey
          ] as LinkingCriteria;
        });
      }
    });
    const transactionLinkingRuleSet: TransactionTypeLinkingRuleSet = {
      linkFromWithdrawal: linkToDepositRules,
      linkFromDeposit: linkToWithdrawalRules,
    };
    return transactionLinkingRuleSet;
  }, [authenticationData]);

  const fetchErrorCodes = useCallback(async () => {
    if (!authenticationData) return;
    return (await TransactionService.getErrorCodes(
      authenticationData.token
    )) as TransactionErrors;
  }, [authenticationData]);

  const fetchServiceCodes = useCallback(async () => {
    if (!authenticationData) return;
    return (await TransactionService.getServiceCodes(
      authenticationData.token
    )) as ServiceCodes;
  }, [authenticationData]);

  const linkingRulesQuery = useQuery("linkingRules", fetchLinkingRules, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const errorCodesQuery = useQuery("errorCodes", fetchErrorCodes, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
  const serviceCodesQuery = useQuery("serviceCodes", fetchServiceCodes, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const fetchTransactionFilters = useCallback(async () => {
    if (!authenticationData) return;
    const response = await TransactionService.getTransactionFilters(
      authenticationData.token
    );
    const exchangeFilters: FilterExchange[] = Object.keys(
      response["exchanges"]
    ).map((key) => {
      return {
        exchangeId: key.toString(),
        exchangeName: response["exchanges"][key],
      };
    });

    const filterCurrencies: FilterCurrency[] = Object.keys(
      response["currencies"]
    ).map((key) => {
      return {
        currencyId: key.toString(),
        currencyShortName: response["currencies"][key],
      };
    });

    const transactionTypes: FilterTransactionType[] = Object.keys(
      response["transactionTypes"]
    ).map((key) => {
      return {
        transactionTypeId: key.toString(),
        description: response["transactionTypes"][key],
      };
    });
    const filterResponse: TransactionFilters = {
      exchanges: exchangeFilters,
      currencies: filterCurrencies,
      transactionTypes: transactionTypes,
    };
    return filterResponse;
  }, [authenticationData]);

  const transactionFiltersQuery = useQuery(
    "transactionFilters",
    fetchTransactionFilters,

    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );

  const popTransactionBodyUpdate = useCallback(
    (transactionId: string) => {
      const transactionBodyUpdates = tryGetTransactionBodyUpdates();
      const transaction = transactionBodyUpdates[transactionId];
      if (!transaction) return;
      delete transactionBodyUpdates[transactionId];
      localStorage.setItem(
        transactionBodyUpdatesStorageKey,
        JSON.stringify(transactionBodyUpdates)
      );
      setTransactionBodyUpdates(transactionBodyUpdates);
      forceUpdate();
    },
    [
      forceUpdate,
      tryGetTransactionBodyUpdates,
      transactionBodyUpdatesStorageKey,
      setTransactionBodyUpdates,
    ]
  );

  const popTransactionTagUpdate = useCallback(
    (transactionId: string) => {
      const transactionTagUpdates = tryGetTransactionTagUpdates();
      if (!Object.keys(transactionTagUpdates).includes(transactionId)) return;
      delete transactionTagUpdates[transactionId];
      localStorage.setItem(
        transactionTagUpdatesStorageKey,
        JSON.stringify(transactionTagUpdates)
      );
      setTransactionTagUpdates(transactionTagUpdates);
      forceUpdate();
    },
    [
      forceUpdate,
      tryGetTransactionTagUpdates,
      transactionTagUpdatesStorageKey,
      setTransactionTagUpdates,
    ]
  );

  useEffect(() => {
    if (pollStatus.reportUpdating) {
      setTitle(
        `CryptoSkat | ${
          CalculatingTransactionsLabel[props.applicationSettings.locale]
        }`
      );
      return;
    } else {
      setTitle(idleTitle);
    }

    if (!transactionsQuery.data && !transactionsQuery.isLoading) {
      transactionsQuery.refetch();
    }

    if (!transactionFiltersQuery.data && !transactionFiltersQuery.isLoading) {
      transactionFiltersQuery.refetch();
    }

    if (!totalLossGainQuery.data && !totalLossGainQuery.isLoading) {
      totalLossGainQuery.refetch();
    }

    if (!linkingRulesQuery.data && !linkingRulesQuery.isLoading) {
      linkingRulesQuery.refetch();
    }

    return () => {};
  }, [
    pollStatus,
    errorCodesQuery,
    transactionsQuery,
    transactionFiltersQuery,
    totalLossGainQuery,
    linkingRulesQuery,
    setTitle,
    props.applicationSettings,
    idleTitle,
  ]);

  return (
    <div className="page-card-container">
      <TransactionFilterContext.Provider value={transactionFiltersQuery.data}>
        <TransactionErrorContext.Provider value={errorCodesQuery.data}>
          <ServiceCodeContext.Provider value={serviceCodesQuery.data}>
            <GetTransactionPostUpdateErrorsContext.Provider
              value={reportErrors}
            >
              <GetPendingTransactionBodyUpdatesContext.Provider
                value={pendingTransactionBodyUpdates}
              >
                <GetTransactionBodyUpdatesContext.Provider
                  value={transactionBodyUpdates}
                >
                  <GetTransactionDeletionsContext.Provider
                    value={transactionDeletions}
                  >
                    <GetTransactionTagUpdatesContext.Provider
                      value={transactionTagUpdates}
                    >
                      <GetTransactionLinkUpdatesContext.Provider
                        value={transactionLinkUpdates}
                      >
                        <GetTransactionUnlinkedPairsContext.Provider
                          value={unlinkedPairings}
                        >
                          <SetTransactionPostUpdateErrorsContext.Provider
                            value={handleSetReportUpdateErrors}
                          >
                            <SetPendingTransactionBodyUpdatesContext.Provider
                              value={handleSetPendingTransactionBodyUpdates}
                            >
                              <SetTransactionBodyUpdatesContext.Provider
                                value={handleSetTransactionBodyUpdates}
                              >
                                <SetTransactionDeletionsContext.Provider
                                  value={handleSetTransactionDeletions}
                                >
                                  <SetTransactionTagUpdatesContext.Provider
                                    value={handleSetTransactionTagUpdates}
                                  >
                                    <SetTransactionLinkUpdatesContext.Provider
                                      value={handleSetTransactionLinkUpdates}
                                    >
                                      <EditedTransactionsContext.Provider
                                        value={editedTransactionIds}
                                      >
                                        <SetTransactionBodyRestoredContext.Provider
                                          value={handleRestoreTransaction}
                                        >
                                          <SetFilterParametersContext.Provider
                                            value={
                                              handleSetNewTransactionFilterParameters
                                            }
                                          >
                                            <TransactionFilterParametersContext.Provider
                                              value={
                                                transactionFilterParameters
                                              }
                                            >
                                              <RefetchTransactionsPageContext.Provider
                                                value={() =>
                                                  transactionsQuery.refetch()
                                                }
                                              >
                                                <ReportHeader
                                                  applicationSettings={
                                                    props.applicationSettings
                                                  }
                                                  authenticationData={
                                                    authenticationData
                                                  }
                                                  popTransactionBodyUpdate={
                                                    popTransactionBodyUpdate
                                                  }
                                                  popTransactionTagUpdate={
                                                    popTransactionTagUpdate
                                                  }
                                                  walletsWithLogos={
                                                    walletsWithLogos
                                                  }
                                                  exchangesWithLogos={
                                                    exchangesWithLogos
                                                  }
                                                  transactionsQuery={
                                                    transactionsQuery
                                                  }
                                                  totalLossGainQuery={
                                                    totalLossGainQuery
                                                  }
                                                />

                                                <Space h={"xs"} />
                                                <TransactionLinkingRuleSet.Provider
                                                  value={linkingRulesQuery.data}
                                                >
                                                  <ReportBody
                                                    applicationSettings={
                                                      props.applicationSettings
                                                    }
                                                    authenticationData={
                                                      authenticationData
                                                    }
                                                    transactionsQuery={
                                                      transactionsQuery
                                                    }
                                                    handleSetPage={
                                                      handleSetPage
                                                    }
                                                    pageNumber={
                                                      transactionFilterParameters.page
                                                        ? parseInt(
                                                            transactionFilterParameters.page
                                                          )
                                                        : 1
                                                    }
                                                    handleLogout={
                                                      props.handleLogout
                                                    }
                                                    popTransactionTagUpdate={
                                                      popTransactionTagUpdate
                                                    }
                                                    walletsWithLogos={
                                                      walletsWithLogos
                                                    }
                                                    exchangesWithLogos={
                                                      exchangesWithLogos
                                                    }
                                                  />
                                                </TransactionLinkingRuleSet.Provider>
                                              </RefetchTransactionsPageContext.Provider>
                                            </TransactionFilterParametersContext.Provider>
                                          </SetFilterParametersContext.Provider>
                                        </SetTransactionBodyRestoredContext.Provider>
                                      </EditedTransactionsContext.Provider>
                                    </SetTransactionLinkUpdatesContext.Provider>
                                  </SetTransactionTagUpdatesContext.Provider>
                                </SetTransactionDeletionsContext.Provider>
                              </SetTransactionBodyUpdatesContext.Provider>
                            </SetPendingTransactionBodyUpdatesContext.Provider>
                          </SetTransactionPostUpdateErrorsContext.Provider>
                        </GetTransactionUnlinkedPairsContext.Provider>
                      </GetTransactionLinkUpdatesContext.Provider>
                    </GetTransactionTagUpdatesContext.Provider>
                  </GetTransactionDeletionsContext.Provider>
                </GetTransactionBodyUpdatesContext.Provider>
              </GetPendingTransactionBodyUpdatesContext.Provider>
            </GetTransactionPostUpdateErrorsContext.Provider>
          </ServiceCodeContext.Provider>
        </TransactionErrorContext.Provider>
      </TransactionFilterContext.Provider>
    </div>
  );
}

export const TransactionErrorContext = createContext<
  TransactionErrors | undefined
>(undefined);

export const TransactionFilterContext = createContext<
  TransactionFilters | undefined
>(undefined);

export const ServiceCodeContext = createContext<ServiceCodes | undefined>(
  undefined
);

export const SetTransactionTagUpdatesContext = createContext<
  (
    transactionId: number,
    specialTypeId: string | number | null | "delete"
  ) => void
>(() => {});
export const SetTransactionLinkUpdatesContext = createContext<
  (
    transactionId: number,
    linkedToId: number | undefined | "delete",
    unlinkedFrom?: string
  ) => void
>(() => {});

export const SetTransactionBodyUpdatesContext = createContext<
  (transaction: TransactionItem | "delete") => void
>(() => {});

export const SetPendingTransactionBodyUpdatesContext = createContext<
  (transaction: TransactionItem | "delete") => void
>(() => {});

export const SetTransactionDeletionsContext = createContext<
  (transactionId: number | "delete") => void
>(() => {});

export const SetTransactionPostUpdateErrorsContext = createContext<
  (updateError: PostReportUpdateError | "delete") => void
>(() => {});

export const GetTransactionPostUpdateErrorsContext = createContext<
  PostReportUpdateErrors | undefined
>(undefined);

export const GetTransactionDeletionsContext = createContext<
  LocalTransactionDeletions | undefined
>(undefined);

export const GetTransactionBodyUpdatesContext = createContext<
  LocalTransactionBodyUpdates | undefined
>(undefined);

export const GetPendingTransactionBodyUpdatesContext = createContext<
  LocalTransactionBodyUpdates | undefined
>(undefined);

export const GetTransactionTagUpdatesContext = createContext<
  LocalTransactionTagUpdates | undefined
>(undefined);

export const GetTransactionLinkUpdatesContext = createContext<
  LocalTransactionLinkUpdates | undefined
>(undefined);

export const GetTransactionUnlinkedPairsContext = createContext<
  string[] | undefined
>(undefined);

export const SetTransactionBodyRestoredContext = createContext<
  (transactionId: number) => void
>(() => {});

export const EditedTransactionsContext = createContext<string[]>([]);
export const RefetchTransactionsPageContext = createContext<any>(() => {});
export const SetFilterParametersContext = createContext<
  (newFilterParameters: TransactionFilterParameters) => void
>(() => {});
export const TransactionFilterParametersContext =
  createContext<TransactionFilterParameters>({});
export const TransactionLinkingRuleSet = createContext<
  TransactionTypeLinkingRuleSet | undefined
>(undefined);
