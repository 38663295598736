import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { darkTheme, lightTheme } from "../../style/Themes";
import "./bar-loader.scss";

interface Props {
  applicationSettings: ApplicationSettings;
  label?: string;
}
export function BarLoader(props: React.PropsWithChildren<Props>) {
  const theme =
    props.applicationSettings.theme === "dark" ? darkTheme : lightTheme;

  const root = document.documentElement;
  root?.style.setProperty("--loader-color1", theme.csLogoColor1);
  root?.style.setProperty("--loader-color2", theme.csLogoColor2);

  return (
    <div className="bar-loader-container">
      <div className="middle">
        <div className="bar-type1 bar1">
          <div className="line-type1 line1"></div>
        </div>
        <div className="bar-type1 bar2">
          <div className="line-type1 line2"></div>
        </div>
        <div className="bar-type1 bar3">
          <div className="line-type1 line3"></div>
        </div>
        <div className="bar-type2 bar4">
          <div className="line-type2 line4"></div>
        </div>
        <div className="bar-type2 bar5">
          <div className="line-type2 line5"></div>
        </div>
      </div>
      <label className="loading-label">{props.label}</label>
    </div>
  );
}
