import { Divider, Text } from "@mantine/core";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaEquals } from "react-icons/fa";
import { FiMinus } from "react-icons/fi";
import { UseQueryResult } from "react-query";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { LossGain } from "../../../interfaces/Report/LossGainStats";
import {
  CopiedLabel,
  GainLabel,
  LossLabel,
  TotalInLabel,
} from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  totalLossGainQuery: UseQueryResult<LossGain | undefined, unknown>;
  compact?: boolean;
}
export function ReportStats(props: React.PropsWithChildren<Props>) {
  const [usdToggled, setUsdToggled] = useState(false);

  const narrowScreen = useMediaQuery("(max-width: 636px)"); //return true if screen is less than 1000px

  //return sum of list of numbers
  const gainClipboard = useClipboard({ timeout: 1200 });
  const lossClipboard = useClipboard({ timeout: 1200 });
  const resultClipboard = useClipboard({ timeout: 1200 });

  const calculateChange = (lossGain: LossGain | undefined, nc?: boolean) => {
    if (!lossGain) return 0;
    if (nc) {
      return (
        parseFloat(lossGain.ncGain) - Math.abs(-parseFloat(lossGain.ncLoss))
      );
    }
    const change =
      parseFloat(lossGain.usdGain) - Math.abs(-parseFloat(lossGain.usdLoss));
    return change;
  };

  const formatMoneyAmountToUSFormat = (amount: string) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(parseFloat(amount));
  };

  const formatMoneyAmountToDKFormat = (amount: string) => {
    return new Intl.NumberFormat("da-DK", {
      style: "currency",
      currency: "DKK",
    }).format(parseFloat(amount));
  };

  const calculatedUSDChange = calculateChange(props.totalLossGainQuery.data);
  const calculatedNCChange = calculateChange(
    props.totalLossGainQuery.data,
    true
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      className="report-stats-container"
    >
      {props.totalLossGainQuery.data && (
        <div className="report-stats-row-container">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25, delay: 0.35, bounce: 0.25 }}
            className="report-stats-header-container"
          >
            <div
              className="clickable-column"
              //onClick={() => setUsdToggled(!usdToggled)}
            >
              <label>
                {TotalInLabel[props.applicationSettings.locale]}{" "}
                {usdToggled ? "USD" : "DKK"}:
              </label>
              {/* <Text color={"dimmed"}>
                <label className="subtitle">
                  {SwitchToLabel[props.applicationSettings.locale]}{" "}
                  {usdToggled ? "DKK" : "USD"}
                </label>
              </Text> */}
            </div>
          </motion.div>
          {!narrowScreen && (
            <>
              <Divider orientation="vertical" />
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.75, delay: 0.3 }}
                className="report-stats-equation-container"
              >
                <div
                  className="equation-column"
                  onClick={() => {
                    if (usdToggled) {
                      gainClipboard.copy(
                        props.totalLossGainQuery.data?.usdGain
                      );
                    } else {
                      gainClipboard.copy(props.totalLossGainQuery.data?.ncGain);
                    }
                  }}
                >
                  <label>
                    {usdToggled ? (
                      <>
                        {formatMoneyAmountToUSFormat(
                          props.totalLossGainQuery.data?.usdGain
                        )}
                      </>
                    ) : (
                      <>
                        {formatMoneyAmountToDKFormat(
                          props.totalLossGainQuery.data?.ncGain
                        )}
                      </>
                    )}{" "}
                  </label>
                  {gainClipboard.copied && (
                    <motion.div
                      initial={{ opacity: 0, x: 0, y: 20 }}
                      animate={{
                        opacity: 1,
                        x: 4,
                        y: !props.compact ? 0 : -20,
                      }}
                      exit={{ opacity: 0 }}
                      transition={{
                        duration: 0.45,
                        bounce: 0.25,
                        ease: "easeOut",
                      }}
                      className="copied-label-container"
                    >
                      <label>
                        {CopiedLabel[props.applicationSettings.locale]}
                      </label>
                    </motion.div>
                  )}
                  <Text color={"dimmed"}>
                    <label>{GainLabel[props.applicationSettings.locale]}</label>
                  </Text>
                </div>
                <FiMinus />
                <div
                  className="equation-column"
                  onClick={() => {
                    if (usdToggled) {
                      lossClipboard.copy(
                        props.totalLossGainQuery.data?.usdLoss
                      );
                    } else {
                      lossClipboard.copy(props.totalLossGainQuery.data?.ncLoss);
                    }
                  }}
                >
                  <>
                    {props.totalLossGainQuery.data.usdLoss &&
                      props.totalLossGainQuery.data.ncLoss && (
                        <label>
                          {usdToggled ? (
                            <>
                              {formatMoneyAmountToUSFormat(
                                props.totalLossGainQuery.data.usdLoss.replace(
                                  "-",
                                  ""
                                )
                              )}{" "}
                            </>
                          ) : (
                            <>
                              {formatMoneyAmountToDKFormat(
                                props.totalLossGainQuery.data.ncLoss.replace(
                                  "-",
                                  ""
                                )
                              )}{" "}
                            </>
                          )}
                        </label>
                      )}
                  </>

                  {lossClipboard.copied && (
                    <motion.div
                      initial={{ opacity: 0, x: 0, y: 20 }}
                      animate={{
                        opacity: 1,
                        x: 4,
                        y: !props.compact ? 0 : -20,
                      }}
                      exit={{ opacity: 0 }}
                      transition={{
                        duration: 0.45,
                        bounce: 0.25,
                        ease: "easeOut",
                      }}
                      className="copied-label-container"
                    >
                      <label>
                        {CopiedLabel[props.applicationSettings.locale]}
                      </label>
                    </motion.div>
                  )}
                  <Text color={"dimmed"}>
                    <label>{LossLabel[props.applicationSettings.locale]}</label>
                  </Text>
                </div>
              </motion.div>
              <FaEquals />
            </>
          )}

          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25, delay: 0.4, bounce: 0.25 }}
            className={
              calculatedUSDChange > 0
                ? "result-column-positive"
                : "result-column-negative"
            }
            onClick={() => {
              if (usdToggled) {
                resultClipboard.copy(
                  calculatedUSDChange.toString().replace("-", "")
                );
              } else {
                resultClipboard.copy(
                  calculatedNCChange.toString().replace("-", "")
                );
              }
            }}
          >
            {resultClipboard.copied && (
              <motion.div
                initial={{ opacity: 0, x: 0, y: 20 }}
                animate={{ opacity: 1, x: 4, y: !props.compact ? 40 : -20 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.45, bounce: 0.25, ease: "easeOut" }}
                className="copied-label-container"
              >
                <label>{CopiedLabel[props.applicationSettings.locale]}</label>
              </motion.div>
            )}
            <label>
              {usdToggled
                ? formatMoneyAmountToUSFormat(calculatedUSDChange.toString())
                : formatMoneyAmountToDKFormat(calculatedNCChange.toString())}
            </label>
          </motion.div>
        </div>
      )}
    </motion.div>
  );
}
