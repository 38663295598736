import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { SetPollingStatusContext, TriggerNotificationContext } from "../../App";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AuthenticationData } from "../../interfaces/Authentication/AuthenticationData";
import {
  PollResponse,
  PollStatus,
} from "../../interfaces/Import/PollingResponse";
import ImportService from "../../service/ImportService";

interface Props {
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData;
  pollStatus: PollStatus;
  handleLogout: () => Promise<void>;
}
export function APIPolling(props: React.PropsWithChildren<Props>) {
  const notifactions = useContext(TriggerNotificationContext);
  const setPollingStatus = useContext(SetPollingStatusContext);

  const refetchIntervalMs = 5000;

  const initRefetchInterval = useCallback(() => {
    if (!props.pollStatus) return false;
    if (props.pollStatus.needRequest || props.pollStatus.reportUpdating)
      return refetchIntervalMs;
    return false;
  }, [props.pollStatus, refetchIntervalMs]);

  const [refetchInterval, setRefetchInterval] = useState<number | false>(() =>
    initRefetchInterval()
  );

  const getPollResponseType = useCallback((_pollResponse: PollResponse) => {
    if (_pollResponse.type === "error") {
      return "error";
    } else if (_pollResponse.type === "info") {
      return "info";
    } else {
      return "success";
    }
  }, []);

  useEffect(() => {
    if (props.pollStatus.needRequest || props.pollStatus.reportUpdating) {
      setRefetchInterval(refetchIntervalMs);
    } else {
      setRefetchInterval(false);
    }
    return () => {};
  }, [props.pollStatus, refetchIntervalMs]);

  const fetchPollingData = useCallback(async () => {
    if (!props.authenticationData) return;
    const response = await ImportService.pollInfo(
      props.authenticationData.token
    );

    if (response.status === 401) {
      props.handleLogout();
    }
    const pollResponse = response as PollResponse;
    setPollingStatus({
      needRequest: pollResponse.needRequest,
      reportUpdating: pollResponse.reportUpdating,
      pdfReportGenerating: pollResponse.pdfReportGenerating,
    });
    if (pollResponse.message) {
      const messages =
        props.applicationSettings.locale === "en"
          ? pollResponse.message.en
          : pollResponse.message.da;
      notifactions(messages, getPollResponseType(pollResponse), 10000);
    }
  }, [props, notifactions, getPollResponseType, setPollingStatus]);

  useQuery("infoData", fetchPollingData, {
    refetchInterval: refetchInterval,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: false,
  });

  return <></>;
}
