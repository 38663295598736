import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { useCallback, useContext, useState } from "react";
import { useQuery } from "react-query";
import { AuthenticationContext } from "../../../App";
import { StripePaymentIntent } from "../../../interfaces/Membership/PaymentIntent";
import MembershipService from "../../../service/MembershipService";
import { CheckoutForm } from "../CheckoutForm";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { motion } from "framer-motion";
import { BarLoader } from "../../CustomLoaders/BarLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

interface Props {
  applicationSettings: ApplicationSettings;
  planId: number;
  buttonLabel: string;
  voucherCode?: string;
}
export function PaymentContent(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();

  const getIntent = useCallback(async () => {
    if (!authenticationData) return;
    return (await MembershipService.getStripeSetupIntent(
      authenticationData.token,
      props.planId,
      props.voucherCode
    )) as StripePaymentIntent;
  }, [authenticationData, props.planId, props.voucherCode]);

  const intentQuery = useQuery("intent", getIntent, {
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      if (!data) return;
      const options: StripeElementsOptions = {
        clientSecret: data.clientSecret,
        locale: props.applicationSettings.locale === "da" ? "da" : "en",

        appearance:
          props.applicationSettings.theme === "dark"
            ? darkAppearance
            : lightAppearance,
      };
      setStripeOptions(options);
    },
  });

  const darkAppearance: Appearance = {
    theme: "night",
    labels: "floating",
    variables: {
      fontFamily: "Sohne, system-ui, sans-serif",
      fontWeightNormal: "500",
      borderRadius: "8px",
      colorBackground: "#0A2540",
      colorPrimary: "#EFC078",
      colorPrimaryText: "#1A1B25",
      colorText: "white",
      colorTextSecondary: "white",
      colorTextPlaceholder: "#727F96",
      colorIconTab: "white",
      colorLogo: "dark",
    },
    rules: {
      ".Input, .Block": {
        border: "1.5px solid var(--colorPrimary)",
      },
    },
  };

  const lightAppearance: Appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "10px",
      colorBackground: "#F6F8FA",
      colorPrimaryText: "#262626",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "500",
      },
    },
  };

  return (
    <motion.div
      className="payment-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {intentQuery.data && stripeOptions ? (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CheckoutForm
            applicationSettings={props.applicationSettings}
            clientSecret={intentQuery.data.clientSecret}
            buttonLabel={props.buttonLabel}
          />
        </Elements>
      ) : (
        <BarLoader applicationSettings={props.applicationSettings} />
      )}
    </motion.div>
  );
}
