import { motion } from "framer-motion";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { RegistrationForm } from "./RegistrationForm";

interface Props {
  applicationSettings: ApplicationSettings;
  closeModal?: () => void;
}
export function RegistrationComponent(props: React.PropsWithChildren<Props>) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      className="login-registration-form-container"
    >
      <RegistrationForm
        applicationSettings={props.applicationSettings}
        closeModal={props.closeModal}
      />
    </motion.div>
  );
}
