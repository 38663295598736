import { Pagination, Text } from "@mantine/core";
import { useForceUpdate, useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { TransactionListResponse } from "../../../interfaces/Report/TransactionListResponse";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  pageNumber: number;
  handleSetPage: (_page: number) => void;
  setExpandedIdUndefined: () => void;
}
export function ReportPaginationHeader(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 1150px)");
  const mobile = useMediaQuery("(max-width: 768px)");
  const forceUpdate = useForceUpdate();

  const [totalTransactions, setTotalTransactions] = useState(
    props.transactionsQuery.data?.total ?? 0
  );
  const [pageCount, setPageCount] = useState(
    props.transactionsQuery.data?.last_page ?? 0
  );
  const startDateFragments =
    props.transactionsQuery.data?.transactions[0]?.transactionDate.split(" ");
  const stopDateFragments =
    props.transactionsQuery.data?.transactions[
      props.transactionsQuery.data.transactions.length - 1
    ]?.transactionDate.split(" ");

  useEffect(() => {
    if (!props.transactionsQuery.data && props.transactionsQuery.isLoading) {
      props.transactionsQuery.refetch();
    }
    if (props.transactionsQuery.data) {
      setPageCount(props.transactionsQuery.data.last_page);
      setTotalTransactions(props.transactionsQuery.data.total);
    }
  }, [
    props.transactionsQuery,
    props.transactionsQuery.data,
    setPageCount,
    setTotalTransactions,
  ]);

  return (
    <div className="report-pagination-header-container">
      <div className="report-pagination-header-range-container">
        {props.transactionsQuery.data && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.7 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="report-pagination-header-range-row"
            >
              {!mobile && (
                <>
                  <Text color={"dimmed"} className="report-pagination-header">
                    <label>
                      {props.applicationSettings.locale === "en"
                        ? "From"
                        : "Fra"}
                      :{" "}
                    </label>{" "}
                  </Text>{" "}
                </>
              )}
              <Text className="report-pagination-header">
                {startDateFragments && (
                  <label>
                    <strong>{startDateFragments[0]}</strong>{" "}
                    {startDateFragments[1]}
                  </label>
                )}
              </Text>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.7 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.1 }}
              className="report-pagination-header-range-row"
            >
              {!mobile && (
                <>
                  <Text color={"dimmed"} className="report-pagination-header">
                    <label>
                      {props.applicationSettings.locale === "en" ? "To" : "Til"}
                      :{" "}
                    </label>{" "}
                  </Text>{" "}
                </>
              )}
              <Text className="report-pagination-header">
                {stopDateFragments && (
                  <label>
                    <strong>{stopDateFragments[0]}</strong>{" "}
                    {stopDateFragments[1]}
                  </label>
                )}
              </Text>
            </motion.div>
          </>
        )}
      </div>
      <>
        {!mobile ? (
          <Pagination
            total={pageCount}
            radius={"xs"}
            size={compact ? "xs" : "sm"}
            siblings={compact ? 1 : 3}
            page={props.pageNumber}
            onChange={(page) => {
              props.setExpandedIdUndefined();
              props.handleSetPage(page);
              forceUpdate();
            }}
          />
        ) : (
          <Pagination
            radius={"xs"}
            total={pageCount}
            size={"xs"}
            siblings={pageCount > 5 ? 0 : 1}
            page={props.pageNumber}
            onChange={(page) => {
              props.setExpandedIdUndefined();
              props.handleSetPage(page);
              forceUpdate();
            }}
          />
        )}
      </>

      {!mobile && (
        <div className="report-pagination-header-range-container">
          <div className="report-pagination-header-range-row">
            <Text color={"dimmed"} size={compact ? "xs" : "md"}>
              <label>
                {props.applicationSettings.locale === "en"
                  ? "Total transactions: "
                  : "Antal transaktioner: "}
              </label>
            </Text>
            <label>{totalTransactions}</label>
          </div>
        </div>
      )}
    </div>
  );
}
