import { FiArrowLeft } from "react-icons/fi";
import { ReportTabBarItem } from "../../../interfaces/Report/ReportTabBarItem";

interface Props {
  historyBadges: ReportTabBarItem[];
  selectedInspectedHistoryId: number | undefined;
  handleClickExistingHistoryTab: (id: number) => void;
}
export function ReportPopupTabBar(props: React.PropsWithChildren<Props>) {
  return (
    <div className="tab-bar-container">
      {props.selectedInspectedHistoryId && (
        <>
          {props.historyBadges.map((historyBadge, index) => {
            return (
              <>
                <div
                  key={index}
                  className={"tab-bar-item"}
                  id={
                    props.selectedInspectedHistoryId === historyBadge.globalId
                      ? "tab-bar-item-active"
                      : ""
                  }
                  onClick={() =>
                    props.selectedInspectedHistoryId !== historyBadge.globalId
                      ? props.handleClickExistingHistoryTab(
                          historyBadge.globalId
                        )
                      : undefined
                  }
                >
                  {historyBadge.badgeElement}
                </div>
                {index < props.historyBadges.length - 1 && (
                  <FiArrowLeft key={`arrow-${index}`} />
                )}
              </>
            );
          })}
        </>
      )}
    </div>
  );
}
