import { Anchor } from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function TOS(props: React.PropsWithChildren<Props>) {

  const tradeConditionsLink = (label: string) => (
    <Anchor size="xs" href="https://cryptoskat.dk/handelsbetingelser/" target="_blank">
      <label>
        <strong>{label}</strong>
      </label>
    </Anchor>
  );
  const gdprConditionsLink = (label: string) => (
    <Anchor size="xs" href="https://cryptoskat.dk/persondatapolitik/" target="_blank">
      <label>
        <strong>{label}</strong>
      </label>
    </Anchor>
  );

  const termsBody: { [key: string]: JSX.Element } = {
    da: (
      <label>
        Jeg tiltræder CryptoSkats {tradeConditionsLink("handelsbetingelser")},{" "}
        <br />
        herunder ansvarsklausuler i pkt. 6, og{" "}
        {gdprConditionsLink("persondatapolitik")}
      </label>
    ),
    en: (
      <label>
        I agree to CryptoSkats {tradeConditionsLink("terms and conditions")},{" "}
        <br />
        including liability clauses in section 6, and{" "}
        {gdprConditionsLink("personal data policy")}
      </label>
    ),
  };

  return <div className="terms-body-container">{termsBody[props.applicationSettings.locale]}</div>;
}
