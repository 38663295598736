import { Text, Tooltip } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import {
  FiCheck,
  FiEdit,
  FiMoreHorizontal,
  FiTrash2,
  FiX,
} from "react-icons/fi";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { RefetchTransactionsPageContext } from "../../../pages/ReportPage";
import {
  CancelLabel,
  ConfirmDeletionLabel,
  DeletedLabel,
  DeleteLabel,
  EditLabel,
  SaveChangesLabel,
} from "../../../utils/labels";
import "./multibutton.scss";

interface Props {
  applicationSettings: ApplicationSettings;
  expanded: boolean;
  editModeEnabled: boolean;
  hasChanges: boolean;
  transactionId: number;
  handleEditClicked: () => void;
  setEditModeValue: (isEditMode: boolean) => void;
  markAsDeleted: (id: number) => void;
  handleSaveChanges: () => void;
  resetLocalEditedTransactionItem: () => void;
  deleted?: boolean;
}
export function ReportMultiButton(props: React.PropsWithChildren<Props>) {
  const refetchTransactions = useContext(RefetchTransactionsPageContext);

  const [hover, setHover] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [renderChoice, setRenderChoice] = useState(false);

  const resetButton = useCallback(() => {
    setDeleteMode(false);
    props.setEditModeValue(false);
    setHover(false);
  }, [setDeleteMode, props, setHover]);

  const handleDeleteClicked = useCallback(() => {
    props.markAsDeleted(props.transactionId);
    setDeleteMode(false);
  }, [setDeleteMode, props]);

  const hoverModeBlock = (
    <>
      <Tooltip
        label={<label>{EditLabel[props.applicationSettings.locale]}</label>}
        className={
          props.expanded ? "compact-button-expanded" : "compact-button"
        }
        withArrow
      >
        <div
          className={
            props.expanded ? "compact-button-expanded" : "compact-button"
          }
          id="transaction-edit-shortcut-button"
          onClick={(e: any) => {
            e.stopPropagation();
            props.handleEditClicked();
            props.setEditModeValue(true);
          }}
        >
          <FiEdit />
        </div>
      </Tooltip>
      <Tooltip
        label={<label>{DeleteLabel[props.applicationSettings.locale]}</label>}
        className={
          props.expanded ? "compact-button-expanded" : "compact-button"
        }
        withArrow
      >
        <div
          className={
            props.expanded ? "compact-button-expanded" : "compact-button"
          }
          id="transaction-delete-shortcut-button"
          onClick={(e: any) => {
            e.stopPropagation();
            setDeleteMode(true);
          }}
        >
          <FiTrash2 />
        </div>
      </Tooltip>
    </>
  );

  const editModeBlock = renderChoice ? (
    <>
      <Tooltip
        label={
          <Text size="xs">
            <label>{SaveChangesLabel[props.applicationSettings.locale]}</label>
          </Text>
        }
        className={"compact-button-expanded"}
        withArrow
      >
        <div
          className={"compact-button-expanded"}
          id={props.hasChanges ? "transaction-save-shortcut-button" : undefined}
          onClick={(e: any) => {
            e.stopPropagation();
            props.setEditModeValue(false);
            if (props.hasChanges) props.handleSaveChanges();
          }}
        >
          <FiCheck />
        </div>
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip
        label={
          <Text size="xs">
            <label>{SaveChangesLabel[props.applicationSettings.locale]}</label>
          </Text>
        }
        className={"compact-button-expanded"}
        withArrow
      >
        <div
          className={"compact-button-expanded"}
          id={props.hasChanges ? "transaction-save-shortcut-button" : undefined}
          onClick={(e: any) => {
            e.stopPropagation();
            props.setEditModeValue(false);
            if (props.hasChanges) props.handleSaveChanges();
          }}
        >
          <FiCheck />
        </div>
      </Tooltip>
      <Tooltip
        label={<label>{CancelLabel[props.applicationSettings.locale]}</label>}
        className={"compact-button-expanded"}
        withArrow
      >
        <div
          className={"compact-button-expanded"}
          id="transaction-delete-confirm-button"
          onClick={(e: any) => {
            e.stopPropagation();
            props.setEditModeValue(false);
            props.resetLocalEditedTransactionItem();
            refetchTransactions();
          }}
        >
          <FiX />
        </div>
      </Tooltip>
    </>
  );

  const deleteModeBlock = renderChoice ? (
    <>
      <Tooltip
        label={
          <label>
            {ConfirmDeletionLabel[props.applicationSettings.locale]}
          </label>
        }
        className={"compact-button-expanded"}
        withArrow
      >
        <div
          className={"compact-button-expanded"}
          id="transaction-delete-confirm-button"
          onClick={(e: any) => {
            e.stopPropagation();
            handleDeleteClicked();
            setTimeout(() => {
              refetchTransactions();
            }, 1000);
          }}
        >
          <FiTrash2 />
        </div>
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip
        label={
          <label>
            {ConfirmDeletionLabel[props.applicationSettings.locale]}
          </label>
        }
        className={"compact-button-expanded"}
        withArrow
      >
        <div
          className={"compact-button-expanded"}
          id="transaction-delete-confirm-button"
          onClick={(e: any) => {
            e.stopPropagation();
            handleDeleteClicked();
          }}
        >
          <FiTrash2 />
        </div>
      </Tooltip>
      <Tooltip
        label={<label>{CancelLabel[props.applicationSettings.locale]}</label>}
        className={"compact-button-expanded"}
        withArrow
      >
        <div
          className={
            props.expanded ? "compact-button-expanded" : "compact-button"
          }
          id="transaction-x-shortcut-button"
          onClick={(e: any) => {
            e.stopPropagation();
            setDeleteMode(false);
          }}
        >
          <FiX />
        </div>
      </Tooltip>
    </>
  );

  return (
    <>
      {props.expanded ? (
        <div className="multi-button-container-expanded">
          <div className="button-container-row">
            <>
              {!props.editModeEnabled && !deleteMode
                ? hoverModeBlock
                : undefined}
              {props.editModeEnabled ? editModeBlock : undefined}
              {deleteMode ? deleteModeBlock : undefined}
            </>
          </div>
        </div>
      ) : (
        <div className="multi-button-container">
          <div
            className="button-container-row"
            onMouseOver={() => setHover(true)}
            onMouseLeave={resetButton}
          >
            {props.deleted && props.deleted ? (
              <div className="multi-button-idle">
                <label>{DeletedLabel[props.applicationSettings.locale]}</label>
              </div>
            ) : (
              <>
                {hover ? (
                  <>
                    {!props.editModeEnabled && !deleteMode
                      ? hoverModeBlock
                      : undefined}
                    {props.editModeEnabled ? editModeBlock : undefined}
                    {deleteMode ? deleteModeBlock : undefined}
                  </>
                ) : (
                  <div className="multi-button-idle">
                    {!props.editModeEnabled && !deleteMode ? (
                      <FiMoreHorizontal size={26} />
                    ) : props.editModeEnabled && props.hasChanges ? (
                      <Text size="xs">
                        <label>
                          {SaveChangesLabel[props.applicationSettings.locale]}?
                        </label>
                      </Text>
                    ) : deleteMode ? (
                      <Text color={"red"} size="sm">
                        <label>
                          {DeleteLabel[props.applicationSettings.locale]}?
                        </label>
                      </Text>
                    ) : (
                      <FiMoreHorizontal size={26} />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
