import "./style/animations.scss";
import "./pages/page-layout.scss";
import { MantineProvider } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ApplicationSettings } from "./interfaces/ApplicationSettings";
import { GlobalStyles } from "./style/GlobalStyles";
import { darkTheme, lightTheme } from "./style/Themes";
import { useDocumentTitle, useMediaQuery } from "@mantine/hooks";
import React from "react";
import { MenuComponent } from "./components/menu/Menu";
import { RoutesComponent } from "./components/menu/Routes";
import { AuthenticationData } from "./interfaces/Authentication/AuthenticationData";
import AuthService from "./service/AuthService";
import { showNotification, cleanNotifications } from "@mantine/notifications";
import { FiCheck, FiInfo, FiX } from "react-icons/fi";
import { APIPolling } from "./components/SharedContext/APIPolling";
import { decodeToken } from "./utils/decoder";
import { TransactionTypesContextContainer } from "./components/SharedContext/TransactionTypesContextContainer";
import { AllTransactionTypes } from "./interfaces/Import/SpecialTransactions";
import { Wallets } from "./interfaces/Import/Wallets";
import { Exchanges } from "./interfaces/Import/Exchanges";
import ImportService from "./service/ImportService";
import { useQuery } from "react-query";
import { PollStatus } from "./interfaces/Import/PollingResponse";
import { SessionRefreshedLabel } from "./utils/labels";
import TransactionService from "./service/TransactionService";
import { ExchangeRates } from "./interfaces/Report/ExchangeRates";
import currencyNamesDict from "./currency_name_map.json";
import { Currency } from "./interfaces/Import/Currency";

function App() {
  const title = "CryptoSkat";
  const now = new Date().getTime() / 1000;
  useDocumentTitle(title);
  const compact = useMediaQuery("(max-width: 768px)");

  const tokenIsExpiring = useCallback(
    (authenticationData: AuthenticationData) => {
      const expiry = authenticationData.expiry;
      const now = new Date().getTime() / 1000;
      return expiry - now < 300;
    },
    []
  );

  const [authenticationData, setAuthenticationData] = useState<
    AuthenticationData | undefined
  >();

  const [pollStatus, setPollStatus] = useState<PollStatus>({
    needRequest: true,
    reportUpdating: false,
  });
  const [allTransactionTypes, setAllTransactionTypes] =
    useState<AllTransactionTypes>();

  const handleLogout = useCallback(async () => {
    try {
      localStorage.removeItem("session");
      if (authenticationData) {
        await AuthService.logout(authenticationData?.token);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAuthenticationData(undefined);
    }
  }, [authenticationData, setAuthenticationData]);

  const handleSetAuthenticationData = useCallback(
    (_authenticationData: AuthenticationData | undefined) => {
      setAuthenticationData(_authenticationData);
      localStorage.setItem("session", JSON.stringify(_authenticationData));
    },
    [setAuthenticationData]
  );

  const initializeApplicationSettings = (): ApplicationSettings => {
    const applicationSettings: ApplicationSettings = {
      theme: "light",
      locale: "da",
      resetPhase: false,
    };
    localStorage.setItem(
      "ApplicationSettings",
      JSON.stringify(applicationSettings)
    );

    return applicationSettings;
  };

  function GetApplicationSettings() {
    const applicationSettingsString: string | null = localStorage.getItem(
      "ApplicationSettings"
    );
    const applicationSettings: ApplicationSettings = applicationSettingsString
      ? JSON.parse(applicationSettingsString)
      : initializeApplicationSettings();
    if (
      applicationSettings.locale === null ||
      applicationSettings.locale === undefined
    ) {
      applicationSettings.locale = "da";
    }
    return applicationSettings;
  }

  const fetchCurrencies = useCallback(async () => {
    if (!authenticationData) return;

    return await ImportService.getCurrencies(authenticationData.token);
  }, [authenticationData]);

  const currenciesQuery = useQuery("currencies-list", fetchCurrencies, {
    cacheTime: 60 * 60 * 24 * 1000, // 24 hours
    staleTime: 60 * 60 * 24 * 1000, // 24 hours
    enabled: authenticationData?.expiry ?? 0 > now ? true : false,
  });

  const currencies: { [key: string]: Currency } | undefined = useMemo(() => {
    if (!currenciesQuery.data) return;
    const _currencies: { [key: string]: Currency } = {};
    currenciesQuery.data.currencies.forEach((currency) => {
      _currencies[currency.id] = currency; // the key should be currency.id but untill we get the currency ids on the transaction page this will have to wait
    });
    return _currencies;
  }, [currenciesQuery.data]);

  const currencyNames: { [key: string]: string | undefined } = useMemo(() => {
    return Object.fromEntries(
      Object.entries(currencyNamesDict).map(([key, value]) => [key, value])
    );
  }, []);

  const [theme, setTheme] = useState(() => getActiveTheme());

  const fetchExchanges = useCallback(async () => {
    if (!authenticationData) return;
    if (!authenticationData.verified) return;

    return (await ImportService.getExchanges(
      authenticationData.token
    )) as Exchanges;
  }, [authenticationData]);

  const exchangesQuery = useQuery("exchanges", fetchExchanges, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onError: (error: any) => {
      if (error.response.status === 401) {
        handleLogout();
      }
    },
  });

  const fetchExchangeRates = useCallback(async () => {
    if (!authenticationData) return;
    if (!authenticationData.verified) return;
    return (await TransactionService.getExchangeRates(
      authenticationData.token
    )) as ExchangeRates;
  }, [authenticationData]);

  const exchangeRatesQuery = useQuery("exchangeRates", fetchExchangeRates, {
    cacheTime: 60 * 60 * 24 * 1000, // 24 hours
    staleTime: 60 * 60 * 24 * 1000, // 24 hours
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      if (error.response.status === 401) {
        handleLogout();
      }
    },
  });

  const fetchWallets = useCallback(async () => {
    if (!authenticationData) return;
    if (!authenticationData.verified) return;
    return (await ImportService.getWallets(
      authenticationData.token
    )) as Wallets;
  }, [authenticationData]);

  const walletsQuery = useQuery("wallets", fetchWallets, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onError: (error: any) => {
      if (error.response.status === 401) {
        handleLogout();
      }
    },
  });

  const [locale, setLocale] = useState(() => getActiveLocale());
  const [applicationSettings, setApplicationSettings] = useState(
    GetApplicationSettings()
  );

  const [needsPersist, setNeedsPersist] = useState(false);

  const persistSettings = useCallback(
    (applicationSettings: ApplicationSettings) => {
      localStorage.setItem(
        "ApplicationSettings",
        JSON.stringify(applicationSettings)
      );
      setApplicationSettings(applicationSettings);
      setNeedsPersist(false);
    },
    [setApplicationSettings, setNeedsPersist]
  );

  const handleSwitchTheme = useCallback(() => {
    setNeedsPersist(true);
    setTheme(theme === "light" ? "dark" : "light");
  }, [theme, setTheme, setNeedsPersist]);

  const handleSwitchLocale = useCallback(() => {
    setNeedsPersist(true);
    setLocale(locale === "da" ? "en" : "da");
  }, [locale, setLocale, setNeedsPersist]);

  function getActiveTheme(): string {
    const applicationSettings: ApplicationSettings = GetApplicationSettings();
    return applicationSettings.theme;
  }
  function getActiveLocale(): string {
    const applicationSettings: ApplicationSettings = GetApplicationSettings();
    return applicationSettings.locale;
  }

  const triggerNotification = useCallback(
    (
      message: string | string[],
      type: "success" | "error" | "info",
      autoClose?: number,
      customOnclick?: () => void
    ) => {
      // if message is an array, join it
      const notificationTitle = Array.isArray(message) ? message[0] : message;

      showNotification({
        id: "notification",
        disallowClose: true,
        onClick: () => cleanNotifications(),

        autoClose: autoClose ?? 4000,
        color: type === "error" ? "red" : type === "success" ? "green" : "blue",
        message: (
          <div
            className="notification-message-container"
            onClick={customOnclick}
            //add optional custom onClick here
          >
            <label className="notifications-title">
              {notificationTitle}
              {type === "error" && <FiX className="error-icon" />}
              {type === "success" && <FiCheck className="success-icon" />}
              {type === "info" && <FiInfo className="info-icon" />}
              <FiX className="close-icon" />
            </label>
            {Array.isArray(message)
              ? message.slice(1, message.length).map((messageItem) => {
                  return (
                    <div className="notifications-message-item">
                      <label>
                        {"->"} {messageItem}
                      </label>
                    </div>
                  );
                })
              : undefined}
          </div>
        ),

        loading: false,
      });
    },
    []
  );

  const refreshToken = useCallback(async () => {
    if (!authenticationData) return;
    try {
      const response = await AuthService.refreshToken(authenticationData.token);
      const decodedToken = decodeToken(response["access_token"]);
      const refreshedAuthenticationData: AuthenticationData = {
        expiry: decodedToken.exp,
        token: response["access_token"],
        verified: response["verified"],
        email: decodedToken.email,
        claims: decodedToken?.claims,
      };
      window.location.reload();
      triggerNotification(
        SessionRefreshedLabel[applicationSettings.locale],
        "info"
      );
      handleSetAuthenticationData(refreshedAuthenticationData);
      return response;
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 401) {
          await handleLogout();
        }
      }
    }
  }, [
    authenticationData,
    handleLogout,
    handleSetAuthenticationData,
    triggerNotification,
    applicationSettings,
  ]);

  const getSession = useCallback(() => {
    const session = localStorage.getItem("session");
    if (session) {
      return JSON.parse(session) as AuthenticationData;
    }
    return undefined;
  }, []);

  const getAuthenticationData = useCallback(() => {
    return getSession();
  }, [getSession]);

  const handleSetResetPhase = useCallback(
    (state: boolean) => {
      const _applicationSettings = {
        ...applicationSettings,
        resetPhase: state,
      };
      setApplicationSettings(_applicationSettings);
      localStorage.setItem(
        "ApplicationSettings",
        JSON.stringify(_applicationSettings)
      );
    },
    [applicationSettings, setApplicationSettings]
  );

  const refreshTimer = authenticationData
    ? Math.round(authenticationData.expiry - Date.now() / 1000)
    : 0;

  const handleSetRefreshedAuthenticationData = useCallback(() => {
    const _authenticationData = getSession();
    handleSetAuthenticationData(_authenticationData);
    localStorage.setItem("session", JSON.stringify(_authenticationData));
  }, [handleSetAuthenticationData, getSession]);

  useEffect(() => {
    if (!["light", "dark"].includes(theme)) {
      setTheme("light");
      setNeedsPersist(true);
    }
    if (needsPersist) {
      persistSettings({ theme, locale });
    }
    if (authenticationData && authenticationData.verified) {
      if (refreshTimer < 300) {
        refreshToken();
      }
      if (!walletsQuery.data && !walletsQuery.isFetching) {
        walletsQuery.refetch();
      }
      if (!exchangesQuery.data && !exchangesQuery.isFetching) {
        exchangesQuery.refetch();
      }
      if (!exchangeRatesQuery.data && !exchangeRatesQuery.isFetching) {
        exchangeRatesQuery.refetch();
      }
    }
    if (getSession() !== undefined && authenticationData === undefined) {
      handleSetRefreshedAuthenticationData();
    }
  }, [
    handleSetRefreshedAuthenticationData,
    persistSettings,
    theme,
    locale,
    authenticationData,
    refreshToken,
    getAuthenticationData,
    getSession,
    handleSetAuthenticationData,
    tokenIsExpiring,
    refreshTimer,
    walletsQuery,
    exchangesQuery,
    needsPersist,
    setNeedsPersist,
    exchangeRatesQuery,
  ]);

  const handleSetPollState = useCallback(
    (_pollStatus: PollStatus) => {
      setPollStatus(_pollStatus);
    },
    [setPollStatus]
  );

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <MantineProvider
        theme={{ colorScheme: theme === "light" ? "light" : "dark" }}
      >
        <SettingsContext.Provider value={applicationSettings}>
          <CurrencyContext.Provider value={currencies}>
            <CurrencyNameContext.Provider value={currencyNames}>
              <TriggerNotificationContext.Provider value={triggerNotification}>
                <AuthenticationContext.Provider value={authenticationData}>
                  <GetAuthenticationDataContext.Provider
                    value={authenticationData}
                  >
                    <SetAuthenticationDataContext.Provider
                      value={handleSetAuthenticationData}
                    >
                      <ExchangeRatesContext.Provider
                        value={exchangeRatesQuery.data}
                      >
                        <AllTransactionTypesContext.Provider
                          value={allTransactionTypes}
                        >
                          <FetchedExchangesContext.Provider
                            value={exchangesQuery.data}
                          >
                            <FetchedWalletsContext.Provider
                              value={walletsQuery.data}
                            >
                              <SetPollingStatusContext.Provider
                                value={handleSetPollState}
                              >
                                <PollContext.Provider value={pollStatus}>
                                  <SetResetPhaseContext.Provider
                                    value={handleSetResetPhase}
                                  >
                                    {authenticationData &&
                                    authenticationData.verified ? (
                                      <>
                                        <APIPolling
                                          applicationSettings={
                                            applicationSettings
                                          }
                                          authenticationData={
                                            authenticationData
                                          }
                                          pollStatus={pollStatus}
                                          handleLogout={handleLogout}
                                        />
                                        <TransactionTypesContextContainer
                                          applicationSettings={
                                            applicationSettings
                                          }
                                          setAllTransactionTypes={
                                            setAllTransactionTypes
                                          }
                                        />
                                      </>
                                    ) : undefined}
                                    <GlobalStyles />
                                    <HashRouter>
                                      <MenuComponent
                                        handleSwitchTheme={handleSwitchTheme}
                                        handleSwitchLocale={handleSwitchLocale}
                                        applicationSettings={
                                          applicationSettings
                                        }
                                        handleLogout={handleLogout}
                                      />
                                      {compact ? undefined : (
                                        <div className="page-container">
                                          <RoutesComponent
                                            applicationSettings={
                                              applicationSettings
                                            }
                                            handleSwitchLocale={
                                              handleSwitchLocale
                                            }
                                            handleSwitchTheme={
                                              handleSwitchTheme
                                            }
                                            handleLogout={handleLogout}
                                          />
                                        </div>
                                      )}
                                    </HashRouter>
                                  </SetResetPhaseContext.Provider>
                                </PollContext.Provider>
                              </SetPollingStatusContext.Provider>
                            </FetchedWalletsContext.Provider>
                          </FetchedExchangesContext.Provider>
                        </AllTransactionTypesContext.Provider>
                      </ExchangeRatesContext.Provider>
                    </SetAuthenticationDataContext.Provider>
                  </GetAuthenticationDataContext.Provider>
                </AuthenticationContext.Provider>
              </TriggerNotificationContext.Provider>
            </CurrencyNameContext.Provider>
          </CurrencyContext.Provider>
        </SettingsContext.Provider>
      </MantineProvider>
    </ThemeProvider>
  );
}

export default App;

export const SettingsContext = React.createContext<ApplicationSettings>({
  theme: "light",
  locale: "da",
  resetPhase: false,
});

export const SetPollingStatusContext = React.createContext<
  (pollStatus: PollStatus) => void
>(() => {});
export const PollContext = React.createContext<PollStatus>({
  needRequest: false,
  reportUpdating: false,
  pdfReportGenerating: false,
});

export const AllTransactionTypesContext = React.createContext<
  AllTransactionTypes | undefined
>(undefined);

export const AuthenticationContext = React.createContext<
  AuthenticationData | undefined
>(undefined);

export const GetAuthenticationDataContext = React.createContext<
  AuthenticationData | undefined
>(undefined);

export const SetAuthenticationDataContext = React.createContext<
  (_authenticationData?: AuthenticationData) => void
>(() => {});

export const SetResetPhaseContext = React.createContext<
  (state: boolean) => void
>(() => {});

export const TriggerNotificationContext = React.createContext<
  (
    message: string | string[],
    type: "success" | "error" | "info",
    autoClose?: number,
    customOnlick?: () => void
  ) => void
>(() => {});

export const FetchedWalletsContext = React.createContext<Wallets | undefined>(
  undefined
);
export const FetchedExchangesContext = React.createContext<
  Exchanges | undefined
>(undefined);

export const ExchangeRatesContext = React.createContext<
  ExchangeRates | undefined
>(undefined);

export const CurrencyContext = React.createContext<
  | {
      [k: string]: Currency;
    }
  | undefined
>(undefined);

export const CurrencyNameContext = React.createContext<
  | {
      [k: string]: string | undefined;
    }
  | undefined
>(undefined);
