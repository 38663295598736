import "./inspected-transaction-body.scss";
import { UseQueryResult } from "react-query";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { TransactionItem } from "../../../interfaces/Report/TransactionData";
import { TransactionListResponse } from "../../../interfaces/Report/TransactionListResponse";
import { ReportRowItem } from "./ReportRowItem";
import { useContext } from "react";
import {
  FromTransactionLink,
  LinkingRuleSet,
} from "../../../interfaces/Report/LinkingRuleSet";
import {
  GetTransactionBodyUpdatesContext,
  TransactionLinkingRuleSet,
} from "../../../pages/ReportPage";
import { Currency } from "../../../interfaces/Import/Currency";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionItem: TransactionItem;
  exchangesWithLogos: string[] | undefined;
  walletsWithLogos: string[] | undefined;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  editedTransactionIds: string[];
  deletedTransactionIds: number[];
  fromTransactionId: number | undefined;
  linkingRules: LinkingRuleSet | undefined;
  fromTransactionLinkParameters: FromTransactionLink | undefined;
  currencies: Currency[];

  linkToTransaction: (toTransactionId: number) => Promise<void>;
  popTransactionTagUpdate: (transactionId: string) => void;
  markAsDeleted: (id: number) => void;
  enableLinkMode: (_fromTransactionId: number) => void;
  disableLinkMode: () => void;
}
export function ReportInspectedItem(props: React.PropsWithChildren<Props>) {
  const transactionBodyUpdates = useContext(GetTransactionBodyUpdatesContext);
  const transactionLinkingRules = useContext(TransactionLinkingRuleSet);
  return (
    <div>
      {props.currencies && transactionLinkingRules && (
        <ReportRowItem
          pendingDeletion={false}
          walletsWithLogos={props.walletsWithLogos}
          exchangesWithLogos={props.exchangesWithLogos}
          popTransactionTagUpdate={props.popTransactionTagUpdate}
          refetchTransactions={() => props.transactionsQuery.refetch()}
          id={props.transactionItem.id}
          applicationSettings={props.applicationSettings}
          transactionItem={props.transactionItem}
          expanded={true}
          handleClicked={() => {}}
          collapse={() => {}}
          markAsDeleted={props.markAsDeleted}
          deleted={props.deletedTransactionIds?.includes(
            props.transactionItem.id
          )}
          enableLinkMode={props.enableLinkMode}
          fromTransactionId={props.fromTransactionId}
          linkToTransaction={props.linkToTransaction}
          cancelEditMode={props.disableLinkMode}
          linkingRules={props.linkingRules}
          fromTransactionParameters={props.fromTransactionLinkParameters}
          allTransactionLinkingRules={transactionLinkingRules}
          editedTransaction={
            props.editedTransactionIds?.includes(
              props.transactionItem.id.toString()
            )
              ? transactionBodyUpdates?.[props.transactionItem.id.toString()]
              : undefined
          }
          currencies={props.currencies}
          inspectionMode
        />
      )}
    </div>
  );
}
