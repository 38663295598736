import "../components/Settings/settings.scss";

import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { UserSettingsMenu } from "../components/Settings/UserSettingsMenu";
import { useDocumentTitle } from "@mantine/hooks";
import { MyPageLabel } from "../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  handleSwitchLocale: () => void;
  handleSwitchTheme: () => void;
}

export function SettingsPage(props: React.PropsWithChildren<Props>) {
  const subtitle = MyPageLabel[props.applicationSettings.locale];
  useDocumentTitle(`CryptoSkat | ${subtitle}`);

  return (
    <div className="page-card-container">
      <UserSettingsMenu
        applicationSettings={props.applicationSettings}
        handleSwitchLocale={props.handleSwitchLocale}
        handleSwitchTheme={props.handleSwitchTheme}
      />
    </div>
  );
}
