import "./menu.scss";
import { Link, useLocation } from "react-router-dom";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { menuItems } from "./Routes";
import { AuthenticationMenu } from "./AuthenticationMenu";
import { AuthenticationData } from "../../interfaces/Authentication/AuthenticationData";
import { useContext } from "react";
import { PollContext } from "../../App";
import { Loader } from "@mantine/core";

interface Props {
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  handleLogout: () => void;
}

export function TopMenu(props: React.PropsWithChildren<Props>) {
  const location = useLocation();
  const pollStatus = useContext(PollContext);
  return (
    <div className="menu-container">
      <div className="left-section">
        <div className="home-button-container">
          <Link to="/">
            <img src="/assets/header_logo.png" alt="logo" className="logo" />
          </Link>
        </div>
      </div>
      <div className="center-section">
        <div className="menu-items">
          {menuItems.map((item: any, index: number) => {
            return (
              <div
                className={
                  props.authenticationData?.verified
                    ? location.pathname === item.link
                      ? "menu-item-active"
                      : "menu-item"
                    : "menu-item-disabled"
                }
                key={index}
              >
                <Link className={"menu-label"} to={item.link}>
                  {(pollStatus.reportUpdating || pollStatus.needRequest) && item.link === "/import" ? (
                    <Loader
                      size={"sm"}
                      color={
                        props.applicationSettings.theme === "dark"
                          ? "yellow"
                          : "dark"
                      }
                      variant="dots"
                    />
                  ) : (
                    item.icon
                  )}
                  <label>
                    {item[`${props.applicationSettings.locale}Name`]}
                  </label>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="right-section">
        <AuthenticationMenu
          applicationSettings={props.applicationSettings}
          handleLogout={props.handleLogout}
        />
      </div>
    </div>
  );
}
