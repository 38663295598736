import "./page-layout.scss";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { darkTheme, lightTheme } from "../style/Themes";
import GlobalErrorBoundary from "../components/Error/GlobalErrorBoundary";

interface Props {
  applicationSettings: ApplicationSettings;
  children: React.ReactNode;
}

export function PageContainer(props: React.PropsWithChildren<Props>) {
  const theme =
    props.applicationSettings.theme === "dark" ? darkTheme : lightTheme;

  return (
    <div
      className="landing-page-background-image-container"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${theme.backgroundImageColor},${theme.backgroundImageTint}, ${theme.backgroundImageColor}), url(/assets/cryptocurrency.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <GlobalErrorBoundary applicationSettings={props.applicationSettings}>
        {props.children}
      </GlobalErrorBoundary>
    </div>
  );
}
