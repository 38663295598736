import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string;
  applicationSettings: ApplicationSettings;
}

export function Bitfinex(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor href="https://www.bitfinex.com/" target="_blank">
      <strong>Bitfinex</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto.
      </label>,
      <label className="instruction-label">
        Klik på <strong>API Keys</strong> oppe i højre hjørne.
      </label>,
      <label className="instruction-label">
        Tryk på <strong>Create new key</strong>.
      </label>,
      <label className="instruction-label">
        Lad alle API permissions stå som de er: (<strong>Read Only</strong>).
      </label>,
      <label className="instruction-label">
        Navngiv og generér API nøglen.
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account.
      </label>,
      <label className="instruction-label">
        Select <strong>API Keys</strong> in the top right corner.
      </label>,
      <label className="instruction-label">
        Click <strong>Create new key</strong>.
      </label>,
      <label className="instruction-label">
        Leave all API permissions as is: (<strong>Read Only</strong>).
      </label>,
      <label className="instruction-label">
        Name your key and generate it.
      </label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput
        applicationSettings={props.applicationSettings}
        platformId={props.platformId}
      />

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
