import "./report-platform-badge.scss";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { PlatformLogo } from "../../Import/Instructions/manual/PlatformLogo";
import { Tooltip } from "@mantine/core";
import { PlatformBadgeTooltipLabel } from "./PlatformBadgeTooltipLabel";
import { motion } from "framer-motion";
import { getSpecialCustomers } from "../../../utils/specialCustomersUtil";
import { SpecialCustomerLogo } from "../../Import/Instructions/manual/CustomCustomerLogo";

interface Props {
  applicationSettings: ApplicationSettings;
  platformName: string;
  accountName: string;
  exchangesWithLogos: string[] | undefined;
  walletsWithLogos: string[] | undefined;
  compact: boolean;
  expanded: boolean;
  mobile: boolean;
  transactionDate: string;
  inspectedHeaderMode?: boolean;
  hideSubtitle?: boolean;
  showCompactAccountName?: boolean;
}
export function ReportPlatformBadge(props: React.PropsWithChildren<Props>) {
  const animationDelay = 2; // seconds
  const animationDuration = 0.4; // seconds

  const maxPreviewLength = 15;
  const previewAccountName =
    props.accountName.length <= maxPreviewLength
      ? props.accountName
      : props.accountName.substring(0, maxPreviewLength) + "...";

  const formatDateToPreview = (dateString: string) => {
    if (!dateString || dateString === null) return;
    const ddmmyyyy = dateString.split(" ")[0];
    return ddmmyyyy.replace("-", "/").split("-")[0];
  };

  const specialCustomers = getSpecialCustomers();

  return (
    <Tooltip
      className="platform-badge-container"
      position="bottom"
      withArrow
      label={
        <PlatformBadgeTooltipLabel
          accountName={props.accountName}
          platformName={props.platformName}
        />
      }
    >
      <motion.div
        initial={{ y: 2 }}
        animate={{
          y: !props.inspectedHeaderMode ? 10 : 0,
          animationName: "bounce",
        }}
        transition={{
          duration: animationDuration,
          delay: !props.mobile ? animationDelay + 0.2 : 0,
        }}
      >
        {props.exchangesWithLogos?.includes(
          props.platformName.toLowerCase()
        ) ? (
          <PlatformLogo
            platform="exchanges"
            compact={props.compact}
            title={props.platformName}
            activeTheme={props.applicationSettings.theme}
          />
        ) : props.walletsWithLogos?.includes(
            props.platformName.toLowerCase()
          ) ? (
          <PlatformLogo
            platform="wallets"
            compact={props.compact}
            title={props.platformName}
            activeTheme={props.applicationSettings.theme}
          />
        ) : specialCustomers.includes(props.platformName) ? (
          <SpecialCustomerLogo specialCustomer={props.platformName} />
        ) : (
          <PlatformLogo
            platform="other"
            compact={props.compact}
            title={"otherexchanges"}
            activeTheme={props.applicationSettings.theme}
          />
        )}
      </motion.div>
      {props.showCompactAccountName && (
        <motion.div
          className="compact-account-name-label"
          initial={{ opacity: 0, scaleX: 0, x: "-50%" }}
          animate={{ opacity: 1, scaleX: 1, x: "-120%" }}
          transition={{
            duration: animationDuration,
            delay: animationDelay - 1,
          }}
        >
          {<label>{previewAccountName}</label>}
        </motion.div>
      )}
      {!props.hideSubtitle && (
        <>
          {!props.mobile ? (
            <>
              <motion.div
                className="account-name-label"
                initial={{ opacity: 1, scale: 1 }}
                animate={{ opacity: 0, scale: 0 }}
                transition={{
                  duration: animationDuration,
                  delay: animationDelay,
                }}
              >
                {!props.expanded ? <label>{previewAccountName}</label> : <br />}
              </motion.div>
              {props.expanded && (
                <motion.div
                  className="account-name-label"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: animationDuration,
                    delay: animationDelay / 4,
                  }}
                >
                  <label>{previewAccountName}</label>
                </motion.div>
              )}
            </>
          ) : (
            <>
              {props.inspectedHeaderMode ? (
                <motion.div
                  className="account-name-label"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1, y: 5 }}
                  transition={{
                    duration: animationDuration,
                    delay: animationDelay / 4,
                  }}
                >
                  <label>{previewAccountName}</label>
                </motion.div>
              ) : (
                <motion.div
                  className="account-name-label"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1, y: 10 }}
                  transition={{
                    duration: animationDuration,
                    delay: animationDelay,
                  }}
                >
                  <label>{formatDateToPreview(props.transactionDate)}</label>
                </motion.div>
              )}
            </>
          )}
        </>
      )}
    </Tooltip>
  );
}
