import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { HelpLabel } from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function HelpContent(props: React.PropsWithChildren<Props>) {
  return (
    <div className="help-content-container">
      <div className="content-header">
        <label>{HelpLabel[props.applicationSettings.locale]}</label>
      </div>
      <div className="content-body"></div>
    </div>
  );
}
