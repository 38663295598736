import { useMemo, useState } from "react";
import { Space, Text } from "@mantine/core";
import { FiHelpCircle, FiMoon, FiSun, FiUser } from "react-icons/fi";
import { MdOutlinePayment, MdOutlineLanguage } from "react-icons/md";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { UserMenuItem } from "./UserMenuItem";
import { MembershipContent } from "./MembershipContent";
import { UserAccountContent } from "./UserAccountContent";
import { HelpContent } from "./HelpContent";
import { FaMoneyBill } from "react-icons/fa";
import { InvoicesContent } from "./InvoicesContent";
import {
  AccountLabel,
  AppSettingsLabel,
  DarkThemeLabel,
  InvoicesLabel,
  LanguageLabel,
  LightThemeLabel,
  LocaleNameLabel,
  MembershipLabel,
  ThemeLabel,
} from "../../utils/labels";
import { useMediaQuery } from "@mantine/hooks";
import { getPackageInfo } from "../../utils/package-util";

interface Props {
  applicationSettings: ApplicationSettings;
  handleSwitchLocale: () => void;
  handleSwitchTheme: () => void;
}
export function UserSettingsMenu(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");

  const [selectedPath, setSelectedPath] = useState<string>(
    "membership-settings"
  );

  const menuPaths: any = {
    "account-settings": AccountLabel[props.applicationSettings.locale],
    "membership-settings": MembershipLabel[props.applicationSettings.locale],
    "user-invoices": InvoicesLabel[props.applicationSettings.locale],
    /*  "help-section": HelpLabel[props.applicationSettings.locale], */
  };

  const menuIcons: { [key: string]: React.ReactNode } = {
    "membership-settings": <MdOutlinePayment />,
    "account-settings": <FiUser />,
    "user-invoices": <FaMoneyBill />,
    "help-section": <FiHelpCircle />,
  };

  const menuContent: { [key: string]: React.ReactNode } = {
    "membership-settings": (
      <MembershipContent applicationSettings={props.applicationSettings} />
    ),
    "account-settings": (
      <UserAccountContent applicationSettings={props.applicationSettings} />
    ),
    "user-invoices": (
      <InvoicesContent applicationSettings={props.applicationSettings} />
    ),
    "help-section": (
      <HelpContent applicationSettings={props.applicationSettings} />
    ),
  };

  const packageInfo = useMemo(() => {
    return getPackageInfo();
  }, []);

  const environmentLabel = useMemo(() => {
    return process.env.REACT_APP_CS_ENVIRONMENT === "production"
      ? ""
      : `(${process.env.REACT_APP_CS_ENVIRONMENT})`;
  }, []);

  return (
    <div className="user-settings-container">
      <div className="left-menu-container">
        {Object.keys(menuPaths).map((path) => {
          return (
            <UserMenuItem
              key={path}
              label={menuPaths[path]}
              path={path}
              handlePathClick={(path: string) => setSelectedPath(path)}
              isSelected={selectedPath === path}
              icon={menuIcons[path]}
              compact={compact}
            />
          );
        })}
        <div className="footer">
          {!compact && (
            <div className="footer-header">
              <label>
                <Text color={"dimmed"}>
                  {AppSettingsLabel[props.applicationSettings.locale]}
                </Text>
              </label>
            </div>
          )}
          <div
            className="menu-item-container"
            id="menu-item-container-footer"
            onClick={props.handleSwitchLocale}
          >
            <div className="menu-item-container-inner">
              <MdOutlineLanguage />
              {!compact && (
                <label>{`${LanguageLabel[props.applicationSettings.locale]}: ${
                  LocaleNameLabel[props.applicationSettings.locale]
                }`}</label>
              )}
            </div>
          </div>
          <div
            className="menu-item-container"
            id="menu-item-container-footer"
            onClick={props.handleSwitchTheme}
          >
            <div className="menu-item-container-inner">
              {props.applicationSettings.theme === "dark" ? (
                <FiMoon />
              ) : (
                <FiSun />
              )}
              {!compact && (
                <label>
                  {ThemeLabel[props.applicationSettings.locale]}:{" "}
                  {props.applicationSettings.theme === "dark"
                    ? DarkThemeLabel[props.applicationSettings.locale]
                    : LightThemeLabel[props.applicationSettings.locale]}
                </label>
              )}
            </div>
          </div>
          <label className="package-version-label">
            build version: {packageInfo.version} {environmentLabel}
          </label>
        </div>
      </div>
      <Space w="lg" />
      <div className="item-content-section">{menuContent[selectedPath]}</div>
    </div>
  );
}
