import { useMediaQuery } from "@mantine/hooks";
import { Account } from "../../../interfaces/Accounts/AccountsList";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { AuthenticationData } from "../../../interfaces/Authentication/AuthenticationData";
import { AccountsLabel, DataCollectionInfoLabel } from "../../../utils/labels";
import { AccountsTableRow } from "./AccountsTableRow";

interface Props {
  accounts: Account[];
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  tariffInactive: boolean;
  triggerAccountRefetch: () => void;
}
export function AccountsTable(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <div className="accounts-table-container">
        {props.accounts.map((account, index) => {
          return (
            <AccountsTableRow
              key={index}
              account={account}
              applicationSettings={props.applicationSettings}
              authenticationData={props.authenticationData}
              compact={compact}
              triggerAccountRefetch={props.triggerAccountRefetch}
              tariffInactive={props.tariffInactive}
            />
          );
        })}
      </div>
      {!compact && (
        <div className="accounts-table-footer">
          <label>
            {DataCollectionInfoLabel[props.applicationSettings.locale]}
          </label>
          {props.accounts.length > 1 ? (
            <label>
              {props.accounts.length}{" "}
              {AccountsLabel[props.applicationSettings.locale]}
            </label>
          ) : undefined}
        </div>
      )}
    </>
  );
}
