import { Button, ScrollArea } from "@mantine/core";
import { FiMinusCircle } from "react-icons/fi";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { PostReportUpdateErrors } from "../../../interfaces/Report/PostReportUpdateError";
import { ClearErrorsLabel } from "../../../utils/labels";
import { ErrorRow } from "./ErrorRow";

interface Props {
  applicationSettings: ApplicationSettings;
  transactionUpdateErrors: PostReportUpdateErrors | undefined;
  clearErrors: () => void;
}
export function ErrorsModalContent(props: React.PropsWithChildren<Props>) {
  return (
    <div className="errors-modal-content-container">
      <ScrollArea className="errors-modal-content-scroll-area">
        {props.transactionUpdateErrors?.errors.map((errorItem) => {
          return (
            <ErrorRow
              applicationSettings={props.applicationSettings}
              errorItem={errorItem}
            />
          );
        })}
      </ScrollArea>
      <Button
        className="cs-theme-button"
        leftIcon={<FiMinusCircle />}
        onClick={props.clearErrors}
      >
        {ClearErrorsLabel[props.applicationSettings.locale]}
      </Button>
    </div>
  );
}
