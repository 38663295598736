import { Divider, Space } from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AccountsLabel } from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function AccountsHeader(props: React.PropsWithChildren<Props>) {
  const header = AccountsLabel[props.applicationSettings.locale];
  return (
    <>
      <label className="header">{header}</label>
      <Space h="lg" />
      <Divider size={'sm'}/>
    </>
  );
}
