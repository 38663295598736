import { Popover } from "@mantine/core";
import { useState } from "react";
import { apiSecurityIntructions } from "./apiSecurityInstructions";

interface Props {
  locale: string;
}
export function DefaultNotice(props: React.PropsWithChildren<Props>) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const notice: any = {
    da: (
      <>
        <label>
          Når du bruger API nøgler bør du altid følge disse{" "}
          <Popover
            position="top"
            opened={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            target={
              <strong className="popover-trigger">
                API sikkerhedsanbefalinger.
              </strong>
            }
          >
            <div className="security-popover-container">
              {apiSecurityIntructions["da"]}
            </div>
          </Popover>{" "}
          <br />
          Hvis du har vanskeligheder ved at importere transaktioner via API, kan
          du altid importerede dem via CSV eller manuel registrering af
          transaktioner
        </label>
      </>
    ),
    en: (
      <>
        <label>
          When using API keys we recommend following the{" "}
          <Popover
            position="top"
            opened={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            target={
              <strong className="popover-trigger">
                API security instructions.
              </strong>
            }
          >
            <div className="security-popover-container">
              {apiSecurityIntructions["en"]}
            </div>
          </Popover>{" "}
          <br />
          If you have any difficulties with importing transactions, you can
          import them via csv or the input form
        </label>
      </>
    ),
  };

  return <>{notice[props.locale]}</>;
}
