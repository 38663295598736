import axios from "axios";
import { ManualDepositPayload } from "../interfaces/Import/ManualDepositPayload";
import { ManualTradePayload } from "../interfaces/Import/ManualTradePayload";
import { ManualWithdrawalPayload } from "../interfaces/Import/ManualWithdrawalPayload";
import { createOptions, handleResponse } from "./CreateOptions";
import { CurrenciesList } from "../interfaces/Import/Currency";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const getExchanges = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/exchanges", options);
  return handleResponse(response);
};

const getWallets = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/wallets", options);
  return handleResponse(response);
};

const getWalletInfoById = async (token: string, walletId: number) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-wallet-info-by-id/${walletId}`,
    options
  );
  return handleResponse(response);
};

const createExchangeAccount = async (
  token: string,
  locale: string,
  exchangeId: string,
  accountName: string,
  encryptedKey: string,
  encryptedSecret: string,
  apiPassphrase?: string
) => {
  const options = createOptions(token);
  const body: any = {
    exchange_id: exchangeId,
    account_name: accountName,
    encrypted_api_key: encryptedKey,
    encrypted_api_secret: encryptedSecret,
    locale: locale,
  };
  if (apiPassphrase) {
    body["encrypted_api_passphrase"] = apiPassphrase;
  }
  return await http.post(`/create-exchange-account`, body, options);
};

const getEncodeJSKey = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-encode-js-key", options);
  return handleResponse(response);
};

const importTransactionFromCSV = async (
  token: string,
  file: File,
  selectedPlatformId: string | undefined,
  customExchange: string | undefined,
  selectedUserAccountId: string | undefined,
  customAccount: string | undefined
) => {
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  const formData = new FormData();
  formData.append("file", file);
  if (selectedPlatformId) formData.append("exchange_id", selectedPlatformId);
  if (selectedUserAccountId)
    formData.append("account_id", selectedUserAccountId);
  if (customExchange) formData.append("custom_exchange", customExchange);
  if (customAccount) formData.append("custom_account", customAccount);
  try {
    const response = await http.post(
      `/import-transactions-from-csv`,
      formData,
      options
    );
    return handleResponse(response);
  } catch (error: any) {
    console.log(error);
  }
};

const importWalletTransactionsFromCSV = async (
  token: string,
  file: File,
  walletId: string,
  walletAddress: string,
  etherscanExport?: boolean
) => {
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  const formData = new FormData();
  formData.append("file", file);
  formData.append("wallet_address", walletAddress);
  formData.append("etherscan_export", etherscanExport ? "1" : "0");
  try {
    const response = await http.post(
      `/import-transactions-from-wallet-csv/${walletId}`,
      formData,
      options
    );
    return handleResponse(response);
  } catch (error: any) {
    console.log(error);
  }
};

const getWalletImportFormats = async (token: string, walletId: string) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-wallet-import-options/${walletId}`,
    options
  );
  return handleResponse(response);
};

const createManualTransaction = async (
  token: string,
  importPayload:
    | ManualDepositPayload
    | ManualWithdrawalPayload
    | ManualTradePayload
) => {
  const options = createOptions(token);
  const body = {
    ...importPayload,
  };
  const response = await http.post(`/manual-create-transaction`, body, options);
  return handleResponse(response);
};

const getTransactionTypes = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-transaction-types", options);
  return handleResponse(response);
};

const getSpecialTransactionTypes = async (
  token: string,
  transactionTypeId: number
) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-special-types-of-transaction-types?type_id=${transactionTypeId}`,
    options
  );
  return handleResponse(response);
};

const getCurrencies = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get<CurrenciesList>(
    "/get-currencies-list",
    options
  );
  return handleResponse(response) as CurrenciesList;
};

const pollInfo = async (token: string) => {
  const options = createOptions(token);
  const response = await http.get("/get-info-data", options);
  return handleResponse(response);
};

const getExchangeAPIFields = async (token: string, exchangeId: string) => {
  const options = createOptions(token);
  const response = await http.get(
    `/get-exchange-api-fields/${exchangeId}`,
    options
  );
  return handleResponse(response);
};

const importTransactionsFromEtherscan = async (
  token: string,
  exchange_id: string,
  address: string
) => {
  const options = createOptions(token);
  const body = {
    exchange_id,
    address,
  };
  const response = await http.post(
    `/import-transactions-from-etherscan`,
    body,
    options
  );
  return handleResponse(response);
};

const ImportManualTransactionsBulk = async (
  token: string,
  manualBulkImportPayload: ManualBulkImportPayload
) => {
  const options = createOptions(token);

  const response = await http.post(
    `/bulk-import-transactions`,
    manualBulkImportPayload,
    options
  );
  return handleResponse(response);
};

export interface ManualBulkImportPayload {
  exchange_id?: number;
  custom_exchange?: string;
  custom_account: string;
  data: CustomFormatTransactionInput[];
}
export interface CustomFormatTransactionInput {
  transaction_type: string;
  transaction_date: string;
  currency_in_id?: number;
  amount_in?: number;
  crypto_tx_id?: string;
  special_transaction_type_id?: number;
  currency_out_id?: number;
  amount_out?: number;
  fee_currency_id?: number;
  fee_amount?: number;
}

const ImportService = {
  importTransactionsFromEtherscan,
  getExchanges,
  getWallets,
  getWalletInfoById,
  getEncodeJSKey,
  createExchangeAccount,
  importTransactionFromCSV,
  importWalletTransactionsFromCSV,
  getWalletImportFormats,
  createManualTransaction,
  getTransactionTypes,
  getSpecialTransactionTypes,
  getCurrencies,
  pollInfo,
  getExchangeAPIFields,
  ImportManualTransactionsBulk,
};

export default ImportService;
