import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  body{
    transition: .25s ease all;
    overflow: hidden;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.body};
  }

  .page-container, .mantine-AppShell-root {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }

  
  .item-content-section {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .user-settings-container, .menu-item-container {
    background-color: ${({ theme }) => theme.menuBackground};
    border-color: ${({ theme }) => theme.menuBorder};
  } 

  .platform-badge-container {
    .account-name-label {
      color: ${({ theme }) => theme.text};
      opacity: 0.7;
    }
  }

  .membership-plan-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    background-color: ${({ theme }) => theme.transactionBackgroundColor};
    box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 2px, rgba(0, 0, 0, 0.09) 0px 2px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px,
    rgba(0, 0, 0, 0.09) 0px 8px 16px, rgba(0, 0, 0, 0.09) 0px 16px 32px, rgba(0, 0, 0, 0.09) 0px 32px 64px;
    
    .membership-plan-header {
      border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
    }
    
    .membership-plan-footer {
      border-top: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  #active-membership-plan {
    border: 1px solid ${({ theme }) => theme.transactionIn};
  }

  .currency-section-read-only-container {
    #editable {
      border: 1px solid ${({ theme }) => theme.menuBorder};
    }
    #old {
      background-color: ${({ theme }) => theme.transactionOutBackground};
      opacity: 0.5;
    }
    .new {
      background-color: ${({ theme }) => theme.transactionInBackground};
    }
  }
  

  .user-settings-container {
    .left-menu-container  {
      border-right: 1px solid ${({ theme }) => theme.menuBorder};
      border-left: 1px solid ${({ theme }) => theme.menuBorder};
      border-bottom: 1px solid ${({ theme }) => theme.menuBorder};

      .menu-item-container, .menu-item-container-selected {
        background-color: ${({ theme }) => theme.menuBorderSubtle};
        border: 1px solid ${({ theme }) => theme.menuBorder};
        &:hover {
          .menu-item-container-inner  {
            background-color: ${({ theme }) => theme.dropzoneButton};
            color: ${({ theme }) => theme.dropzoneButtonText};
          }
        }
      }
      .menu-item-container-selected {
        background-color: ${({ theme }) => theme.menuBorderSubtle};
        .menu-item-container-inner  {
          background-color: ${({ theme }) => theme.dropzoneButton};
          color: ${({ theme }) => theme.dropzoneButtonText};
        }
      }
    }
  } 

  .integrations-container {
    background-color: ${({ theme }) => theme.cardBackground};
    border: 1px solid ${({ theme }) => theme.menuBorder};

  }
  .divider {
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .filter-platform-item {
    &:hover {
      border: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  .invoice-item-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .menu-container, .mantine-Header-root, .mantine-Navbar-root {
    background-color: ${({ theme }) => theme.menuBackground};
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
    .menu-label, .settings-button{
      color: ${({ theme }) => theme.text};
      &:hover {
        color: ${({ theme }) => theme.menuText};
      }
    }

    .menu-item-active{
      border-bottom-color: ${({ theme }) => theme.menuText};
      border-radius: 0px;
    }

    .menu-item:hover, .menu-item-active:hover{
      border-radius: 15px;
      border-right-color: ${({ theme }) => theme.menuText};
      border-bottom-color: ${({ theme }) => theme.menuText};
      border-left-color: ${({ theme }) => theme.menuText};
    }
  }

  .generated-reports-container {
    background-color: ${({ theme }) => theme.cardBackground};
    border: 1px solid ${({ theme }) => theme.menuBorder};

    .report-title {
      border: 1px solid ${({ theme }) => theme.menuBorder};
      &:hover {
        background-color: ${({ theme }) => theme.transactionBackgroundColor};
      }
    }
  }

  .landing-page-background-image-container {
    @media only screen and (max-height: 667px) {
      overflow-y: scroll;
      height: 100%;
    }
  }
  
  .mantine-NumberInput-wrapper{
    color: black;
    .price-input-presentation {
      background-color: ${({ theme }) => theme.inputBackgroundColor};
      color: ${({ theme }) => theme.text};
      cursor: pointer;
    }
  }

  .page-card-container {
    background-color: ${({ theme }) => theme.cardBackground};
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-color: ${({ theme }) => theme.menuBorder};
  }

  .accounts-overview-container {
    border-color: ${({ theme }) => theme.menuBorder};
  }

  .dropzone-button-container, .dropzone-button-container-filled {
    border: 1px solid ${({ theme }) => theme.dropzoneButton};
  }

  .dropzone-button-container-filled {

    background-color: ${({ theme }) => theme.dropzoneButton};
    color: ${({ theme }) => theme.dropzoneButtonText};
    .center-section {
      border-right: 1px solid ${({ theme }) => theme.dropzoneButtonText};
      border-left: 1px solid ${({ theme }) => theme.dropzoneButtonText};
    }
    .left-section,
    .right-section {
      transition: .25s ease all;
      &:hover {
        color: ${({ theme }) => theme.dropzoneButtonHover};
      }
    }
    .right-section-disabled {
      transition: .25s ease all;
      color: ${({ theme }) => theme.dropzoneButtonText};
      opacity: .3;
    }
  }
  .accounts-table-container {
    background-color: ${({ theme }) => theme.cardBackground};
    border-color: ${({ theme }) => theme.menuBorder};
    .accounts-table-row {
      opacity: .7;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      border-color: ${({ theme }) => theme.menuBorder};
      &:hover {
        border-color: ${({ theme }) => theme.menuBorder};
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .manual-transaction-in-input-container {
    background-color: ${({ theme }) => theme.transactionBackgroundColor};
  }
  .manual-transaction-out-input-container {
    background-color: ${({ theme }) => theme.transactionBackgroundColor};
  }

  .report-header-container, .report-body-container {
    border-color: ${({ theme }) => theme.menuBorder};
    background-color: ${({ theme }) => theme.cardBackground};
  }

  .report-body-container{
    .transaction-item-container-expanded, .transaction-item-container {
      border-color: ${({ theme }) => theme.menuBorder};
      background-color: ${({ theme }) => theme.cardBackground};
    }
    .transaction-item-container {
      transition: .2s ease all;
      border-color: ${({ theme }) => theme.menuBorderSubtle};
      background-color: ${({ theme }) => theme.cardBackground};
      &:hover {
        border-color: ${({ theme }) => theme.menuBorder};
      }
    }
  }


  .transaction-hash-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    color: ${({ theme }) => theme.text};
  }

  .error-indicator, .error-indicator-edited {
    transition: .25s ease all;
    color: ${({ theme }) => theme.transactionOut};
    padding: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  .error-indicator-edited {
    opacity: .4;
  }

  .multi-button-container, .multi-button-container-expanded {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }
  
  .arrow-transaction-out{
    color: ${({ theme }) => theme.transactionOut};
  }
  .arrow-transaction-in{
    color: ${({ theme }) => theme.transactionIn};
  }

  .transaction-popover-button-menu-row {
    border: 1px solid  ${({ theme }) => theme.menuBorder};
    color: ${({ theme }) => theme.transactionOut};
    &:hover {
      background-color: ${({ theme }) => theme.inputBackgroundColor};
    }
  }

  #service-menu {
    width: 100%;
    color: ${({ theme }) => theme.text};
  }
  #service-menu-fix-button {
    color: ${({ theme }) => theme.text};
  }

  .link-mode-overlay {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: ${({ theme }) => theme.backgroundImageTint};
    .close-button {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .link-id-input {
    border: 1px solid #ced4da;
    width: 50%;
  }

  #transaction-link-to-shortcut-button-linkable {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    padding: 5px;
    background-color: rgb(68, 90, 218, 0.5);
    transition: .25s ease all;
    border-radius: 25%;
    opacity: .8;
    &:hover {
      opacity: 1;
      
    }
  }
  #transaction-link-to-shortcut-button {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    padding: 5px;
    background-color: ${({ theme }) => theme.menuBorder};
    transition: .25s ease all;
    border-radius: 25%;
    visibility: hidden;
    opacity: .0;
  }
  
  #transaction-link-to-shortcut-button-disabled {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    padding: 5px;
    transition: .25s ease all;
    background-color:rgb(244, 79, 98, 0.5);
    opacity: .8;
    border-radius: 25%;
    &:hover {
      opacity: 1;
      
    }
  }

  .date-edit-container  {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  


  //MANTINE and TOASTIFY

  .mantine-Text-root mantine-Anchor {
    @media only screen and (max-width: 768px) {
      font-size: 8px;
    }
  }
  .mantine-Menu-body, .mantine-Modal-modal, .mantine-Modal-body {
    background-color: ${({ theme }) => theme.body};
    border-color: ${({ theme }) => theme.menuBorder};
  }
  
  .mantine-Pagination-item,
  .mantine-TextInput-filledVariant,
  .mantine-PasswordInput-filledVariant,
  .mantine-NumberInput-filledVariant,
  .mantine-DatePicker-input,
  .mantine-DatePicker-wrapper,
  .mantine-TimeInput-input {
    background-color: ${({ theme }) => theme.selectInputBackgroundColor};
  }

  .mantine-Pagination-active {
    background-color: ${({ theme }) => theme.csThemeAccent1Alt};
    color: ${({ theme }) => theme.body};
    font-weight: 600;
  }

  .column-section {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }
  .column-section-gain {
    border: 1px solid ${({ theme }) => theme.transactionIn};
    background-color: ${({ theme }) => theme.transactionInBackground};
  }
  .column-section-loss {
    border: 1px solid ${({ theme }) => theme.transactionOut};
    background-color: ${({ theme }) => theme.transactionOutBackground};
  } 
  
  .notification-container {
    * {
      cursor: pointer;
      background-color: ${({ theme }) => theme.backgroundImageTint};
      border-color: ${({ theme }) => theme.menuBorder};
    }
  }
  
  .notification-message-container {
    transition: .25s ease all;
    display: flex;
    flex-direction: column;
    
    .notifications-title {
      font-size: 18px;
      color: ${({ theme }) => theme.text};
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    .notifications-message-item {
      color: ${({ theme }) => theme.text};
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .close-icon {
      display: none;
    }

    .error-icon {
      color: ${({ theme }) => theme.transactionOut};
    }

    .success-icon {
      color: ${({ theme }) => theme.transactionIn};
    }

    .info-icon {
      color: ${({ theme }) => theme.menuBorder};
    }

    &:hover {
      .error-icon,
      .info-icon,
      .success-icon {
        display: none;
      }

      .close-icon {
        display: flex;
      }
    }
  }

  #price-plan-row-alt {
    background-color: ${({ theme }) => theme.inputBackgroundColor};
  }
  .price-plan-container {
    .price-plan-row {
      border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  .payment-successful-container {
    .checkmark {
      color: ${({ theme }) => theme.transactionIn};
    }
  }

  .error-label-container {
    color: ${({ theme }) => theme.transactionOut};
  }
  .error-label {
    color: ${({ theme }) => theme.transactionOut};
  }

  .voucher-applied-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    color: ${({ theme }) => theme.transactionIn};
  }

  .voucher-content-divider {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  #history-popup-loader {
    border: 1px solid transparent;
    z-index: 0;
    box-shadow: none !important;
  }

  .mfa-input, .voucher-input {
    background-color: ${({ theme }) => theme.selectInputBackgroundColor};
    color: ${({ theme }) => theme.text};
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .memberships-cards-container {

    /* width */
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;

      background: ${({ theme }) => theme.menuBorder} ${({ theme }) => theme.textButtonHover};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.menuBorder} ${({ theme }) => theme.textButtonHover};
      height: 5px;
      width: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      height: 5px;
      width: 5px;
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      width: 2px;

      background: #555;
    }
  }
  .dashboard-container,
  .import-body-container,
  .invoices-scrollable-body,
  .intructions-container-csv,
  .intructions-container,
  .manual-transaction-container,
  .custom-format-results-container,
  .accounts-table-container  {
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
    
   /* width */
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      background: ${({ theme }) => theme.menuBorder} ${({ theme }) => theme.textButtonHover};
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.menuBorder} ${({ theme }) => theme.textButtonHover};
      height: 5px;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      height: 5px;
      background: #888;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      width: 2px;
  
      background: #555;
    } 

  }

  .tab-bar-container,  {
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
    overflow-x: scroll;
    
   /* width */
    ::-webkit-scrollbar {
      height: 5px;
      background: ${({ theme }) => theme.menuBorder} ${({ theme }) => theme.textButtonHover};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.menuBorder} ${({ theme }) => theme.textButtonHover};
      height: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      height: 5px;
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      width: 2px;

      background: #555;
    }
  }

  .upload-bar {
    background-color: ${({ theme }) => theme.textButtonHover};
  }

  #tab-bar-item-active {
    border-color: ${({ theme }) => theme.menuBorder};
    opacity: 1;
  }
  .amount-label {
    font-family: "ShareTechRegular";
  }

  .transaction-input {
    .mantine-TextInput-input, .mantine-NumberInput-input {
      max-width: 200px;
      font-size: 16px;
      font-family: "ShareTechRegular";
      font-weight: 500;
      text-align: right;
      width: 100%;
      &:hover {
        border: 1px solid ${({ theme }) => theme.buttonSelected};
      }
      //media query for mobile
      @media only screen and (max-width: 1268px) {
        font-size: 14px !important;
      }
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .mantine-NumberInput-disabled, .mantine-TextInput-disabled {
      color: ${({ theme }) => theme.text};
      background-color: transparent;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid ${({ theme }) => theme.menuBorder};
      }
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }

  }
  .transaction-input-missing {
    .mantine-TextInput-input,.mantine-NumberInput-input {
      max-width: 200px;
      font-size: 16px;
      font-family: "ShareTechRegular";
      font-weight: 500;
      text-align: right;
      width: 100%;
      border: 1px solid ${({ theme }) => theme.transactionOut};
    }

  }

  .result-column-positive {
    color: ${({ theme }) => theme.transactionIn};
    filter: grayscale(50%);
    border: 1px solid transparent;
    &:hover {
      color: ${({ theme }) => theme.text};
      border: 1px solid ${({ theme }) => theme.text};
    }
  } 
  .result-column-negative {
    color: ${({ theme }) => theme.transactionOut};
    filter: grayscale(50%);
    border: 1px solid transparent;
    &:hover {
      color: ${({ theme }) => theme.text};
      border: 1px solid ${({ theme }) => theme.text};
    }
  }

  /* .clickable-column {
    &:hover {
      color: ${({ theme }) => theme.textButtonHover};
    }
  } */

  .feature-card {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    background-color: ${({ theme }) => theme.backgroundImageTint};
    max-width: 400px;
    width: 14%;
  }

  .landingpage-button {
    &:hover {
    .button-label {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  .cancel-updates-container, .reset-filters-container, .update-errors-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    padding: 4px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      background-color: rgb(244, 79, 98, 0.5);

    }
  }

  .update-errors-container {
    background-color: ${({ theme }) => theme.transactionOutBackground};
  }

  .transactions-submit-button {
    transition: .25s ease-in;
    border: 1px solid ${({ theme }) => theme.menuBorder};
    &:hover {
      background-color: ${({ theme }) => theme.menuBorder};
      color: #efeff0;
      background-color: ${({ theme }) => theme.submitButton};
    }
  }

  .more-options-button {
    cursor: pointer;
    * {
      cursor: pointer;
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.menuBorder};
      background-color: ${({ theme }) => theme.menuBorder};
      color: #efeff0;
      background-color: ${({ theme }) => theme.submitButton};
    }
  }


  .filter-popover-item {
    transition: .25s ease-in;
    &:hover {
      border: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  .unselected-filter-container, .selected-filter-container {
    transition: .25s ease all;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  .mantine-Popover-popover, .mantine-Popover-arrow, .mantine-Drawer-drawer {
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
    border-color: ${({ theme }) => theme.menuBorder};
  }
  
  .mantine-SegmentedControl-input, .mantine-SegmentedControl-root, 	.mantine-DatePicker-dropdown  {
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
  }
  .mantine-SegmentedControl-label, 	.mantine-Badge-root  {
    font-family: "ShareTechRegular";
  }
  .transaction-badge {
    background-color: ${({ theme }) => theme.body};
    span {
      font-family: "ShareTechRegular";
    }
  }

  .mantine-SegmentedControl-controlActive {
    background-color: ${({ theme }) => theme.inputBackgroundColor};
  }
  .mantine-SegmentedControl-active {
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    opacity: .8;
  }

  .notification-class-info, .notification-class-success, .notification-class-error, .mantine-Notification-body {
    color: ${({ theme }) => theme.text};
  }



  .dashboard-tooltip-body-class, .dashboard-tooltip-arrow-class {
    background-color: #e9e9e9;
  }

  .mantine-Select-dropdown, .mantine-Select-input {
    background-color: ${({ theme }) => theme.selectInputBackgroundColor};
  }
  .mantine-Select-item {
    background-color: ${({ theme }) => theme.selectInputBackgroundColor};
    &:hover {
      background-color: ${({ theme }) => theme.selectInputhover};
    }
  }

  .date-filter-menu-item {
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
  }

  .date-filter-menu-item-filled {
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }
  .date-filter-menu-item-checked {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .calendar-filter-menu-item {
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
  }
  .calendar-filter-menu-item-input {
    background-color: ${({ theme }) => theme.popoverBackgroundColor};
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .integrations-parent-container,
  .welcome-container,
  .get-started-container,
  .membership-container, 
  .reports-container {
    background-color: ${({ theme }) => theme.backgroundImageTint};
    border: 1px solid ${({ theme }) => theme.menuBorder};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .get-started-bottom-content, .report-buttons-row-container {
    border-top: 1px solid ${({ theme }) => theme.menuBorder};
  }


  .cs-theme-button {
    transition: .15s ease-in;
    background-color: ${({ theme }) => theme.dashboardButton};
    color: ${({ theme }) => theme.dashboardButtonText};
    &:hover {
      background-color: ${({ theme }) => theme.dashboardButtonHover};
    }

  }

  .information-label {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${({ theme }) => theme.transactionOut};
  }

  .invoices-scrollable-body {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .outer-history-item-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }

  #deposit-type-label {
    // do gradient from left to right, with the left being the color of the deposit type and the right being the background color
    background: linear-gradient(to left, transparent 10%, ${({ theme }) => theme.depositTypeColor} 100%);
    &:hover {
      background-color: ${({ theme }) => theme.depositTypeColor};
    }
  }
  #deposit-type-label-focus {
    background-color: ${({ theme }) => theme.depositTypeColor};
  }
  #withdrawal-type-label {
    // do gradient from left to right, with the left being the color of the withdrawal type and the right being the background color
    background: linear-gradient(to left, transparent 10%, ${({ theme }) => theme.withdrawalTypeColor} 100%);
    &:hover {
      background-color: ${({ theme }) => theme.withdrawalTypeColor};
    }
  }
  #withdrawal-type-label-focus {
    background-color: ${({ theme }) => theme.withdrawalTypeColor};
  }
  #trade-type-label-focus {
    background-color: ${({ theme }) => theme.tradeTypeColor};
  }

  #trade-type-label {
    // do gradient from left to right, with the left being the color of the trade type and the right being the background color
    background: linear-gradient(to left, transparent 10%, ${({ theme }) => theme.tradeTypeColor} 100%);
    &:hover {
      background-color: ${({ theme }) => theme.tradeTypeColor};
    }
  }
  
  #cancelled-type-label-focus {
    background-color: ${({ theme }) => theme.menuBorder};
  }
  #cancelled-type-label {
    // do gradient from left to right, with the left being the color of the trade type and the right being the background color
    background: linear-gradient(to left, transparent 10%, ${({ theme }) => theme.menuBorder} 100%);
    &:hover {
      background-color: ${({ theme }) => theme.menuBorder};
    }
  }

  .compact-report-header-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    
    .compact-transactions-submit-button {
      border: 1px solid ${({ theme }) => theme.menuBorder};
    }
  }

  .inspected-transactions-header-container {
    background-color: ${({ theme }) => theme.backgroundImageTint};
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .inspected-transaction-item-header-container {
    background-color: ${({ theme }) => theme.overlayBackground};
  }

  .transaction-fee-container,
  .transaction-in-container,
  .transaction-out-container {
    @media (max-width: 768px) {
      border-top: 1px solid ${({ theme }) => theme.menuBorder};
      margin-top: 10px;
    }
  }
  
  .currency-in-label-container {
    color: ${({ theme }) => theme.transactionIn};
    @media (max-width: 768px) {
      width: fit-content;
      padding: 0 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.transactionIn};
      border-right: 1px solid ${({ theme }) => theme.transactionIn}
    }
  
  }
  .currency-out-label-container {
    color: ${({ theme }) => theme.transactionOut};
    @media (max-width: 768px) {
      width: fit-content;
      padding: 0 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.transactionOut};
      border-right: 1px solid ${({ theme }) => theme.transactionOut}
    }
  }
  .currency-fee-label-container {
    color: ${({ theme }) => theme.transactionFee};
    @media (max-width: 768px) {
      width: fit-content;
      padding: 0 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.transactionFee};
      border-right: 1px solid ${({ theme }) => theme.transactionFee};
    }
  }

  .acquisition-date-container {
    border: 1px solid ${({ theme }) => theme.menuBorder};
  }
  .acquisition-box-divider {
    border-bottom: 1px solid ${({ theme }) => theme.menuBorder};
  }

  .error-card {
    border: 1px solid ${({ theme }) => theme.menuBorder};
    background-color: ${({ theme }) => theme.menuBorderSubtle};
  }

  
  .custom-format-results-container {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border: 1px solid ${({ theme }) => theme.menuBorderSubtle};
    .converted-transaction-container {
      border: 1px solid ${({ theme }) => theme.menuBorder};
      &.invalid-row {
        background: ${({ theme }) => theme.transactionOutBackground};
      }
    }
  }

  .converted-transaction-type-container {
    border: 1px solid ${({ theme }) => theme.menuBorderSubtle};
    &.missing {
      border: 1px solid ${({ theme }) => theme.transactionOut};
    }
    &.clickable {
      &:hover {
        background: ${({ theme }) => theme.textButtonHover};
        border: 1px solid ${({ theme }) => theme.menuBorder};
        color: ${({ theme }) => theme.body};
      }
    }
    .transaction-in {
      color: ${({ theme }) => theme.transactionIn};
    }
    .transaction-out {
      color: ${({ theme }) => theme.transactionOut};
    }
    .transaction-fee {
      color: ${({ theme }) => theme.transactionFee};
    }
  }


  `
