import { Avatar, Divider, Drawer, Space } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import React, { ReactNode, useContext, useMemo } from "react";
import { useCallback, useState } from "react";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ImportFromLabel, ManualTransactionLabel } from "../../utils/labels";
import { ExchangesOverview } from "./ExchangesOverview";
import { Instructions } from "./Instructions";
import { WalletsOverview } from "./WalletsOverview";
import { GetAuthenticationDataContext } from "../../App";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}
export function ImportBody(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");
  const authenticationData = useContext(GetAuthenticationDataContext);

  const specialFeatures = useMemo(() => {
    return authenticationData?.claims ?? [];
  }, [authenticationData]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>();
  const [selectedPlatformId, setSelectedPlatformId] = useState<string>();
  const [selectedPlatformType, setSelectedPlatformType] = useState<string>();
  const [drawerTitle, setDrawerTitle] = useState<string>();
  const [drawerLogo, setDrawerLogo] = useState<ReactNode | undefined>();

  const csvDrawerTitle = "Import CSV";
  const manualDrawerTitle =
    ManualTransactionLabel[props.applicationSettings.locale];

  const drawerTitles = {
    csv: csvDrawerTitle,
    manual: manualDrawerTitle,
    penning: "Penning import",
    "bitcoin-suisse": "Bitcoin Suisse import",
  };

  const handlePlatformClick = useCallback(
    (
      type: string,
      platformName: string,
      platformId: string,
      manualType?: "manual" | "csv" | SpecialCustomer
    ) => {
      setSelectedPlatformType(type);
      setSelectedPlatform(platformName);
      setSelectedPlatformId(platformId);
      setDrawerOpen(true);
      const titlePrefix = ImportFromLabel[props.applicationSettings.locale];
      setDrawerTitle(titlePrefix);
      if (manualType) {
        setDrawerTitle(
          drawerTitles[manualType as "manual" | "csv" | SpecialCustomer]
        );
        setDrawerLogo(
          <Avatar
            size={compact ? "md" : "xl"}
            radius="xs"
            src={`/assets/other/${manualType}.png`}
          />
        );
      } else {
        setDrawerLogo(
          <div
            className="drawer-logo-container"
            style={{
              backgroundImage: `url(/assets/${type}/${platformName}/normal/${platformName.toLowerCase()}-${
                props.applicationSettings.theme
              }.svg)`,
              height: `2rem`,
              width: `5rem`,
            }}
          />
        );
      }
    },
    [
      setDrawerOpen,
      setSelectedPlatform,
      setSelectedPlatformId,
      setSelectedPlatformType,
      setDrawerTitle,
      props.applicationSettings,
      csvDrawerTitle,
      manualDrawerTitle,
    ]
  );

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      className="import-body-container"
    >
      <div>
        <Space h={compact ? "xs" : "md"} />
        <label className="sub-header">Exchanges</label>
        <ExchangesOverview
          handlePlatformClick={handlePlatformClick}
          handleLogout={props.handleLogout}
          applicationSettings={props.applicationSettings}
        />
      </div>
      <Divider size={"xs"} />
      <div>
        <Space h={compact ? "xs" : "md"} />
        <label className="sub-header">Wallets</label>
        <WalletsOverview
          handlePlatformClick={handlePlatformClick}
          handleLogout={props.handleLogout}
          applicationSettings={props.applicationSettings}
        />
      </div>
      <div>
        <Divider size={"xs"} />
        <Space h={compact ? "xs" : "md"} />
        <label className="sub-header">Other</label>
        <div className="import-selection-container">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.15 }}
            whileHover={{ scale: 1.1 }}
            className="manual-import-container"
            onClick={() => {
              handlePlatformClick("manual", "csv", "csv", "csv");
            }}
          >
            <Avatar
              size={compact ? "md" : "xl"}
              radius="xs"
              src="/assets/other/csv.png"
            />
            <label>Upload CSV</label>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.15 }}
            whileHover={{ scale: 1.1 }}
            className="manual-import-container"
            onClick={() => {
              handlePlatformClick("manual", "manual", "manual", "manual");
            }}
          >
            <Avatar
              size={compact ? "md" : "xl"}
              radius="xs"
              src="/assets/other/manual.png"
            />
            <label>
              {ManualTransactionLabel[props.applicationSettings.locale]}
            </label>
          </motion.div>

        {/*   {specialFeatures.includes("penning") ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.15 }}
              whileHover={{ scale: 1.1 }}
              className="manual-import-container"
              onClick={() => {
                handlePlatformClick("manual", "penning", "penning", "penning");
              }}
            >
              <Avatar
                size={compact ? "md" : "xl"}
                radius="xs"
                src="/assets/other/penning.png"
              />
              <label>Penning</label>
            </motion.div>
          ) : undefined} */}

          {specialFeatures.includes("bitcoin-suisse") ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.15 }}
              whileHover={{ scale: 1.1 }}
              className="manual-import-container"
              onClick={() => {
                handlePlatformClick(
                  "manual",
                  "bitcoin-suisse",
                  "bitcoin-suisse",
                  "bitcoin-suisse"
                );
              }}
            >
              <Avatar
                size={compact ? "md" : "xl"}
                radius="xs"
                src="/assets/other/bitcoin-suisse.png"
              />
              <label>Bitcoin Suisse</label>
            </motion.div>
          ) : undefined}
        </div>
      </div>
      <Drawer
        opened={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        title={
          <div className="drawer-title-container">
            <label>{drawerTitle}</label>
            <Space w={"xs"} />
            {drawerLogo}
          </div>
        }
        position="right"
        size={compact ? "xl" : 800}
        padding="xl"
        overlayOpacity={0.55}
        overlayBlur={3}
      >
        {selectedPlatformType && selectedPlatformId && selectedPlatform ? (
          <Instructions
            applicationSettings={props.applicationSettings}
            platformId={selectedPlatformId}
            platformName={selectedPlatform}
            platformType={selectedPlatformType}
            handleLogout={props.handleLogout}
            closeDrawer={closeDrawer}
          />
        ) : undefined}
      </Drawer>
    </motion.div>
  );
}

export type SpecialCustomer = "bitcoin-suisse" | "penning";
