import { Select, Text } from "@mantine/core";
import { useContext, useMemo } from "react";
import {
  getCurrencyDataItems,
  SelectItem,
} from "../../../../../utils/selectDataUtil";
import { CurrencyContext, CurrencyNameContext } from "../../../../../App";

interface Props {
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
  cleareable?: true;
  searchable?: true;
  transactionMode?: true;
  rightSection?: React.ReactNode;
  required?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  blacklistedCurrencyId?: string;
}
export function CurrencySelect(props: React.PropsWithChildren<Props>) {
  // use memo to prevent rerendering
  const currencies = useContext(CurrencyContext);

  const currenciesList = useMemo(() => {
    if (!currencies) return [];
    const _currenciesList = Object.values(currencies);
    if (props.blacklistedCurrencyId) {
      return _currenciesList.filter(
        (c) => c.id !== props.blacklistedCurrencyId
      );
    }
    return _currenciesList;
  }, [currencies, props.blacklistedCurrencyId]);

  const selectData = useMemo(() => {
    if (currenciesList.length === 0) return [];
    return getCurrencyDataItems(currenciesList);
  }, [currenciesList]);

  return (
    <>
      {props.transactionMode ? (
        <Select
          size={props.size ?? "sm"}
          label={
            <Text color={"dimmed"}>
              <label>{props.label}</label>
            </Text>
          }
          rightSection={props.rightSection}
          data={selectData}
          itemComponent={SelectItem}
          placeholder={props.placeholder}
          clearable={props.cleareable}
          searchable={props.searchable}
          onChange={props.onChange}
          limit={50}
        />
      ) : (
        <Select
          size={props.size ?? "sm"}
          required={props.required ?? false}
          data={selectData}
          itemComponent={SelectItem}
          placeholder={props.placeholder}
          label={props.label}
          clearable={props.cleareable}
          searchable={props.searchable}
          onChange={props.onChange}
          limit={50}
        />
      )}
    </>
  );
}

export interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}
