import { AxiosResponse } from "axios";

export const createOptions = (token: string | undefined) => {
  if (!token) {
    const session = localStorage.getItem("session");
    if (!session) return;
    token = JSON.parse(session).access_token;
  }
  const applicationSettings = localStorage.getItem("ApplicationSettings");
  let locale = "en";
  if (applicationSettings) {
    locale = JSON.parse(applicationSettings).locale;
  }
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
      "Accept-Language": locale,
    },
  };
  return options;
};

export const handleResponse = (response: AxiosResponse | CSResponse) => {
  if (response.status === 200) {
    return response.data;
  }
  switch (response.status) {
    case 400:
      throw new Error("Token is blacklisted");
    case 401:
      throw new Error("Token is expired");
    case 403:
      throw new Error("Token is invalid");
    case 404:
      throw new Error("Not found");
    case 500:
      throw new Error("Internal server error");
    default:
      throw new Error("Unknown error");
  }
};

export interface CSResponse {
  status: string;
  message: string;
  data?: any;
}
