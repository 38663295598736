import axios, { AxiosError } from "axios";

export const uploadFileToBlobStorage = async (
  file: File,
  sasUri: string,
  token: string
): Promise<boolean> => {
  try {
    const response = await axios.put(sasUri, file, {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "x-ms-meta-token": token, // Adding token as metadata
      },
    });

    return response.status === 201;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      console.error("File upload failed:", axiosError.response.data);
    } else {
      console.error("Error during file upload:", axiosError.message);
    }
    return false;
  }
};

export const getCustomAPISasUploadUri = async (
  token: string,
  integration: string,
  blobName: string
): Promise<string | null> => {
  const baseUrl = process.env.REACT_APP_AZ_FUNCTION_URL;
  const sasUploadUri = process.env.REACT_APP_AZ_FUNCTION_SAS_URI_GENERATOR;
  if (!baseUrl) {
    console.error(
      "Azure Function base URL is not set in environment variables."
    );
    return null;
  }

  try {
    const response = await axios.post(
      `${baseUrl}/${sasUploadUri}`,
      { name: blobName, integration: integration }, // Send blob name in request body
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      // Handle response error
      console.error(
        "Error response from SAS URI generator:",
        axiosError.response.data
      );
    } else {
      // Handle other axios errors
      console.error("Error fetching SAS URI:", axiosError.message);
    }
    return null;
  }
};

export const processBlob = async (
  token: string,
  blobUrl: string,
  customerUri: string
): Promise<ProcessedTransactionPayload | null> => {
  const baseUrl = process.env.REACT_APP_AZ_FUNCTION_URL;
  if (!baseUrl) {
    console.error(
      "Azure Function base URL is not set in environment variables."
    );
    return null;
  }

  try {
    const response = await axios.post<ProcessedTransactionPayload>(
      `${baseUrl}/${customerUri}`,
      { blob_url: blobUrl, token: token }, // Send blob name in request body
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export interface ProcessedTransactionPayload {
  processed_transactions: ProcessedTransaction[];
}

export interface ProcessedTransaction {
  transaction_date: string;
  currency_in: string;
  currency_in_id?: number;
  amount_in: number;
  currency_out: string;
  currency_out_id?: number;
  amount_out: number;
  crypto_tx_id: string;
  fee: number;
  fee_currency: string;
  fee_currency_id?: number;
  transaction_type: string;
  special_transaction_type_id?: number;
  special_transaction_type?: string;
}
