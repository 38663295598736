import { useCallback, useContext, useState } from "react";
import {
  getStrength,
  PasswordRequirement,
  requirements,
} from "../Registration/RegistrationForm";
import {
  Space,
  Progress,
  PasswordInput,
  Button,
  Modal,
  TextInput,
} from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AuthenticationContext, TriggerNotificationContext } from "../../App";
import AuthService from "../../service/AuthService";
import {
  AccountSettingsLabel,
  ChangePasswordLabel,
  ConfirmYourNewPasswordLabel,
  OldPasswordLabel,
  PasswordRequirementLabel,
  UpdatePasswordLabel,
  YourNewPasswordLabel,
} from "../../utils/labels";
import { FiLock } from "react-icons/fi";

interface Props {
  applicationSettings: ApplicationSettings;
}

export function UserAccountContent(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);
  const triggerNotification = useContext(TriggerNotificationContext);

  const [opened, setOpened] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const strength = getStrength(newPassword);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={
        props.applicationSettings.locale === "en"
          ? requirement.en_label
          : requirement.da_label
      }
      meets={requirement.re.test(newPassword)}
    />
  ));

  const requestPasswordChange = useCallback(async () => {
    if (!authenticationData) return;
    setLoading(true);

    try {
      const response = await AuthService.changePassword(
        authenticationData.token,
        authenticationData.email,
        oldPassword,
        newPassword,
        confirmPassword
      );
      setOpened(false);
      triggerNotification(response["message"], "info");
    } finally {
      setLoading(false);
    }
  }, [
    confirmPassword,
    newPassword,
    oldPassword,
    authenticationData,
    triggerNotification,
    setLoading,
    setOpened,
  ]);

  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  return (
    <div className="user-account-content">
      <div className="content-header">
        <label>{AccountSettingsLabel[props.applicationSettings.locale]}</label>
      </div>
      <div className="account-settings-body">
        <TextInput readOnly label="Email" value={authenticationData?.email} />
        <Space h="xl" />
        <Button
          leftIcon={<FiLock />}
          onClick={() => setOpened(true)}
          className="cs-theme-button"
        >
          {ChangePasswordLabel[props.applicationSettings.locale]}
        </Button>
      </div>
      <Modal opened={opened} onClose={() => setOpened(false)}>
        <div className="password-reset-container">
          <PasswordInput
            className="input-field"
            id="password-input"
            label={OldPasswordLabel[props.applicationSettings.locale]}
            placeholder={OldPasswordLabel[props.applicationSettings.locale]}
            value={oldPassword}
            onChange={(e: any) => setOldPassword(e.currentTarget.value)}
            required
          />
          <PasswordInput
            className="input-field"
            id="password-input"
            label={YourNewPasswordLabel[props.applicationSettings.locale]}
            placeholder={YourNewPasswordLabel[props.applicationSettings.locale]}
            description={
              PasswordRequirementLabel[props.applicationSettings.locale]
            }
            value={newPassword}
            onChange={(e: any) => setNewPassword(e.currentTarget.value)}
            required
          />
          <PasswordInput
            className="input-field"
            id="password-input"
            label={
              ConfirmYourNewPasswordLabel[props.applicationSettings.locale]
            }
            placeholder={
              ConfirmYourNewPasswordLabel[props.applicationSettings.locale]
            }
            value={confirmPassword}
            onChange={(e: any) => setConfirmPassword(e.currentTarget.value)}
            required
          />
          <Space h="xl" />
          <Progress
            color={color}
            value={strength}
            size={5}
            style={{ marginBottom: 10 }}
          />
          <PasswordRequirement
            label={PasswordRequirementLabel[props.applicationSettings.locale]}
            meets={newPassword.length > 7}
          />
          {checks}
          <Button
            disabled={
              newPassword !== confirmPassword ||
              newPassword === "" ||
              oldPassword === ""
            }
            className="cs-theme-button"
            style={{ marginTop: 10 }}
            onClick={requestPasswordChange}
            loading={loading}
          >
            {UpdatePasswordLabel[props.applicationSettings.locale]}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
