import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string;

  applicationSettings: ApplicationSettings;
}

export function Kucoin(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor href="https://kucoin.com/account/api" target="_blank">
      <strong>KuCoin</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto og gå til <strong>API</strong>.
      </label>,
      <label className="instruction-label">Generér en ny API nøgle.</label>,
      <label className="instruction-label">
        Udfyld feltet <strong>API passphrase</strong> – f.eks. med ordet
        CryptoSkat.
      </label>,
      <label className="instruction-label">
        Vælg kun <strong>General</strong> som rettighed og{" "}
        <strong>No limit</strong> som IP limit.
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account and navigate to{" "}
        <strong>API</strong>.
      </label>,
      <label className="instruction-label">Generate a new API key.</label>,
      <label className="instruction-label">
        Fill out <strong>API passphrase</strong> with e.g. "CryptoTax"
      </label>,
      <label className="instruction-label">
        Choose only <strong>General</strong> as right and{" "}
        <strong>No limit</strong> as IP limit.
      </label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput
        applicationSettings={props.applicationSettings}
        platformId={props.platformId}
      />

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
