import { Divider, Space } from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function DashboardHeader(props: React.PropsWithChildren<Props>) {
  const header = "Dashboard";

  return (
    <>
      <label className="header">{header}</label>
      <Space h="lg" />
      <Divider size={"sm"} />
    </>
  );
}
