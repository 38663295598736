import "../components/Accounts/accounts.scss";
import { useDocumentTitle } from "@mantine/hooks";
import { AccountBody } from "../components/Accounts/AccountBody";
import { AccountsHeader } from "../components/Accounts/AccountsHeader";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { AccountsLabel } from "../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  handleLogout: () => Promise<void>;
}
export function AccountPage(props: React.PropsWithChildren<Props>) {
  const subtitle = AccountsLabel[props.applicationSettings.locale];
  useDocumentTitle(`CryptoSkat | ${subtitle}`);
  return (
    <div className="page-card-container">
      <AccountsHeader applicationSettings={props.applicationSettings} />
      <AccountBody
        applicationSettings={props.applicationSettings}
        handleLogout={props.handleLogout}
      />
    </div>
  );
}
