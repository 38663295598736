import { useCallback, useContext, useMemo, useState } from "react";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { LoginForm } from "./LoginForm";
import { motion } from "framer-motion";
import { MfaForm } from "./MfaForm";
import { AuthenticationContext } from "../../App";
import { useNavigate } from "react-router-dom";

interface Props {
  applicationSettings: ApplicationSettings;
  closeModal?: () => void;
}
export function LoginComponent(props: React.PropsWithChildren<Props>) {
  const navigate = useNavigate();

  const authenticationData = useContext(AuthenticationContext);
  const [mfaStep, setMfaStep] = useState(false);

  const handleSuccesfulLogin = useCallback(() => {
    setTimeout(() => {
      setMfaStep(true);
    }, 500);
  }, [setMfaStep]);

  const closeModal = useCallback(() => {
    if (props.closeModal) {
      props.closeModal();
      setMfaStep(true);
      navigate("/");
    }
  }, [props, setMfaStep, navigate]);

  useMemo(() => {
    if (authenticationData) {
      setMfaStep(!authenticationData.verified);
    }
  }, [authenticationData]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      className="login-registration-form-container"
    >
      {!mfaStep ? (
        <LoginForm
          applicationSettings={props.applicationSettings}
          handleSuccesfulLogin={handleSuccesfulLogin}
          closeModal={closeModal}
        />
      ) : (
        <MfaForm applicationSettings={props.applicationSettings} />
      )}
    </motion.div>
  );
}
