import { Text } from "@mantine/core";
import { FiArrowRight } from "react-icons/fi";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  FeeHistory,
  TransactionHistory,
} from "../../../interfaces/Report/TransactionData";
import { HistoryItem } from "./HistoryItem";

interface Props {
  applicationSettings: ApplicationSettings;
  historyItems: TransactionHistory[] | FeeHistory[] | undefined;
  exchange: string;
}
export function HistorySection(props: React.PropsWithChildren<Props>) {
  const parseToTimestamp = (date: string) => {
    const dateObjectParts = date.split(" ");
    const dateParts = dateObjectParts[0].split(".");
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const year = parseInt(dateParts[2]);
    const timeParts = dateObjectParts[1].split(":");
    const hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);
    const second = parseInt(timeParts[2]);
    return new Date(year, month, day, hour, minute, second).getTime();
  };

  const sortedHistory = props.historyItems?.sort((a, b) => {
    return (
      parseToTimestamp(a.transactionDate) - parseToTimestamp(b.transactionDate)
    );
  });

  return (
    <div className="history-items-container">
      <Text color={"dimmed"}>
        <label>
          {props.applicationSettings.locale === "en"
            ? "History: "
            : "Historik: "}
        </label>
      </Text>
      {sortedHistory?.map((historyItem, index) => (
        <div className="outer-history-item-container" key={index}>
          <FiArrowRight />
          <HistoryItem
            applicationSettings={props.applicationSettings}
            exchange={props.exchange}
            key={index}
            history={historyItem}
            parsedTimestamp={parseToTimestamp(historyItem.transactionDate)}
            showDiff={historyItem.showDiff}
          />
        </div>
      ))}
    </div>
  );
}
