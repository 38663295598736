import "./transaction-item-types.scss";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import {
  CurrencyIn,
  CurrencyOut,
} from "../../../interfaces/Report/TransactionData";
import { Tooltip, Space } from "@mantine/core";
import { CurrencyIcon } from "../CurrencyIcon";
import { PreviewAmountLabel } from "../ReportBodyComponents/PreviewAmountLabel";
import { useMediaQuery } from "@mantine/hooks";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { motion } from "framer-motion";
import { TransactionType } from "../TransactionTypeLabels/TransactionTypeLabel";
import { useContext, useMemo } from "react";
import { CurrencyContext } from "../../../App";

interface Props {
  applicationSettings: ApplicationSettings;
  currencyOut?: CurrencyOut;
  currencyIn?: CurrencyIn;
  showExtras?: boolean;
  focus?: boolean;
}
export function TradeHeader(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 1000px)");
  const currencyContext = useContext(CurrencyContext);

  const inCurrency = useMemo(() => {
    if (!props.currencyIn || !currencyContext) return undefined;
    return currencyContext[props.currencyIn.id];
  }, [currencyContext, props.currencyIn]);

  const outCurrency = useMemo(() => {
    if (!props.currencyOut || !currencyContext) return undefined;
    return currencyContext[props.currencyOut.id];
  }, [currencyContext, props.currencyOut]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.1 }}
      className="trade-type-container"
    >
      <TransactionType type={"Trade"} focus={props.focus} />
      {inCurrency &&
        outCurrency &&
        props.currencyIn &&
        props.currencyOut &&
        props.showExtras && (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="transaction-data-preview"
          >
            <Tooltip
              style={{ display: "flex", alignItems: "center" }}
              label={`${inCurrency.full_name} | + ${props.currencyIn.amount} `}
              withArrow
              position="bottom"
              transition={"fade"}
            >
              <CurrencyIcon currency={inCurrency} />
              <Space w="xs" />
              <PreviewAmountLabel amount={props.currencyIn.amount} />
            </Tooltip>
            <div>
              <FiArrowLeft
                className="arrow-transaction-in"
                size={compact ? 12 : 16}
              />
              <FiArrowRight
                className="arrow-transaction-out"
                size={compact ? 12 : 16}
              />
            </div>

            <Tooltip
              style={{ display: "flex", alignItems: "center" }}
              label={`${
                outCurrency.full_name
              } | - ${props.currencyOut.amount} `}
              withArrow
              position="bottom"
              transition={"fade"}
            >
              <CurrencyIcon currency={outCurrency} />
              <Space w="xs" />
              <PreviewAmountLabel
                withdrawal
                amount={props.currencyOut.amount}
              />
            </Tooltip>
          </motion.div>
        )}
    </motion.div>
  );
}
