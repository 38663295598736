import "./registrationform.scss";
import {
  Space,
  TextInput,
  Text,
  Box,
  Progress,
  Popover,
  PasswordInput,
  Checkbox,
  Button,
  Anchor,
  Divider,
} from "@mantine/core";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  FiArrowLeft,
  FiAtSign,
  FiCheck,
  FiUserPlus,
  FiX,
} from "react-icons/fi";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { validateEmail } from "../../utils/validation";
import RegistrationService from "../../service/RegistrationService";
import { RegistrationInput } from "../../interfaces/Registration/RegistrationInput";
import { AuthenticationResponse } from "../../interfaces/Authentication/AuthenticationResponse";
import { decodeToken } from "../../utils/decoder";
import {
  SetAuthenticationDataContext,
  TriggerNotificationContext,
} from "../../App";
import { useNavigate } from "react-router-dom";
import { MfaForm } from "../Authentication/MfaForm";
import { TOS } from "../SharedContext/TOS";
import {
  BackToLoginLabel,
  ConfirmEmailLabel,
  ConfirmPasswordLabel,
  EmailLabel,
  FirstNameLabel,
  LastNameLabel,
  PasswordLabel,
  PasswordRequirementLabel,
  PasswordsMustMatchLabel,
  RegistationSuccessLabel,
  RegisterLabel,
  RegistrationSuccessMessageLabel,
} from "../../utils/labels";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  applicationSettings: ApplicationSettings;
  closeModal?: () => void;
}
export function RegistrationForm(props: React.PropsWithChildren<Props>) {
  const setAuthenticationData = useContext(SetAuthenticationDataContext);
  const triggerNotification = useContext(TriggerNotificationContext);

  const [mfaPhase, setMfaPhase] = useState(false);
  const mobile = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [validInput, setValidInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const [popoverOpened, setPopoverOpened] = useState(false);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={
        props.applicationSettings.locale === "en"
          ? requirement.en_label
          : requirement.da_label
      }
      meets={requirement.re.test(password)}
    />
  ));

  const strength = getStrength(password);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const validateInput = useCallback(() => {
    setValidInput(
      validateEmail(email) &&
        password.length > 7 &&
        password === passwordConfirmation &&
        email === emailConfirmation &&
        agreeToTerms
    );
  }, [
    setValidInput,
    email,
    emailConfirmation,
    password,
    passwordConfirmation,
    agreeToTerms,
  ]);

  useEffect(() => {
    validateInput();
  }, [agreeToTerms, validateInput]);

  const attachToken = useCallback(
    (authenticationResponse: AuthenticationResponse) => {
      const decodedToken = decodeToken(authenticationResponse.access_token);
      setAuthenticationData({
        expiry: decodedToken.exp,
        token: authenticationResponse.access_token,
        verified: false,
        email: decodedToken.email,
        claims: decodedToken?.claims,
      });
    },
    [setAuthenticationData]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    let isSuccessful = false;
    try {
      const registrationInput: RegistrationInput = {
        email: email,
        emailConfirmation: emailConfirmation,
        password: password,
        passwordConfirmation: passwordConfirmation,
        firstName: firstName,
        lastName: lastName,
        agreeToTerms: true,
      };
      const RegistrationResponse = await RegistrationService.register(
        registrationInput
      );
      isSuccessful = RegistrationResponse.success;
      if (RegistrationResponse.success) {
        triggerNotification(
          [
            RegistationSuccessLabel[props.applicationSettings.locale],
            RegistrationSuccessMessageLabel[props.applicationSettings.locale],
          ],
          "success"
        );
      }
    } catch (error: any) {
      triggerNotification("Invalid input", "error");
    } finally {
      setLoading(false);
      if (props.closeModal) props.closeModal();
      if (isSuccessful) {
        navigate("/login", { state: { registered: true } });
      }
    }
  }, [
    email,
    password,
    firstName,
    lastName,
    emailConfirmation,
    passwordConfirmation,
    setLoading,
    navigate,
    props,
    triggerNotification,
  ]);

  return (
    <>
      {mfaPhase ? (
        <MfaForm applicationSettings={props.applicationSettings} />
      ) : (
        <div className="register-form-container">
          <div className="row-container">
            <Button
              size="xs"
              className="cs-theme-button"
              leftIcon={<FiArrowLeft />}
              radius={"xs"}
              onClick={() => navigate("/login")}
            >
              {BackToLoginLabel[props.applicationSettings.locale]}
            </Button>
          </div>
          <div className={mobile ? "mobile-row-container" : "row-container"}>
            <TextInput
              size={mobile ? "xs" : "sm"}
              className="input-field"
              id="firstName-input"
              placeholder={FirstNameLabel[props.applicationSettings.locale]}
              label={FirstNameLabel[props.applicationSettings.locale]}
              value={firstName}
              onChange={(e: any) => setFirstName(e.target.value.trim())}
              rightSection={
                <FiCheck
                  className={
                    firstName.length > 0 ? "checkmark-valid" : "checkmark"
                  }
                />
              }
              required
            />
            <Space w={"lg"} />
            <TextInput
              size={mobile ? "xs" : "sm"}
              className="input-field"
              id="lastName-input"
              placeholder={LastNameLabel[props.applicationSettings.locale]}
              label={LastNameLabel[props.applicationSettings.locale]}
              value={lastName}
              onChange={(e: any) => setLastName(e.target.value.trim())}
              rightSection={
                <FiCheck
                  className={
                    lastName.length > 0 ? "checkmark-valid" : "checkmark"
                  }
                />
              }
              required
            />
          </div>
          <div className="row-container">
            <TextInput
              size={mobile ? "xs" : "sm"}
              className="input-field"
              id="email-input"
              placeholder={EmailLabel[props.applicationSettings.locale]}
              label={EmailLabel[props.applicationSettings.locale]}
              icon={<FiAtSign size={14} />}
              value={email}
              onChange={(e: any) => setEmail(e.target.value.trim())}
              rightSection={
                <FiCheck
                  className={
                    validateEmail(email) ? "checkmark-valid" : "checkmark"
                  }
                />
              }
              required
            />
            <Space w={"lg"} />
            <TextInput
              size={mobile ? "xs" : "sm"}
              className="input-field"
              id="email-confirmation-input"
              placeholder={ConfirmEmailLabel[props.applicationSettings.locale]}
              label={ConfirmEmailLabel[props.applicationSettings.locale]}
              icon={<FiAtSign size={14} />}
              value={emailConfirmation}
              onChange={(e: any) => setEmailConfirmation(e.target.value.trim())}
              error={email !== emailConfirmation}
              rightSection={
                <FiCheck
                  className={
                    validateEmail(email) && email === emailConfirmation
                      ? "checkmark-valid"
                      : "checkmark"
                  }
                />
              }
              required
            />
          </div>

          <div className="row-container">
            <Popover
              opened={popoverOpened}
              position="bottom"
              placement="start"
              withArrow
              styles={{ popover: { width: "100%" } }}
              trapFocus={false}
              transition="pop-top-left"
              onFocusCapture={() => setPopoverOpened(true)}
              onBlurCapture={() => setPopoverOpened(false)}
              target={
                <PasswordInput
                  size={mobile ? "xs" : "sm"}
                  className="input-field"
                  id="password-input"
                  label={PasswordLabel[props.applicationSettings.locale]}
                  placeholder={PasswordLabel[props.applicationSettings.locale]}
                  description={
                    PasswordRequirementLabel[props.applicationSettings.locale]
                  }
                  value={password}
                  onChange={(e: any) => setPassword(e.currentTarget.value)}
                  required
                />
              }
            >
              <Progress
                color={color}
                value={strength}
                size={5}
                style={{ marginBottom: 10 }}
              />
              <PasswordRequirement
                label={
                  PasswordRequirementLabel[props.applicationSettings.locale]
                }
                meets={password.length > 7}
              />
              {checks}
            </Popover>
            <Space w={"lg"} />
            <PasswordInput
              size={mobile ? "xs" : "sm"}
              autoComplete="new-password"
              className="input-field"
              id="password-confirmation-input"
              label={ConfirmPasswordLabel[props.applicationSettings.locale]}
              placeholder={
                ConfirmPasswordLabel[props.applicationSettings.locale]
              }
              description={
                PasswordsMustMatchLabel[props.applicationSettings.locale]
              }
              value={passwordConfirmation}
              onChange={(e: any) =>
                setPasswordConfirmation(e.currentTarget.value)
              }
              error={password !== passwordConfirmation}
              required
            />
          </div>
          <Space h={"lg"} />
          <div className="terms-container">
            <Checkbox
              label={<TOS applicationSettings={props.applicationSettings} />}
              checked={agreeToTerms}
              onChange={(e: any) => setAgreeToTerms(e.target.checked)}
              onClick={() => setAgreeToTerms(!agreeToTerms)}
            />
          </div>
          {!mobile && <Divider orientation="horizontal" />}
          <Space h={"md"} />
          <div className="submit-button-container">
            <Button
              className="cs-theme-button"
              radius={"xs"}
              disabled={!validInput || loading}
              onClick={handleSubmit}
              leftIcon={<FiUserPlus />}
            >
              {RegisterLabel[props.applicationSettings.locale]}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export const requirements = [
  { re: /[0-9]/, en_label: "Includes number", da_label: "Indeholder tal" },
  {
    re: /[a-z]/,
    en_label: "Includes lowercase letter",
    da_label: "Indeholder små bogstaver",
  },
  {
    re: /[A-Z]/,
    en_label: "Includes uppercase letter",
    da_label: "Indeholder store bogstaver",
  },
  {
    re: /[$&+,:;=?@#|'<>.^*()%!-]/,
    en_label: "Includes special symbol",
    da_label: "Indeholder specialtegn",
  },
];

export function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? <FiCheck /> : <FiX />} <Box ml={10}>{label}</Box>
    </Text>
  );
}

export function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}
