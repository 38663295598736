import "./page-layout.scss";
import { RegistrationComponent } from "../components/Registration/RegistrationComponent";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { RegisterLabel } from "../utils/labels";
import { useDocumentTitle } from "@mantine/hooks";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function RegistrationPage(props: React.PropsWithChildren<Props>) {
  const subtitle = RegisterLabel[props.applicationSettings.locale];
  useDocumentTitle(`CryptoSkat | ${subtitle}`);
  return (
    <div className="page-card-container">
      <RegistrationComponent applicationSettings={props.applicationSettings} />
    </div>
  );
}
