interface Props {
  platformName: string;
  accountName: string;
}
export function PlatformBadgeTooltipLabel(
  props: React.PropsWithChildren<Props>
) {
  return (
    <div className="platform-badge-tooltip-label">
      <label>
        Platform: <strong>{props.platformName}</strong>
      </label>
      <label>
        Account: <strong>{props.accountName}</strong>
      </label>
    </div>
  );
}
