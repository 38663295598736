import { motion } from "framer-motion";
import { createContext, useCallback, useState } from "react";
import { UseQueryResult } from "react-query";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { AuthenticationData } from "../../interfaces/Authentication/AuthenticationData";
import { TransactionListResponse } from "../../interfaces/Report/TransactionListResponse";
import { ReportPaginationHeader } from "./ReportBodyComponents/ReportPaginationHeader";
import { ReportScrollableBody } from "./ReportBodyComponents/ReportScrollableBody";
import { useMediaQuery } from "@mantine/hooks";
import { InspectedTransactionHeader } from "./ReportBodyComponents/InspectedTransactionHeader";
import { TransactionItem } from "../../interfaces/Report/TransactionData";

interface Props {
  applicationSettings: ApplicationSettings;
  authenticationData: AuthenticationData | undefined;
  transactionsQuery: UseQueryResult<
    TransactionListResponse | undefined,
    unknown
  >;
  pageNumber: number;
  walletsWithLogos: string[] | undefined;
  exchangesWithLogos: string[] | undefined;
  handleSetPage: (_page: number) => void;
  handleLogout: () => Promise<void>;
  popTransactionTagUpdate: (transactionId: string) => void;
}
export function ReportBody(props: React.PropsWithChildren<Props>) {
  const mobile = useMediaQuery("(max-width: 768px)");
  const [inspectedTransactionItem, setInspectedTransactionItem] = useState<
    TransactionItem | undefined
  >();

  const [expandedId, setExpandedId] = useState<number>();

  const handleClicked = useCallback(
    (id: number | undefined) => {
      setExpandedId(id);
    },
    [setExpandedId]
  );

  const collapse = useCallback(() => {
    setExpandedId(undefined);
  }, [setExpandedId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
      className="report-body-container"
    >
      {mobile && inspectedTransactionItem && expandedId ? (
        <InspectedTransactionHeader
          transactionItem={inspectedTransactionItem}
          exchangesWithLogos={props.exchangesWithLogos}
          applicationSettings={props.applicationSettings}
          walletsWithLogos={props.walletsWithLogos}
          handleClicked={handleClicked}
        />
      ) : (
        <ReportPaginationHeader
          applicationSettings={props.applicationSettings}
          transactionsQuery={props.transactionsQuery}
          pageNumber={props.pageNumber}
          handleSetPage={props.handleSetPage}
          setExpandedIdUndefined={collapse}
        />
      )}

      <ReportScrollableBody
        inspectedTransactionItem={inspectedTransactionItem}
        setInspectedTransactionItem={setInspectedTransactionItem}
        walletsWithLogos={props.walletsWithLogos}
        exchangesWithLogos={props.exchangesWithLogos}
        applicationSettings={props.applicationSettings}
        transactionsQuery={props.transactionsQuery}
        handleClicked={handleClicked}
        expandedId={expandedId}
        collapse={collapse}
        handleLogout={props.handleLogout}
        mobile={mobile}
        popTransactionTagUpdate={props.popTransactionTagUpdate}
      />
    </motion.div>
  );
}
