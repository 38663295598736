import "./expanded-body-style.scss";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { useCallback, useContext, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import { stringHasValue } from "../../../utils/validation";
import { CurrencySection } from "./CurrencySection";
import { Currency } from "../../../interfaces/Import/Currency";
import { Divider, NumberInput, Space, Text } from "@mantine/core";
import { HistorySection } from "./HistorySection";
import { SalesSection } from "./SalesSection";
import { BiGasPump } from "react-icons/bi";
import {
  AmountLabel,
  CurrencyOutLabel,
  FeeCurrencyLabel,
  FeeLabel,
} from "../../../utils/labels";
import {
  SetLocalEditedTransactionItemContext,
  GetLocalEditedTransactionItemContext,
} from "./ReportRowItem";
import {
  CurrencyContext,
  CurrencyNameContext,
  ExchangeRatesContext,
} from "../../../App";
import { getExchangeRate } from "../../../utils/exchangeRateUtil";

interface Props {
  applicationSettings: ApplicationSettings;
  currencies: Currency[];
  editMode: boolean;
  exchange: string;
  inspectionMode?: boolean;
}
export function ExpandedWithdrawalBody(props: React.PropsWithChildren<Props>) {
  const currencies = useContext(CurrencyContext);
  const currencyNames = useContext(CurrencyNameContext);
  const setLocalEditedTransactionItem = useContext(
    SetLocalEditedTransactionItemContext
  );
  const localEditedTransactionItem = useContext(
    GetLocalEditedTransactionItemContext
  );

  const exchangeRates = useContext(ExchangeRatesContext);

  const exchangeRate = useMemo(() => {
    return exchangeRates
      ? getExchangeRate(
          localEditedTransactionItem?.transactionDate,
          exchangeRates
        )
      : undefined;
  }, [localEditedTransactionItem?.transactionDate, exchangeRates]);

  const [currencyOutAmount, setCurrencyOutAmount] = useState(
    localEditedTransactionItem?.currencyOut
      ? parseFloat(localEditedTransactionItem?.currencyOut.amount)
      : 0
  );
  const [feeAmount, setFeeAmount] = useState(
    localEditedTransactionItem?.fee
      ? parseFloat(localEditedTransactionItem?.fee.amount)
      : 0
  );

  const handleCurrencyOutAmountChange = useCallback(
    (value: number | undefined) => {
      if (!value && value !== 0) return;
      if (!localEditedTransactionItem) return;
      const _newCurrencyOut = localEditedTransactionItem.currencyOut;
      if (!_newCurrencyOut) return;
      _newCurrencyOut.amount = value.toString().replace(",", ".");
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        currencyOut: _newCurrencyOut,
      });
      setCurrencyOutAmount(value);
    },
    [setLocalEditedTransactionItem, localEditedTransactionItem]
  );
  const handleFeeAmountChange = useCallback(
    (value: number | undefined) => {
      if (!value && value !== 0) return;
      if (!localEditedTransactionItem) return;
      const _newFee = localEditedTransactionItem.fee;
      if (!_newFee) return;
      _newFee.amount = value.toString().replace(",", ".");
      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        fee: _newFee,
      });
      setFeeAmount(value);
    },
    [setLocalEditedTransactionItem, localEditedTransactionItem]
  );

  return (
    <div className="expanded-body-container">
      {currencies && currencyNames && (
        <>
          {localEditedTransactionItem?.currencyOut && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2, delay: 0.2 }}
              className="transaction-in-container"
            >
              <div className="transaction-left-section">
                <div className="transaction-column">
                  {!props.editMode && (
                    <motion.div
                      className="currency-out-label-container"
                      initial={{ opacity: 0.6, filter: "grayscale(20%)" }}
                      animate={{ opacity: 1, filter: "grayscale(70%)" }}
                      transition={{ duration: 0.1, delay: 0.2 }}
                    >
                      <label>
                        {CurrencyOutLabel[props.applicationSettings.locale]}
                      </label>
                    </motion.div>
                  )}
                  <div className="transaction-column">
                    <CurrencySection
                      label={CurrencyOutLabel[props.applicationSettings.locale]}
                      applicationSettings={props.applicationSettings}
                      activeTheme={props.applicationSettings.theme}
                      currencyId={localEditedTransactionItem.currencyOut.id}
                      iconSizeRem={1.5}
                      editMode={props.editMode}
                      blockToEditKey={"currencyOut"}
                    />
                  </div>
                </div>
                <div className="transaction-column">
                  <div className="transaction-in-out-row">
                    {stringHasValue(
                      localEditedTransactionItem.currencyOut?.amount
                    ) && (
                      <NumberInput
                        icon={
                          <FiArrowRight
                            className="arrow-transaction-out"
                            size={18}
                          />
                        }
                        label={
                          <Text color={"dimmed"}>
                            <label>
                              {AmountLabel[props.applicationSettings.locale]}
                            </label>
                          </Text>
                        }
                        precision={6}
                        hideControls
                        className={"transaction-input"}
                        disabled={!props.editMode}
                        value={currencyOutAmount}
                        onChange={(value) => {
                          handleCurrencyOutAmountChange(value);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Divider orientation="vertical" />
              <SalesSection
                applicationSettings={props.applicationSettings}
                salesUsd={
                  localEditedTransactionItem?.currencyOut.salesProceedInUsd
                }
                salesNc={
                  localEditedTransactionItem?.currencyOut.salesProceedInNC
                }
                gainUsd={localEditedTransactionItem?.currencyOut.usdGain}
                gainNc={localEditedTransactionItem?.currencyOut.ncGain}
                lossNc={localEditedTransactionItem?.currencyOut.ncLoss}
                lossUsd={localEditedTransactionItem?.currencyOut.usdLoss}
                disableSalesProceeds={
                  localEditedTransactionItem.currencyOut.editSalesProceed ===
                  false
                }
                editMode={props.editMode}
                blockToEditKey={"currencyOut"}
                exchangeRate={exchangeRate}
              />
              <Space w={"xs"} />
              {!props.inspectionMode &&
                localEditedTransactionItem.currencyOut.history?.length > 0 && (
                  <>
                    <Divider orientation="vertical" />
                    <Space w={"xs"} />
                    <HistorySection
                      historyItems={
                        localEditedTransactionItem.currencyOut.history
                      }
                      applicationSettings={props.applicationSettings}
                      exchange={props.exchange}
                    />
                    <Divider />
                  </>
                )}
            </motion.div>
          )}
          {props.inspectionMode &&
            localEditedTransactionItem &&
            localEditedTransactionItem?.currencyOut &&
            localEditedTransactionItem.currencyOut.history?.length > 0 && (
              <>
                <HistorySection
                  historyItems={localEditedTransactionItem.currencyOut.history}
                  applicationSettings={props.applicationSettings}
                  exchange={props.exchange}
                />
                <Divider />
              </>
            )}
          {!props.inspectionMode && <Divider />}

          {localEditedTransactionItem?.fee && (
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
              className="transaction-fee-container"
            >
              <div className="transaction-left-section">
                <div className="transaction-outer-column">
                  {!props.editMode && (
                    <motion.div
                      className="currency-fee-label-container"
                      initial={{ opacity: 0.6, filter: "grayscale(20%)" }}
                      animate={{ opacity: 1, filter: "grayscale(70%)" }}
                      transition={{ duration: 0.1, delay: 0.2 }}
                    >
                      <label>
                        {FeeLabel[props.applicationSettings.locale]}
                      </label>
                    </motion.div>
                  )}
                  <div className="transaction-column">
                    <CurrencySection
                      label={FeeCurrencyLabel[props.applicationSettings.locale]}
                      applicationSettings={props.applicationSettings}
                      activeTheme={props.applicationSettings.theme}
                      currencyId={localEditedTransactionItem.fee.id}
                      iconSizeRem={1.5}
                      editMode={props.editMode}
                      blockToEditKey={"fee"}
                    />
                  </div>
                </div>
                <div className="transaction-column">
                  <div className="transaction-in-out-row">
                    {stringHasValue(localEditedTransactionItem.fee?.amount) && (
                      <NumberInput
                        icon={
                          <BiGasPump
                            className="arrow-transaction-fee"
                            size={18}
                          />
                        }
                        label={
                          <Text color={"dimmed"}>
                            <label>
                              {AmountLabel[props.applicationSettings.locale]}
                            </label>
                          </Text>
                        }
                        precision={6}
                        hideControls
                        className={"transaction-input"}
                        disabled={!props.editMode}
                        value={feeAmount}
                        onChange={(value) => {
                          handleFeeAmountChange(value);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Divider orientation="vertical" />
              <SalesSection
                applicationSettings={props.applicationSettings}
                salesUsd={localEditedTransactionItem.fee.salesProceedInUsd}
                salesNc={localEditedTransactionItem.fee.salesProceedInNC}
                gainUsd={localEditedTransactionItem.fee.usdGain}
                gainNc={localEditedTransactionItem.fee.ncGain}
                lossNc={localEditedTransactionItem.fee.ncLoss}
                lossUsd={localEditedTransactionItem.fee.usdLoss}
                editMode={props.editMode}
                blockToEditKey={"fee"}
                exchangeRate={exchangeRate}
              />
              <Space w={"xs"} />
              {!props.inspectionMode &&
                localEditedTransactionItem.fee.history?.length > 0 && (
                  <>
                    <Divider orientation="vertical" />
                    <Space w={"xs"} />
                    <HistorySection
                      historyItems={localEditedTransactionItem.fee.history}
                      applicationSettings={props.applicationSettings}
                      exchange={props.exchange}
                    />
                  </>
                )}
            </motion.div>
          )}
          {props.inspectionMode &&
            localEditedTransactionItem &&
            localEditedTransactionItem.fee &&
            localEditedTransactionItem.fee.history?.length > 0 && (
              <>
                <HistorySection
                  historyItems={localEditedTransactionItem.fee.history}
                  applicationSettings={props.applicationSettings}
                  exchange={props.exchange}
                />
              </>
            )}
        </>
      )}
    </div>
  );
}
