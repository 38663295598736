import { FiDollarSign } from "react-icons/fi";
import { FaEuroSign, FaQuestion } from "react-icons/fa";
import { Currency } from "../../interfaces/Import/Currency";
import { getCurrencySlugUrl } from "../../utils/selectDataUtil";
import { Avatar, MantineSize } from "@mantine/core";

interface Props {
  currency: Currency | undefined;
  size?: MantineSize;
}

export function CurrencyIcon(props: React.PropsWithChildren<Props>) {
  const url = getCurrencySlugUrl(props.currency);

  const fiatIconsIds: any = {
    "270": <FiDollarSign size={18} />,
    Euro: <FaEuroSign size={18} />,
  };

  const unknownIcon = <FaQuestion size={18} />;

  return <Avatar src={url} size={props.size ?? "sm"} radius={"xl"} />;
}
