import { Group, Avatar, Text } from "@mantine/core";
import { forwardRef } from "react";
import { ItemProps } from "../components/Import/Instructions/manual/manualComponents/CurrencySelect";
import { CustomExchanges } from "../interfaces/Accounts/CustomAccounts";
import { Currency } from "../interfaces/Import/Currency";
import {
  Exchanges,
  ManualTransactionPlatform,
} from "../interfaces/Import/Exchanges";
import { Wallets } from "../interfaces/Import/Wallets";
import { capitalizeString } from "./formatter";
import { SpecialTypeIcon } from "../components/SpecialTypeIcons/SpecialTypeIcon";
import { SpecialTransactionItem } from "../interfaces/Import/SpecialTransactions";

export const getExchangeDataItems = (exchanges: Exchanges) => {
  const exchangeDataItems: any = [];
  exchanges.data.forEach((exchange) => {
    exchangeDataItems.push({
      label: capitalizeString(exchange.slug),
      value: exchange.id.toString(),
      group: "Exchanges",
      image: `/assets/exchanges/${exchange.slug.toLowerCase()}/compact/${exchange.slug.toLowerCase()}.svg`,
      key: `Exchanges-${exchange.slug}`,
    });
  });
  return exchangeDataItems;
};

export const getManualTransactionPlatformSelectItems = (
  platform: ManualTransactionPlatform[],
  group: string
) => {
  const selectItems: {
    label: string;
    value: string;
    group: string;
    key: string;
    image: any;
  }[] = [];
  platform.forEach((item) => {
    selectItems.push({
      label: capitalizeString(item.name),
      value: item.id.toString(),
      group: capitalizeString(group),
      image: `/assets/${group}/${item.name
        .toLowerCase()
        .split(" ")
        .join("")}/compact/${item.name.toLowerCase().split(" ").join("")}.svg`,
      key: `${group}-${item.name}`,
    });
  });
  return selectItems;
};

export const getWalletDataItems = (wallets: Wallets) => {
  const walletDataItems: any = [];
  wallets.data.forEach((wallet) => {
    walletDataItems.push({
      label: capitalizeString(wallet.slug),
      value: wallet.id.toString(),
      group: "Wallets",
      image: `/assets/wallets/${wallet.slug.toLowerCase()}/compact/${wallet.slug.toLowerCase()}.svg`,
      key: `Wallets-${wallet.slug}`,
    });
  });
  return walletDataItems;
};

export const getUserExchangesDataItems = (customAccounts: CustomExchanges) => {
  const customAccountsDataItems: any = [];
  customAccounts.exchanges.forEach((customExchange) => {
    customAccountsDataItems.push({
      label: capitalizeString(customExchange.name),
      value: customExchange.name,
      group: "User Exchanges",
      image: undefined,
      key: `UserExchanges-${customExchange.id}`,
    });
  });
  return customAccountsDataItems;
};

/**
 * Returns the URL for the currency's logo image.
 * If the currency's slug is not available, it uses "unknown" as the default logo name.
 *
 * @param currency - The currency object.
 * @returns The URL for the currency's logo image.
 */
export const getCurrencySlugUrl = (currency: Currency | undefined) => {
  const logoName = currency?.slug ?? "cryptoskat-unknown";
  return `https://neoton-media.azureedge.net/currency-assets/${logoName}.png`;
};

export const getCurrencyDataItems = (currencies: Currency[]) => {
  const currenciesDataItems: any = [];
  currencies.forEach((currency) => {
    const realCurrencyName = currency.full_name;
    const currencyName = realCurrencyName ?? currency.code;
    const slugUrl = getCurrencySlugUrl(currency);
    currenciesDataItems.push({
      value: currency.id,
      label: currencyName,
      code: currency.code,
      group: "Currency",
      description: currencyName?.length > 0 ? currency.code : undefined,
      image: slugUrl,
      key: `currency-${currency.id}`,
      slugexists: !slugUrl.includes("cryptoskat-unknown"),
    });
  });
  // sort by label
  currenciesDataItems.sort((a: any, b: any) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  // sort by slugexists
  currenciesDataItems.sort((a: any, b: any) => {
    if (a.slugexists && !b.slugexists) {
      return -1;
    }
    if (!a.slugexists && b.slugexists) {
      return 1;
    }
    return 0;
  });
  return currenciesDataItems;
};

export const getSpecialTypeDataItems = (
  specialTransactionTypes: SpecialTransactionItem[]
) => {
  const specialTransactionTypeDataItems: any = [];
  specialTransactionTypes.forEach((specialTransactionType) => {
    specialTransactionTypeDataItems.push({
      label: specialTransactionType.transactionName,
      value: specialTransactionType.specialTransactionTypeId.toString(),
      image: specialTransactionType.specialTransactionTypeId.toString(),
      key: `SpecialTransactionTypes-${specialTransactionType.specialTransactionTypeId}`,
    });
  });
  return specialTransactionTypeDataItems;
};

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} key={image}>
      <Group noWrap>
        <Avatar src={image} alt="currency" radius={"xs"} />
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const SpecialTypeSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} key={image}>
      <Group noWrap>
        <SpecialTypeIcon typeID={image} iconSize={20} />
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);
