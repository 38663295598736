import "dayjs/locale/en";
import "dayjs/locale/da";
import { Badge, Divider, Popover, Space, Text, Tooltip } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useClipboard, useForceUpdate, useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import {
  createContext,
  LegacyRef,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  FiChevronUp,
  FiEdit2,
  FiMoreHorizontal,
  FiRefreshCcw,
} from "react-icons/fi";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { Currency } from "../../../interfaces/Import/Currency";
import {
  FromTransactionLink,
  LinkingRuleSet,
  TransactionTypeLinkingRuleSet,
} from "../../../interfaces/Report/LinkingRuleSet";
import { TransactionItem } from "../../../interfaces/Report/TransactionData";
import {
  GetTransactionTagUpdatesContext,
  SetTransactionBodyRestoredContext,
  SetTransactionBodyUpdatesContext,
  SetTransactionTagUpdatesContext,
} from "../../../pages/ReportPage";
import { getMD5Hash } from "../../../utils/decoder";
import {
  parseTransactionDateStringToDate,
  parseTransactionDateToDatestring,
} from "../../../utils/formatter";
import {
  CopiedLabel,
  PickDateLabel,
  TransactionDateLabel,
} from "../../../utils/labels";
import { transactionTypeIdToSnakeCase } from "../../../utils/specialTransactionsTypeHelper";
import { ReportPlatformBadge } from "../ReportSharedComponents/ReportPlatformBadge";
import { ExpandedDepositBody } from "./ExpandedDepositBody";
import { ExpandedTradeBody } from "./ExpandedTradeBody";
import { ExpandedWithdrawalBody } from "./ExpandedWithdrawalBody";
import { QuickEditButtons } from "./QuickEditButtons";
import { ReportDepositContainer } from "./ReportDepositContainer";
import { ReportMultiButton } from "./ReportMultiButton";
import { ReportTradeContainer } from "./ReportTradeContainer";
import { ReportWithdrawalContainer } from "./ReportWithdrawalContainer";
import { PreviewExtras } from "./PreviewExtras";
import { ReportCancelledTransactionContainer } from "./ReportCancelledTransactionContainer";

interface Props {
  applicationSettings: ApplicationSettings;
  currencies: Currency[];
  id: number;
  transactionItem: TransactionItem;
  expanded: boolean;
  targetRef?: React.MutableRefObject<HTMLElement>;
  deleted?: boolean;
  fromTransactionId: number | undefined;
  linkingRules: LinkingRuleSet | undefined;
  fromTransactionParameters: FromTransactionLink | undefined;
  editedTransaction: TransactionItem | undefined;
  isPopup?: true;
  walletsWithLogos: string[] | undefined;
  exchangesWithLogos: string[] | undefined;
  pendingDeletion: boolean;
  allTransactionLinkingRules: TransactionTypeLinkingRuleSet;
  inspectionMode?: boolean;
  handleClicked: (id: number, ref?: LegacyRef<HTMLDivElement>) => void;
  collapse: () => void;
  markAsDeleted: (id: number) => void;
  enableLinkMode: (fromTransactionId: number) => void;
  linkToTransaction: (fromTransactionId: number) => void;
  cancelEditMode: () => void;
  refetchTransactions: () => void;
  popTransactionTagUpdate: (transactionId: string) => void;
}
export function ReportRowItem(props: React.PropsWithChildren<Props>) {
  const handleSetTransactionUpdates = useContext(
    SetTransactionBodyUpdatesContext
  );
  const handleSetTransactionTagUpdates = useContext(
    SetTransactionTagUpdatesContext
  );
  const transactionTagUpdates = useContext(GetTransactionTagUpdatesContext);
  const handleSetTransactionRestored = useContext(
    SetTransactionBodyRestoredContext
  );

  const originalTransactionsStorgeKey = "originalTransactions";

  const compact = useMediaQuery("(max-width: 968px)");
  const mobile = useMediaQuery("(max-width: 768px)");
  const showExtras = !mobile;
  const IdClipboard = useClipboard({ timeout: 800 });
  const txClipboard = useClipboard({ timeout: 800 });
  const forceUpdate = useForceUpdate();
  const now = new Date();
  const [popoverMenuOpened, setPopoverMenuOpened] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [opened, setOpened] = useState(false);
  const [transactionItem, setTransactionItem] = useState(props.transactionItem);

  const tryGetOriginalTransaction = useCallback(() => {
    const originalTransactionsRaw = localStorage.getItem(
      originalTransactionsStorgeKey
    );
    if (!originalTransactionsRaw || originalTransactionsRaw === null) return;
    const originalTransactions = JSON.parse(originalTransactionsRaw);
    const allTransactions = originalTransactions[
      "transactions"
    ] as TransactionItem[];
    const originalTransaction = allTransactions.find(
      (t) => t.id === props.transactionItem.id
    );
    return originalTransaction;
  }, [originalTransactionsStorgeKey, props.transactionItem.id]);

  const originalTransaction = tryGetOriginalTransaction();

  const [localEditedTransactionItem, setLocalEditedTransactionItem] =
    useState<TransactionItem>(props.editedTransaction ?? props.transactionItem);

  const originalTransactionDigest = getMD5Hash(
    JSON.stringify(originalTransaction)
  );

  const handleSetLocalEditedTransactionItem = useCallback(
    (_transactionItem: TransactionItem) => {
      if (!props.expanded) return;
      setLocalEditedTransactionItem(_transactionItem);
    },
    [setLocalEditedTransactionItem, props.expanded]
  );

  const resetLocalEditedTransactionItem = useCallback(() => {
    setLocalEditedTransactionItem(props.transactionItem);
  }, [setLocalEditedTransactionItem, props.transactionItem]);

  const handleExpand = useCallback(() => {
    if (props.deleted && props.deleted) return;
    props.handleClicked(transactionItem.id, ref as LegacyRef<HTMLDivElement>);
    setPopoverMenuOpened(false);
  }, [props, transactionItem, setPopoverMenuOpened]);

  const refreshView = useCallback(() => {
    props.collapse();
    setTimeout(() => {
      handleExpand();
    }, 1000);
  }, [handleExpand, props.collapse]);

  const handleTransactionRestore = useCallback(() => {
    if (!originalTransaction) return;
    setLocalEditedTransactionItem(originalTransaction);
    handleSetTransactionRestored(props.transactionItem.id);
    forceUpdate();
    if (props.expanded) {
      refreshView();
    }
  }, [
    originalTransaction,
    props.transactionItem,
    props.expanded,
    handleSetTransactionRestored,
    handleSetTransactionUpdates,
    setLocalEditedTransactionItem,
    forceUpdate,
  ]);

  const setEditModeValue = useCallback(
    (isEditMode: boolean) => {
      setEditMode(isEditMode);
    },
    [setEditMode]
  );

  const dateFragments = props.editedTransaction
    ? props.editedTransaction.transactionDate.split(" ")
    : props.transactionItem.transactionDate.split(" ");

  const handleEditClicked = useCallback(() => {
    props.handleClicked(transactionItem.id);
  }, [props, transactionItem]);

  const handleSaveChanges = useCallback(() => {
    if (!localEditedTransactionItem) return;
    handleSetTransactionUpdates(localEditedTransactionItem);
    setEditModeValue(false);
    forceUpdate();
  }, [
    localEditedTransactionItem,
    handleSetTransactionUpdates,
    setEditModeValue,
    forceUpdate,
  ]);

  const ref = useRef<HTMLDivElement>(null);

  const markAsDeleted = useCallback(() => {
    props.markAsDeleted(transactionItem.id);
    props.collapse();
  }, [props, transactionItem]);

  const localEditedTransactionDigest =
    localEditedTransactionItem &&
    getMD5Hash(JSON.stringify(localEditedTransactionItem));
  const hasChanges = originalTransactionDigest !== localEditedTransactionDigest;

  const setSpecialTransactionType = useCallback(
    (_transactionsTypeId: string | null) => {
      if (_transactionsTypeId !== localEditedTransactionItem.specialType) {
        handleSetTransactionTagUpdates(
          localEditedTransactionItem.id,
          _transactionsTypeId
        );
      } else {
        props.popTransactionTagUpdate(localEditedTransactionItem.id.toString());
      }

      setLocalEditedTransactionItem({
        ...localEditedTransactionItem,
        specialType:
          _transactionsTypeId === null
            ? _transactionsTypeId
            : parseInt(_transactionsTypeId),
      });
      forceUpdate();
    },
    [
      localEditedTransactionItem,
      handleSetTransactionTagUpdates,
      props,
      forceUpdate,
      setLocalEditedTransactionItem,
    ]
  );

  const checkIfCanBeLinkedFrom = useCallback(
    (_localSpecialType: number | null): boolean => {
      if (localEditedTransactionItem.transactionType === "trade") return false;
      const snakeCaseKey = transactionTypeIdToSnakeCase(
        _localSpecialType?.toString() ?? ""
      );
      const ruleSet =
        localEditedTransactionItem.transactionType.toLowerCase() === "deposit"
          ? props.allTransactionLinkingRules.linkFromWithdrawal
          : props.allTransactionLinkingRules.linkFromDeposit;
      const rule = ruleSet[snakeCaseKey];
      return rule !== null;
    },
    [
      localEditedTransactionItem,
      props.allTransactionLinkingRules.linkFromWithdrawal,
      props.allTransactionLinkingRules.linkFromDeposit,
    ]
  );

  const canBeLinked = checkIfCanBeLinkedFrom(
    localEditedTransactionItem.specialType
  );

  const checkIfCanBeLinkedTo = useCallback(
    (
      rules: LinkingRuleSet,
      fromTransactionLink: FromTransactionLink
    ): boolean => {
      if (hasChanges) return false;
      const snakeCaseKey = transactionTypeIdToSnakeCase(
        fromTransactionLink.specialTypeId?.toString() ?? ""
      );
      const linkingCriteria = rules[snakeCaseKey];
      if (linkingCriteria === null) return false;
      if (linkingCriteria?.amountMatch && linkingCriteria?.currencyMatch) {
        const block =
          fromTransactionLink.transactionType === "deposit"
            ? localEditedTransactionItem.currencyOut
            : localEditedTransactionItem.currencyIn;
        if (!block) return false;
        return (
          block.amount === fromTransactionLink.amount &&
          block.currency === fromTransactionLink.currencyShortName &&
          fromTransactionLink.specialTypeId === null
        );
      }
      return (
        linkingCriteria?.specialType ===
        transactionTypeIdToSnakeCase(
          localEditedTransactionItem.specialType?.toString() ?? ""
        )
      );
    },
    [localEditedTransactionItem, hasChanges]
  );

  const isLinkable =
    props.linkingRules && props.fromTransactionParameters
      ? checkIfCanBeLinkedTo(
          props.linkingRules,
          props.fromTransactionParameters
        )
      : false;

  const transactionTypePreviewContainers: any = {
    deposit: (
      <ReportDepositContainer
        innerId={transactionItem.innerId}
        enableLinkMode={props.enableLinkMode}
        applicationSettings={props.applicationSettings}
        transactionId={transactionItem.id}
        currencyIn={transactionItem.currencyIn}
        handleClicked={handleExpand}
        expanded={props.expanded}
        editMode={editMode}
        setEditModeValue={setEditModeValue}
        edited={props.editedTransaction !== undefined}
        errorInformation={transactionItem.errorInformation}
        serviceInformation={transactionItem.serviceInformation}
      />
    ),
    withdrawal: (
      <ReportWithdrawalContainer
        innerId={localEditedTransactionItem.innerId}
        enableLinkMode={props.enableLinkMode}
        applicationSettings={props.applicationSettings}
        transactionId={localEditedTransactionItem.id}
        currencyOut={localEditedTransactionItem.currencyOut}
        fee={localEditedTransactionItem.fee}
        handleClicked={handleExpand}
        expanded={props.expanded}
        setEditModeValue={setEditModeValue}
        edited={props.editedTransaction !== undefined}
        errorInformation={localEditedTransactionItem.errorInformation}
        serviceInformation={localEditedTransactionItem.serviceInformation}
      />
    ),
    canceled_transaction: (
      <ReportCancelledTransactionContainer
        innerId={localEditedTransactionItem.innerId}
        enableLinkMode={props.enableLinkMode}
        applicationSettings={props.applicationSettings}
        transactionId={localEditedTransactionItem.id}
        currencyOut={localEditedTransactionItem.currencyOut}
        fee={localEditedTransactionItem.fee}
        handleClicked={handleExpand}
        expanded={props.expanded}
        setEditModeValue={setEditModeValue}
        edited={props.editedTransaction !== undefined}
        errorInformation={localEditedTransactionItem.errorInformation}
        serviceInformation={localEditedTransactionItem.serviceInformation}
      />
    ),
    trade: (
      <ReportTradeContainer
        innerId={localEditedTransactionItem.innerId}
        enableLinkMode={props.enableLinkMode}
        applicationSettings={props.applicationSettings}
        transactionId={localEditedTransactionItem.id}
        currencyOut={localEditedTransactionItem.currencyOut}
        currencyIn={localEditedTransactionItem.currencyIn}
        fee={localEditedTransactionItem.fee}
        handleClicked={handleExpand}
        expanded={props.expanded}
        setEditModeValue={setEditModeValue}
        edited={props.editedTransaction !== undefined}
        errorInformation={localEditedTransactionItem.errorInformation}
        serviceInformation={localEditedTransactionItem.serviceInformation}
      />
    ),
  };

  const expandedBodies: any = {
    deposit: (
      <ExpandedDepositBody
        applicationSettings={props.applicationSettings}
        editMode={editMode}
        currencies={props.currencies}
        inspectionMode={props.inspectionMode}
        exchange={localEditedTransactionItem.exchange}
      />
    ),
    withdrawal: (
      <ExpandedWithdrawalBody
        applicationSettings={props.applicationSettings}
        currencies={props.currencies}
        exchange={localEditedTransactionItem.exchange}
        editMode={editMode}
        inspectionMode={props.inspectionMode}
      />
    ),
    canceled_transaction: (
      <ExpandedWithdrawalBody
        applicationSettings={props.applicationSettings}
        currencies={props.currencies}
        exchange={localEditedTransactionItem.exchange}
        inspectionMode={props.inspectionMode}
        editMode={editMode}
      />
    ),
    trade: (
      <ExpandedTradeBody
        exchange={localEditedTransactionItem.exchange}
        applicationSettings={props.applicationSettings}
        currencies={props.currencies}
        editMode={editMode}
        inspectionMode={props.inspectionMode}
      />
    ),
  };

  const getTxIdPreviewText = (txId: string) => {
    if (txId.length < 10) return txId;
    return `${txId.substring(0, 5)}...${txId.substring(
      txId.length - 5,
      txId.length
    )}`;
  };

  return (
    <SetLocalEditedTransactionItemContext.Provider
      value={handleSetLocalEditedTransactionItem}
    >
      <GetLocalEditedTransactionItemContext.Provider
        value={localEditedTransactionItem}
      >
        {!props.inspectionMode ? (
          <motion.div
            onMouseLeave={() => setOpened(false)}
            className={
              props.pendingDeletion
                ? "transaction-item-container-deleted"
                : props.expanded
                ? "transaction-item-container-expanded"
                : "transaction-item-container"
            }
            initial={{ height: 40 }}
            animate={{
              height: props.expanded ? "auto" : 40,
              minHeight: props.expanded ? 500 : undefined,
            }}
            transition={{ duration: props.isPopup ? 0 : 0.15 }}
            id={props.isPopup ? "popup-item" : "list-item"}
          >
            {props.expanded && (
              <div
                className="transaction-item-container-close-button"
                onClick={() => (!props.isPopup ? props.collapse() : undefined)}
              >
                <FiChevronUp
                  className="close-chevron"
                  size={24}
                  style={{ opacity: props.isPopup ? 0 : 1 }}
                />
                {!props.inspectionMode && props.transactionItem.txId && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                    className="transaction-hash-container"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      txClipboard.copy(props.transactionItem.txId);
                    }}
                  >
                    {txClipboard.copied && (
                      <motion.div
                        initial={{ opacity: 0, x: 20, y: 0 }}
                        animate={{ opacity: 1, x: 24, y: -20 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 0.45,
                          bounce: 0.25,
                          ease: "easeOut",
                        }}
                        className="copied-label-container"
                      >
                        <label>
                          {CopiedLabel[props.applicationSettings.locale]}
                        </label>
                      </motion.div>
                    )}
                    <Tooltip
                      label={props.transactionItem.txId}
                      position="right"
                    >
                      <label className="txn-hash-label">
                        Txn Hash:{" "}
                        <strong>
                          {getTxIdPreviewText(props.transactionItem.txId)}
                        </strong>
                      </label>
                    </Tooltip>
                  </motion.div>
                )}
              </div>
            )}
            <div
              className="transaction-item-container-upper-container"
              onClick={() => handleExpand()}
            >
              {!mobile && (
                <div className="transaction-item-container-left-section">
                  {showExtras && (
                    <Text
                      className="id-container"
                      color={"dimmed"}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        IdClipboard.copy(props.transactionItem.innerId);
                      }}
                    >
                      {IdClipboard.copied ? (
                        <label>
                          ID:
                          {props.transactionItem.innerId.toString().length >
                            3 && <br />}
                          {props.applicationSettings.locale === "en"
                            ? "Copied"
                            : "Kopieret"}
                          !
                        </label>
                      ) : (
                        <label>ID: {props.transactionItem.innerId}</label>
                      )}
                    </Text>
                  )}
                  <Popover
                    opened={opened}
                    position={"bottom"}
                    target={
                      <label
                        className="row-item-date-container"
                        onClick={() => {
                          if (props.expanded) {
                            setEditModeValue(true);
                            setOpened(!opened);
                          }
                        }}
                      >
                        <strong>{dateFragments[0]}</strong> {dateFragments[1]}
                      </label>
                    }
                  >
                    {editMode && (
                      <div className="date-edit-container">
                        <DatePicker
                          locale={props.applicationSettings.locale}
                          placeholder={
                            PickDateLabel[props.applicationSettings.locale]
                          }
                          label={
                            TransactionDateLabel[
                              props.applicationSettings.locale
                            ]
                          }
                          value={parseTransactionDateStringToDate(
                            localEditedTransactionItem.transactionDate
                          )}
                          inputFormat="DD-MM-YYYY"
                          maxDate={now}
                          onChange={(date) => {
                            const _transactionDate = date
                              ? parseTransactionDateToDatestring(
                                  date,
                                  localEditedTransactionItem.transactionDate.split(
                                    " "
                                  )[1]
                                )
                              : localEditedTransactionItem.transactionDate;
                            setLocalEditedTransactionItem({
                              ...localEditedTransactionItem,
                              transactionDate: _transactionDate,
                            });
                            forceUpdate();
                          }}
                          required
                        />
                        <TimeInput
                          withSeconds
                          label={
                            props.applicationSettings.locale === "en"
                              ? "Transaction time"
                              : "og tidspunkt"
                          }
                          format="24"
                          onChange={(time) => {
                            const dateString =
                              parseTransactionDateToDatestring(time);
                            const chunkToBeReplaced =
                              transactionItem.transactionDate.split(" ")[1];
                            transactionItem.transactionDate =
                              transactionItem.transactionDate.replace(
                                chunkToBeReplaced,
                                dateString.split(" ")[1]
                              );
                            //setTransactionItem(transactionItem);
                            forceUpdate();
                          }}
                          value={parseTransactionDateStringToDate(
                            transactionItem.transactionDate
                          )}
                          required
                        />
                      </div>
                    )}
                  </Popover>
                </div>
              )}

              <div
                className="transaction-item-container-middle-section"
                onClick={() => handleExpand()}
              >
                <ReportPlatformBadge
                  transactionDate={transactionItem.transactionDate}
                  applicationSettings={props.applicationSettings}
                  exchangesWithLogos={props.exchangesWithLogos}
                  walletsWithLogos={props.walletsWithLogos}
                  compact={compact || mobile}
                  platformName={transactionItem.exchange}
                  accountName={transactionItem.accountName}
                  expanded={props.expanded}
                  mobile={mobile}
                />
                {
                  transactionTypePreviewContainers[
                    transactionItem.transactionType
                  ]
                }
              </div>

              {showExtras && props.editedTransaction && (
                <div
                  className="edited-flag-container"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleTransactionRestore();
                  }}
                >
                  <Space w="xs" />
                  <Badge
                    className="non-hover"
                    variant="light"
                    radius={"xs"}
                    size="lg"
                    rightSection={<FiEdit2 />}
                  >
                    {props.applicationSettings.locale === "en"
                      ? "Edited"
                      : "Redigeret"}
                  </Badge>
                  <Badge
                    className="hover"
                    variant="outline"
                    radius={"xs"}
                    size="lg"
                    rightSection={<FiRefreshCcw />}
                  >
                    {props.applicationSettings.locale === "en"
                      ? "Undo"
                      : "Fortryd"}
                  </Badge>
                </div>
              )}

              {showExtras ? (
                <div
                  className="transaction-item-container-right-section"
                  onClick={() => handleExpand()}
                >
                  <PreviewExtras
                    applicationSettings={props.applicationSettings}
                    errorInformation={transactionItem.errorInformation}
                    serviceInformation={transactionItem.serviceInformation}
                    enableLinkMode={props.enableLinkMode}
                    innerId={transactionItem.innerId}
                    edited={props.editedTransaction !== undefined}
                    handleFix={(_) => {
                      props.handleClicked(transactionItem.id);
                      setEditModeValue(true);
                    }}
                  />
                  {localEditedTransactionItem.transactionType.toLowerCase() !==
                    "trade" && (
                    <QuickEditButtons
                      refetchTransactions={props.refetchTransactions}
                      setSpecialTransactionType={(
                        transactionsTypeId: string | null
                      ) => {
                        setSpecialTransactionType(transactionsTypeId);
                      }}
                      applicationSettings={props.applicationSettings}
                      innerId={localEditedTransactionItem.innerId}
                      specialTransactionType={
                        Object.keys(transactionTagUpdates ?? []).includes(
                          localEditedTransactionItem.id.toString()
                        )
                          ? transactionTagUpdates?.[
                              localEditedTransactionItem.id
                            ]
                          : localEditedTransactionItem.specialType
                      }
                      enableLinkMode={() =>
                        props.enableLinkMode(localEditedTransactionItem.innerId)
                      }
                      fromTransactionId={props.fromTransactionId}
                      disabled={
                        props.fromTransactionId ===
                        localEditedTransactionItem.innerId
                      }
                      linkToTransaction={() =>
                        props.linkToTransaction(
                          localEditedTransactionItem.innerId
                        )
                      }
                      cancelEditMode={props.cancelEditMode}
                      linkable={isLinkable}
                      transactionType={
                        localEditedTransactionItem.transactionType
                      }
                      innerLinkedTo={localEditedTransactionItem.innerLinkedTo}
                      canBeLinked={canBeLinked}
                      taggable={
                        localEditedTransactionItem.transactionType.toLowerCase() !==
                        "trade"
                      }
                    />
                  )}

                  <Space w={"sm"} />
                  {props.pendingDeletion ? (
                    <label>Deleted</label>
                  ) : (
                    <ReportMultiButton
                      applicationSettings={props.applicationSettings}
                      expanded={props.expanded}
                      handleEditClicked={handleEditClicked}
                      setEditModeValue={setEditModeValue}
                      editModeEnabled={editMode}
                      transactionId={transactionItem.id}
                      markAsDeleted={markAsDeleted}
                      deleted={props.deleted}
                      handleSaveChanges={handleSaveChanges}
                      hasChanges={hasChanges}
                      resetLocalEditedTransactionItem={
                        resetLocalEditedTransactionItem
                      }
                    />
                  )}
                </div>
              ) : (
                <div
                  className="transaction-item-container-right-section"
                  onClick={() => handleExpand()}
                >
                  <Popover
                    opened={popoverMenuOpened}
                    closeOnClickOutside={true}
                    closeOnEscape={true}
                    clickOutsideEvents={["mousedown", "touchstart"]}
                    withArrow
                    target={
                      <FiMoreHorizontal
                        onClick={(e: any) => {
                          e.stopPropagation();
                          console.log("clicked");
                          setPopoverMenuOpened(!popoverMenuOpened);
                        }}
                      />
                    }
                  >
                    <div className="compact-transactions-popover">
                      <QuickEditButtons
                        refetchTransactions={props.refetchTransactions}
                        setSpecialTransactionType={(
                          transactionsTypeId: string | null
                        ) => {
                          setSpecialTransactionType(transactionsTypeId);
                        }}
                        applicationSettings={props.applicationSettings}
                        innerId={localEditedTransactionItem.innerId}
                        specialTransactionType={
                          Object.keys(transactionTagUpdates ?? []).includes(
                            localEditedTransactionItem.id.toString()
                          )
                            ? transactionTagUpdates?.[
                                localEditedTransactionItem.id
                              ]
                            : localEditedTransactionItem.specialType
                        }
                        enableLinkMode={() =>
                          props.enableLinkMode(
                            localEditedTransactionItem.innerId
                          )
                        }
                        fromTransactionId={props.fromTransactionId}
                        disabled={
                          props.fromTransactionId ===
                          localEditedTransactionItem.innerId
                        }
                        linkToTransaction={() =>
                          props.linkToTransaction(
                            localEditedTransactionItem.innerId
                          )
                        }
                        cancelEditMode={props.cancelEditMode}
                        linkable={isLinkable}
                        transactionType={
                          localEditedTransactionItem.transactionType
                        }
                        innerLinkedTo={localEditedTransactionItem.innerLinkedTo}
                        canBeLinked={canBeLinked}
                        taggable={
                          localEditedTransactionItem.transactionType.toLowerCase() !==
                          "trade"
                        }
                      />
                      <br />
                      <ReportMultiButton
                        applicationSettings={props.applicationSettings}
                        expanded={true}
                        handleEditClicked={handleEditClicked}
                        setEditModeValue={setEditModeValue}
                        editModeEnabled={editMode}
                        transactionId={transactionItem.id}
                        markAsDeleted={markAsDeleted}
                        deleted={props.deleted}
                        handleSaveChanges={handleSaveChanges}
                        hasChanges={hasChanges}
                        resetLocalEditedTransactionItem={
                          resetLocalEditedTransactionItem
                        }
                      />
                      <br />
                      {props.editedTransaction && (
                        <div
                          className="edited-flag-container"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            handleTransactionRestore();
                          }}
                        >
                          <Space w="xs" />
                          <Badge
                            className="non-hover"
                            variant="light"
                            radius={"xs"}
                            size="lg"
                            rightSection={<FiEdit2 />}
                          >
                            {props.applicationSettings.locale === "en"
                              ? "Edited"
                              : "Redigeret"}
                          </Badge>
                          <Badge
                            className="hover"
                            variant="outline"
                            radius={"xs"}
                            size="lg"
                            rightSection={<FiRefreshCcw />}
                          >
                            {props.applicationSettings.locale === "en"
                              ? "Undo"
                              : "Fortryd"}
                          </Badge>
                        </div>
                      )}
                    </div>
                  </Popover>
                </div>
              )}
            </div>

            {props.expanded ? (
              <>
                <Divider size={"xs"} />
                <motion.div
                  className="transaction-item-container-expanded-section"
                  onClick={() => handleExpand()}
                >
                  {expandedBodies[transactionItem.transactionType]}
                </motion.div>
              </>
            ) : undefined}
          </motion.div>
        ) : (
          <div className="inspected-transaction-item-container">
            <div className="inspected-transaction-item-header-container">
              <div className="left-section">
                <Text
                  className="id-container"
                  color={"dimmed"}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    IdClipboard.copy(props.transactionItem.innerId);
                  }}
                >
                  {IdClipboard.copied ? (
                    <label>
                      ID:
                      {props.transactionItem.innerId.toString().length > 3 && (
                        <br />
                      )}
                      {props.applicationSettings.locale === "en"
                        ? "Copied"
                        : "Kopieret"}
                      !
                    </label>
                  ) : (
                    <label>ID: {props.transactionItem.innerId}</label>
                  )}
                </Text>
                <Popover
                  opened={opened}
                  position={"bottom"}
                  target={
                    <label
                      className="row-item-date-container"
                      onClick={() => {
                        if (props.expanded) {
                          setEditModeValue(true);
                          setOpened(!opened);
                        }
                      }}
                    >
                      <strong>{dateFragments[0]}</strong> {dateFragments[1]}
                    </label>
                  }
                >
                  {editMode && (
                    <div className="date-edit-container">
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        locale={props.applicationSettings.locale}
                        placeholder={
                          PickDateLabel[props.applicationSettings.locale]
                        }
                        label={
                          TransactionDateLabel[props.applicationSettings.locale]
                        }
                        value={parseTransactionDateStringToDate(
                          localEditedTransactionItem.transactionDate
                        )}
                        maxDate={now}
                        onChange={(date) => {
                          const _transactionDate = date
                            ? parseTransactionDateToDatestring(
                                date,
                                localEditedTransactionItem.transactionDate.split(
                                  " "
                                )[1]
                              )
                            : localEditedTransactionItem.transactionDate;
                          setLocalEditedTransactionItem({
                            ...localEditedTransactionItem,
                            transactionDate: _transactionDate,
                          });
                          forceUpdate();
                        }}
                        required
                      />
                      <TimeInput
                        withSeconds
                        label={
                          props.applicationSettings.locale === "en"
                            ? "Transaction time"
                            : "og tidspunkt"
                        }
                        format="24"
                        onChange={(time) => {
                          const dateString =
                            parseTransactionDateToDatestring(time);
                          const chunkToBeReplaced =
                            transactionItem.transactionDate.split(" ")[1];
                          transactionItem.transactionDate =
                            transactionItem.transactionDate.replace(
                              chunkToBeReplaced,
                              dateString.split(" ")[1]
                            );
                          forceUpdate();
                        }}
                        value={parseTransactionDateStringToDate(
                          transactionItem.transactionDate
                        )}
                        required
                      />
                    </div>
                  )}
                </Popover>
              </div>
              <div className="right-section">
                <PreviewExtras
                  applicationSettings={props.applicationSettings}
                  errorInformation={transactionItem.errorInformation}
                  serviceInformation={transactionItem.serviceInformation}
                  enableLinkMode={props.enableLinkMode}
                  innerId={transactionItem.innerId}
                  edited={props.editedTransaction !== undefined}
                  handleFix={(_) => {
                    props.handleClicked(transactionItem.id);
                    setEditModeValue(true);
                  }}
                />
                <QuickEditButtons
                  refetchTransactions={props.refetchTransactions}
                  setSpecialTransactionType={(
                    transactionsTypeId: string | null
                  ) => {
                    setSpecialTransactionType(transactionsTypeId);
                  }}
                  applicationSettings={props.applicationSettings}
                  innerId={localEditedTransactionItem.innerId}
                  specialTransactionType={
                    Object.keys(transactionTagUpdates ?? []).includes(
                      localEditedTransactionItem.id.toString()
                    )
                      ? transactionTagUpdates?.[localEditedTransactionItem.id]
                      : localEditedTransactionItem.specialType
                  }
                  enableLinkMode={() =>
                    props.enableLinkMode(localEditedTransactionItem.innerId)
                  }
                  fromTransactionId={props.fromTransactionId}
                  disabled={
                    props.fromTransactionId ===
                    localEditedTransactionItem.innerId
                  }
                  linkToTransaction={() =>
                    props.linkToTransaction(localEditedTransactionItem.innerId)
                  }
                  cancelEditMode={props.cancelEditMode}
                  linkable={isLinkable}
                  transactionType={localEditedTransactionItem.transactionType}
                  innerLinkedTo={localEditedTransactionItem.innerLinkedTo}
                  canBeLinked={canBeLinked}
                  taggable={
                    localEditedTransactionItem.transactionType.toLowerCase() !==
                    "trade"
                  }
                />
              </div>
              <Space w={"xs"} />
              <ReportMultiButton
                applicationSettings={props.applicationSettings}
                expanded={props.expanded}
                handleEditClicked={handleEditClicked}
                setEditModeValue={setEditModeValue}
                editModeEnabled={editMode}
                transactionId={transactionItem.id}
                markAsDeleted={markAsDeleted}
                deleted={props.deleted}
                handleSaveChanges={handleSaveChanges}
                hasChanges={hasChanges}
                resetLocalEditedTransactionItem={
                  resetLocalEditedTransactionItem
                }
              />
            </div>
            <div className="inspected-transaction-item-body-container">
              {expandedBodies[transactionItem.transactionType]}
            </div>
          </div>
        )}
      </GetLocalEditedTransactionItemContext.Provider>
    </SetLocalEditedTransactionItemContext.Provider>
  );
}

export const SetLocalEditedTransactionItemContext = createContext<
  (transaction: TransactionItem) => void
>(() => {});

export const GetLocalEditedTransactionItemContext = createContext<
  TransactionItem | undefined
>(undefined);
