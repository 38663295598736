import { Divider, Space } from "@mantine/core";
import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { ImportTransactionsLabel } from "../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
}
export function ImportHeader(props: React.PropsWithChildren<Props>) {
  const header = ImportTransactionsLabel[props.applicationSettings.locale];

  return (
    <>
      <label className="header">{header}</label>
      <Space h="lg" />
      <Divider size={"sm"} />
    </>
  );
}
