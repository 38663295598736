export const lightTheme = {
  body: "#F5F5F5",
  text: "#020912",
  menuText: "#020912",
  menuBackground: "#f2f2f2",
  overlayBackground: "rgba(242, 242, 242, .7)",
  menuBorder: "rgba(2, 9, 18, .1)",
  menuBorderSubtle: "rgba(242, 242, 242, .2)",
  cardBackground: "rgba(242, 242, 242, .4)",
  backgroundImageTint: "rgba(242, 242, 242, .8)",
  backgroundImageColor: "rgba(242, 242, 242, 1)",
  inputBackgroundColor: "#f2f2f2",
  popoverBackgroundColor: "#EBEBEB",
  selectInputBackgroundColor: "#FFFCFC",
  selectInputhover: "#F0F0F0",
  linkModeActive: "#7a86d1",
  submitButton: "#043577",
  dashboardButton: "#043577",
  dashboardButtonHover: "#25262b",
  dashboardButtonText: "#F5F5F5",
  dropzoneButton: "#25262b",
  dropzoneButtonHover: "#ffdd59",
  textButtonHover: "#043577",
  activeLink: "#043577",
  dropzoneButtonText: "#F5F5F5",
  transactionInBackgroundColor: "rgba(123, 216, 143, .2)",
  transactionOutBackgroundColor: "rgba(252, 76, 76, .2)",
  transactionBackgroundColor: "#dbdbdb",
  buttonSelected: "#040911",
  transactionIn: "#49b762",
  transactionInBackground: "rgba(73, 183, 98, .2)",
  transactionOut: "#F45B69",
  transactionFee: "#A364D9 ",
  transactionOutBackground: "rgba(244, 91, 105, .2)",
  csLogoColor1: "#043577",
  csLogoColor2: "#fedd58",
  discountColor: "#28997e",
  csThemeAccent1: "#043577",
  csThemeAccent2: "#fedd58",
  csThemeAccent1Alt: "rgb(4, 53, 119, .8)",
  depositTypeColor: "#49b762",
  tradeTypeColor: "#2E86AB",
  withdrawalTypeColor: "#c14753",
  commonInsetShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
};

export const darkTheme = {
  body: "#020912",
  text: "#F5F5F5",
  menuText: "#ffdd59",
  menuBackground: "#030d1c",
  overlayBackground: "rgba(3, 13, 28, .6)",
  menuBorder: "rgba(242, 242, 242, .2)",
  menuBorderSubtle: "#0a111d",
  cardBackground: "rgba(3, 13, 28, .6)",
  backgroundImageTint: "rgba(3, 13, 28, .8)",
  backgroundImageColor: "rgba(3, 13, 28, 1)",
  inputBackgroundColor: "#0B2132",
  popoverBackgroundColor: "#030d1c",
  selectInputBackgroundColor: "#041328",
  selectInputhover: "#0C264E",
  linkModeActive: "#0C264E",
  submitButton: "#0B2132",
  dashboardButton: "#0B2132",
  dashboardButtonHover: "#0C264E",
  dashboardButtonText: "#F5F5F5",
  dropzoneButton: "#0B2132",
  dropzoneButtonHover: "#ffdd59",
  textButtonHover: "#ffdd59",
  activeLink: "#F0F0F0",
  dropzoneButtonText: "#b6b8bb",
  transactionInBackgroundColor: "rgba(123, 216, 143, .07)",
  transactionOutBackgroundColor: "rgba(252, 76, 76, .09)",
  transactionBackgroundColor: "#010915",
  buttonSelected: "#beaf63",
  transactionIn: "#44FFD2",
  transactionInBackground: "rgba(68, 255, 210, .2)",
  transactionOut: "#F45B69",
  transactionFee: "#A364D9 ",
  transactionOutBackground: "rgba(244, 91, 105, .2)",
  csLogoColor1: "#043577",
  csLogoColor2: "#fedd58",
  discountColor: "#44FFD2",
  csThemeAccent1: "#fedd58",
  csThemeAccent2: "#043577",
  csThemeAccent1Alt: "rgb(254, 221, 88, .6)",
  depositTypeColor: "rgb(132, 226, 116, .5)",
  tradeTypeColor: "rgb(46, 134, 171, .5)",
  withdrawalTypeColor: "rgb(193, 71, 83, .5)",
  commonInsetShadow: "rgba(0, 10, 43, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"

};
