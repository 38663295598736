import { ApplicationSettings } from "../../interfaces/ApplicationSettings";
import { useContext } from "react";
import { AuthenticationContext } from "../../App";
import { Account } from "../../interfaces/Accounts/AccountsList";
import { AccountsTable } from "./AccountsTable/AccountsTable";

interface Props {
  applicationSettings: ApplicationSettings;
  accounts: Account[] | undefined;
  tariffInactive: boolean;
  handleLogout: () => Promise<void>;
  triggerAccountRefetch: () => void;
}
export function AccountsOverview(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);

  return (
    <>
      {props.accounts && (
        <AccountsTable
          tariffInactive={props.tariffInactive}
          accounts={props.accounts}
          applicationSettings={props.applicationSettings}
          authenticationData={authenticationData}
          triggerAccountRefetch={props.triggerAccountRefetch}
        />
      )}
    </>
  );
}
