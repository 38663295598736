import "./dropzone-button.scss";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { convertFileSizeToReadable } from "../../../utils/formatter";
import { FiUpload, FiUploadCloud, FiX } from "react-icons/fi";
import { Space } from "@mantine/core";
import {
  DropFileHereLabel,
  NotACSVFileLabel,
  RemoveLabel,
  UploadLabel,
} from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  onSuccesfulDrop: (file: any) => void;
  onClick: () => void;
  buttonDisabled?: boolean;
  acceptedFileTypes?: string[];
  customUploadText?: string;
}

export function UploadDropzoneButton(props: React.PropsWithChildren<Props>) {
  const [uploadFileName, setUploadFileName] = useState<string>();
  const [uploadFileSize, setUploadFileSize] = useState<number>();
  const [error, setError] = useState<string>();
  const [file, setFile] = useState<any>();

  const defaultCenterText = `${uploadFileName}, ${convertFileSizeToReadable(
    uploadFileSize!
  )}`;

  const [centerText, setCenterText] = useState(defaultCenterText);
  const acceptedFileTypes = props.acceptedFileTypes || ["text/csv"];

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      if (acceptedFiles.length < 0) return;
      const file = acceptedFiles[0];
      if (!acceptedFileTypes.includes(file["type"])) {
        setError(NotACSVFileLabel[props.applicationSettings.locale]);
        setFile(file);
        return;
      }
      setError(undefined);
      setUploadFileName(file["name"]);
      setUploadFileSize(file["size"]);
      setFile(file);
      props.onSuccesfulDrop(file);
      setCenterText(
        `${file["name"]} | ${convertFileSizeToReadable(file["size"])}`
      );
    },
    [
      setError,
      setUploadFileName,
      setUploadFileSize,
      setFile,
      props,
      setCenterText,
      acceptedFileTypes,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleXClick = useCallback(() => {
    setFile(undefined);
    setUploadFileName(undefined);
  }, []);

  const crossHoverText = RemoveLabel[props.applicationSettings.locale];
  const uploadHoverText = UploadLabel[props.applicationSettings.locale];

  const buttonDisabled =
    props.buttonDisabled || file === undefined || error !== undefined;

  return (
    <>
      {file ? (
        <div className="dropzone-button-container-filled">
          <div
            className="left-section"
            onClick={handleXClick}
            onMouseEnter={() => setCenterText(crossHoverText)}
            onMouseLeave={() => setCenterText(defaultCenterText)}
          >
            <FiX size={22} />
          </div>
          <div className="center-section">
            <label>{error ? error : centerText}</label>
          </div>
          <div
            className={
              buttonDisabled ? "right-section-disabled" : "right-section"
            }
            onMouseEnter={() => {
              if (buttonDisabled) setCenterText(uploadHoverText);
            }}
            onMouseLeave={() => setCenterText(defaultCenterText)}
            onClick={buttonDisabled ? undefined : props.onClick}
          >
            <FiUploadCloud size={22} />
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className="dropzone-button-container">
          <input {...getInputProps()} accept="text/csv" multiple={false} />
          {isDragActive ? (
            <label>{DropFileHereLabel[props.applicationSettings.locale]}</label>
          ) : uploadFileName && uploadFileName.length > 0 ? (
            <label>{error ? error : centerText}</label>
          ) : (
            <div className="upload-zone-input">
              <FiUpload />
              <Space w="xs" />
              <label>{props.customUploadText ?? "Upload CSV"}</label>
            </div>
          )}
        </div>
      )}
    </>
  );
}
