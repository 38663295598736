import CountUp from "react-countup";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { MissingLabel, ReportedAsLabel } from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  dkkValue: number | undefined;
}
export function ExchangeRateDescription(props: React.PropsWithChildren<Props>) {
  return (
    <div className="exchange-rate-description-container">
      {props.dkkValue ? (
        <>
          <label>{ReportedAsLabel[props.applicationSettings.locale]}:</label>
          <CountUp
            end={props.dkkValue}
            start={0}
            duration={0.7}
            separator="."
            decimal=","
            suffix=" DKK"
            decimals={2}
            preserveValue
          />
        </>
      ) : (
        <label>{MissingLabel[props.applicationSettings.locale]}</label>
      )}
    </div>
  );
}
