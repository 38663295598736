const specialCustomers = ["Bitcoin Suisse", "Penning"];

const lowercaseSpecialCustomersMap: { [key: string]: string } = {
  "bitcoin suisse": "Bitcoin Suisse",
  penning: "Penning",
};

const defaultAccountName: { [key: string]: string } = {
  "bitcoin-suisse": "My Bitcoin Suisse Account",
  penning: "My Penning Account",
};

const defaultExchangeName: { [key: string]: string } = {
  "bitcoin-suisse": "Bitcoin Suisse",
  penning: "Penning",
};

export const getExchangeName = (customer: string) => {
  return defaultExchangeName[customer];
}

export const getSpecialCustomers = () => {
  return specialCustomers;
};

export const getSpecialCustomerFromLowercase = (customer: string) => {
  return lowercaseSpecialCustomersMap[customer.toLowerCase()];
};

export const getSpecialCustomersLowercase = () => {
  return Object.keys(lowercaseSpecialCustomersMap);
};

export const getDefaultAccountName = (customer: string) => {
  return defaultAccountName[customer];
};

export const getLowercaseSpecialCustomers = () => {
  return Object.keys(lowercaseSpecialCustomersMap);
};
