import { Anchor, Space } from "@mantine/core";
import { ApplicationSettings } from "../../../../interfaces/ApplicationSettings";
import { DefaultNotice } from "../../DefaultNotice";
import { ExchangeApiInput } from "../../ExchangeApiInput";

interface Props {
  platformId: string; 

  applicationSettings: ApplicationSettings;
}

export function FTX(props: React.PropsWithChildren<Props>) {
  const platformLink = (
    <Anchor href="https://ftx.com/profile" target="_blank">
      <strong>FTX</strong>
    </Anchor>
  );

  const instructions: any = {
    da: [
      <label className="instruction-label">
        Log ind på din {platformLink} konto og gå til <strong>Profile</strong>.
      </label>,
      <label className="instruction-label">
        Scroll ned til <strong>API Keys</strong>-sektionen.
      </label>,
      <label className="instruction-label">
        Tryk på <strong>Create Read-only API Key</strong>.
      </label>,
      <label className="instruction-label">
        Kopier din API nøglekode og dit API "hemmelige password" og udfyld det
        her på CryptoSkat.
      </label>,
    ],
    en: [
      <label className="instruction-label">
        Log in to your {platformLink} account and navigate to{" "}
        <strong>Profile</strong>.
      </label>,
      <label className="instruction-label">
        Scroll down to the <strong>API Keys</strong>-section.
      </label>,
      <label className="instruction-label">
        Click <strong>Create Read-only API Key</strong>.
      </label>,
      <label className="instruction-label">
        Copy your Key and your Secret and paste them below.
      </label>,
    ],
  };
  return (
    <div className="intructions-container">
      <ul>
        {instructions[props.applicationSettings.locale].map(
          (instruction: JSX.Element, index: number) => {
            return (
              <li key={index}>
                <label>
                  <strong>{index + 1}.</strong>
                </label>
                {instruction}
              </li>
            );
          }
        )}
      </ul>

      <ExchangeApiInput applicationSettings={props.applicationSettings} platformId={props.platformId} />

      <div className="notice-container">
        <Space h={"md"} />
        <DefaultNotice locale={props.applicationSettings.locale} />
      </div>
    </div>
  );
}
