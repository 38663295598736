import { DecodedToken } from "../interfaces/Authentication/DecodedToken";
import jwt_decode from "jwt-decode";
import MD5 from "crypto-js/md5";

export const decodeToken = (token: string): DecodedToken => {
  return jwt_decode(token) as DecodedToken;
};

export const getMD5Hash = (input: string): string => {
  return MD5(input).toString();
};
