import "./page-layout.scss";
import "./landing-page.scss";
import { ApplicationSettings } from "../interfaces/ApplicationSettings";
import { useContext, useEffect } from "react";
import { AuthenticationContext } from "../App";
import { useNavigate } from "react-router-dom";

interface Props {
  applicationSettings: ApplicationSettings;
}

export function LandingPage(props: React.PropsWithChildren<Props>) {
  const authenticationData = useContext(AuthenticationContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticationData?.token && authenticationData?.verified) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }

    return () => {};
  }, [navigate, authenticationData]);

  return (
    <div className="page-card-container">
      {/* <label className="header">Cryptoskat</label>
      <Text color={'dimmed'}>
        Automatisk beregning af profit og tab på Bitcoin og anden kryptovaluta
        til indberetning til Skattestyrelsen
      </Text>

      <LandingpageBody applicationSettings={props.applicationSettings} /> */}
    </div>
  );
}
