import "./inspected-transaction-header.scss";
import { FiX } from "react-icons/fi";
import { ReportPlatformBadge } from "../ReportSharedComponents/ReportPlatformBadge";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { TransactionItem } from "../../../interfaces/Report/TransactionData";
import { DepositHeader } from "../ReportSharedComponents/DepositHeader";
import { WithdrawalHeader } from "../ReportSharedComponents/WithdrawalHeader";
import { TradeHeader } from "../ReportSharedComponents/TradeHeader";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  handleClicked: (id: number | undefined) => void;
  applicationSettings: ApplicationSettings;
  transactionItem: TransactionItem;
  exchangesWithLogos: string[] | undefined;
  walletsWithLogos: string[] | undefined;
}

export function InspectedTransactionHeader(
  props: React.PropsWithChildren<Props>
) {
  const narrowMobile = useMediaQuery("(max-width: 520px)");

  return (
    <div className="inspected-transactions-header-container">
      <div
        className="back-button"
        onClick={() => props.handleClicked(undefined)}
      >
        <FiX size={24} />
      </div>

      {props.transactionItem.transactionType === "deposit" && (
        <DepositHeader
          applicationSettings={props.applicationSettings}
          currencyIn={props.transactionItem.currencyIn}
          showExtras
          focus={true}
        />
      )}
      {props.transactionItem.transactionType === "withdrawal" && (
        <WithdrawalHeader
          currencyOut={props.transactionItem.currencyOut}
          applicationSettings={props.applicationSettings}
          showExtras
          focus={true}
          fee={props.transactionItem.fee}
        />
      )}

      {props.transactionItem.transactionType === "trade" && (
        <TradeHeader
          currencyOut={props.transactionItem.currencyOut}
          currencyIn={props.transactionItem.currencyIn}
          applicationSettings={props.applicationSettings}
          showExtras
          focus={true}
        />
      )}
      <div className="inspected-platform-badge">
        <ReportPlatformBadge
          transactionDate={props.transactionItem.transactionDate}
          applicationSettings={props.applicationSettings}
          exchangesWithLogos={props.exchangesWithLogos}
          walletsWithLogos={props.walletsWithLogos}
          compact={narrowMobile}
          platformName={props.transactionItem.exchange}
          accountName={props.transactionItem.accountName}
          expanded={true}
          mobile={true}
          inspectedHeaderMode={true}
          hideSubtitle={narrowMobile}
          showCompactAccountName={narrowMobile}
        />
      </div>
    </div>
  );
}
