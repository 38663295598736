import { Menu, Tooltip } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import { FiLink, FiLink2, FiTag, FiX } from "react-icons/fi";
import { BiUnlink } from "react-icons/bi";
import { AllTransactionTypesContext } from "../../../App";
import { ApplicationSettings } from "../../../interfaces/ApplicationSettings";
import { SpecialTransactionItem } from "../../../interfaces/Import/SpecialTransactions";
import {
  GetTransactionLinkUpdatesContext,
  GetTransactionUnlinkedPairsContext,
  SetTransactionLinkUpdatesContext,
} from "../../../pages/ReportPage";
import { darkTheme, lightTheme } from "../../../style/Themes";
import { SpecialTypeIcon } from "../../SpecialTypeIcons/SpecialTypeIcon";
import {
  LinkedToTransactionLabel,
  LinkTransactionLabel,
  TaggedAsLabel,
  TagTransactionLabel,
  UnlinkedFromTransactionLabel,
  UpdatePendingLabel,
  UpdateYourChangesToLinkLabel,
} from "../../../utils/labels";

interface Props {
  applicationSettings: ApplicationSettings;
  fromTransactionId: number | undefined;
  disabled: boolean;
  linkable: boolean;
  transactionType: string;
  innerLinkedTo: string | undefined;
  canBeLinked: boolean;
  taggable: boolean;
  innerId: number;
  specialTransactionType: string | number | undefined;
  enableLinkMode: () => void;
  linkToTransaction: () => void;
  cancelEditMode: () => void;
  setSpecialTransactionType: (transactionTypeId: string | null) => void;
  refetchTransactions: () => void;
}

const iconSize = 20;

export function QuickEditButtons(props: React.PropsWithChildren<Props>) {
  const theme =
    props.applicationSettings.theme === "dark" ? darkTheme : lightTheme;

  const allTransactionTypes = useContext(AllTransactionTypesContext);
  const setLinkUpdates = useContext(SetTransactionLinkUpdatesContext);
  const linkUpdates = useContext(GetTransactionLinkUpdatesContext);
  const unlinkedPairs = useContext(GetTransactionUnlinkedPairsContext);

  const checkIfLocalUnlinked = useCallback(() => {
    if (!unlinkedPairs) return false;
    try {
      return unlinkedPairs.includes(props.innerId.toString());
    } catch (e) {
      console.log(e); // remove for production
      return false;
    }
  }, [unlinkedPairs, props.innerId]);

  const isLocalUnlinked = checkIfLocalUnlinked();

  const localLinkUpdates = linkUpdates
    ? Object.keys(linkUpdates)
        .concat(Object.values(linkUpdates))
        .map((item) => parseInt(item))
    : [];

  // check if the transaction id is within the local link updates
  const isLocalLinked = localLinkUpdates.includes(props.innerId);

  const [hoverLink, setHoverLink] = useState(false);
  const [tagDeleteHover, setTagDeleteHover] = useState(false);

  const handleUnlink = useCallback(
    (innerId: number) => {
      setLinkUpdates(innerId, undefined, props.innerLinkedTo);
    },
    [setLinkUpdates, props.innerLinkedTo]
  );

  const getAllSpecialTransactionTypes = () => {
    const allSpecialTransactionTypes: SpecialTransactionItem[] = [];
    allTransactionTypes?.trade.forEach((item) => {
      allSpecialTransactionTypes.push(item);
    });
    allTransactionTypes?.deposit.forEach((item) => {
      allSpecialTransactionTypes.push(item);
    });
    allTransactionTypes?.withdrawal.forEach((item) => {
      allSpecialTransactionTypes.push(item);
    });
    return allSpecialTransactionTypes;
  };

  const allSpecialTransactionTypes = getAllSpecialTransactionTypes();

  const selectedSpecialTransactionType =
    props.specialTransactionType === null
      ? null
      : allSpecialTransactionTypes.find(
          (item) =>
            item.specialTransactionTypeId ===
            props.specialTransactionType?.toString()
        );

  const getSpecialTransactionTypeNameFromTransactionTypeId = useCallback(
    (_id: number | string) => {
      // merge all special transaction types into one array
      return allSpecialTransactionTypes.find(
        (item) => item.specialTransactionTypeId === _id.toString()
      )?.transactionName;
    },
    [allSpecialTransactionTypes]
  );

  const tagTransactionButton = (
    <Menu
      style={{ visibility: !props.taggable ? "hidden" : "visible" }}
      closeOnItemClick
      onClick={(e) => {
        e.stopPropagation();
      }}
      control={
        <div
          className={"compact-button-expanded"}
          id="transaction-check-shortcut-button"
        >
          {props.specialTransactionType ? (
            <Tooltip
              className="special-transaction-type-tooltip"
              label={
                <label>
                  {TaggedAsLabel[props.applicationSettings.locale]}:{" "}
                  <strong>
                    {getSpecialTransactionTypeNameFromTransactionTypeId(
                      props.specialTransactionType
                    )}
                  </strong>
                </label>
              }
              withArrow
            >
              <SpecialTypeIcon
                iconSize={iconSize}
                theme={theme}
                typeID={props.specialTransactionType.toString()}
              />
            </Tooltip>
          ) : (
            <FiTag />
          )}
        </div>
      }
    >
      {allTransactionTypes && (
        <>
          {props.specialTransactionType &&
            props.specialTransactionType !== null && (
              <>
                <Menu.Label>Selected tag</Menu.Label>
                {selectedSpecialTransactionType && (
                  <Menu.Item
                    rightSection={
                      tagDeleteHover ? (
                        <FiX size={iconSize} />
                      ) : (
                        <SpecialTypeIcon
                          iconSize={iconSize}
                          theme={theme}
                          typeID={
                            selectedSpecialTransactionType.specialTransactionTypeId
                          }
                        />
                      )
                    }
                    className="selected-menu-item"
                    onClick={() => props.setSpecialTransactionType(null)}
                    onMouseOver={() => setTagDeleteHover(true)}
                    onMouseLeave={() => setTagDeleteHover(false)}
                  >
                    <div>
                      <label>
                        {tagDeleteHover
                          ? "Untag"
                          : selectedSpecialTransactionType.transactionName}
                      </label>
                    </div>
                  </Menu.Item>
                )}
              </>
            )}

          <Menu.Label>
            {TagTransactionLabel[props.applicationSettings.locale]}
          </Menu.Label>
          {props.transactionType === "deposit" && (
            <>
              {allTransactionTypes.deposit.map(
                (specialType: SpecialTransactionItem) => {
                  if (
                    props.specialTransactionType &&
                    props.specialTransactionType !== null &&
                    props.specialTransactionType.toString() ===
                      specialType.specialTransactionTypeId
                  )
                    return undefined;

                  return (
                    <Menu.Item
                      key={specialType.specialTransactionTypeId}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        props.setSpecialTransactionType(
                          specialType.specialTransactionTypeId
                        );
                      }}
                      rightSection={
                        <SpecialTypeIcon
                          iconSize={iconSize}
                          theme={theme}
                          typeID={specialType.specialTransactionTypeId}
                        />
                      }
                    >
                      <div className="menu-item">
                        <label>{specialType.transactionName}</label>
                      </div>
                    </Menu.Item>
                  );
                }
              )}
            </>
          )}
          {props.transactionType === "withdrawal" && (
            <>
              {allTransactionTypes.withdrawal.map(
                (specialType: SpecialTransactionItem) => {
                  return (
                    <Menu.Item
                      key={specialType.specialTransactionTypeId}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        props.setSpecialTransactionType(
                          specialType.specialTransactionTypeId
                        );
                      }}
                      rightSection={
                        <SpecialTypeIcon
                          iconSize={iconSize}
                          theme={theme}
                          typeID={specialType.specialTransactionTypeId}
                        />
                      }
                    >
                      <div className="authentication-menu-item">
                        <label>{specialType.transactionName}</label>
                      </div>
                    </Menu.Item>
                  );
                }
              )}
            </>
          )}
          {props.transactionType === "trade" && (
            <>
              {props.specialTransactionType && <>hej</>}
              {allTransactionTypes.trade.map(
                (specialType: SpecialTransactionItem) => {
                  return (
                    <Menu.Item
                      key={specialType.specialTransactionTypeId}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        props.setSpecialTransactionType(
                          specialType.specialTransactionTypeId
                        );
                      }}
                      rightSection={
                        <SpecialTypeIcon
                          iconSize={iconSize}
                          theme={theme}
                          typeID={specialType.specialTransactionTypeId}
                        />
                      }
                    >
                      <div className="authentication-menu-item">
                        <label>{specialType.transactionName}</label>
                      </div>
                    </Menu.Item>
                  );
                }
              )}
            </>
          )}
        </>
      )}
    </Menu>
  );

  return (
    <>
      {props.fromTransactionId ? (
        <div className="quick-edit-buttons-container">
          <Tooltip
            style={{ visibility: !props.canBeLinked ? "hidden" : "visible" }}
            label={
              props.disabled
                ? props.applicationSettings.locale === "da"
                  ? "Annullér link"
                  : "Cancel link"
                : props.applicationSettings.locale === "da"
                ? "Link til denne transaktion"
                : "Link to this transaction"
            }
            disabled={!props.linkable}
            transition={"fade"}
            transitionTimingFunction={"ease-in-out"}
            transitionDuration={200}
            position={"left"}
            className={"compact-button-expanded"}
            id={
              props.disabled
                ? "transaction-link-to-shortcut-button-disabled"
                : props.linkable
                ? "transaction-link-to-shortcut-button-linkable"
                : "transaction-link-to-shortcut-button"
            }
            withArrow
            onClick={(e) => {
              e.stopPropagation();
              if (props.disabled) {
                props.cancelEditMode();
                return;
              }
              if (props.linkable) {
                props.linkToTransaction();
                return;
              }
            }}
          >
            <div
              className={"compact-button-expanded"}
              id={"transaction-check-shortcut-button"}
            >
              {props.disabled ? <FiX /> : <FiLink />}
            </div>
          </Tooltip>
          {tagTransactionButton}
        </div>
      ) : (
        <>
          <div className="quick-edit-buttons-container">
            <Tooltip
              style={{
                visibility:
                  !props.canBeLinked && !props.innerLinkedTo
                    ? "hidden"
                    : "visible",
                opacity: props.innerLinkedTo ? 0.5 : 1,
              }}
              label={
                props.innerLinkedTo ? (
                  <>
                    {isLocalUnlinked && isLocalUnlinked === true ? (
                      <label>
                        {
                          UnlinkedFromTransactionLabel[
                            props.applicationSettings.locale
                          ]
                        }{" "}
                        <strong>{props.innerLinkedTo}</strong>
                        <br />
                        <label>
                          {
                            UpdateYourChangesToLinkLabel[
                              props.applicationSettings.locale
                            ]
                          }
                        </label>
                      </label>
                    ) : (
                      <>
                        <label>
                          {
                            LinkedToTransactionLabel[
                              props.applicationSettings.locale
                            ]
                          }
                          : <strong>{props.innerLinkedTo}</strong>
                        </label>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isLocalLinked ? (
                      <label>
                        {UpdatePendingLabel[props.applicationSettings.locale]}
                      </label>
                    ) : (
                      <label>
                        {LinkTransactionLabel[props.applicationSettings.locale]}
                      </label>
                    )}
                  </>
                )
              }
              id={
                props.innerLinkedTo ? "transaction-unlink-shortcut-button" : ""
              }
              className={"compact-button-expanded"}
              withArrow
              onClick={(e: any) => {
                e.stopPropagation();
                if (isLocalUnlinked && isLocalUnlinked === true)
                  return undefined;
                props.innerLinkedTo || isLocalLinked
                  ? handleUnlink(props.innerId)
                  : props.enableLinkMode();
              }}
            >
              <div
                className={"compact-button-expanded"}
                onMouseOver={(e: any) => {
                  e.stopPropagation();
                  setHoverLink(true);
                }}
                onMouseLeave={(e: any) => {
                  e.stopPropagation();
                  setHoverLink(false);
                }}
              >
                {isLocalUnlinked && isLocalUnlinked === true ? (
                  <BiUnlink color={theme.transactionOut} />
                ) : (
                  <>
                    {props.innerLinkedTo ? (
                      <FiLink2 />
                    ) : isLocalLinked ? (
                      <FiLink color={theme.textButtonHover} />
                    ) : (
                      <FiLink />
                    )}
                  </>
                )}
              </div>
            </Tooltip>
            {tagTransactionButton}
          </div>
        </>
      )}
    </>
  );
}
